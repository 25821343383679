import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { tap, map } from 'rxjs/operators';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
// import { EquipmentZonesList } from '../interfaces/equipment-service/equipment-management';
import { Utility } from '../../../../shared-main/classes/utility';
import { EquipmentZoneRequest } from '../interfaces/equipment-service/equipment-management';
// import { cacheMaxCountOfZone, cacheMaxCountOfEdge } from '../classes/rest-client-caches';
// import { EquipmentFilterOptions } from '../../../../monitoring-and-operation/equipment-list/enums/equipment-filter-options.enum';

const pathOfEquipment = `equipment/${apiVersion}/`;

@Injectable()
export class RestClientEquipmentService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-4. 機器サービス
  //  3-4. Equipment service
  /////////////////////////////////////////////////////////////////////////////
  // 3-4-1. 機器管理
  // 3-4-1. Device management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 機器登録・設定変更・削除結果確認API
   * response body: EquipmentTransaction
   *
   * @param {string} transactionId 処理ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device registration / setting change / deletion result confirmation API
   * response body: EquipmentTransaction
   *
   * @param {string} transactionId Process ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEquipmentTransaction(transactionId: string): Observable<Response> {
    const query = `?transactionId=${transactionId}`;
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEquipment}equipments/transactionResults${query}`,
    );
  }

  /**
   * 機器情報一覧取得（ゾーン/エッジ指定）API
   * response body: EquipmentZonesList
   *
   * @param {EquipmentZoneRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device information list acquisition (zone / edge specification) API
   * response body: EquipmentZonesList
   *
   * @param {EquipmentZoneRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  getEquipmentZonesEquipments(param: EquipmentZoneRequest): Observable<Response> {
    return this.getEquipments(param);
  }

  /**
   * 機器情報一覧取得（ゾーン/エッジ指定）API
   * response body: EquipmentZonesList
   *
   * @param {EquipmentZoneRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device information list acquisition (zone / edge specification) API
   * response body: EquipmentZonesList
   *
   * @param {EquipmentZoneRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  private getEquipments(param: EquipmentZoneRequest): Observable<Response> {
    const callback = () =>
      this.restClientCommonService.request(
        'post',
        `${this.endPoint}${pathOfEquipment}equipments/equipment`,
        {
          ...param,
          // filter: param.filter ? param.filter.toString() : undefined,
        },
      );
    return this.restClientCommonService.sqsRequest(callback, { dueTime: 1000, periodTime: 1000 });
  }

  /**
   * 機器情報更新
   *
   * @param [equipmentList] 機器リスト
   * @param [caches] 更新対象のキャッシュ
   */
  /**
   * Update device information
   *
   * @param [equipmentList] Equipment list
   * @param [caches] Cache to be updated
   */
  private updateEquipment(equipmentList, caches) {
    for (const equipment of equipmentList) {
      for (const key in caches) {
        const target = caches[key].equipmentList.find(
          (item) => item.equipmentId === equipment.equipmentId,
        );
        if (target) {
          Utility.deepMerge(target, equipment);
        }
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-4-3. 機種管理
  // 3-4-3. Model management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 機種マスタ取得API
   * response body: EquipmentModel
   *
   * @param {string} equipmentModelName 機種名
   * @param {string} equipmentModelVersion 機種バージョン
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Model master acquisition API
   * response body: EquipmentModel
   *
   * @param {string} equipmentModelName Model name
   * @param {string} equipmentModelVersion Model version
   * @return {Observable<Response>} status:HTTP status
   */
  getEquipmentEquipmentModels(
    equipmentModelName: string,
    equipmentModelVersion: string,
  ): Observable<Response> {
    let query = '';
    if (equipmentModelVersion) {
      query = `?equipmentModelVersion=${encodeURIComponent(equipmentModelVersion)}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEquipment}equipmentModels/${encodeURIComponent(
        equipmentModelName,
      )}${query}`,
    );
  }

  /**
   * 機種名一覧取得
   * response body: EquipmentModelName
   *
   * @param {string} topEntity トップエンティティ
   * @param {string} equipmentModelName 機種名検索文字列
   * @param {number} productCode 製品コード
   * @param {number} modelCode 機種コード
   * @param {number} horsepower 馬力
   * @param {number} capacity 容量
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get model name list
   * response body: EquipmentModelName
   *
   * @param {string} topEntity Top entity
   * @param {string} equipmentModelName Model name search string
   * @param {number} productCode Product code
   * @param {number} modelCode Model code
   * @param {number} horsepower horsepower
   * @param {number} capacity capacity
   * @return {Observable<Response>} status:HTTP status
   */
  getEquipmentEquipmentModelName(
    topEntity: string,
    equipmentModelName: string,
    productCode: number,
    modelCode: number,
    horsepower: number,
    capacity: number,
  ): Observable<Response> {
    let query = '';
    const queryArray: string[] = [];
    if (topEntity) {
      queryArray.push(`topEntity=${encodeURIComponent(topEntity)}`);
    }
    if (equipmentModelName) {
      queryArray.push(`equipmentModelName=${encodeURIComponent(equipmentModelName)}`);
    }
    if (productCode) {
      queryArray.push(`productCode=${encodeURIComponent(productCode.toString())}`);
    }
    if (modelCode) {
      queryArray.push(`modelCode=${encodeURIComponent(modelCode.toString())}`);
    }
    if (horsepower) {
      queryArray.push(`horsepower=${encodeURIComponent(horsepower.toString())}`);
    }
    if (capacity) {
      queryArray.push(`capacity=${encodeURIComponent(capacity.toString())}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEquipment}equipmentModels${query}`,
    );
  }
}
