<div *ngIf="isDisplay" class="cookie-bar" [style.padding]="cookieBarPadding">
  <span class="cookie-bar__body">
    <span class="cookie-bar__text">
      {{ multiLanguageMessageService.dictionary('sidUseCookies') }}
      <a class="cookie-bar__link" (click)="$event.stopPropagation(); onLinkClick()">
        {{ multiLanguageMessageService.dictionary('sidLearnMore') }}
      </a>
    </span>
  </span>
  <button class="cookie-bar__button" (click)="clickButton()">
    {{ multiLanguageMessageService.dictionary('sidAccept') }}
  </button>
</div>