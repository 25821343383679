import { Component, OnInit } from '@angular/core';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';

import { AgentType } from 'src/app/core/services/data-management/enums/agent-type';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { Link, MainMenuLink } from '../main/services/menu-management/interfaces';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss'],
})
export class ServiceUnavailableComponent implements OnInit {
  activeMenu: MainMenuLink;
  isUpgradePackageSite: boolean = false;
  isUpgradePackageEnergy: boolean = false;
  isUpgradePackageGeography: boolean = false;
  isUpgradeDataCollection: boolean = false;
  isUpgradeDataOutput: boolean = false;
  isUpgradeDemandControl: boolean = false;
  isUpgradeHistory: boolean = false;
  isUpgradePpdCollection: boolean = false;
  isUpgradeMailNotification: boolean = false;
  isUpgradeMailNotificationSetting: boolean = false;
  isUpgradeRefrigerantLeakNotification: boolean = false;
  isUpgradeToUsePreseasonRemoteInspection: boolean = false;
  isAgentTypePC: boolean = false;
  isAgentTypeTablet: boolean = false;
  isAgentTypeSmartPhone: boolean = false;

  /**
   * コンストラクター関数
   * @param menuManagementService メニュー管理サービス
   */
  /**
   * Constructor function
   * @param menuManagementService Menu management service
   */
  constructor(private menuManagementService: MenuManagementService) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 画面切り替えの更新にサブスクライブ
    // Listen to page navigation
    this.menuManagementService.$activeMenuStream.subscribe((activeMenu: Link) => {
      this.activeMenu = activeMenu;
      this.isUpgradePackageSite =
        this.activeMenu.id === ScreenId.EquipmentList ||
        this.activeMenu.id === ScreenId.LayoutView ||
        this.activeMenu.id === ScreenId.Schedule ||
        this.activeMenu.id === ScreenId.ScheduleExecution ||
        this.activeMenu.id === ScreenId.Interlocking ||
        this.activeMenu.id === ScreenId.ErrorNotification;
      this.isUpgradePackageEnergy =
        this.activeMenu.id === ScreenId.EnergyConsumption ||
        this.activeMenu.id === ScreenId.MultiSiteComparison ||
        this.activeMenu.id === ScreenId.OutdoorUnitComparison ||
        this.activeMenu.id === ScreenId.MeteringTrend ||
        this.activeMenu.id === ScreenId.TemperatureMonitoring ||
        this.activeMenu.id === ScreenId.OperationMonitoring ||
        this.activeMenu.id === ScreenId.OperationDataOutput ||
        this.activeMenu.id === ScreenId.PatternSettings ||
        this.activeMenu.id === ScreenId.MeterList ||
        this.activeMenu.id === ScreenId.EnergySavingSimulation;
      this.isUpgradePackageGeography = this.activeMenu.id === ScreenId.MapView;
      this.isUpgradeDataCollection =
        this.activeMenu.id === ScreenId.HourlyData || this.activeMenu.id === ScreenId.EquipmentData;
      this.isUpgradeDataOutput = this.activeMenu.id === ScreenId.DataOutput;
      this.isUpgradeDemandControl =
        this.activeMenu.id === ScreenId.DemandModeSettings ||
        this.activeMenu.id === ScreenId.DemandCurrentState ||
        this.activeMenu.id === ScreenId.DemandControlGroupSettings ||
        this.activeMenu.id === ScreenId.DemandGraph ||
        this.activeMenu.id === ScreenId.DemandTargetPowerSettings ||
        this.activeMenu.id === ScreenId.DemandControlState ||
        this.activeMenu.id === ScreenId.DemandControlSettings;
      this.isUpgradeHistory = this.activeMenu.id === ScreenId.History;
      this.isUpgradePpdCollection =
        this.activeMenu.id === ScreenId.PpdCollectionTotal ||
        this.activeMenu.id === ScreenId.PpdCollectionPeriodSetup;
      this.isUpgradeMailNotification = this.activeMenu.id === ScreenId.MailSetting;
      this.isUpgradeMailNotificationSetting =
        this.activeMenu.id === ScreenId.MailNotificationSetting;
      this.isUpgradeRefrigerantLeakNotification =
        this.activeMenu.id === ScreenId.RefrigerantLeakNotificationList;
      this.isUpgradeToUsePreseasonRemoteInspection =
        this.activeMenu.id === ScreenId.InspectionNotificationList ||
        this.activeMenu.id === ScreenId.InspectionRequest ||
        this.activeMenu.id === ScreenId.InspectionResult;

      this.isAgentTypePC =
        DataManagementService.userAgentType() === AgentType.WindowsPC ||
        DataManagementService.userAgentType() === AgentType.Other;
      this.isAgentTypeTablet =
        DataManagementService.userAgentType() === AgentType.iPad ||
        DataManagementService.userAgentType() === AgentType.AndroidTablet ||
        DataManagementService.userAgentType() === AgentType.Macintosh;
      this.isAgentTypeSmartPhone =
        DataManagementService.userAgentType() === AgentType.iPhone ||
        DataManagementService.userAgentType() === AgentType.AndroidPhone;
    });
  }
}
