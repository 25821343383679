import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';

import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';

import {
  SMARTPHONE_MAX_WIDTH,
  TABLET_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'src/app/shared-main/constants/breakpoints';
import { Screens } from 'src/app/shared-main/enums';
import {
  Link,
  MainMenuLink,
  MenuFooterLink,
  MyProfileLink,
} from 'src/app/main/main/services/menu-management/interfaces';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';

@Component({
  selector: 'app-menu',
  animations: [
    trigger('expandCollapse', [
      state('expand', style({ height: '*' })),
      state('collapse', style({ height: 0 })),
      transition('expand <=> collapse', [animate('.3s ease')]),
    ]),
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  userInfo: any;
  isMenuActive: boolean = false;
  isMenuContentsActive: boolean = false;
  mainMenuLinks: MainMenuLink[];
  menuFooterLinks: MenuFooterLink[];
  activeMenu: Link;
  screenSize: Screens;

  get isTabletMenuInactive(): boolean {
    return this.screenSize === Screens.Tablet && !this.isMenuActive;
  }
  get isTabletMenuContentsInactive(): boolean {
    return this.screenSize === Screens.Tablet && !this.isMenuContentsActive;
  }
  get disableNavigation(): boolean {
    return this.dataManagementService.person().termsOfServiceState === 0;
  }

  /**
   * コンストラクター関数
   * @param router Angularルーター
   * @param breakpointObserver ブレークポイントユーティリティ
   * @param dataManagementService データ管理サービス
   * @param menuManagementService メニュー管理サービス
   * @param multiLanguageMessageService 多言語対応サービス
   * @param toastService トーストサービス
   * @param cookieService クッキーサービス
   */
  /**
   * Constructor function
   * @param router Angular router
   * @param breakpointObserver Breakpoint observer
   * @param dataManagementService Data management service
   * @param menuManagementService Menu Management Service
   * @param multiLanguageMessageService Multilingual service
   * @param toastService toast service
   * @param cookieService cookie service
   */
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private dataManagementService: DataManagementService,
    private menuManagementService: MenuManagementService,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private toastService: ToastService,
    private cookieService: CookieService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // サイドメニューのメニュー一覧の更新にサブスクライブ
    // Listen to changes from main menu links
    this.menuManagementService.$mainMenuLinksStream.subscribe(
      (mainMenuLinks: MainMenuLink[]) => (this.mainMenuLinks = mainMenuLinks),
    );

    // メニューフッターのメニュー一覧の更新にサブスクライブ
    // Listen to changes from menu footer links
    this.menuManagementService.$menuFooterLinksStream.subscribe(
      (menuFooterLinks: MenuFooterLink[]) => (this.menuFooterLinks = menuFooterLinks),
    );

    // 画面切り替えの更新にサブスクライブ
    // Listen to changes from page navigation
    this.menuManagementService.$activeMenuStream.subscribe(
      (activeMenu: Link) => (this.activeMenu = activeMenu),
    );

    // 各ブレークポイントの更新にサブスクライブ
    // Listen to changes from each breakpoints
    this.breakpointObserver
      .observe([`(min-width: ${TABLET_MIN_WIDTH}px)`, `(max-width: ${TABLET_MAX_WIDTH}px)`])
      .subscribe((result) => {
        if (result.matches) {
          this.inactivateMenu();
          if (this.breakpointObserver.isMatched(`(max-width: ${SMARTPHONE_MAX_WIDTH}px)`)) {
            this.screenSize = Screens.SmartPhone;
          } else if (this.breakpointObserver.isMatched(`(max-width: ${TABLET_MAX_WIDTH}px)`)) {
            this.screenSize = Screens.Tablet;
          } else {
            this.screenSize = Screens.Desktop;
          }
        }
      });
  }

  /**
   * メニューをクリックされたとき、サイドメニューを開閉する（タブレットモード時）
   */
  /**
   * Open / close side menu when menu is clicked (in tablet mode)
   */
  onSideMenuClick() {
    if (this.isTabletMenuInactive) {
      this.isMenuActive = true;

      setTimeout(() => {
        this.isMenuContentsActive = true;
      }, 300);
    }
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、サイドメニューを開閉する（タブレットモード時）
   * @param event キーダウンイベント
   */
  /**
   * Open / close the side menu when the enter key or space key is pressed (in tablet mode)
   * @param event Key down event
   */
  onMenuButtonKeyDown(event: any) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onSideMenuClick();
    }
  }

  /**
   * クリックされたメニュー項目に応じて処理を実施する
   * @param menu メニュー項目
   */
  /**
   * Perform processing according to the clicked menu item
   * @param menu menu item
   */
  onMenuSelect(menu: MyProfileLink | MenuFooterLink) {
    this.inactivateMenu();

    if (!this.disableNavigation) {
      if (menu.isExternalLink) {
        // 試運転web-appへ遷移する場合、ログイントークンをセッションストレージに保存
        // Store login token in session storage when transitioning to comissioning web-app
        for (let i = 0; i < localStorage.length; i += 1) {
          const key = localStorage.key(i);
          if (
            key !== 'dictionary' &&
            key !== 'dictionaryVersion' &&
            key !== 'localeId' &&
            key !== 'dictionaryLanguage'
          ) {
            sessionStorage.setItem(key, localStorage.getItem(key));
          }
        }
        window.open(
          menu.id === ScreenId.Commissioning
            ? menu.url
            : this.multiLanguageMessageService.dictionary(menu.url),
          '_blank',
        );
        sessionStorage.clear();
      } else if (menu.id === ScreenId.EnergySavingSimulation) {
        // Cookieが承認されているか確認する
        // Check to see if cookies are approved
        if (!this.cookieService.getCookieConsentState()) {
          // Cookieが未承認の場合は、トーストを表示し、遷移しないようにする
          // If cookie is unauthorized, display toast and do not transition
          this.toastService.openToast(
            'success',
            'center',
            this.multiLanguageMessageService.dictionary('sidCookieUseConsent'),
          );
          return;
        }

        // environmentにenergySavingUrlが含まれているか確認する
        // Check if environment contains energySavingUrl
        if (environment['energySavingUrl'] !== undefined) {
          // environmentにcookieDomainが含まれているか確認する
          // Check if environment contains cookieDomain
          if (environment['cookieDomain']) {
            // ログイントークンをcookieに保存
            // Save log-in token in cookie
            for (let i = 0; i < localStorage.length; i += 1) {
              const key = localStorage.key(i);
              if (
                key.indexOf('CognitoIdentityServiceProvider') != -1 ||
                key === 'amplify-signin-with-hostedUI' ||
                key === 'lastSelectedRealBuildingId' ||
                key === 'localeId'
              ) {
                this.dataManagementService.setCookie(
                  key,
                  localStorage.getItem(key),
                  null,
                  environment['cookieDomain'],
                  '/',
                  'Lax',
                );
              }
            }
          }
          // 省エネアプリ画面へ遷移する
          // Transition to energy saving application screen
          this.router.navigate([menu.url]);
        }
      } else {
        this.router.navigate([menu.url]);
      }
    } else {
      if (menu.isExternalLink && menu.id !== ScreenId.Commissioning) {
        window.open(this.multiLanguageMessageService.dictionary(menu.url), '_blank');
      }
    }
    this.menuManagementService.$selectedMenuStream.next(menu);
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、フォーカスの当たっているメニュー項目に応じて処理を実施する
   * @param event キーダウンイベント
   * @param menu メニュー項目
   */
  /**
   * When the enter key or space key is pressed,
   * processing is performed according to the focused menu item
   * @param event Key down event
   * @param menu menu item
   */
  onMenuKeyDown(event: any, menu: Link) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onMenuSelect(menu);
    }
  }

  /**
   * サブメニューを持っているメニュー項目をクリックしたとき、サブメニューを開閉する
   * @param selectedMenu メニュー項目
   */
  /**
   * Opens / closes a submenu when clicking a menu item that has a submenu
   * @param selectedMenu menu item
   */
  onSubmenuOpen(selectedMenu: MainMenuLink) {
    this.mainMenuLinks.map(
      (menu: MainMenuLink) => (menu.expanded = selectedMenu.id === menu.id && !menu.expanded),
    );
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、フォーカスの当たっているメニュー項目のサブメニューを開閉する
   * @param event キーダウンイベント
   */
  /**
   * Opens / closes the submenu of the focused menu item when the enter key
   * or space key is pressed
   * @param event Key down event
   */
  onSubmenuKeyDown(event: any, menu: MainMenuLink) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onSubmenuOpen(menu);
    }
  }

  /**
   * サイドメニューを閉じる（タブレットモード時）
   */
  /**
   * Close side menu (in tablet mode)
   */
  inactivateMenu() {
    this.isMenuActive = false;
    this.isMenuContentsActive = false;
  }
}
