import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';

import { ControlGroupsInfoPutRequest } from '../interfaces/demand-control-service/demand-control';
// import { ApiBody } from 'src/app/demand-control/demand-target-power-settings/interfaces/api-body';
const pathOfDemandControl = `demand/${apiVersion}/`;

@Injectable()
export class RestClientDemandControlService extends RestClient {
  /**
   * Constructor function
   * @param http
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor(private http: HttpClient) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  2-2-1. デマンド制御グループ設定
  //  2-2-1. Demand control group settings
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 制御グループ情報取得API
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Control group information acquisition API
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getGroupsInfo(edgeId: string): Observable<Response> {
    // return this.http.get<any>('http://localhost:3000/controlGroup');
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDemandControl}edges/${edgeId}/controlGroup`,
    );
  }

  /**
   * 制御グループ情報更新API
   * @param {string} edgeId エッジID
   * @param {ControlGroupsInfoPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Control group information update API
   * @param {string} edgeId Edge ID
   * @param {ControlGroupsInfoPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putGroupsInfo(edgeId: string, param: ControlGroupsInfoPutRequest): Observable<Response> {
    // return this.http.put<any>(`http://localhost:3000/put`, param);
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfDemandControl}edges/${edgeId}/controlGroup`,
      param,
    );
  }

  /**
   * 強制レベル更新API
   * @param edgeId エッジID
   * @param forcedLevel 変更したい強制レベル
   */
  /**
   * Forced level update API
   * @param edgeId Edge ID
   * @param forcedLevel Force level you want to change
   */
  postForcedLevel(edgeId: string, forcedLevel: number) {
    // return this.http.put<any>(`http://localhost:3000/put_forced`, { forcedLevel });
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfDemandControl}edges/${edgeId}/forcedLevel`,
      { forcedLevel },
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // 2-4. 目標電力設定
  // 2-4. TargetPowerSetting
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 目標電力取得API
   * @param edgeId エッジID
   */
  /**
   * getTargetPowerAPI
   * @param edgeId Edge ID
   */
  getTargetPowerSetting(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDemandControl}edges/${edgeId}/targetPower`,
    );
  }

  /**
   * 目標電力更新API
   * @param edgeId エッジID
   * @param param 目標電力の更新値、更新日時
   */
  /**
   * putTargetPowerAPI
   * @param edgeId Edge ID
   * @param param New Target Power Value, Update Time
   */
  // putTargetPowerSetting(edgeId: string, param: ApiBody): Observable<Response> {
  //   return this.restClientCommonService.request(
  //     'put',
  //     `${this.endPoint}${pathOfDemandControl}edges/${edgeId}/targetPower`,
  //     param,
  //   );
  // }
}
