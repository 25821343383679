export interface AlertBarData {
  type: 'warn' | 'error';
  summary?: string;
  details?: AlertBarDetails;
}

interface AlertBarDetails {
  title?: string;
  list?: AlertBarListItem[];
}

export interface AlertBarListItem {
  detail: string;
  subDetails?: { detail: string; errorId?: string }[];
  link?: 'info' | 'download' | 'subDetails';
  errorType?: AlertBarErrorType;
  errorId?: string;
}

export enum AlertBarErrorType {
  EdgeSystemError,
  EdgeCommunicationError,
  ForcedStop,
  EquipmentCommunicationError,
  EquipmentError,
  R32RefrigerantLeakAlarm,
  UnselectedBuildingError,
}

export enum EquipmentErrorType {
  EquipmentCommunicationError,
  EquipmentError,
  R32RefrigerantLeakAlarm,
}
