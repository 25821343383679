import { Component, OnInit } from '@angular/core';

import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';
import { FooterMenuLink } from 'src/app/main/main/services/menu-management/interfaces';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent implements OnInit {
  footerMenuItems: FooterMenuLink[];
  openTermsOfUseDialog: boolean = false;
  openLicenseDialog: boolean = false;
  /**
   * コンストラクター関数
   * @param multiLanguageMessageService 多言語対応サービス
   * @param menuManagementService メニュー管理サービス
   * @param cookieService クッキーサービス
   */
  /**
   * Constructor function
   * @param multiLanguageMessageService Multilingual service
   * @param menuManagementService Menu Management Service
   * @param cookieService cookie service
   */
  constructor(
    public multiLanguageMessageService: MultiLanguageMessageService,
    private menuManagementService: MenuManagementService,
    private cookieService: CookieService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // フッターメニューのメニューの更新にサブスクライブ
    // Listen to changes from footer menu links
    this.menuManagementService.$footerMenuLinksStream.subscribe(
      (footerMenuLinks: FooterMenuLink[]) => (this.footerMenuItems = footerMenuLinks),
    );
  }

  /**
   * クリックされたメニュー項目に応じて処理を実施する
   * @param item クリックされたメニューの項目
   */
  /**
   * Processing is performed according to the clicked menu item.
   * @param item Clicked menu item
   */
  onLinkClick(item: any) {
    if (item.isExternalLink) {
      window.open(this.multiLanguageMessageService.dictionary(item.url), '_blank');
    } else {
      switch (item.id) {
        case ScreenId.CookieNotice:
          this.cookieService.showCookieDialog();
          break;
        case ScreenId.TermsOfUse:
          this.openTermsOfUseDialog = true;
          break;
        case ScreenId.License:
          this.openLicenseDialog = true;
          break;
      }
    }
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、フォーカスの当たっているメニュー項目に応じて処理を実施する
   * @param event キーダウンイベント
   * @param item メニュー項目
   */
  /**
   * When the enter key or space key is pressed,
   * processing is performed according to the focused menu item
   * @param event Key down event
   * @param item menu item
   */
  onLinkKeyDown(event: any, item: any) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.onLinkClick(item);
    }
  }
}
