import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { RestClientAuthService } from 'src/app/core/services/rest-client';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageListItem, ConfirmationEmailModel } from '../../interfaces/login-model';
import { LoginService } from '../../services/login.service';
import {
  AuthInitiateAuthResponse,
  AuthTokenAuthResponse,
} from 'src/app/core/services/rest-client/interfaces/auth-serivce';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { ErrorResponseParser } from '../../interfaces/error-response-parser';
import { ApiType } from 'src/app/core/services/toast/api-type';
import { Utility } from 'src/app/shared-main/classes/utility';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss'],
})
export class UserConfirmationComponent implements OnInit {
  confirmResetPassword: ConfirmationEmailModel = new ConfirmationEmailModel();
  formGroup: UntypedFormGroup;
  errorMessage: string = null;
  languages: LanguageListItem[] = [];
  localeId: string;
  @Output() showCode: EventEmitter<boolean> = new EventEmitter();

  get password(): AbstractControl {
    return this.formGroup.get('password');
  }

  get enableConfirmButton(): boolean {
    if (this.password.value) {
      return true;
    }
    return false;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private restClientAuthService: RestClientAuthService,
    private loginService: LoginService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    public multiLanguageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      password: [this.confirmResetPassword.password, [Validators.required]],
    });
    this.loadData();
  }

  async loadData() {
    const languageItem = await this.loginService.asyncGetLanguage(this.multiLanguageService);
    this.languages = languageItem.languages;
    this.localeId = languageItem.localeId;
    // 有効期限切れかチェック
    // Check if expired
    this.restClientAuthService
      .getAuthChangeEmail(this.loginService.changeEmailModel.hashCode)
      .subscribe(
        (ret) => {},
        (err) => {
          const response = err as Response;
          this.handleGetAuthChangeEmailError(response);
        },
      );
  }

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    if (this.enableConfirmButton) {
      this.onConfirmClick();
    }
  }

  /**
   * 認証ボタンクリック
   * ログイン認証処理
   */
  /**
   * Click authentication button
   * Login authentication process
   */
  async onConfirmClick() {
    this.errorMessage = null;
    this.loaderService.showLoader();

    // ユーザーIDの取得
    // Get user ID
    this.restClientAuthService
      .getAuthChangeEmail(this.loginService.changeEmailModel.hashCode)
      .subscribe(
        (res) => {
          const getAuthChangeEmailResponseData = res.data as AuthTokenAuthResponse;
          const userId = getAuthChangeEmailResponseData.email;

          // ログイン認証
          // Login authentication
          this.restClientAuthService.authInitiateAuth(userId, this.password.value).subscribe(
            (response) => {
              const responseData = response.data as AuthInitiateAuthResponse;

              if (responseData.accountSts === 1) {
                // 多要素認証なので確認コード画面を表示
                // Display verification code screen because it is multi-factor authentication
                this.loaderService.hideLoader();
                this.loginService.changeCodeViewDisplay.next(true);
              } else if (responseData.accountSts === 2) {
                // 未認証
                // unauthenticated
                this.loaderService.hideLoader();
                this.errorMessage = 'sidFailedChangeEmail';
              } else {
                // Eメール変更確定
                // Confirm email change
                this.restClientAuthService
                  .postAuthConfirmChangeEmail(this.loginService.changeEmailModel.hashCode)
                  .subscribe(
                    () => {
                      this.loaderService.hideLoader();
                      this.router.navigate(['/login'], {
                        relativeTo: this.route,
                      });
                    },
                    (err) => {
                      this.loaderService.hideLoader();
                      this.handlePostAuthConfirmChangeEmailError(err);
                    },
                  );
              }
            },
            (err) => {
              this.loaderService.hideLoader();
              this.handleAuthRError(err);
            },
          );
        },
        (err) => {
          this.loaderService.hideLoader();
          const response = err as Response;
          this.handleGetAuthChangeEmailError(response);
        },
      );
  }

  /**
   * GetAuthChangeEmailのサーバーエラー処理
   * @param response サーバーレスポンス
   */
  /**
   * GetAuthChangeEmail server error handling
   * @param response Server response
   */
  private handleGetAuthChangeEmailError(response: Response) {
    const errorParser = new ErrorResponseParser(response);
    if (errorParser.isTimeout) {
      this.errorMessage = 'sidServerErrorOccurred';
      this.showErrorToast('sidServerErrorOccurred');
    } else if (errorParser.hasErrorCode('BER00011')) {
      // 有効期限切れの場合
      // if expired
      this.errorMessage = 'sidLinkExpired';
    } else if (errorParser.isSystemError) {
      this.errorMessage = 'sidSystemError';
      this.showErrorToast('sidSystemError');
    } else {
      this.errorMessage = 'sidFailedChangeEmail';
    }
  }

  /**
   * ログイン認証のサーバーエラー処理
   *
   * @private
   * @param {Response} response サーバーレスポンス
   */
  /**
   * Server error handling for login authentication
   *
   * @private
   * @param {Response} response Server response
   */
  private handleAuthRError(response: Response) {
    if (response.status === 400) {
      this.errorMessage = 'sidFailedChangeEmail';
      this.showErrorToast(this.errorMessage);
    } else {
      // その他のエラー
      // other errors
      this.errorMessage = 'sidServerErrorOccurred';
      this.showErrorToast(this.errorMessage);
    }
  }

  /**
   * PostAuthConfirmChangeEmailのサーバーエラー処理
   * @param response サーバーレスポンス
   */
  /**
   * PostAuthConfirmChangeEmail server error handling
   * @param response Server response
   */
  private handlePostAuthConfirmChangeEmailError(response: Response) {
    const errorParser = new ErrorResponseParser(response);
    if (errorParser.isTimeout) {
      this.errorMessage = 'sidServerErrorOccurred';
      this.showErrorToast('sidServerErrorOccurred');
    } else if (errorParser.hasErrorCode('BER00011')) {
      // 有効期限切れの場合
      // if expired
      this.errorMessage = 'sidLinkExpired';
    } else {
      const apiType = ApiType.register;
      const sid = Utility.getErrorMessageSid(response, apiType);
      this.errorMessage = sid;
      this.showErrorToast(sid);
    }
  }

  /**
   * 言語変更時
   *
   * @param {*} e
   */
  /**
   * When changing language
   *
   * @param {*} e
   */
  async onSelectionChange(e: any) {
    this.loaderService.showLoader();
    this.localeId = await this.loginService.changeLocale(e.localeId, this.multiLanguageService);
    this.loaderService.hideLoader();
  }

  /**
   * エラートーストの表示
   *
   * @private
   * @param {string} resourceId リソースID
   * @memberof UserConfirmationComponent
   */
  /**
   * Display error toast
   *
   * @private
   * @param {string} resourceId Resource ID
   * @memberof UserConfirmationComponent
   */
  private showErrorToast(resourceId: string) {
    this.toastService.openToast(
      'error',
      'center',
      this.multiLanguageService.dictionary(resourceId),
    );
  }
}
