import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export class PasswordPolicy {
  label: string;
  invalid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PasswordPolicyService {
  /**
   * パスワードポリシー一覧の取得
   * @param control フォームコントロール
   * @returns パスワードポリシー一覧
   */
  /**
   * Gets password policies
   * @param control Form control
   * @returns Password policies
   */
  getPasswordPolicies(control: AbstractControl): PasswordPolicy[] {
    return [
      {
        label: 'sidAtLeast10',
        invalid: (control.errors && control.errors.invalidMinLength) || control.pristine,
      },
      {
        label: 'sidAtMaximum20',
        invalid: control.errors && control.errors.invalidMaxLength,
      },
      {
        label: 'sidAtLeastNumber',
        invalid: (control.errors && control.errors.requiresNumber) || control.pristine,
      },
      {
        label: 'sidAtLeastCapital',
        invalid: (control.errors && control.errors.requiresUpperCase) || control.pristine,
      },
      {
        label: 'sidAtLeastSmall',
        invalid: (control.errors && control.errors.requiresLowerCase) || control.pristine,
      },
      {
        label: 'sidAtLeastSpecialSignPass',
        invalid: (control.errors && control.errors.requiresSpecialCharacter) || control.pristine,
      },
    ];
  }
}
