import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';

import { MenuFooterLink } from 'src/app/main/main/services/menu-management/interfaces';

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
})
export class MenuFooterComponent implements OnInit {
  @Output() menuFooterSelect: EventEmitter<MenuFooterLink> = new EventEmitter<MenuFooterLink>();

  menuFooterItems: MenuFooterLink[];

  /**
   * コンストラクター関数
   * @param menuManagementService メニュー管理サービス
   */
  /**
   * Constructor function
   * @param menuManagementService Menu Management Service
   */
  constructor(private menuManagementService: MenuManagementService) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // メニューフッターのメニューの更新にサブスクライブ
    // Listen to changes from menu footer links
    this.menuManagementService.$menuFooterLinksStream.subscribe(
      (menuFooterLinks: MenuFooterLink[]) => (this.menuFooterItems = menuFooterLinks),
    );
  }

  /**
   * クリックされたメニュー項目に応じて処理を実施する
   * @param item クリックされたメニューの項目
   */
  /**
   * Processing is performed according to the clicked menu item.
   * @param item Clicked menu item
   */
  onLinkClick(item: MenuFooterLink) {
    this.menuFooterSelect.emit(item);
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、フォーカスの当たっているメニュー項目に応じて処理を実施する
   * @param event キーダウンイベント
   * @param item メニュー項目
   */
  /**
   * When the enter key or space key is pressed,
   * processing is performed according to the focused menu item
   * @param event Key down event
   * @param item menu item
   */
  onSelectKeyDown(event: any, item: MenuFooterLink) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.onLinkClick(item);
    }
  }
}
