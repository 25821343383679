import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { CommissioningEquipmentsGetResponse } from '../interfaces/commissioning-service/commissioning-edge';
import { CommissioningRefrigerantGetResponse } from '../interfaces/commissioning-service';
import {
  GetCommissioningCompleteListRequestParam,
  GetCommissioningCompleteListResponse,
} from '../interfaces/commissioning-service/commissioning-building';
import { GetCommissioningVirtualListResponse } from '../interfaces/commissioning-service/commissioning-virtual';

const pathOfCommissioning = `commissioning/${apiVersion}/`;

@Injectable()
export class RestClientCommissioningService extends RestClient {
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-9. 試運転サービス
  // 3-9. Commissioning service
  /////////////////////////////////////////////////////////////////////////////
  // 3-9-1. エッジ編集状態管理
  // 3-9-1. Edge editing status management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 試運転一時保存機器設定データ取得(エッジ指定)API
   * response body: CommissioningEquipmentsGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string[]} [protocol] プロトコル
   * @return {Observable<Response>} status:HTTPステータス
   * @this {RestClientService}
   */
  /**
   * Test run temporary storage device setting data acquisition (edge ​​specification) API
   * response body: CommissioningEquipmentsGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string []} [protocol] protocol
   * @return {Observable <Response>} status: HTTP status
   * @this {RestClientService}
   */
  getCommissioningEquipments(
    edgeId: string,
    protocol?: ('0b00100000' | '0b00001000' | '0b00000001' | '0b00000100')[],
  ): Observable<Response<CommissioningEquipmentsGetResponse>> {
    let query = '';
    if (protocol && protocol.length > 0) {
      query = `?protocol=${protocol.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCommissioning}edges/${edgeId}/equipments${query}`,
      undefined,
      this.screenId,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-9-2. 一時保存機器管理
  // 3-9-2. Machine Management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 試運転一時保存冷媒系統取得(エッジ/機器指定)API
   * response body: CommissioningRefrigerantGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   * @this {RestClientService}
   */
  /**
   * Acquisition of test run temporary storage refrigerant system (edge ​​/ device designation) API
   * response body: CommissioningRefrigerantGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} equipmentId Equipment ID
   * @return {Observable <Response>} status: HTTP status
   * @this {RestClientService}
   */
  getCommissioningRefrigerant(
    edgeId: string,
    equipmentId?: string,
  ): Observable<Response<CommissioningRefrigerantGetResponse>> {
    let query = `?edgeId=${edgeId}`;
    if (equipmentId) {
      query = `${query}&outdoorEquipmentId=${equipmentId}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCommissioning}refrigerantSystems${query}`,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-9-3. 機器管理
  // 3-9-3. Machine management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 試運転完了済機器一覧取得API
   * response body: CommissioningEdgesEquipmentsGetResponse
   * @param  param リクエストパラメータ
   * @return  レスポンス
   */
  /**
   * Trial operation device information batch acquisition API
   * @param  param Request parameter
   * @return response
   */
  getCommissioningBuildingsCompleteEquipments(
    param: GetCommissioningCompleteListRequestParam,
  ): Observable<Response<GetCommissioningCompleteListResponse>> {
    const topEntity = param.topEntity ?? [];
    const topEntityQueryValue =
      topEntity.length === 0
        ? undefined
        : topEntity.map((value) => encodeURIComponent(value)).join(',');
    const queryList = [
      ['topEntity', topEntityQueryValue],
      ['equipmentUnitFlag', param.equipmentUnitFlag],
      ['outdoorConfirmFlag', param.outdoorConfirmFlag],
      ['energyManagementTargetFlag', param.energyManagementTargetFlag],
      ['edgeId', param.edgeId],
      ['patternId', param.patternId, param.energyManagementTargetFlag],
    ] as const;
    const query = queryList
      .filter(
        ([key, value, condition_value]) =>
          value !== undefined && !(key === 'patternId' && condition_value === false),
      )
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCommissioning}buildings/${param.buildingId}/completeList?${query}`,
    );
  }

  /**
   * 仮想機器一覧取得API
   * response body: CommissioningEdgesEquipmentsGetResponse
   *
   * @param {string} buildingId 物件ID
   * @param {boolean} registeredDataFlg 登録済みデータ取得フラグ
   * @return {Observable<Response>} status:HTTPステータス
   * @this {RestClientService}
   */
  /**
   * Virtual device list acquisition API
   * response body: CommissioningEdgesEquipmentsGetResponse
   *
   * @param {string} buildingId Building ID
   * @param {boolean} registeredDataFlg Registered data acquisition flag
   * @return {Observable <Response>} status: HTTP status
   * @this {RestClientService}
   */
  getCommissioningVirtualList(
    buildingId: string,
    registeredDataFlg: boolean = false,
    edgeId?: string,
  ): Observable<Response<GetCommissioningVirtualListResponse>> {
    let query = '';
    const queryArray: string[] = Object.entries({ registeredDataFlg, edgeId })
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => {
        return `${key}=${encodeURIComponent(value)}`;
      });
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCommissioning}virtualList/${buildingId}${query}`,
    );
  }
}
