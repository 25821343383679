import { fromEvent } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { filterBy } from '@progress/kendo-data-query';

import { Building, Packages } from 'src/app/core/services/data-management/classes/building-data';
import { Edge } from 'src/app/core/services/data-management/classes/edge-data';

@Component({
  selector: 'app-filterable-combo-box',
  templateUrl: './filterable-combo-box.component.html',
  styleUrls: ['./filterable-combo-box.component.scss'],
})
export class FilterableComboBoxComponent implements OnInit, OnChanges {
  @ViewChild('filter') filterElement: ElementRef;
  @ViewChild('anchor', { static: true }) anchorElement: ElementRef;
  @ViewChild(ContextMenuComponent, { static: true }) popup: ContextMenuComponent;
  @ViewChild('comboBox', { read: ViewContainerRef, static: true }) comboBox: ViewContainerRef;

  @Input() label: string = '';
  @Input() items: Building[] | Edge[] = [];
  @Input() currentItem: Building | Edge;
  @Input() inactivatePopup: boolean = false;

  @Output() filterableComboBoxSelect: EventEmitter<Building | Edge> = new EventEmitter<
    Building | Edge
  >();

  filterValue: string = '';
  displayItems: Building[] | Edge[] = [];

  /**
   * コンストラクター関数
   */
  /**
   * Constructor function
   */
  constructor() {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // メインコンポーネントのスクロールがトリガーされたら、ポップアップを閉じる
    // Closes popup when main component scroll is triggered
    fromEvent(document.getElementsByClassName('main'), 'scroll').subscribe(() => this.popup.hide());
  }

  /**
   * Angular Life Cycle Method
   * @param changes バインドされている値の変更値
   */
  /**
   * Angular Life Cycle Method
   * @param changes Bound data changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.displayItems = this.items;
    }
  }

  /**
   * ポップアップの開閉のトグル
   */
  /**
   * Toggle between opening and closing popup
   */
  onPopupToggle() {
    // フィルター要素が表示されていたら、ポップアップを閉じ、フィルター要素が表示されていなかったら、ポップアップを開く
    // Closes popup when filter element is displayed and opens popup when filter element is not displayed
    if (this.filterElement) {
      this.popup.hide();
    } else {
      this.popup.appendTo = this.comboBox;
      this.popup.show(this.anchorElement);
    }
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、ポップアップを開閉する
   * @param event キーダウンイベント
   */
  /**
   * Opens and closes the popup when the enter key or space key is pressed
   * @param event Key down event
   */
  onToggleKeyDown(event: any) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onPopupToggle();
    }
  }

  /**
   * フィルタ処理の実施
   * @param filterValue フィルタの値
   */
  /**
   * Performs filter
   * @param filterValue Filter value
   */
  onFilter(filterValue: string) {
    this.filterValue = filterValue;
    this.displayItems = filterBy(this.items, {
      field: 'name',
      operator: 'contains',
      value: this.filterValue,
      ignoreCase: true,
    });
  }

  /**
   * 物件またはエッジの選択
   * @param selecteditem 選択した物件またはエッジ
   */
  /**
   * Building or edge selection
   * @param selecteditem Selected building or edge
   */
  onItemSelect(selecteditem: Building | Edge) {
    // ポップアップを閉じて、選択した物件またはエッジを検知する
    // Closes popup and emits selected building or edge
    this.popup.hide();
    this.filterableComboBoxSelect.emit(selecteditem);
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、ポップアップを開閉する
   * @param event キーダウンイベント
   * @param item 物またはエッジどちらかの種類
   */
  /**
   * Toggle between opening and closing popup
   * @param event Key down event
   * @param item Building or Edge type
   */
  onSelectKeyDown(event: any, item: Building | Edge) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onItemSelect(item);
    }
  }

  /**
   * カレント物件またはエッジの利用期限切れの確認
   * @param currentItem カレント物件またはエッジ
   * @returns 利用期限切れの状態
   */
  /**
   * Checks current building's or edge's expiry status
   * @param currentItem Current building or edge
   * @returns Expiry status
   */
  checkExpiryStatus(currentItem: Building | Edge): boolean {
    return (
      currentItem['packageList'] &&
      currentItem['packageList'].find((packages: Packages) => packages.expired)
    );
  }
}
