import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/el';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/tr';
import 'dayjs/locale/cs';
import 'dayjs/locale/hr';
import 'dayjs/locale/hu';
import 'dayjs/locale/ro';
import 'dayjs/locale/sl';
import 'dayjs/locale/sk';
import 'dayjs/locale/bg';
import 'dayjs/locale/pl';
import 'dayjs/locale/da';
import 'dayjs/locale/sv';
import 'dayjs/locale/nb';
import 'dayjs/locale/fi';
import 'dayjs/locale/sr';
import 'dayjs/locale/th';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/vi';
import 'dayjs/locale/id'; 