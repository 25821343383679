import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { screens } from '../constants/screens';
import { HttpHeaders } from '@angular/common/http';

const pathOfAuthority = `authority/${apiVersion}/`;

@Injectable()
export class RestClientAuthorityService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-8. 権限サービス
  //  3-8. Authority service
  /////////////////////////////////////////////////////////////////////////////
  // 3-8-1. 権限管理
  // 3-8-1. Permission management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * パッケージ取得API
   * response body: AuthorityPackages
   *
   * @param {string} packageId パッケージID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Package acquisition API
   * response body: AuthorityPackages
   *
   * @param {string} packageId Package ID
   * @return {Observable<Response>} status:HTTP status
   */
  getAuthorityPackage(packageId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfAuthority}packages/${packageId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * アクセスレベル取得API
   * response body: AuthorityAccessLevel
   *
   * @param {string} roleId 役割ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Access level acquisition API
   * response body: AuthorityAccessLevel
   *
   * @param {string} roleId Role ID
   * @return {Observable<Response>} status:HTTP status
   */
  getAuthorityAccessLevel(roleId: string): Observable<Response> {
    // 多物件管理アプリ対応: 利用しないので固定のダミー情報を返す
    // multi-store-app: Returning fixed dummy information as it is not used
    const responseBody = {
      roleId: '002',
      roleName: 'dummy admin',
      screens,
    };

    const response: Response<any> = {
      status: 200,
      data: responseBody,
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    // Observableとして返す
    // Return as an Observable
    return of(response);

    // return this.restClientCommonService.request(
    //   'get',
    //   `${this.endPoint}${pathOfAuthority}accessLevels/${roleId}`,
    //   undefined,
    //   this.screenId,
    // );
  }
}
