export class Edge {
  id: string;
  name: string;

  /**
   * コンストラクタ
   *
   * @param id エッジID
   * @param name エッジ名
   */
  /**
   * constructor
   *
   * @param id Edge id
   * @param name Edge name
   */
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  /**
   * IDが有効かチェックする
   *
   * @return チェック結果
   */
  /**
   * Check if id is valid
   *
   * @return Check result
   */
  isValid(): boolean {
    if (this.id) {
      return true;
    }
    return false;
  }

  /**
   * 更新が必要か判定する
   *
   * @param newEdge 新しいエッジ情報
   * @return 判定結果
   */
  /**
   * Determine if an update is required
   *
   * @param newEdge New edge information
   * @return judgement result
   */
  isUpdated(newEdge: Edge): boolean {
    if (newEdge.id !== this.id || newEdge.name !== this.name) {
      return true;
    }
    return false;
  }
}
