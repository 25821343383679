<div class="reset-password-form">
  <div class="reset-password-form__title">
    {{'sidNewPass' | translate}}
  </div>

  <div class="reset-password-form__group" (keyup.enter)="onEnter()">
    <app-text-box type="password" placeholder="{{'sidVerificationCode'| translate }}"
      [novalidation]="false" [isIconLeftAligned]="true" [formControl]="code"
      [isIconRightAligned]="true" [passwordMaskIcon]="true">
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>

  <div class="reset-password-form__group" (keyup.enter)="onEnter()">
    <app-text-box type="password" placeholder="{{ 'sidEnterNewPass' | translate }}"
      [novalidation]="false" [usePasswordPolicies]="true" [isIconLeftAligned]="true"
      [formControl]="password" (ngModelChange)="onChangePassword()" [isIconRightAligned]="true"
      [passwordMaskIcon]="true">
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>

  <div class="reset-password-form__group-last" (keyup.enter)="onEnter()">
    <app-text-box type="password" placeholder="{{ 'sidVerifyNewPass' | translate }}"
      [novalidation]="false" [usePasswordPolicies]="false" [isIconLeftAligned]="true"
      [formControl]="confirmPassword" (ngModelChange)="onChangePassword()"
      [isIconRightAligned]="true" [passwordMaskIcon]="true">
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>

  <div class="reset-password-form__error-message">
    <app-form-inline-message *ngIf="errorMessage">
      {{ errorMessage }}
    </app-form-inline-message>
  </div>

  <div class="reset-password-form__link">
    <app-form-link (click)="onBackToLoginClick()" (keydown)="onKeydown($event)">
      {{'sidBackToLogin' | translate }}
    </app-form-link>
  </div>

  <app-button class="reset-password-form__button" [disabled]="!enableResetButton"
    (generalButtonClick)="onResetPasswordClick()">
    {{'sidResetPass' | translate}}
  </app-button>

</div>