import { OperationStatusColor } from 'src/app/common/enums/operation-status-color';
import { Edge } from './edge-data';

export class Building {
  id: string;
  name: string;
  currentTime: string;
  edgeList: Edge[];
  packageList: Packages[];
  // idがALLの場合にその画面内で選んだ物件を格納する
  // the building selected in the screen when id is ALL
  selectedId: string;
  operationStatusColor: OperationStatusColor;
  /**
   * コンストラクタ
   *
   * @param id 物件ID
   * @param name 物件名
   * @param currentTime 物件が配置された場所の時間
   * @param edgeList エッジリスト
   * @param packageList パッケージリスト
   * @param selectedId 画面上で選択された物件ID
   * @param operationStatusColor 運転状態色
   */
  /**
   * constructor
   *
   * @param id Building id
   * @param name Building name
   * @param currentTime Time of the place where the building is located
   * @param edgeList Edge list
   * @param packageList Package list
   * @param selectedId Building id selected on the screen
   * @param operationStatusColor Operation status color
   */
  constructor(
    id: string,
    name: string,
    currentTime: string = '',
    edgeList: Edge[] = [],
    packageList: Packages[] = [],
    selectedId: string = '',
    operationStatusColor: OperationStatusColor = OperationStatusColor.MixedColorOperationBlue,
  ) {
    this.id = id;
    this.name = name;
    this.currentTime = currentTime;
    this.edgeList = edgeList;
    this.packageList = packageList;
    this.selectedId = selectedId;
    this.operationStatusColor = operationStatusColor;
  }

  static copy(souce: Building): Building {
    const newBuilding = new Building(
      souce.id,
      souce.name,
      souce.currentTime,
      [],
      [],
      '',
      souce.operationStatusColor,
    );
    if (souce.edgeList.length > 0) {
      Object.assign(newBuilding.edgeList, souce.edgeList);
    }
    if (souce.packageList.length > 0) {
      Object.assign(newBuilding.packageList, souce.packageList);
    }
    return newBuilding;
  }

  /**
   * IDが有効かチェックする
   *
   * @return チェック結果
   */
  /**
   * Check if id is valid
   *
   * @return Check result
   */
  isValid(): boolean {
    if (this.id) {
      return true;
    }
    return false;
  }

  /**
   * 更新が必要か判定する
   *
   * @param newBuilding 新しい物件情報
   * @return 判定結果
   */
  /**
   * Determine if an update is required
   *
   * @param newBuilding New building information
   * @return judgement result
   */
  isUpdated(newBuilding: Building): boolean {
    return (
      newBuilding.id !== this.id ||
      newBuilding.name !== this.name ||
      newBuilding.currentTime !== this.currentTime ||
      JSON.stringify(newBuilding.edgeList) !== JSON.stringify(this.edgeList) ||
      JSON.stringify(newBuilding.packageList) !== JSON.stringify(this.packageList) ||
      newBuilding.operationStatusColor !== this.operationStatusColor
    );
  }

  /**
   * 通知が必要か判定する
   *
   * @param newBuilding 新しい物件情報
   * @return 判定結果
   */
  /**
   * Determine if notification is required
   *
   * @param newBuilding New building information
   * @return judgement result
   */
  checkStream(newBuilding: Building) {
    return (
      newBuilding.id !== this.id ||
      newBuilding.name !== this.name ||
      newBuilding.operationStatusColor !== this.operationStatusColor
    );
  }
}

export class Packages {
  packageId: string;
  status: number;
  expirationDate: string;
  expired: boolean;
  isAirnet: boolean;
  /**
   * コンストラクタ
   *
   * @param packageId パッケージID
   * @param status 状態
   * @param expirationDate 有効期限
   * @param expired 期限切れフラグ
   * @param isAirnet エアネット契約かどうか
   */
  /**
   * constructor
   *
   * @param packageId Package id
   * @param status status
   * @param expirationDate Expiration date
   * @param expired Expired flag
   * @param isAirnet エアネット契約かどうか
   */
  constructor(
    packageId: string,
    status: number,
    expirationDate: string,
    expired: boolean,
    isAirnet: boolean,
  ) {
    this.packageId = packageId;
    this.status = status;
    this.expirationDate = expirationDate;
    this.expired = expired;
    this.isAirnet = isAirnet;
  }
}
