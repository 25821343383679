import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PartialBaseComponent } from 'src/app/base/partial-base.component';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectBoxComponent),
    },
  ],
})
export class SelectBoxComponent extends PartialBaseComponent implements ControlValueAccessor {
  @Input() defaultItem: any;
  @Input() data: any[] = null;
  @Input() listHeight: number = 200;
  @Input() textField: string = '';
  @Input() valueField: string = '';
  @Input() valuePrimitive = false;

  @Output() selectBoxBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectBoxFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectBoxOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdownlist', { static: true }) public dropdownlist: any;

  value: any;

  private onChangeCallback: any;
  private onTouchCallBack: any;

  /**
   * コンストラクター
   * @param changeDetector 変更検出
   */
  /**
   * Constructor
   * @param changeDetector Change detector
   */
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  /**
   * ngModel(あるいはform control)で渡された値を設定する
   * @param value 画面から渡される値
   */
  /**
   * Set the value passed in ngModel (or form control)
   * @param value The value passed from the screen
   */
  writeValue(value: any) {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * ControlValueAccessor Method
   * @param fn ControlValueAccessorに登録する関数
   */
  /**
   * ControlValueAccessor Method
   * @param fn Function registered in ControlValueAccessor
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * ControlValueAccessor Method
   * @param fn ControlValueAccessorに登録する関数
   */
  /**
   * ControlValueAccessor Method
   * @param fn Function registered in ControlValueAccessor
   */
  registerOnTouched(fn: any) {
    this.onTouchCallBack = fn;
  }

  /**
   * ControlValueAccessor Method
   * @param disabled 部品の非活性フラグ
   */
  /**
   * ControlValueAccessor Method
   * @param disabled Part inactivity flag
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    if (disabled) {
      this.dropdownlist.toggle(false);
    }
  }

  /**
   * セレクトボックスが変更されたら、選択中の値を変更する
   * @param value 選択された値
   */
  /**
   * If the select box changes, change the selected value
   * @param value Selected value
   */
  onChange(value: string) {
    this.value = value;
    this.onChangeCallback(this.value);
  }

  /**
   * セレクトボックスからフォーカスが外れたら、Blurイベントを発行する
   */
  /**
   * Issue a Blur event when the select box is out of focus
   */
  onBlur() {
    this.onTouchCallBack();
    this.selectBoxBlur.emit();
  }
}
