<header class="site-header clearfix">
  <div class="combo-box__container" [class.empty]="!showSiteSelection">
    <div *ngIf="showSiteSelection" class="combo-box__site-items">
      <app-filterable-combo-box label="sidSelectSite" [items]="siteList"
        [currentItem]="selectedSite" [inactivatePopup]="inactivateSiteSelection"
        (filterableComboBoxSelect)="onSiteSelect($event)"></app-filterable-combo-box>
    </div>
    <div *ngIf="showControllerSelection" class="combo-box__controller-items">
      <app-filterable-combo-box label="sidSelectController" [items]="this.selectedSite?.edgeList"
        [currentItem]="selectedController"
        (filterableComboBoxSelect)="onControllerSelect($event)"></app-filterable-combo-box>
    </div>
  </div>
  <div class="account-panel__container">
    <app-account-panel (accountPanelSelect)="onMenuSelect($event)"></app-account-panel>
  </div>
</header>