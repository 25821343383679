<div class="footer-menu">
  <ul class="footer-menu__list">
    <ng-container *ngFor="let item of footerMenuItems">
      <li *ngIf="item.isDisplay" class="footer-menu__item" tabIndex="0"
        (keydown)="onLinkKeyDown($event, item)">
        <a class="footer-menu__link" (click)="$event.stopPropagation(); onLinkClick(item)">
          {{ multiLanguageMessageService.dictionary(item.name) }}
        </a>
      </li>
    </ng-container>
  </ul>
  <div class="footer-menu__copyright">
    {{ multiLanguageMessageService.dictionary('sidCopyright') }}
  </div>
</div>
<app-dialog *ngIf="openTermsOfUseDialog" flexibility="full"
  [declineButtonLabel]="'sidClose' | translate" (output)="openTermsOfUseDialog = false"
  (overlayclick)="openTermsOfUseDialog = false">
  <app-terms-of-use></app-terms-of-use>
</app-dialog>
<app-dialog *ngIf="openLicenseDialog" flexibility="full"
  [declineButtonLabel]="'sidClose' | translate" (output)="openLicenseDialog = false"
  (overlayclick)="openLicenseDialog = false">
  <app-license></app-license>
</app-dialog>