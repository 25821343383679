import { environment } from 'src/environments/environment';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';

import { MainMenuLink, MenuFooterLink, FooterMenuLink, MyProfileLink } from './interfaces';

/** メインメニューのメニュー項目とステータス */
/** Menu items and status of the main menu */
export const mainMenuLinks: MainMenuLink[] = [
  // {
  //   id: ScreenId.MonitoringAndOperation,
  //   name: 'sidUCMonitoringOperation',
  //   iconId: 'monitoring-and-operation',
  //   isDisplay: true,
  //   expanded: false,
  //   submenus: [
  //     {
  //       id: ScreenId.EquipmentList,
  //       name: 'sidUCEquipmentList',
  //       url: '/monitoring-and-operation/equipment-list',
  //       iconId: 'monitoring-and-operation',
  //       isDisplay: true,
  //       enableSiteSelect: true,
  //       enablePackageEdit: true,
  //       enableAlert: true,
  //       needUpgrade: false,
  //       isError: false,
  //       active: false,
  //     },
  //     {
  //       id: ScreenId.LayoutView,
  //       name: 'sidUCLayoutView',
  //       url: '/monitoring-and-operation/layout-view',
  //       iconId: 'monitoring-and-operation',
  //       isDisplay: true,
  //       enableSiteSelect: true,
  //       enableControllerSelect: true,
  //       enablePackageEdit: true,
  //       enableAlert: true,
  //       needUpgrade: false,
  //       isError: false,
  //       active: false,
  //     },
  //   ],
  // },
  {
    id: ScreenId.DashboardView,
    name: 'sidUCMultistoreManagement',
    url: '/multi-store-management/dashboard',
    iconId: 'multi-store-management',
    isDisplay: true,
    enablePackageEdit: true,
    needUpgrade: false,
    active: false,
  },
];

/** メニューフッターのメニュー項目とステータス */
/** Menu footer menu items and status */
export const menuFooterLinks: MenuFooterLink[] = [
  // {
  //     id: ScreenId.Contact,
  //     name: 'sidUCRMS',
  //     url: 'sidRMSUrl',
  //     isExternalLink: true,
  //     isDisplay: true,
  //   },
  // {
  //   id: ScreenId.Commissioning,
  //   name: 'sidUCCommissioning',
  //   url: environment.commissioningUrl,
  //   isExternalLink: true,
  //   isDisplay: true,
  // },
  // {
  //   id: ScreenId.CookieNotice,
  //   name: 'sidUCCookies',
  //   url: '/cookie-notice',
  //   isDisplay: true,
  //   active: false,
  // },
  // {
  //   id: ScreenId.TermsOfUse,
  //   name: 'sidUCTermOfUse',
  //   url: '/terms-of-use',
  //   isDisplay: true,
  //   active: false,
  // },
  // {
  //   id: ScreenId.License,
  //   name: 'sidUCLicense',
  //   url: '/license',
  //   isDisplay: true,
  //   active: false,
  // },
  // {
  //   id: ScreenId.Information,
  //   name: 'sidUCInformation',
  //   url: 'sidInformationUrl',
  //   isExternalLink: true,
  //   isDisplay: true,
  // },
  // {
  //   id: ScreenId.Download,
  //   name: 'sidUCAppDownload',
  //   url: '/download',
  //   isDisplay: true,
  //   active: false,
  // },
  // {
  //   id: ScreenId.Contact,
  //   name: 'sidUCContact',
  //   url: 'sidContactUrl',
  //   isExternalLink: true,
  //   isDisplay: true,
  // },
];

/** フッターメニューのメニュー項目とステータス */
/** Menu items and status in the footer menu */
export const footerMenuLinks: FooterMenuLink[] = [
  // {
  //   id: ScreenId.CookieNotice,
  //   name: 'sidUCCookies',
  //   url: '/cookie-notice',
  //   isDisplay: true,
  //   active: false,
  // },
  // {
  //   id: ScreenId.TermsOfUse,
  //   name: 'sidUCTermOfUse',
  //   url: '/terms-of-use',
  //   isDisplay: true,
  //   active: false,
  // },
  {
    id: ScreenId.License,
    name: 'sidUCLicense',
    url: '/license',
    isDisplay: true,
    active: false,
  },
  // {
  //   id: ScreenId.Information,
  //   name: 'sidUCInformation',
  //   url: 'sidInformationUrl',
  //   isExternalLink: true,
  //   isDisplay: true,
  // },
  // {
  //   id: ScreenId.Contact,
  //   name: 'sidUCContact',
  //   url: 'sidContactUrl',
  //   isExternalLink: true,
  //   isDisplay: true,
  // },
];

/** マイプロフィールメニューのメニュー項目とステータス */
/** My profile menu menu items and status */
export const myProfileLinks: MyProfileLink[] = [
  // {
  //   id: ScreenId.MyProfile,
  //   name: 'sidUCMyProfile',
  //   url: '/account-settings/my-profile',
  //   isDisplay: true,
  //   active: false,
  // },
  // {
  //   id: ScreenId.ChangePassword,
  //   name: 'sidUCChangePassword',
  //   url: '/account-settings/change-password',
  //   isDisplay: true,
  //   active: false,
  // },
  {
    id: ScreenId.Version,
    name: 'sidUCVersionInfo',
    isExternalLink: true,
    isDisplay: true,
    isVersionInfo: true,
  },
  {
    id: ScreenId.Logout,
    name: 'sidLogout',
    url: '/login',
    isDisplay: true,
    active: false,
  },
];
