import { Component, OnInit } from '@angular/core';
import { LoginConfirmationFormModel } from './components/login-confirmation-form/login-confirmation-form.component';

@Component({
  selector: 'app-login-confirmation',
  templateUrl: './login-confirmation.component.html',
  styleUrls: ['./login-confirmation.component.scss'],
})
export class LoginConfirmationComponent implements OnInit {
  loginConfirmationFormModel: LoginConfirmationFormModel;

  constructor() {}

  ngOnInit() {
    this.loginConfirmationFormModel = new LoginConfirmationFormModel();
  }
}
