<div class="main-wrapper">
  <div class="menu menu--vertical" [class.menu--inactive]="verticalMenu.isTabletMenuInactive"
    [style.height]="offsetHeight">
    <app-menu #verticalMenu></app-menu>
  </div>
  <div class="main" (click)="verticalMenu.inactivateMenu()" [style.height]="offsetHeight">
    <div class="menu menu--horizontal">
      <app-menu #horizontalMenu></app-menu>
    </div>
    <div class="notification-bar">
      <app-notification-bar></app-notification-bar>
    </div>
    <div class="header">
      <app-header></app-header>
    </div>
    <div class="breadcrumb">
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div *ngIf="!isServiceUnactivated" class="alert-bar">
      <app-alert-bar></app-alert-bar>
    </div>
    <div class="main-module">
      <ng-container *ngIf="isSitesUnavailable">
        <p>{{ 'sidNoSiteAssign' | translate }}</p>
      </ng-container>
      <ng-container *ngIf="isServiceUnactivated">
        <app-service-unactivated></app-service-unactivated>
      </ng-container>
      <ng-container *ngIf="isServiceUnavailable">
        <app-service-unavailable></app-service-unavailable>
      </ng-container>
      <ng-container *ngIf="!isSitesUnavailable && !isServiceUnactivated && !isServiceUnavailable">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
    <div class="footer">
      <app-menu-footer (menuFooterSelect)="verticalMenu.onMenuSelect($event)"></app-menu-footer>
    </div>
  </div>
</div>
<div class="cookie-bar">
  <app-cookie-bar #cookieBar></app-cookie-bar>
</div>
<app-cookie-dialog></app-cookie-dialog>