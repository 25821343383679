import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  InterlockingEdgePrgPostRequest,
  InterlockingEdgePrgPutRequest,
  InterlockingEdgePrgGetResponse,
  InterlockingEdgePrgsGetResponse,
  InterlockingEdgePrgPostResponse,
} from '../interfaces/edge-service/edge-edges';

const pathOfInterlocking = `interlocking/${apiVersion}/`;

@Injectable()
export class RestClientInterlockingService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-12. 連動制御サービス
  //  3-12. Linked control service
  /////////////////////////////////////////////////////////////////////////////
  //  3-12-1. 連動制御管理
  //  3-12-1. Interlocking control management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 連動機器情報一覧取得
   * response body: InterlockingListGetResponse
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Acquire linked device information list
   * response body: InterlockingListGetResponse
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getInterlockingList(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfInterlocking}edges/${edgeId}/equipments`,
    );
  }

  /**
   * 連動制御プログラム一覧取得
   * response body: InterlockingEdgePrgsGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} fields フィールド指定
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Acquire linked control program list
   * response body: InterlockingEdgePrgsGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} fields Field specification
   * @return {Observable<Response>} status:HTTP status
   */
  getInterlockingEdgePrgs(edgeId: string, fields: string): Observable<Response> {
    let query = '';
    if (fields) {
      query = `?fields=${fields}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfInterlocking}edges/${edgeId}/prgs${query}`,
    );
  }

  /**
   * 連動制御プログラム登録
   * response body: InterlockingEdgePrgPostResponse
   *
   * @param {string} edgeId エッジID
   * @param {IlkProgram} param 連動制御プログラム
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Interlocking control program registration
   * response body: InterlockingEdgePrgPostResponse
   *
   * @param {string} edgeId Edge ID
   * @param {IlkProgram} param Interlocking control program
   * @return {Observable<Response>} status:HTTP status
   */
  postInterlockingEdgePrgs(
    edgeId: string,
    param: InterlockingEdgePrgPostRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfInterlocking}edges/${edgeId}/prgs`,
      param,
    );
  }

  /**
   * 連動制御プログラム取得
   * response body: InterlockingEdgePrgGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 連動制御プログラムID
   * @param {string} fields フィールド指定
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Acquire interlocking control program
   * response body: InterlockingEdgePrgGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Interlocking control program ID
   * @param {string} fields Field specification
   * @return {Observable<Response>} status:HTTP status
   */
  getInterlockingEdgePrg(edgeId: string, prgId: string, fields: string): Observable<Response> {
    let query = '';
    if (fields) {
      query = `?fields=${fields}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfInterlocking}edges/${edgeId}/prgs/${prgId}${query}`,
    );
  }

  /**
   * 連動制御プログラム更新
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 連動制御プログラムID
   * @param {IlkProgram} param 連動制御プログラム
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Linked control program update
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Interlocking control program ID
   * @param {IlkProgram} param Interlocking control program
   * @return {Observable<Response>} status:HTTP status
   */
  putInterlockingEdgePrg(
    edgeId: string,
    prgId: string,
    param: InterlockingEdgePrgPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfInterlocking}edges/${edgeId}/prgs/${prgId}`,
      param,
    );
  }

  /**
   * 連動制御プログラム削除
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 連動制御プログラムID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Delete linked control program
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Interlocking control program ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteInterlockingEdgePrg(edgeId: string, prgId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfInterlocking}edges/${edgeId}/prgs/${prgId}`,
    );
  }
}
