export enum DateFormats {
  YmdSlash = 0,
  Ymd,
  MdSlash,
  Md,
  YmSlash,
  Ym,
  YmdSlash4,
  Ym4,
}
