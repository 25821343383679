export enum ScreenId {
  MonitoringAndOperation = 'monitoringAndOperation',
  ScreenCommon = 'screenCommon',
  EmailAddressConfirmation = 'emailConfirmation',
  EquipmentList = 'equipmentList',
  LayoutView = 'layoutView',
  Preview = 'preview',
  LayoutSettings = 'layoutSetting',
  Screen = 'screen',
  ScreenGroupSettings = 'screenGroupSettings',
  Schedule = 'schedule',
  ScheduleExecution = 'scheduleExecution',
  Interlocking = 'interlocking',
  ForcedStop = 'forcedStop',
  TemperatureMonitoring = 'temperatureMonitoring',
  EnergyConsumption = 'energyConsumption',
  OutdoorUnitComparison = 'outdoorUnitComparison',
  MultiSiteComparison = 'multiSiteComparison',
  OperationMonitoring = 'operationMonitoring',
  MeteringTrend = 'meteringTrend',
  MeterList = 'meterList',
  OperationDataOutput = 'operationDataOutput',
  PatternSettings = 'patternSettings',
  PatternMeterSelection = 'patternMeterSelection',
  EnergySavingSimulation = 'energySavingSimulation',
  MapView = 'mapView',
  DashboardView = 'dashboardView',
  HourlyData = 'hourlyData',
  EquipmentData = 'equipmentData',
  DataOutput = 'dataOutput',
  UserList = 'userList',
  AddUser = 'addUser',
  EditUser = 'editUser',
  SiteList = 'siteList',
  AddSite = 'addSite',
  EditSite = 'editSite',
  ZoneList = 'zoneList',
  EdgeNetwork = 'edgeNetwork',
  EdgeUpdate = 'edgeUpdate',
  EdgeControl = 'edgeControl',
  Package = 'packages',
  NotificationMessage = 'notificationMessage',
  AccessLevels = 'accessLevels',
  Commissioning = 'commissioning',
  TermsOfUse = 'termOfUse',
  MyProfile = 'myProfile',
  Download = 'download',
  Version = 'version',
  AutomaticControl = 'automaticControl',
  EnergyManagement = 'energyManagement',
  DataCollectionSettings = 'dataCollectionSettings',
  BuildingAdministration = 'buildingAdministration',
  ZoneEquipment = 'zoneEquipment',
  SystemSettings = 'systemSettings',
  CookieNotice = 'cookieNotice',
  License = 'license',
  Information = 'information',
  Contact = 'contact',
  ChangePassword = 'changePassword',
  Login = 'login',
  Logout = 'logout',
  TimeExtension = 'timeExtention',
  TemperatureLimit = 'temperatureLimit',
  CommonFunction = 'commonFunction',
  ErrorNotification = 'errorNotification',
  // package一覧取得apiやの権限apiで扱うscreenidを記載しておく必要
  // It is necessary to describe the screenid handled by the package list acquisition api and the authority api.
  DemandControl = 'demandControl',
  DemandModeSettings = 'demandModeSettings',
  DemandCurrentState = 'demandCurrentState',
  DemandControlGroupSettings = 'demandControlGroupSettings',
  DemandGraph = 'demandGraph',
  DemandTargetPowerSettings = 'demandTargetPowerSettings',
  DemandControlState = 'demandControlState',
  DemandControlSettings = 'demandControlSettings',
  History = 'history',
  MailSetting = 'mailSetting',
  EmergencyOperationConfigurationRequest = 'emergencyOperationConfigurationRequest',
  EmergencyOperationConfigurationConfirmation = 'emergencyOperationConfigurationConfirmation',
  EmergencyOperationRequestCompletion = 'emergencyOperationRequestCompletion',
  EmergencyOperationError = 'emergencyOperationError',
  PowerProportionalDistribution = 'powerProportionalDistribution',
  PpdCollectionTotal = 'ppdCollectionTotal',
  PpdCollectionPeriodSetup = 'ppdCollectionPeriodSetup',
  ErrorNotificationSetting = 'errorNotificationSetting',
  MailNotificationSetting = 'mailNotificationSetting',
  AirConditioningSupport = 'airConditioningSupport',
  RefrigerantLeakNotificationList = 'refrigerantLeakNotificationList',
  AddRefrigerantLeakNotification = 'addRefrigerantLeakNotification',
  EditRefrigerantLeakNotification = 'editRefrigerantLeakNotification',
  R32RefrigerantLeakAlarmSetting = 'r32RefrigerantLeakAlarmSetting',
  // 機能は試運転に存在、パッケージ管理のために追加
  // Function is added to commissioning and adding package management
  Bacnet = 'bacnet',
  AirnetService = 'airnetService',
  NotificationSettings = 'notificationSettings',
  InspectionNotificationList = 'inspectionNotificationList',
  AddInspectionNotification = 'addInspectionNotification',
  EditInspectionNotification = 'editInspectionNotification',
  InspectionResult = 'inspectionResult',
  InspectionRequest = 'inspectionRequest',
  // 機能は試運転に存在、パッケージ管理のために追加
  // Function is added to commissioning and adding package management
  Gateway = 'gateway',
  ModuleController = 'modCtrl',
  BacnetClient = 'bacnetClient',
}
