import { Person } from 'src/app/core/services/data-management/classes/person-data';
import { Role } from 'src/app/core/services/data-management/classes/role-data';
import { Affiliate } from 'src/app/core/services/data-management/classes/affiliate-data';
import { AccessLevel } from 'src/app/shared-main/components/authority-control/classes/access-level';
import { Package } from 'src/app/core/services/data-management/classes/package-data';
import { RestClientCacheBuilding } from 'src/app/core/services/rest-client/classes/rest-client-caches';
import { BuildingBuildingsGetResponse } from 'src/app/core/services/rest-client/interfaces/building-service/building-buildings-get';

export class CachesLoginData {
  person: Person;
  role: Role;
  affiliate: Affiliate;
  accessLevel: AccessLevel;
  buildingList: BuildingBuildingsGetResponse;
  buildings: RestClientCacheBuilding[];
  packageList: Package[];
}
