export enum Actions {
  DELETE = 0,
  EDIT = 1,
  ADD = 2,
  SAVE = 3,
  CANCEL = 4,
  GET = 5,
  UPDATE = 6,
  COPY = 7,
  SELECT = 8,
  DOWNLOAD = 9,
  ORDER = 10,
  SETTING = 11,
}
