import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements OnInit {
  @ViewChild('template', { static: true }) templateRef: ElementRef;
  /**
   * コンストラクター関数
   * @param http Angular Http クライアント
   * @param multiLanguageMessageService 多言語対応サービス
   */
  /**
   * Constructor function
   * @param http Angular Http Client
   * @param multiLanguageMessageService Multilingual service
   */
  constructor(
    private http: HttpClient,
    private multiLanguageMessageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // Assets から外部ファイルを取得
    // Grab HTML external file from assets
    this.http
      .get(this.multiLanguageMessageService.dictionary('sidLicenseTemplate'), {
        responseType: 'text',
      })
      .subscribe(
        (template: any) => {
          this.templateRef.nativeElement.insertAdjacentHTML('afterbegin', template);
          this.setSelectors();
        },
        () => {
          this.templateRef.nativeElement.insertAdjacentHTML('afterbegin', '-');
        },
      );
  }

  /**
   * セレクタの設定
   */
  /**
   * Set selectors
   */
  private setSelectors() {
    const selectors: string[] = [
      'mit',
      'apache',
      'bsd2',
      'bsd3',
      'bsd0',
      'isc',
      'zlib',
      'sil',
      'epl',
      'ccby',
      'ipafont',
    ];

    selectors.forEach((selector) => {
      document.querySelectorAll(`.license__table-link--${selector}`).forEach((link) => {
        link.addEventListener('click', () => {
          document.querySelector(`.license__subtitle--${selector}`).scrollIntoView();
        });
      });
    });
  }
}
