import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { RestClientSettingService } from 'src/app/core/services/rest-client';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';
import { PackageManagementService } from 'src/app/main/main/services/package-management/package-management.service';

import { BuildingEdgeStream } from 'src/app/core/services/data-management/classes/stream-data';
import { StreamType } from 'src/app/core/services/data-management/enums/stream-type';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { SystemPackageGetResponse } from 'src/app/core/services/rest-client/interfaces/system-service/system-package';
import { Building } from 'src/app/core/services/data-management/classes/building-data';
import {
  Package,
  FunctionStatus,
  Menu,
} from 'src/app/core/services/data-management/classes/package-data';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { AlertBarData } from 'src/app/main/main/components/alert-bar/interfaces';
import { Link } from 'src/app/main/main/services/menu-management/interfaces';
import { Utility } from 'src/app/shared-main/classes/utility';
import { ApiType } from 'src/app/core/services/toast/api-type';

@Injectable()
export class MainManagementService {
  $serviceUnactivatedStream: Subject<boolean> = new Subject<boolean>();
  $serviceUnavailableStream: Subject<boolean> = new Subject<boolean>();
  $alertDataStream: BehaviorSubject<AlertBarData | null> = new BehaviorSubject(null);
  isZoomOut: boolean = false;

  private packageList: Package[] = [];
  private excludedSiteList: Building[] = [];
  private availablePackageList: Package[] = [];
  private isServiceUnactivated: boolean = false;
  private isServiceUnavailable: boolean = false;
  private selectedSite: Building = new Building('', '', '', [], []);
  private siteList: Building[] = [];
  private activeMenu: Link;

  /**
   * コンストラクター関数
   * @param dataManagementService データ管理サービス
   * @param toastService トーストサービス
   * @param loaderService ローダーサービス
   * @param multiLanguageMessageService 多言語対応サービス
   * @param restClientSettingService Rest通信部 システム設定サービス
   * @param menuManagementService メニュー管理サービス
   * @param packageManagementService パッケージ管理サービス
   */
  /**
   * Constructor function
   * @param dataManagementService Data management service
   * @param toastService Toast service
   * @param loaderService Loading animation service
   * @param multiLanguageMessageService Multilingual service
   * @param restClientSettingService RestClient Setting Service
   * @param menuManagementService Menu Management Service
   * @param packageManagementService Package Management Service
   */
  constructor(
    private dataManagementService: DataManagementService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private restClientSettingService: RestClientSettingService,
    private menuManagementService: MenuManagementService,
    private packageManagementService: PackageManagementService,
  ) {
    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // this.restClientSettingService.setScreenId(ScreenId.ScreenCommon);
    // // 物件一覧、物件、エッジ、画面切り替えの更新にサブスクライブ
    // // Listen to changes from building list, building, edge and page navigation
    // combineLatest([
    //   this.dataManagementService.buildingListStream,
    //   this.dataManagementService.edgeStream.pipe(
    //     filter((stream: BuildingEdgeStream) => stream.type === StreamType.Building),
    //   ),
    //   this.dataManagementService.edgeStream.pipe(
    //     filter((stream: BuildingEdgeStream) => stream.type === StreamType.Edge),
    //   ),
    //   this.menuManagementService.$activeMenuStream,
    // ]).subscribe(([siteListStream, siteStream, controllerStream, activeMenu]) => {
    //   this.siteList = this.dataManagementService.buildingList();
    //   this.selectedSite = this.dataManagementService.building();
    //   // カレント物件IDがNONEの場合、以前選択していた物件情報を利用する
    //   // Use previously selected building when selected building id is NONE
    //   if (this.selectedSite.id === 'NONE') {
    //     this.selectedSite = this.dataManagementService.prevBuilding();
    //   }
    //   this.activeMenu = activeMenu;
    //   this.checkPackages();
    // });
    // // パッケージ更新の通知にサブスクライブ
    // // Listen to notifications from package update
    // this.packageManagementService.$updatePackagesStream.subscribe(() => this.checkPackages());
    // this.dataManagementService.loginStatusStream
    //   .pipe(filter((status) => !status))
    //   .subscribe((status) => {
    //     this.clearProperty();
    //   });
  }

  /**
   * パッケージの確認
   */
  /**
   * Check packages
   */
  private async checkPackages() {
    this.$alertDataStream.next(null);
    this.menuManagementService.toggleUpgradeIcon(false);
    this.isServiceUnactivated = false;
    this.$serviceUnactivatedStream.next(this.isServiceUnactivated);
    this.isServiceUnavailable = false;
    this.$serviceUnavailableStream.next(this.isServiceUnavailable);

    if (this.dataManagementService.isLogin()) {
      if (this.dataManagementService.packageList().length > 0) {
        await this.updatePackageList();
      } else if (this.dataManagementService.cachesLoginData.packageList) {
        await this.updatePackageList(
          Utility.deepCopy(this.dataManagementService.cachesLoginData.packageList),
        );
      } else {
        await this.fetchPackages();
      }
      this.menuManagementService.setDisplayMenu(this.siteList);
    }

    if (
      this.activeMenu === null ||
      !this.activeMenu.enablePackageEdit ||
      this.siteList.length <= 0
    ) {
      this.checkServiceAvailability();
      return;
    }

    // 未契約か確認
    // Check if service is active
    this.checkServiceActivity();
    if (this.isServiceUnactivated) {
      return;
    }

    // 利用不可か確認
    // Check if service is available
    this.checkServiceAvailability();
    if (this.isServiceUnavailable) {
      return;
    }

    // 除外物件一覧の設定
    // Set excluded site list
    this.setExcludedSiteList();

    // 契約関連アラートバーの設定
    // Set alert-bar data
    this.setAlertData();
  }

  /**
   * 未契約か確認
   */
  /**
   * Check service activity
   */
  private checkServiceActivity() {
    this.isServiceUnactivated = this.siteList.every(
      (site) =>
        site.packageList.length <= 0 || site.packageList.every((packages) => packages.expired),
    );
    this.$serviceUnactivatedStream.next(this.isServiceUnactivated);
    this.menuManagementService.toggleUpgradeIcon(this.isServiceUnactivated);
  }

  /**
   * 利用不可か確認
   */
  /**
   * Check service availability
   */
  private checkServiceAvailability() {
    // 全物件の利用可能パッケージの一覧
    // Available packages from all sites
    const allSitesPackagesList = this.siteList
      .map((site) => this.getAvailableAssignedPackageList(site))
      .filter((packages) => packages.length > 0);

    // 全物件が利用できる画面一覧
    // Accessible screens from all sites
    const allSitesAvailableScreenList: ScreenId[] = Array.from(
      new Set(
        allSitesPackagesList
          .map((packages) => this.getAvailableMenuList(packages))
          .reduce((acc, val) => acc.concat(val), []),
      ),
    );

    // 選択物件の利用可能パッケージの一覧
    // Available packages from selected sites
    let selectedSitePackages = this.getAvailableAssignedPackageList(this.selectedSite);
    if (this.selectedSite.id === 'ALL') {
      // カレント物件IDがALLの場合、以前選択していた物件情報を利用する
      // Use previously selected building when selected building id is ALL
      const prevSelectedSite = this.dataManagementService.prevBuilding();
      selectedSitePackages = this.getAvailableAssignedPackageList(prevSelectedSite);
    }

    // 選択中物件が利用できる画面一覧
    // Accessible screens from selected site
    const selectedSiteAvailableScreenList: ScreenId[] = Array.from(
      new Set(this.getAvailableMenuList(selectedSitePackages)),
    );

    if (this.activeMenu !== null && this.activeMenu.enablePackageEdit && this.siteList.length > 0) {
      if (!this.activeMenu.enableSiteSelect || this.selectedSite.id === 'ALL') {
        this.isServiceUnavailable = !allSitesPackagesList.some((packages) =>
          this.getServiceAvailabilityState(packages),
        );
      } else if (this.activeMenu.id === ScreenId.ForcedStop) {
        // 緊急停止制御画面は利用不可画面出ない
        // unavailable screen does not appear from forced-stop screen
        this.isServiceUnavailable = false;
      } else {
        this.isServiceUnavailable = !this.getServiceAvailabilityState(selectedSitePackages);
      }
    }

    // 紐づく物件がない時、アップグレードアイコン出さない
    // When there is no associated building, the upgrade icon will not be displayed
    const hasAssociatedSite = this.selectedSite.id ? true : false;
    this.menuManagementService.toggleUpgradeIcon(
      hasAssociatedSite,
      allSitesAvailableScreenList,
      selectedSiteAvailableScreenList,
    );

    this.$serviceUnavailableStream.next(this.isServiceUnavailable);
  }

  /**
   * パッケージ一覧の取得
   * @returns 取得完了の Promise
   */
  /**
   * Fetch packages
   * @returns Promise upon completion
   */
  private fetchPackages(): Promise<void> {
    return new Promise((resolve) => {
      this.loaderService.showLoader();

      this.restClientSettingService.getSystemPackage('', false, true).subscribe(
        (res: Response) => {
          const data = res.data ? (res.data as SystemPackageGetResponse).items : [];

          this.updatePackageList(
            data.map(
              (packages) =>
                new Package(
                  packages.packageId,
                  packages.packageName,
                  packages.functionStatusList.map(
                    ({ mainMenu, subMenu }) =>
                      new FunctionStatus(
                        new Menu(mainMenu.screenId as ScreenId, mainMenu.availability),
                        subMenu.map(
                          (submenu) => new Menu(submenu.screenId as ScreenId, submenu.availability),
                        ),
                      ),
                  ),
                ),
            ),
          ).then(() => this.loaderService.hideLoader());
        },
        (err: Response) => {
          if (err.data.errors) {
            switch (err.status) {
              case 403:
                const apiType = ApiType.get;
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary(
                    Utility.getErrorMessageSid(err, apiType),
                  ),
                );
                break;
              case 408:
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary('sidServerErrorOccurred'),
                );
                break;
            }
          }

          this.updatePackageList([]).then(() => this.loaderService.hideLoader());
        },
        () => resolve(),
      );
    });
  }

  /**
   * パッケージ一覧の更新
   * @param packageList パッケージ一覧
   * @returns 更新完了の Promise
   */
  /**
   * Update package list
   * @param packageList Package list
   * @returns Promise upon completion
   */
  private updatePackageList(
    packageList: Package[] = this.dataManagementService.packageList(),
  ): Promise<void> {
    return new Promise((resolve) => {
      this.packageList = packageList;
      this.dataManagementService.setPackageList(this.packageList);

      // 利用可能パッケージ一覧の洗い出し
      // Filter out packages that are available
      this.availablePackageList = this.packageList.map((packages) => ({
        ...packages,
        functionStatusList: packages.functionStatusList
          .filter((functionStatus) => {
            return (
              (functionStatus.subMenu.length <= 0 &&
                functionStatus.mainMenu.hasOwnProperty('availability') &&
                functionStatus.mainMenu.availability) ||
              (functionStatus.subMenu.length > 0 &&
                functionStatus.subMenu.some(
                  (menu) => menu.hasOwnProperty('availability') && menu.availability,
                ))
            );
          })
          .map((functionStatus) => {
            if (functionStatus.subMenu.length > 0) {
              return {
                ...functionStatus,
                subMenu: functionStatus.subMenu.filter((menu) => menu.availability),
              };
            }

            return functionStatus;
          }),
      }));

      resolve();
    });
  }

  /**
   * 物件に紐づいている利用可能パッケージ一覧の取得
   * @param 対象物件
   * @returns 利用可能パッケージ一覧
   */
  /**
   * Get available packages assigned to the given site
   * @param site Site
   * @returns Available assigned packages
   */
  private getAvailableAssignedPackageList(site: Building): Package[] {
    return this.availablePackageList
      .filter((packages) =>
        site.packageList
          .filter((packages) => !packages.expired)
          .map((packages) => packages.packageId)
          .includes(packages.id),
      )
      .filter((packages) => packages.functionStatusList.length > 0);
  }

  /**
   * パッケージ一覧から利用可否状態の取得
   * @param packageList パッケージ一覧
   * @returns 利用可否状態（true: 利用可、false: 利用不可）
   */
  /**
   * Get availability state from given package list
   * @param packageList Package list
   * @returns Availability state (true: available, false: unavailable)
   */
  private getServiceAvailabilityState(packageList: Package[]): boolean {
    return packageList.some((packages) =>
      packages.functionStatusList.some(
        (functionStatus) =>
          (functionStatus.subMenu.length <= 0 &&
            functionStatus.mainMenu.screenId === this.activeMenu.id) ||
          (functionStatus.subMenu.length > 0 &&
            functionStatus.subMenu.some((menu) => menu.screenId === this.activeMenu.id)),
      ),
    );
  }

  /**
   * パッケージ一覧から利用可能画面一覧の取得
   * @param packageList パッケージ一覧
   * @returns 利用可能画面一覧
   */
  /**
   * Get accessible screens from given package list
   * @param packageList Package list
   * @returns Accessible screens
   */
  private getAvailableMenuList(packageList: Package[]): ScreenId[] {
    return [].concat.apply(
      [],
      packageList.map((packages) =>
        [].concat.apply(
          [],
          packages.functionStatusList.map((functionStatus) => {
            if (functionStatus.subMenu.length > 0) {
              return [].concat.apply(
                [],
                functionStatus.subMenu.map((menu) => menu.screenId),
              );
            }
            return functionStatus.mainMenu.screenId;
          }),
        ),
      ),
    );
  }

  /**
   * アラートバーのデータの設定
   */
  /**
   * Set alert-bar data
   */
  private setAlertData() {
    if (
      (!this.activeMenu.enableSiteSelect || this.selectedSite.id === 'ALL') &&
      this.siteList.length > 1 &&
      this.excludedSiteList.length > 0
    ) {
      this.$alertDataStream.next({
        type: 'warn',
        summary: this.multiLanguageMessageService.dictionary('sidFunctionNotAvailable'),
        details: {
          title: this.multiLanguageMessageService.dictionary('sidFollowingSiteExcluded'),
          list: this.excludedSiteList.map((site) => ({ detail: site.name })),
        },
      });
    }
  }

  /**
   * 除外物件一覧の設定
   */
  /**
   * Set excluded site list
   */
  private setExcludedSiteList() {
    const packageIdList = this.packageList
      .map((packages) => ({
        ...packages,
        functionStatusList: packages.functionStatusList.filter(
          (functionStatus) =>
            (functionStatus.mainMenu.screenId === this.activeMenu.id &&
              functionStatus.mainMenu.hasOwnProperty('availability') &&
              functionStatus.mainMenu.availability) ||
            functionStatus.subMenu.some(
              (menu) =>
                menu.screenId === this.activeMenu.id &&
                menu.hasOwnProperty('availability') &&
                menu.availability,
            ),
        ),
      }))
      .filter((packages) => packages.functionStatusList.length > 0)
      .map((packages) => packages.id);

    this.excludedSiteList = this.siteList
      .filter((site) => site.id !== 'ALL')
      .map(
        (site) =>
          new Building(
            site.id,
            site.name,
            site.currentTime,
            site.edgeList,
            site.packageList.filter(
              (packages) => packageIdList.includes(packages.packageId) && !packages.expired,
            ),
          ),
      )
      .filter((site) => site.packageList.length <= 0);

    this.dataManagementService.setExcludedBuildingList(
      this.excludedSiteList.map(
        (site) =>
          new Building(site.id, site.name, site.currentTime, site.edgeList, site.packageList),
      ),
    );
  }

  /**
   * プロパティをクリアする
   */
  /**
   * Clear Property
   */
  private clearProperty() {
    this.isZoomOut = false;
  }
}
