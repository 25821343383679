import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';
import { DialogComponent } from 'src/app/shared-preparation/components/dialog/dialog.component';

@Component({
  selector: 'app-dialog-overlay-setting',
  templateUrl: './dialog-overlay-setting.component.html',
  styleUrls: ['../dialog/dialog.component.scss', './dialog-overlay-setting.component.scss'],
})
export class DialogOverlaySettingComponent extends DialogComponent {
  @Input() maxWidth?: number;
  @Input() disabledConfirmButton: boolean = false;
  @Input() overlayContents: boolean = false;
  @Output() overlayClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * コンストラクター
   * @param elementRef エレメントのリファレンス
   * @param {CookieService} cookieService クッキーサービス
   */
  /**
   * Constructor
   * @param elementRef Element reference
   * @param {CookieService} cookieService Cookie service
   */
  constructor(private eElementRef: ElementRef, private eCookieService: CookieService) {
    super(eElementRef, eCookieService);
  }

  /**
   * 画面サイズに応じたスタイルを取得
   * @return ダイアログサイズのスタイル
   */
  /**
   * Get the style according to the screen size
   * @return Dialog size style
   */
  get dialogPopupStyle(): { [key: string]: any } {
    return {
      'max-height.%': 80,
      'max-width.px': this.maxWidth,
    };
  }
}
