export enum EditSiteItemId {
  EditPackage = 'editPackage',
  EditPayment = 'editPayment',
  RelatedAffiliate = 'relatedAffiliate',
  RelatedCompanyGroup = 'relatedCompanyGroup',
  RelatedCompany = 'relatedCompany',
  RelatedTenant = 'relatedTenant',
  RelatedSites = 'relatedSites',
  SelectableAffiliate = 'selectableAffiliate',
  MultiSiteRelated = 'multiSiteRelated',
  EditAccess = 'showAccess',
  ShowScreenGroup = 'showScreenGroup',
  ShowSiteAndPackage = 'showSiteAndPackage',
}
