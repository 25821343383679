export const screens = [
  {
    screenId: '10000',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'COMMISSIONING WEB APP & LOCAL APP',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: '20000',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'COMMISSIONING MOBILE APP',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: '9997',
    screenName: 'Reference Roll for ScreenGroup',
    roleIds: ['014', '015'],
  },
  {
    screenId: '9998',
    screenName: 'Reference Roll for Building',
    roleIds: [
      '002',
      '003',
      '004',
      '005',
      '006',
      '007',
      '008',
      '009',
      '010',
      '011',
      '012',
      '013',
      '014',
      '015',
    ],
  },
  {
    screenId: '9999',
    screenName: 'Reference Roll for User',
    roleIds: [
      '002',
      '003',
      '004',
      '005',
      '006',
      '007',
      '008',
      '009',
      '010',
      '011',
      '012',
      '013',
      '014',
      '015',
    ],
  },
  {
    screenId: 'accessLevels',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'ACCESS LEVELS',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/systemSettings/v1/accessLevels',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/systemSettings/v1/accessLevels',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: false,
        url: '/systemSettings/v1/accessLevels/{roleId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: false,
        url: '/systemSettings/v1/accessLevels/{roleId}',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'addInspectionNotification',
    enableSetting: false,
    referenceScreenId: 'inspectionNotificationList',
    screenType: 'main',
    screenName: 'ADD INSPECTION NOTIFICATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}/state',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'addRefrigerantLeakNotification',
    enableSetting: false,
    referenceScreenId: 'refrigerantLeakNotificationList',
    screenType: 'main',
    screenName: 'ADD REFRIGERANT LEAK  NOTIFICATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}/state',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotificationTrialMail',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'addSite',
    enableSetting: false,
    referenceScreenId: 'siteList',
    screenType: 'main',
    screenName: 'ADD SITE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/systemSettings/v1/packages',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'EditPackage',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editPackage',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'SelectableAffiliate',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'selectableAffiliate',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'addUser',
    enableSetting: false,
    referenceScreenId: 'userList',
    screenType: 'main',
    screenName: 'ADD USER',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/role/v1/roles/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/{affiliateId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/{affiliateId}/companyGroups/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/{companyGroupId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/{companyGroupId}/companies/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/companies/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/userTag/v1/companies/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/userTag/v1/companies/{companyId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/authority/v1/accessLevels/{roleId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'EditableUserTag',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editableUserTag',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowAffiliate',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showAffiliate',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowCompanyGroup',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showCompanyGroup',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowCompany',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showCompany',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowTenant',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showTenant',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'SelectableUserTag',
        itemType: 'checkbox',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'selectableAffiliate',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'selectableCompanyGroup',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'selectableCompany',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'selectableTenant',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowAssociatedSite',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showAssociatedSite',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'RequiredTelephoneNumber',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'screenCommon',
            linkageItemId: '',
            itemId: 'requiredTelephoneNumber',
            referenceItemId: 'multiFactorAuthentication',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowAutomaticLogout',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showAutomaticLogout',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowErrorNotificationSetting',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showErrorNotificationSetting',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditableAutomaticLogout',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editableAutomaticLogout',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditableErrorNotificationSetting',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editableErrorNotificationSetting',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowCommunicationErrorNotification',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showCommunicationErrorNotification',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditableCommunicationErrorNotification',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editableCommunicationErrorNotification',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'InheritUserData',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'inheritUserData',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
    ],
  },
  {
    screenId: 'appliedEquipment',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'APPLIED EQUIPMENT',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/clientSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/clientSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/serverSettings/check',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/serverSettings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/serverSettings',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'bacnet',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'BACNET',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/function',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/function',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/communicationSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/communicationSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/covEventSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/covEventSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/blackoutAndRestore',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/blackoutAndRestore',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/blockSettings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/blockSettings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/schedules',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/schedules/{scheduleNo}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/bacnet-historyData',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'bacnetClient',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'BACNET CLIENT',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/function/client',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/clientSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/clientSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/serverSettings/check',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/serverSettings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/bacnet/v1/edges/{edgeId}/serverSettings',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'daikinFreontenkenCheckTool',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DAIKIN FREONTENKEN CHECK TOOL',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'dataOutput',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DATA OUTPUT',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/queues',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/queues',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/queues/{queueId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/queues/{queueId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/queues/{queueId}/download',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/dataOutputTemplates',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/dataOutputTemplates',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/dataOutput/v1/buildings/{buildingId}/dataOutputTemplates/{templateId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'HourlyDataOutputItem',
        itemType: 'radioGroup',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'hourlyDataNone',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'hourlyDataAll',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'hourlyDataPart',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EquipmentDataOutputItem',
        itemType: 'radioGroup',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'equipmentDataNone',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'equipmentDataAll',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'equipmentDataPart',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'demandControlGroupSettings',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND CONTROL GROUP SETTINGS',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/controlGroup',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/controlGroup',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/forcedLevel',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'ForcedLevelComp',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'forcedLevelComp',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'demandControlSettings',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND CONTROL SETTINGS',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/demandData',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/demandData',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'demandControlState',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND CONTROL STATE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/currentControlState',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'demandCurrentState',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND CURRENT STATE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/predictionState/requestToSend',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/currentPredictionState',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'DemandGraphTrans',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'demandGraph',
            linkageItemId: '',
            itemId: 'demandGraphTrans',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ControlSettingsTrans',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'demandControlSettings',
            linkageItemId: '',
            itemId: 'controlSettingsTrans',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'TargetPowerTrans',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'demandTargetPowerSettings',
            linkageItemId: '',
            itemId: 'targetPowerTrans',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'GroupSettingsTrans',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'demandControlGroupSettings',
            linkageItemId: '',
            itemId: 'groupSettingsTrans',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
    ],
  },
  {
    screenId: 'demandGraph',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND GRAPH',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/demandHistory',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'demandModeSettings',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND MODE SETTINGS',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/demandData',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/demandData',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'demandTargetPowerSettings',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DEMAND TARGET POWER SETTINGS',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/targetPower',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/targetPower',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'download',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'DOWNLOAD',
    apiAttributes: [],
    screenAvailable: true,
    items: [
      {
        itemName: 'LocalApplication',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'localApplication',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'edgeControl',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'EDGE CONTROL',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/currentEdgeStates',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/edge/v1/certificationStates/{edgeId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'edgeNetwork',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: "EDGE'S NETWORK INFORMATION",
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/currentEdgeStates',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'edgeUpdate',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'EDGE UPDATE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges/firmwareUpgradeSchedules/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edgeModels/{edgeModelCode}/edgeFirmwares/{firmwareCode}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/edge/v1/edges/{edgeId}/firmwareUpgradeSchedules',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'editInspectionNotification',
    enableSetting: false,
    referenceScreenId: 'inspectionNotificationList',
    screenType: 'main',
    screenName: 'EDIT INSPECTION NOTIFICATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}/state',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'editRefrigerantLeakNotification',
    enableSetting: false,
    referenceScreenId: 'refrigerantLeakNotificationList',
    screenType: 'main',
    screenName: 'EDIT REFRIGERANT LEAK  NOTIFICATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}/state',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotificationTrialMail',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'editSite',
    enableSetting: false,
    referenceScreenId: 'siteList',
    screenType: 'main',
    screenName: 'EDIT SITE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/systemSettings/v1/packages',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/role/v1/roles/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/authority/v1/accessLevels/{roleId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groups',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groupLink/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groupLink/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groupLink/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groupLink',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'EditPackage',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addSite',
            linkageItemId: '',
            itemId: 'editPackage',
            referenceItemId: 'editPackage',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'EditPayment',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editPayment',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'RelatedUserTag',
        itemType: 'checkbox',
        authorityStates: [
          {
            referenceScreenId: 'userList',
            linkageItemId: '',
            itemId: 'relatedAffiliate',
            referenceItemId: 'relatedAffiliate',
            itemEnable: false,
          },
          {
            referenceScreenId: 'userList',
            linkageItemId: '',
            itemId: 'relatedCompanyGroup',
            referenceItemId: 'relatedCompanyGroup',
            itemEnable: false,
          },
          {
            referenceScreenId: 'userList',
            linkageItemId: '',
            itemId: 'relatedCompany',
            referenceItemId: 'relatedCompany',
            itemEnable: false,
          },
          {
            referenceScreenId: 'userList',
            linkageItemId: '',
            itemId: 'relatedTenant',
            referenceItemId: 'relatedTenant',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'RelatedSites',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'userList',
            linkageItemId: '',
            itemId: 'relatedSites',
            referenceItemId: 'relatedSites',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'SelectableAffiliate',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addSite',
            linkageItemId: '',
            itemId: 'selectableAffiliate',
            referenceItemId: 'selectableAffiliate',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'MultiSiteRelated',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'multiSiteRelated',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowAccess',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showAccess',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditAccessDeadline',
        itemType: 'radioGroup',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editAccessDeadline',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showAccessDeadline',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'hideAccessDeadline',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'AutoAssociationRelease',
        itemType: 'radioGroup',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'noDeadline',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'deadline',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'both',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowSiteAndPackage',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showSiteAndPackage',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowScreenGroup',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showScreenGroup',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'editUser',
    enableSetting: false,
    referenceScreenId: 'userList',
    screenType: 'main',
    screenName: 'EDIT USER',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/role/v1/roles/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/{affiliateId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/{affiliateId}/companyGroups/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/{companyGroupId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/companyGroups/{companyGroupId}/companies/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/companies/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/userTag/v1/companies/',
      },
      {
        exclusionParameters: [],
        itemId: 'editableUserTag',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/userTag/v1/companies/{companyId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/authority/v1/accessLevels/{roleId}',
      },
      {
        exclusionParameters: [],
        itemId: 'requiredTelephoneNumber',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/auth/v1/changeTelephoneNumber/send',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'EditableUserTag',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'editableUserTag',
            referenceItemId: 'editableUserTag',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowAffiliate',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showAffiliate',
            referenceItemId: 'showAffiliate',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowCompanyGroup',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showCompanyGroup',
            referenceItemId: 'showCompanyGroup',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowCompany',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showCompany',
            referenceItemId: 'showCompany',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowTenant',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showTenant',
            referenceItemId: 'showTenant',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'SelectableUserTag',
        itemType: 'checkbox',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'selectableAffiliate',
            referenceItemId: 'selectableAffiliate',
            itemEnable: true,
          },
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'selectableCompanyGroup',
            referenceItemId: 'selectableCompanyGroup',
            itemEnable: true,
          },
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'selectableCompany',
            referenceItemId: 'selectableCompany',
            itemEnable: true,
          },
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'selectableTenant',
            referenceItemId: 'selectableTenant',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'RequiredTelephoneNumber',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'screenCommon',
            linkageItemId: '',
            itemId: 'requiredTelephoneNumber',
            referenceItemId: 'multiFactorAuthentication',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowAutomaticLogout',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showAutomaticLogout',
            referenceItemId: 'showAutomaticLogout',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowErrorNotificationSetting',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showErrorNotificationSetting',
            referenceItemId: 'showErrorNotificationSetting',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'EditableAutomaticLogout',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'editableAutomaticLogout',
            referenceItemId: 'editableAutomaticLogout',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'EditableErrorNotificationSetting',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'editableErrorNotificationSetting',
            referenceItemId: 'editableErrorNotificationSetting',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowCommunicationErrorNotification',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'showCommunicationErrorNotification',
            referenceItemId: 'showCommunicationErrorNotification',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'EditableCommunicationErrorNotification',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'addUser',
            linkageItemId: '',
            itemId: 'editableCommunicationErrorNotification',
            referenceItemId: 'editableCommunicationErrorNotification',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
    ],
  },
  {
    screenId: 'emailConfirmation',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'EMAIL ADDRESS CONFIRMATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/auth/v1/changeEmail/execute',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'emergencyOperationConfigurationConfirmation',
    enableSetting: false,
    referenceScreenId: 'mailSetting',
    screenType: 'sub',
    screenName: 'EMERGENCY OPERATION CONFIRMATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/emergencyOperation/v1/emergencyOn/{equipmentId}',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'emergencyOperationConfigurationRequest',
    enableSetting: false,
    referenceScreenId: 'mailSetting',
    screenType: 'sub',
    screenName: 'EMERGENCY OPERATION REQUEST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/emergencyOperation/v1/errorInfo/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/emergencyOperation/v1/check/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/systemSettings/v1/packages',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'emergencyOperationError',
    enableSetting: false,
    referenceScreenId: 'mailSetting',
    screenType: 'sub',
    screenName: 'EMERGENCY OPERATION ERROR',
    apiAttributes: [],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'emergencyOperationRequestCompletion',
    enableSetting: false,
    referenceScreenId: 'mailSetting',
    screenType: 'sub',
    screenName: 'EMERGENCY OPERATION COMPLETION',
    apiAttributes: [],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'energyConsumption',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'ENERGY CONSUMPTION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/actualConsumption/{patternId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'energySavingSimulation',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'ENERGY SAVING SIMULATION',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'equipmentData',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'EQUIPMENT DATA',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataCollectionSettings/v1/edges/{edgeId}/equipments/{equipmentId}/accumulationFrequency',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/dataCollectionSettings/v1/edges/{edgeId}/equipments/{equipmentId}/accumulationFrequency',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataCollectionSettings/v1/edges/{edgeId}/updateFrequency',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/dataCollectionSettings/v1/edges/{edgeId}/updateFrequency',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'equipmentList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'EQUIPMENT LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/currentEquipmentStates/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipments',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/names/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/zones/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/equipment/v1/edges/{edgeId}/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/edges/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/equipment/v1/edges/{edgeId}/equipments/{equipmentId}/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'SOCKET',
        urlAvailable: true,
        url: 'equipmentEventNotification',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/equipment/v1/edges/{edgeId}/operation',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/edges/operation',
      },
      {
        exclusionParameters: [],
        itemId: 'uploadSettings',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/checkEquipments',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges/firmwareUpgradeSchedules/{buildingId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'SettingWhenMaintenance',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'settingWhenMaintenance',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'SettingWhenLocal',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'settingWhenLocal',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditEquipmentName',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editEquipmentName',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'NavigationToZoneList',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'zoneList',
            linkageItemId: '',
            itemId: 'navigationToZoneList',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'OutdoorUnitOperation',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'outdoorUnitOperation',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'AdvancedSettings',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'advancedSettings',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'R32RefrigerantLeakAlarmStop',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'r32RefrigerantLeakAlarmStop',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'DownloadSettings',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'downloadSettings',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'UploadSettings',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'uploadSettings',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'errorNotification',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'ERROR NOTIFICATION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/errorNotificationSetting/{personId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'forcedStop',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'FORCED STOP',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs/{prgId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs/{prgId}/valid',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs/{prgId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs/{prgId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/emergencystop/v1/edges/{edgeId}/prgs/{prgId}/release',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'gateway',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'GATEWAY',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments/check',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'history',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'HISTORY',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/virtualList/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/history/v1/buildings/{buildingId}/historyData',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'hourlyData',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'HOURLY DATA',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/dataCollectionSettings/v1/buildings/{buildingId}/hourlyData',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/dataCollectionSettings/v1/buildings/{buildingId}/hourlyData',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'inspectionNotificationList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'INSPECTION NOTIFICATION LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preSeasonMailNotifications/{remoteInspectionNotificationId}/state',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'inspectionRequest',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'INSPECTION REQUEST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/refrigerantSystems',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preseasonInspectionDates',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preseasonInspectionDates',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/inspectionPeriod',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'inspectionResult',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'INSPECTION RESULT',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/getProposalInfo',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/preseasonInspectionResult',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/preSeasonRemoteInspection/v1/buildings/{buildingId}/inspectionPeriod',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'interlocking',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'INTERLOCKING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs/{prgId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs/{prgId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs/{prgId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges/{edgeId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'layoutSetting',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'LAYOUT SETTING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/screen',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/order',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/screen',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/screen',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/screen',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/view',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/equipmentlinks',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/equipmentlinks/{equipmentLinkId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/equipmentlinks/{equipmentLinkId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/equipmentlinks',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/image',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groups',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groups/{layoutGroupId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groups/{layoutGroupId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/groups',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/edges/{edgeId}/movecopy',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/layouts/{layoutId}/linkButtons',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/layout/v1/layouts/{layoutId}/linkButtons',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/layouts/{layoutId}/linkButtons/{linkButtonId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/layout/v1/layouts/{layoutId}/linkButtons/{linkButtonId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/edges/{edgeId}/layoutDataStatus',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/layout/v1/layouts/{layoutId}/temporaryData',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/equipment/v1/edges/{edgeId}/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/edges/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/authority/v1/accessLevels/{roleId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'ShowAllLayoutScreens',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'layoutView',
            linkageItemId: '',
            itemId: 'showAllLayoutScreens',
            referenceItemId: 'showAllLayoutScreens',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'OutdoorUnitOperation',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'outdoorUnitOperation',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'layoutView',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'LAYOUT VIEW',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/screen',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}/view',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/currentEquipmentStates/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'SOCKET',
        urlAvailable: true,
        url: 'equipmentEventNotification',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/equipment/v1/edges/{edgeId}/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/edges/icon',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/zone/v1/currentZoneStates',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/equipment/v1/edges/{edgeId}/operation',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/edges/operation',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/zones/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/order',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/layout/v1/buildings/{buildingId}/layouts/{layoutId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'OutdoorUnitOperation',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'outdoorUnitOperation',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowAllLayoutScreens',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showAllLayoutScreens',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditScreenSequential',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editScreenSequential',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'R32RefrigerantLeakAlarmStop',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'r32RefrigerantLeakAlarmStop',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'login',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'LOGIN',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/auth/v1/changeEmail/execute',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/auth/v1/changeEmail/',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'mailNotificationSetting',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'MAIL NOTIFICATION SETTING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/mailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/mailNotifications/{errorMailNotificationId}/state',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/mailNotifications/{errorMailNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/mailNotifications/{errorMailNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/mailNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/mailNotifications/{errorMailNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/mailNotification/v1/buildings/{buildingId}/trialMail',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'mailSetting',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'MAIL SETTING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/emergencyOperation/v1/notification/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/emergencyOperation/v1/notification/{personId}',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'mapView',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'MAP VIEW',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/mapkey',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/siteStates/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/edgeStates/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/equipmentStates/{edgeId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'dashboardView',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'DASHBOARD VIEW',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/mapkey',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/siteStates/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multistore/v1/failureMap/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/edgeStates/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/multisite/v1/equipmentStates/{edgeId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'meterList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'METER LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/listDisplayData/{edgeId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'meteringTrend',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'METERING TREND',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/meteringTrendOperationMonitoring/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/virtualList/{buildingId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'modCtrl',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'MOD_CTRL',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/modbustcp/v1/edges/{edgeId}/modbusTcpSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/modbustcp/v1/edges/{edgeId}/modbusTcpSetting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments/check',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/equipments',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/monitoringItemsViewSetting/list',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/commissioning/v1/edges/{edgeId}/monitoringItemsViewSetting',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'multiSiteCombinedConsumption',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'MULTI-SITE COMBINED CONSUMPTION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/energyManagement/v1/consumption/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/building/v1/buildings',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'multiSiteComparison',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'MULTI-SITE COMPARISON',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/energyManagement/v1/consumption/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'myProfile',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'MY PROFILE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/auth/v1/changeTelephoneNumber/send',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/auth/v1/changeTelephoneNumber/execute',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'RequiredTelephoneNumber',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'screenCommon',
            linkageItemId: '',
            itemId: 'requiredTelephoneNumber',
            referenceItemId: 'multiFactorAuthentication',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
    ],
  },
  {
    screenId: 'notificationMessage',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'NOTIFICATION MESSAGE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/systemSettings/v1/notificationMessage',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/systemSettings/v1/notificationMessage',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'operationDataOutput',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'OPERATION DATA OUTPUT',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/dataOutput/queues',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/energyManagement/v1/dataOutput/queues',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/dataOutput/queues/{queueId}/download',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/energyManagement/v1/dataOutput/queues/{queueId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/dataOutput/queues/{queueId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'operationMonitoring',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'OPERATION MONITORING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/meteringTrendOperationMonitoring/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'outdoorUnitComparison',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'OUTDOOR UNIT COMPARISON',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/outdoorUnitComparison/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'packages',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'PACKAGES',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/systemSettings/v1/packages',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/systemSettings/v1/packages',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: false,
        url: '/systemSettings/v1/packages/{packageId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: false,
        url: '/systemSettings/v1/packages/{packageId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/authority/v1/packages/{packageId}',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'patternMeterSelection',
    enableSetting: false,
    referenceScreenId: 'patternSettings',
    screenType: 'main',
    screenName: 'PATTERN METER SELECTION',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'patternSettings',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'PATTERN SETTINGS',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns/{patternId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns/{patternId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/buildings/{buildingId}/completeList',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/commissioning/v1/virtualList/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/targetEnergySettings/{patternId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/actualConsumption/{patternId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/energyManagement/v1/targetEnergySettings/',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'ppdCollectionPeriodSetup',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'PPD COLLECTION PERIOD SETUP',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/powerProportionalDistribution/v1/buildings/{buildingId}/scheduleSettings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/powerProportionalDistribution/v1/buildings/{buildingId}/scheduleSettings',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'ppdCollectionTotal',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'PPD COLLECTION TOTAL',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/powerProportionalDistribution/v1/buildings/{buildingId}/ppdResults',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'preview',
    enableSetting: false,
    referenceScreenId: 'layoutSetting',
    screenType: 'sub',
    screenName: 'LAYOUT PREVIEW',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'r32RefrigerantLeakAlarmSetting',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'R32 REFRIGERANT LEAK ALARM SETTING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/r32RefrigerantLeakAlarm/v1/edges/{edgeId}/requestToTest',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/r32RefrigerantLeakAlarm/v1/edges/{edgeId}/setting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/r32RefrigerantLeakAlarm/v1/edges/{edgeId}/setting',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'refrigerantLeakNotificationList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'REFRIGERANT LEAK  NOTIFICATION LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotifications/{refrigerantLeakNotificationId}/state',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/refrigerantLeakNotification/v1/buildings/{buildingId}/refrigerantLeakNotificationTrialMail',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'schedule',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'SCHEDULE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: 'multiSiteEditSchedule',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/{programId}',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/{programId}',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/{programId}',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/copy',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/copy',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/programs/{programId}/overwrite',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/calendars',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/calendars',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/actionTemplates',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/actionTemplates',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/actionTemplates/{actionTemplateId}',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/specialDaysTemplates',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/specialDaysTemplates',
      },
      {
        exclusionParameters: [],
        itemId: 'editSchedule',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/specialDaysTemplates/{specialDaysTemplateId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges/{edgeId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/outputPrograms',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/schedule/v1/buildings/{buildingId}/checkPrograms',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges/firmwareUpgradeSchedules/{buildingId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'MultiSiteEditSchedule',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'multiSiteEditSchedule',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditSchedule',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editSchedule',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditAdvancedSettings',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'equipmentList',
            linkageItemId: '',
            itemId: 'editAdvancedSettings',
            referenceItemId: 'advancedSettings',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'RelatedInterlocking',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'interlocking',
            linkageItemId: '',
            itemId: 'relatedInterlocking',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowAccess',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'editSite',
            linkageItemId: '',
            itemId: 'showAccess',
            referenceItemId: 'showAccess',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'EditOutdoorControl',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'equipmentList',
            linkageItemId: '',
            itemId: 'editOutdoorControl',
            referenceItemId: 'outdoorUnitOperation',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
    ],
  },
  {
    screenId: 'scheduleExecution',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'SCHEDULE EXECUTION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/executeSchedules/',
      },
      {
        exclusionParameters: [],
        itemId: 'editScheduleExecute',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/schedule/v1/edges/{edgeId}/zones/{zoneId}/executeSchedules/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/actionTemplates',
      },
      {
        exclusionParameters: [],
        itemId: 'editScheduleExecute',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/actionTemplates',
      },
      {
        exclusionParameters: [],
        itemId: 'editScheduleExecute',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/schedule/v1/persons/person/actionTemplates/{actionTemplateId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/equipment/v1/equipments/equipment',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/interlocking/v1/edges/{edgeId}/prgs',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/edge/v1/edges/{edgeId}',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'EditScheduleExecute',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editScheduleExecute',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ShowAccess',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'editSite',
            linkageItemId: '',
            itemId: 'showAccess',
            referenceItemId: 'showAccess',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
    ],
  },
  {
    screenId: 'screen',
    enableSetting: false,
    referenceScreenId: 'layoutSetting',
    screenType: 'sub',
    screenName: 'LAYOUT SCREEN',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'screenCommon',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'SCREEN COMMON',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/authority/v1/accessLevels/{roleId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/state/v1/states/error',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/state/v1/states/read',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/systemSettings/v1/notificationMessage',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/systemSettings/v1/packages',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/state/v1/transactionResults',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'SOCKET',
        urlAvailable: true,
        url: 'equipmentEventNotification',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/demand/v1/edges/{edgeId}/demandData',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'CommissioningLink',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'commissioningLink',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'DownloadLink',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'downloadLink',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'AlertBar',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'alertBar',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'Version',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'version',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'ErrorNotification',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'errorNotification',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ForcedStopAtAlertBar',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'forcedStop',
            linkageItemId: '',
            itemId: 'forcedStopAtAlertBar',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'DownloadAtAlertBar',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'screenCommon',
            linkageItemId: '',
            itemId: 'downloadAtAlertBar',
            referenceItemId: 'downloadLink',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'MultiFactorAuthentication',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'multiFactorAuthentication',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'screenGroupSettings',
    enableSetting: false,
    referenceScreenId: 'layoutSetting',
    screenType: 'sub',
    screenName: 'LAYOUT SCREEN GROUP SETTINGS',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'siteConsumption',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'SITE CONSUMPTION',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: false,
        url: '/energyManagement/v1/consumption/get',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: false,
        url: '/building/v1/buildings/{buildingId}',
      },
    ],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'siteList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'SITE LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/systemSettings/v1/packages',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: 'createAndDeleteSite',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: 'showSiteNotRelated',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'CreateAndDeleteSite',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'createAndDeleteSite',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EditSite',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'editSite',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'EdgeNetwork',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'edgeNetwork',
            linkageItemId: '',
            itemId: 'edgeNetwork',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'EdgeControl',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: 'edgeControl',
            linkageItemId: '',
            itemId: 'edgeControl',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: false,
      },
      {
        itemName: 'ShowSiteNotRelated',
        itemType: 'checkbox',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showSiteNotRelated',
            referenceItemId: '',
            itemEnable: true,
          },
          {
            referenceScreenId: '',
            linkageItemId: 'showSiteNotRelated',
            itemId: 'relatedAffiliate',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: true,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'targetEnergySettings',
    enableSetting: false,
    referenceScreenId: 'patternSettings',
    screenType: 'main',
    screenName: 'TARGET ENERGY SETTINGS',
    apiAttributes: [],
    screenAvailable: false,
    items: [],
  },
  {
    screenId: 'temperatureMonitoring',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'TEMPERATURE MONITORING',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/temperatureMonitoring/{equipmentId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/equipments/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/building/v1/buildings/{buildingId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/thermalEnvironmentTrend/{patternId}',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/energyManagement/v1/patterns',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'termOfUse',
    enableSetting: false,
    referenceScreenId: '',
    screenType: 'sub',
    screenName: 'TERMS OF USE',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'userList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'USER LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/role/v1/roles/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/userTag/v1/affiliates/',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/persons/get',
      },
      {
        exclusionParameters: [],
        itemId: 'showUserDelete',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/person/v1/persons/{personId}',
      },
      {
        exclusionParameters: [],
        itemId: 'showUserDelete',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/person/v1/checkPersons',
      },
    ],
    screenAvailable: true,
    items: [
      {
        itemName: 'ShowUserDelete',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'showUserDelete',
            referenceItemId: '',
            itemEnable: true,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'RelatedSites',
        itemType: 'radio',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'relatedSites',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
      {
        itemName: 'RelatedUserTag',
        itemType: 'checkbox',
        authorityStates: [
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'relatedAffiliate',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'relatedCompanyGroup',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'relatedCompany',
            referenceItemId: '',
            itemEnable: false,
          },
          {
            referenceScreenId: '',
            linkageItemId: '',
            itemId: 'relatedTenant',
            referenceItemId: '',
            itemEnable: false,
          },
        ],
        linkage: false,
        enableSetting: true,
      },
    ],
  },
  {
    screenId: 'zoneEquipment',
    enableSetting: false,
    referenceScreenId: 'layoutSetting',
    screenType: 'sub',
    screenName: 'LAYOUT ZONE EQUIPMENT',
    apiAttributes: [],
    screenAvailable: true,
    items: [],
  },
  {
    screenId: 'zoneList',
    enableSetting: true,
    referenceScreenId: '',
    screenType: 'main',
    screenName: 'ZONE LIST',
    apiAttributes: [
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'GET',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'POST',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'PUT',
        urlAvailable: true,
        url: '/zone/v1/zones',
      },
      {
        exclusionParameters: [],
        itemId: '',
        httpMethod: 'DELETE',
        urlAvailable: true,
        url: '/zone/v1/zones/{zoneId}',
      },
    ],
    screenAvailable: true,
    items: [],
  },
];
