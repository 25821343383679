import { Pipe, PipeTransform } from '@angular/core';
import { MultiLanguageMessageService } from '../../core/services/multi-language-message/multi-language-message.service';
import { DecimalPipe as CommonDecimalPipe } from '../../common/pipes/decimal.pipe';

@Pipe({
  name: 'decimal',
  pure: false,
})
export class DecimalPipe extends CommonDecimalPipe implements PipeTransform {
  /**
   * コンストラクタ
   *
   * @param multiLanguageMessageService 多言語サービス
   */
  /**
   * constructor
   *
   * @param multiLanguageMessageService Multi language Service
   */
  constructor(multiLanguageMessageService: MultiLanguageMessageService) {
    super(multiLanguageMessageService);
  }

  /**
   * パイプメイン関数
   *
   * @param value 指定値
   * @param isDefaultLanguage デフォルト言語の設定有無
   * @return 変換結果
   */
  /**
   * Pipe main method
   *
   * @param value Value
   * @param isDefaultLanguage Whether to set the default language
   * @return Conversion number
   */
  transform(value: string | number, isDefaultLanguage: boolean = false): string {
    return super.transform(value, isDefaultLanguage);
  }
}
