import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';

import {
  DataOutputQueuePostRequest,
  DataOutputTemplateUpdateRequest,
} from '../interfaces/data-output-service';

const pathOfDataOutput = `dataOutput/${apiVersion}/`;

@Injectable()
export class RestClientDataOutputService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-28. 運転データ出力サービス
  // 3-28. Data output service
  /////////////////////////////////////////////////////////////////////////////
  // 3-28-1. 運転データ出力管理
  // 3-28-1. Data output management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * データ取出しキュー登録API
   *
   * @param {string} [buildingId] 物件ID
   * @param {DataOutputQueuePostRequest} param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Data output queue registration API
   * response body: queueId(string)
   *
   * @param {string} [buildingId] Building ID
   * @param {DataOutputQueuePostRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  postQueue(buildingId: string, param: DataOutputQueuePostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/queues`,
      param,
      this.screenId,
      false,
      true,
      true,
    );
  }

  /**
   * データ取出しキュー情報取得API
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [queueId] キューID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Data output queue info get API
   * response body: DataOutputQueueGetResponse
   *
   * @param {string} [buildingId] Building ID
   * @param {string} [queueId] Queue ID
   * @returns {Observable<Response>} status: HTTP status
   */
  getQueue(buildingId: string, queueId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/queues/${queueId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * データ取出しキュー情報一覧取得API
   *
   * @param {string} [buildingId] 物件ID
   * @param {boolean} [polling] ポーリング
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Data output queue info list get API
   * response body: DataOutputQueueListResponse
   *
   * @param {string} [buildingId] Building ID
   * @param {boolean} [polling] Polling
   * @returns {Observable<Response>} status: HTTP status
   */
  getQueueList(buildingId: string, polling: boolean = false): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/queues`,
      undefined,
      this.screenId,
      polling,
      polling,
    );
  }

  /**
   * データ取出しキュー削除API
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [queueId] キューID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Data output queue delete API
   * response body: none
   *
   * @param {string} [buildingId] Building ID
   * @param {string} [queueId] Queue ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteQueue(buildingId: string, queueId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/queues/${queueId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 運転データダウンロードAPI
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [queueId] キューID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Output data download API
   * response body: Location(string)
   *
   * @param {string} [buildingId] Building ID
   * @param {string} [queueId] Queue ID
   * @returns {Observable<Response>} status: HTTP status
   */
  getDownloadUrl(buildingId: string, queueId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/queues/${queueId}/download`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 出力データテンプレート一覧取得API
   *
   * @param {string} [buildingId] 物件ID
   * @param {number} [dataType] データ種別
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Output data template list get API
   * response body: DataOutputTemplateListGetResponse
   *
   * @param {string} [buildingId] Building ID
   * @param {number} [dataType] Data type
   * @returns {Observable<Response>} status: HTTP status
   */
  getDataOutputTemplateList(buildingId: string, dataType: number): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/dataOutputTemplates?dataType=${dataType}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 出力データテンプレート更新API
   *
   * @param {string} [buildingId] 物件ID
   * @param {number} [dataType] データ種別
   * @param {any[]} [templateList] 更新テンプレート一覧
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Output data template update API
   * response body: none
   *
   * @param {string} [buildingId] Building ID
   * @param {number} [dataType] Data type
   * @param {any[]} [templateList] Update template list
   * @returns {Observable<Response>} status: HTTP status
   */
  updateDataOutputTemplate(
    buildingId: string,
    dataType: number,
    param: DataOutputTemplateUpdateRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/dataOutputTemplates?dataType=${dataType}`,
      param,
      this.screenId,
    );
  }

  /**
   * 出力データテンプレート削除API
   *
   * @param {string} [buildingId] 物件ID
   * @param {any[]} [templateId] テンプレートID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Output data template update API
   * response body: none
   *
   * @param {string} [buildingId] Building ID
   * @param {string} [templateId] Template ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteDataOutputTemplate(buildingId: string, templateId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfDataOutput}buildings/${buildingId}/dataOutputTemplates/${templateId}`,
      undefined,
      this.screenId,
    );
  }
}
