import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/common/response';
import {
  EnergySiteConsumptionRequest,
  EnergyOutdoorRequest,
  EnergyTargetRequest,
  EnergyEquipmentsResponse,
} from '../interfaces/energy-service';
import { TopEntity } from '../enums/top-entity.enum';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  GetPatternListBasicInfoResponseData,
  GetPatternListRequestParam,
  GetPatternListResponseData,
  PatternType,
  PostPatternRequestBody,
  PostPatternResponseData,
  PutPatternRequestBody,
} from '../interfaces/energy-service/energy-pattern';
import {
  DataType,
  EnergyActualConsumptionRequest,
  GetEnergyActualConsumption,
} from '../interfaces/energy-service/energy-actual-consumption';
import { EnergyTargetResponse } from '../interfaces/energy-service/energy-target';
import {
  ThermalEnvironmentTrendRequestParam,
  ThermalEnvironmentTrendResponseData,
} from '../interfaces/energy-service/energy-thermal-environment-trend';
import {
  OperationDataOutputQueueGetResponse,
  OperationDataOutputQueueListResponse,
  OperationDataOutputQueuePostRequest,
  OperationDataOutputQueuePostGetResponse,
} from '../interfaces/energy-service/energy-data-output';
import {
  GetEnergyMeteringAndEstimateTrendRequest,
  GetEnergyMeteringAndEstimateTrend,
} from '../interfaces/energy-service/energy-metering-and-estimate-trend';
import {
  GetUpdatedMeterListTransactionResultResponseData,
  GetMeterListRequestParam,
  GetInitialMeterListResponses,
  GetMeterListResponseData,
} from '../interfaces/energy-service/energy-meter-list';
import {
  EnergyConsumptionRequestParam,
  EnergyConsumptionResponseData,
} from '../interfaces/energy-service/energy-consumption';
import { EnergyOutdoorResponse } from '../interfaces/energy-service/energy-outdoor';

const pathOfEnergy = `energyManagement/${apiVersion}/`;

@Injectable()
export class RestClientEnergyService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14. エネルギーマネジメント
  //  3-14. Energy Management
  /////////////////////////////////////////////////////////////////////////////
  //  3-14-1. 温度データ取得
  //  3-14-1. Temperature data acquisition
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 温熱環境トレンド取得API
   * @param param リクエストパラメータ
   * @return レスポンス
   */
  /**
   * Semen environment trend acquisition API
   * @param param Request parameter
   * @return response
   */
  getThermalEnvironmentTrend(
    param: ThermalEnvironmentTrendRequestParam,
  ): Observable<Response<ThermalEnvironmentTrendResponseData>> {
    const patternId = encodeURIComponent(param.patternId);
    const targetDate = encodeURIComponent(param.targetDate);
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}thermalEnvironmentTrend/${patternId}?targetDate=${targetDate}`,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-2. 物件消費量取得
  //  3-14-2. Acquisition of property consumption
  /////////////////////////////////////////////////////////////////////////////

  /**
   *  @deprecated
   * （廃止）物件消費量取得API
   * response body: EnergySiteConsumptionResponse(物件消費量)
   *                EnergyMultiSiteConsumptionTotalResponse(多物件消費量合計)
   *                EnergyMultiSiteConsumptionCompareResponse(多物件消費量比較)
   *
   * @param {EnergySiteConsumptionRequest} param リクエストパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * @deprecated
   * （abolition）Property consumption acquisition API
   * response body: EnergySiteConsumptionResponse
   *                  (Property consumption)
   *                EnergyMultiSiteConsumptionTotalResponse
   *                  (Total consumption of multiple properties)
   *                EnergyMultiSiteConsumptionCompareResponse
   *                  (Multiple property consumption comparison)
   *
   * @param {EnergySiteConsumptionRequest} param Request parameters
   * @return {Observable<Response>} status:HTTP status
   */
  // getEnergyConsumption(param: EnergySiteConsumptionRequest): Observable<Response> {
    /*
    Period毎に対象となる設定値のみをリクエストボディに設定してもいいが、
    許可されていないパラメータが設定されてもバックエンド側で
    無視されるため要求のまま設定する
    */
    /*
    Only the target setting value can be set in the request body for each period, 
    but even if a parameter that is not allowed is set, 
    it will be ignored on the back end side and set as requested
    */
    // const body = { mode: param.mode, period: param.period };
    // if (param.targetDate1) {
    //   Object.assign(body, { targetDate1: param.targetDate1 });
    // }
    // if (param.targetDate2) {
    //   Object.assign(body, { targetDate2: param.targetDate2 });
    // }
    // if (param.startMonth) {
    //   Object.assign(body, { startMonth: param.startMonth });
    // }
    // if (param.buildingId) {
    //   Object.assign(body, { buildingId: param.buildingId });
    // }
    // if (param.buildingIdList && param.buildingIdList.length > 0) {
    //   Object.assign(body, { buildingIdList: param.buildingIdList.join(',') });
    // }
    // if (param.hvacIdList && param.hvacIdList.length > 0) {
    //   Object.assign(body, { hvacIdList: param.hvacIdList.join(',') });
    // }
    // if (param.nonHvacIdList && param.nonHvacIdList.length > 0) {
    //   Object.assign(body, { nonHvacIdList: param.nonHvacIdList.join(',') });
    // }
    // if (param.sortType) {
    //   Object.assign(body, { sortType: param.sortType });
    // }
    // if (param.unitKind) {
    //   Object.assign(body, { unitKind: param.unitKind });
    // }
    // const callback = (): Observable<Response> => {
    //   return this.restClientCommonService.request(
    //     'post',
    //     `${this.endPoint}${pathOfEnergy}consumption/get`,
    //     body,
    //   );
    // };
    // return this.restClientCommonService.sqsRequest(callback);
  // }

  /**
   * 物件消費量取得API
   * @param param リクエストパラメータ
   * @returns レスポンス
   */
  /**
   * Object consumption API
   * @param param Request parameter
   * @returns response
   */
  getConsumption(
    param: EnergyConsumptionRequestParam,
  ): Observable<Response<EnergyConsumptionResponseData>> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEnergy}consumption/get`,
      param,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-3. 室外機消費量比較取得
  //  3-14-3. Comparison of outdoor unit consumption
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 室外機消費量比較取得API
   * response body: EnergyOutdoorResponse
   *
   * @param {EnergyOutdoorRequest} param クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Outdoor unit consumption comparison acquisition API
   * response body: EnergyOutdoorResponse
   *
   * @param {EnergyOutdoorRequest} param Query parameters
   * @return {Observable<Response>} status:HTTP status
   */
  getEnergyOutdoor(param: EnergyOutdoorRequest): Observable<Response<EnergyOutdoorResponse>> {
    let query = '';
    const queryArray: string[] = [];
    if (param.targetDate1) {
      queryArray.push(`targetDate1=${param.targetDate1}`);
    }
    if (param.targetDate2) {
      queryArray.push(`targetDate2=${param.targetDate2}`);
    }
    if (param.startMonth) {
      queryArray.push(`startMonth=${param.startMonth}`);
    }
    if (param.period) {
      queryArray.push(`period=${param.period}`);
    }
    if (param.outdoorIdList && param.outdoorIdList.length > 0) {
      queryArray.push(`outdoorIdList=${param.outdoorIdList.join(',')}`);
    }
    if (param.sortType) {
      queryArray.push(`sortType=${param.sortType}`);
    }
    if (param.unitKind) {
      queryArray.push(`unitKind=${param.unitKind}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    const callback = () =>
      this.restClientCommonService.request(
        'get',
        `${this.endPoint}${pathOfEnergy}outdoorUnitComparison/${param.buildingId}${query}`,
      );
    return this.restClientCommonService.sqsRequest(callback);
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-4. 消費電力目標値
  //  3-14-4. Power consumption target value
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 消費電力目標値取得API
   * response body: EnergyTargetResponse
   *
   * @param {string} buildingId 物件ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Power consumption target value acquisition API
   * response body: EnergyTargetResponse
   *
   * @param {string} buildingId Property ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEnergyTargetEnergy(patternId: string): Observable<Response<EnergyTargetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}targetEnergySettings/${patternId}`,
    );
  }

  /**
   * 消費電力目標値登録・更新API
   * response body: none
   *
   * @param {EnergyTargetRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Power consumption target value registration / update API
   * response body: none
   *
   * @param {EnergyTargetRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postEnergyTargetEnergy(param: EnergyTargetRequest): Observable<Response<{}>> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEnergy}targetEnergySettings`,
      param,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-5. 機種別機器一覧取得
  //  3-14-5. Acquisition of device list by model
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 機種別機器一覧取得API
   * response body: EnergyEquipmentsResponse or EnergyEquipmentsIndoorResponse
   *
   * @param {TopEntity} topEntity トップエンティティ
   * @param {string} buildingId 物件ID
   * @param {string} [edgeId] エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device list acquisition API by model
   * response body: EnergyEquipmentsResponse or EnergyEquipmentsIndoorResponse
   *
   * @param {TopEntity} topEntity Top entity
   * @param {string} buildingId Property ID
   * @param {string} [edgeId] Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEnergyEquipments(
    topEntity: TopEntity,
    buildingId: string,
    edgeId?: string,
  ): Observable<Response<EnergyEquipmentsResponse>> {
    let query = `?topEntity=${topEntity}`;
    if (edgeId) {
      query += `&edgeId=${edgeId}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}equipments/${buildingId}${query}`,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-6. パターンデータ管理
  //  3-14-6. Pattern data management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * パターン一覧取得API
   * @param requestParam リクエストパラメータ
   * @return レスポンス
   */
  /**
   * Pattern list acquisition API
   * @param requestParam Request parameter
   * @return response
   */
  getPatternList<T_Type extends PatternType>(
    requestParam: GetPatternListRequestParam<T_Type>,
  ): Observable<Response<GetPatternListResponseData<T_Type>>> {
    const queryArray: string[] = [`patternType=${encodeURIComponent(requestParam.patternType)}`];
    if ('personId' in requestParam) {
      queryArray.push(`personId=${encodeURIComponent(requestParam.personId)}`);
    } else {
      queryArray.push(`buildingId=${encodeURIComponent(requestParam.buildingId)}`);
    }
    const query = `?${queryArray.join('&')}`;
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}patterns${query}`,
    );
  }

  /**
   * パターン一覧取得API(基本情報)
   * @param requestParam リクエストパラメータ
   * @return レスポンス
   */
  /**
   * Pattern list acquisition API (basic information)
   * @param requestParam Request parameter
   * @return response
   */
  getPatternListBasicInfo<T_Type extends PatternType>(
    requestParam: GetPatternListRequestParam<T_Type>,
  ): Observable<Response<GetPatternListBasicInfoResponseData<T_Type>>> {
    let query = '';
    const queryArray: string[] = [];

    if ('personId' in requestParam) {
      queryArray.push(`personId=${encodeURIComponent(requestParam.personId)}`);
    } else {
      queryArray.push(`buildingId=${encodeURIComponent(requestParam.buildingId)}`);
    }
    if (requestParam.patternType) {
      queryArray.push(`patternType=${encodeURIComponent(requestParam.patternType)}`);
    }
    if (requestParam.isBasicInfo) {
      queryArray.push(`isBasicInfo=${encodeURIComponent(requestParam.isBasicInfo)}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}patterns${query}`,
    );
  }

  /**
   * パターン削除API
   * @returns レスポンス
   */

  /**
   * パターン削除API
   * @returns response
   */
  deletePatternSetting(patternId: string): Observable<Response<{}>> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfEnergy}patterns/${encodeURIComponent(patternId)}`,
    );
  }

  /**
   * パターン登録API
   * @param body リクエストボディ
   * @param copyFlag コピーフラグ
   * @returns レスポンス
   */
  /**
   * Pattern registration API
   * @param body Request body
   * @param copyFlag Copy frag
   * @returns response
   */
  postPatternSetting<T_Type extends PatternType>(
    body: PostPatternRequestBody<T_Type>,
    copyFlag: boolean = false,
  ): Observable<Response<PostPatternResponseData>> {
    const query = `?copyFlag=${copyFlag}`;
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEnergy}patterns/${query}`,
      body,
    );
  }

  /**
   * パターン変更API
   * @param patternId パターンID
   * @param body リクエストボディ
   * @returns レスポンス
   */
  /**
   * Pattern change API
   * @param patternId pattern ID
   * @param body Request body
   * @returns response
   */
  putPatternSetting<T_Type extends PatternType>(
    patternId: string,
    body: PutPatternRequestBody<T_Type>,
  ): Observable<Response<{}>> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEnergy}patterns/${encodeURIComponent(patternId)}`,
      body,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-7. 一覧表示データ取得
  //  3-14-7. List display data acquisition
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 一覧表示データ取得API(初回データ取得)
   * @param param リクエストパラメータ
   * @returns レスポンス
   */
  /**
   * List display data acquisition API first data acquisition
   * @param param Request parameter
   * @returns response
   */
  getInitialListDisplayData(
    param: Omit<GetMeterListRequestParam, 'updateData'>,
  ): GetInitialMeterListResponses {
    return this.restClientCommonService.sqsRequestAndReturnCallback(() =>
      this.getListDisplayDataCallback({
        ...param,
        updateData: false,
      }),
    );
  }

  /**
   * 一覧表示データ取得API(更新データ取得)
   * @param param リクエストパラメータ
   * @returns レスポンス
   */
  /**
   * List display data acquisition API update data acquisition
   * @param param Request parameter
   * @returns response
   */
  getUpdatedListDisplayData(
    param: Omit<GetMeterListRequestParam, 'updateData'>,
  ): Observable<Response<GetUpdatedMeterListTransactionResultResponseData>> {
    return this.restClientCommonService.sqsRequest(() =>
      this.getListDisplayDataCallback({
        ...param,
        updateData: true,
      }),
    );
  }

  /**
   * 一覧表示データ取得API
   * @param param リクエストパラメータ
   * @returns レスポンス
   */
  /**
   * List display data acquisition API
   * @param param Request parameter
   * @returns response
   */
  private getListDisplayDataCallback<T_UpdateDate extends boolean>(
    param: GetMeterListRequestParam<T_UpdateDate>,
  ): Observable<Response<GetMeterListResponseData<T_UpdateDate>>> {
    const updatedDataQuery =
      param.updateData !== undefined ? [`updateData=${encodeURIComponent(param.updateData)}`] : [];
    const queryString: string = [
      `buildingId=${encodeURIComponent(param.buildingId)}`,
      ...updatedDataQuery,
    ].join('&');
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}listDisplayData/${encodeURIComponent(
        param.edgeId,
      )}?${queryString}`,
    );
  }

  /**
   * 予実消費量取得API
   * @param requestParam リクエストパラメータ
   * @returns レスポンス
   */
  /**
   * Consumption of Consumption Obtain API
   * @param requestParam Request parameter
   * @returns response
   */
  getActualConsumption<T_Type extends DataType>(
    requestParam: EnergyActualConsumptionRequest<T_Type>,
  ): Observable<Response<GetEnergyActualConsumption>> {
    let query = '';
    const queryArray: string[] = [];
    if (requestParam.dataType) {
      queryArray.push(`dataType=${encodeURIComponent(requestParam.dataType)}`);
      switch (requestParam.dataType) {
        case 'year':
          if (requestParam.targetYear) {
            queryArray.push(`targetYear=${encodeURIComponent(requestParam.targetYear)}`);
          }
          break;
        case 'month':
          if (requestParam.targetMonth) {
            queryArray.push(`targetMonth=${encodeURIComponent(requestParam.targetMonth)}`);
          }
          break;
        case 'day':
          if (requestParam.targetDay) {
            queryArray.push(`targetDay=${encodeURIComponent(requestParam.targetDay)}`);
          }
          break;
        case 'vsPast':
          queryArray.push(`targetYear1=${encodeURIComponent(requestParam.targetYear1)}`);
          queryArray.push(`targetYear2=${encodeURIComponent(requestParam.targetYear2)}`);
          break;
      }
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}actualConsumption/${encodeURIComponent(
        requestParam.patternId,
      )}${query}`,
    );
  }

  /**
   * 計量・積算トレンド取得API
   * @param requestParam リクエストパラメータ
   * @returns レスポンス
   */
  /**
   * Measurement / Accident Trend acquisition API
   * @param requestParam Request parameter
   * @returns response
   */
  getMeteringAndEstimateTrend(
    requestParam: GetEnergyMeteringAndEstimateTrendRequest,
  ): Observable<Response<GetEnergyMeteringAndEstimateTrend>> {
    let query = '';
    const queryArray: string[] = [];

    queryArray.push(`period=${encodeURIComponent(requestParam.period)}`);
    if (requestParam.targetDate !== undefined) {
      queryArray.push(`targetDate=${encodeURIComponent(requestParam.targetDate)}`);
    }
    // if (requestParam.period === 'Year' && requestParam.startMonth !== undefined) {
    //   queryArray.push(`startMonth=${encodeURIComponent(requestParam.startMonth)}`);
    // }
    query = `?${queryArray.join('&')}`;
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}meteringTrendOperationMonitoring/${encodeURIComponent(
        requestParam.equipmentId,
      )}${query}`,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-14-9. エネルギーマネージメントデータ出力
  //  3-14-9. Energy management data output
  /////////////////////////////////////////////////////////////////////////////

  /**
   * エネルギーマネージメントデータ出力キュー登録API
   *
   * @param param リクエストボディ
   * @return レスポンス
   */
  /**
   * Energy Management Data Output Queue Registration API
   *
   * @param param request body
   * @return response
   */
  postEnergyQueue(
    param: OperationDataOutputQueuePostRequest,
  ): Observable<Response<OperationDataOutputQueuePostGetResponse>> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEnergy}dataOutput/queues`,
      param,
      this.screenId,
      false,
      true,
      true,
    );
  }

  /**
   * エネルギーマネージメントデータ出力キュー詳細取得API
   *
   * @param queueId キューID
   * @return レスポンス
   */
  /**
   * Energy Management Data Output Queue Detail Acquisition API
   *
   * @param queueId Queue ID
   * @return response
   */
  getEnergyQueue(queueId: string): Observable<Response<OperationDataOutputQueueGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}dataOutput/queues/${queueId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エネルギーマネージメントデータ出力キュー情報一覧取得API
   *
   * @param polling ポーリング
   * @return レスポンス
   */
  /**
   * API for acquiring a list of energy management data output queue information
   *
   * @param polling Polling
   * @return response
   */
  getEnergyQueueList(
    polling: boolean = false,
  ): Observable<Response<OperationDataOutputQueueListResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}dataOutput/queues`,
      undefined,
      this.screenId,
      polling,
      polling,
    );
  }

  /**
   * エネルギーマネージメントデータ出力キュー削除API
   *
   * @param queueId キューID
   * @return レスポンス
   */
  /**
   * Energy Management Data Output Queue Deletion API
   *
   * @param queueId Queue ID
   * @return response
   */
  deleteEnergyQueue(queueId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfEnergy}dataOutput/queues/${queueId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エネルギーマネージメントデータダウンロードAPI
   *
   * @param queueId キューID
   * @return レスポンス
   */
  /**
   * Energy Management Data Download API
   *
   * @param queueId Queue ID
   * @return response
   */
  getEnergyDownloadUrl(queueId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEnergy}dataOutput/queues/${queueId}/download`,
      undefined,
      this.screenId,
    );
  }
}
