<div class="main-login" [style.height]="offsetHeight">
  <div
    [ngClass]="{'main-login__header':true,'main-login__header--withDescription':descriptionExist,'main-login__header--noneDescription':!descriptionExist}">
    <router-outlet></router-outlet>
  </div>
  <app-description *ngIf="descriptionExist"></app-description>
  <app-footer-menu></app-footer-menu>
</div>
<div class="cookie-bar">
  <app-cookie-bar #cookieBar></app-cookie-bar>
</div>
<app-cookie-dialog></app-cookie-dialog>