export class ApiKey {
  apiKey: string;
  version: string;

  /**
   * コンストラクタ
   *
   * @param apiKey APIキー
   * @param version バージョン
   */
  /**
   * constructor
   *
   * @param apiKey API key
   * @param version API key version
   */
  constructor(apiKey: string, version: string) {
    this.apiKey = apiKey;
    this.version = version;
  }
}
