import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, startWith, skip } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import {
  RestClientMonitoringService,
  RestClientSharedService,
} from 'src/app/core/services/rest-client';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';

import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { Utility } from 'src/app/shared-main/classes/utility';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import {
  StateErrorGetResponse,
  StateErrorBuilding,
} from 'src/app/core/services/rest-client/interfaces/shared-service/shared';
import { DateFormats } from 'src/app/core/services/multi-language-message/enums/date-formats.enum';
import { BuildingEdgeStream } from 'src/app/core/services/data-management/classes/stream-data';
import { StreamType } from 'src/app/core/services/data-management/enums/stream-type';
import { Building } from 'src/app/core/services/data-management/classes/building-data';
import {
  AlertBarData,
  AlertBarErrorType,
  EquipmentErrorType,
} from 'src/app/main/main/components/alert-bar/interfaces';
import { EquipmentEvents } from 'src/app/core/services/rest-client/interfaces/equipment-service/equipment-events';
import { AlertBarListItem } from 'src/app/main/main/components/alert-bar/interfaces/alert-bar-data';
import { Package } from 'src/app/core/services/data-management/classes/package-data';
import { localStorageKeys } from 'src/app/shared-main/constants/local-storage-keys';

// 通信異常リスト
// Communication error list
const communicationErrorList = 'communicationErrorList';
// 機器異常リスト
// Equipment error list
const equipmentErrorList = 'equipmentErrorList';
// R32冷媒漏れ警報リスト
// R32 refrigerant leak alarm error list
const r32RefrigerantLeakAlarmErrorList = 'r32RefrigerantLeakAlarmErrorList';
const errorParamNameByEdge = [
  // 緊急停止
  // emergency stop
  'emergencyStopList',
  // システム異常（アドレス重複異常）
  // system error (address duplicate error)
  'systemErrorAddressDuplicationList',
  // システム異常（DⅢPort親重複異常）
  // system error (D3PORT parent duplicator)
  'systemErrorD3portDuplicationList',
  // システム異常（DⅢプラスアダプタ通信異常）
  // System error (D3 PLASE adapter communication error)
  'systemErrorD3portAdapterList',
  // システム異常（外部時刻同期信号異常）
  // System error (external time sync signal error)
  'systemErrorExTimeSyncSignalList',
  // 集中機器組み合わせ異常
  // Concentrated equipment combination abnormality
  'centralEquipmentErrorList',
  // 通信異常（GPFコア⇔GPFエッジ）
  // Communication error (GPF core ⇔ GPF edge)
  'communicationErrorGpfEdgeList',
];
const errorParamNameByEquipment = [
  // 通信異常リスト
  // Communication error list
  communicationErrorList,
  // 機器異常リスト
  // Equipment error list
  equipmentErrorList,
  // R32冷媒漏れ警報リスト
  // R32 refrigerant leak alarm list
  r32RefrigerantLeakAlarmErrorList,
];

const updateAlertIntervalTime = 60000;

@Injectable()
export class AlertManagementService {
  private UTC_FORMAT = /^[0-9]{4}-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]\.\d+Z$/;
  $alertDataStream: BehaviorSubject<AlertBarData | null> = new BehaviorSubject(null);
  $alertFetchStream: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isPlayNoticationSound: boolean = false;
  private siteList: Building[] = [];
  private packageList: Package[] = [];
  private selectedSite: Building = new Building('', '', '', [], []);
  private prevSelectedSiteId: string = '';
  private currStateErrorBuildings: StateErrorGetResponse;
  private prevStateErrorBuildings: StateErrorGetResponse;
  private currentDate: Date;
  private intervalId: any;
  private timeoutId: any;
  private prevCommunicationErrorNotification: boolean;
  private notificationSoundAvailable: boolean;
  private intervalUpdateAlertFlg: boolean = true;
  private reserveUpdateAlertFlg: boolean = false;
  private updateAlertIntervalId: any;
  private dataManagementCombineSubscription: Subscription;
  private personSubscription: Subscription;
  private stateErrorSubscriptionList: Subscription = new Subscription();
  private zoomOutDateUtc: string;

  // 縮小フラグ
  // reduction flag
  private zoomOutFlg: boolean = false;

  /**
   * コンストラクター関数
   * @param dataManagementService データ管理サービス
   * @param restClientSharedService Rest通信部 共通サービス
   * @param multiLanguageMessageService 多言語対応サービス
   * @param menuManagementService メニュー管理サービス
   * @param restClientMonitoringService Rest通信部 遠隔監視サービス
   */
  /**
   * Constructor function
   * @param dataManagementService Data management service
   * @param restClientAuthService RestClient Shared Service
   * @param multiLanguageMessageService Multilingual service
   * @param menuManagementService Menu Management Service
   * @param restClientMonitoringService REST communication unit remote monitoring service
   */
  constructor(
    private dataManagementService: DataManagementService,
    private restClientSharedService: RestClientSharedService,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private menuManagementService: MenuManagementService,
    private restClientMonitoringService: RestClientMonitoringService,
  ) {
    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // this.restClientSharedService.setScreenId(ScreenId.ScreenCommon);
    // this.dataManagementService.loginStatusStream
    //   .pipe(filter((status) => !status))
    //   .subscribe((status) => {
    //     this.stateErrorSubscriptionList.unsubscribe();
    //     this.clearProperty();
    //   });
  }

  get getNotificationSoundAvailable(): boolean {
    return this.notificationSoundAvailable;
  }

  /**
   * 状態変化通知接続
   *
   * @return {Observable<EquipmentEvents>} 状態検知のストリーム
   */
  /**
   * State change notification connection
   *
   * @return {Observable<EquipmentEvents>} Status detection stream
   */
  wssEquipmentEventsReport(): Observable<EquipmentEvents> {
    return this.restClientMonitoringService.wssEquipmentEventsReport(undefined, true);
  }

  /**
   * 状態変化通知切断
   */
  /**
   * State change notification disconnection
   */
  wssEquipmentEventsReportClose(): void {
    this.restClientMonitoringService.wssClose();
  }

  /**
   * アラート更新
   * @param fetchStateErrorFlg 異常状態確認APIを取得するかどうかのフラグ
   * @param realTimeUpdateFlg アラートバーをリアルタイムで更新するかどうかのフラグ
   */
  /**
   * Update alert
   * @param fetchStateErrorFlg An abnormal condition check whether to get an API
   * @param realTimeUpdateFlg Flag whether to update an alert bar in real time
   */
  async updateAlert(fetchStateErrorFlg: boolean, realTimeUpdateFlg: boolean) {
    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // 1分以内に機器イベント通知で呼ばれた場合
    // If it is called by device event notification within 1 minute
    // if (!realTimeUpdateFlg && !this.intervalUpdateAlertFlg) {
    //   this.reserveUpdateAlertFlg = true;
    //   return;
    // }

    // // 異常状態確認APIを呼び出す場合
    // // When calling an abnormal condition confirmation API
    // if (fetchStateErrorFlg) {
    //   this.intervalUpdateAlertFlg = false;
    //   this.reserveUpdateAlertFlg = false;
    // }

    // if (this.siteList.length <= 0) {
    //   this.clearData();
    //   return;
    // }

    // if (fetchStateErrorFlg) {
    //   this.$alertFetchStream.next(true);
    //   await this.fetchStateError({ selectedBuildingId: this.selectedSite.id });
    // }
    // this.clearData();

    // // エラーメッセージ処理
    // // Error message processing
    // if (this.currStateErrorBuildings) {
    //   this.setAlertBarMessage();
    // }
    // if (fetchStateErrorFlg) {
    //   this.$alertFetchStream.next(false);
    // }

    // // 異常発生していない場合
    // // If no abnormality has occurred
    // if (!this.isErrorBuildings) {
    //   this.clearNotificationSound();
    // }
  }

  /**
   * アラートバーにメッセージデータを流す
   */
  /**
   * Charge message data to the alert bar
   */
  private setAlertBarMessage(): void {
    const alertData: AlertBarData = {
      type: 'error',
      summary: '',
      details: { list: [] },
    };

    // 選択物件
    // Selection building
    const selectStateErrorBuilding = this.currStateErrorBuildings.buildingErrorItems?.find(
      (item) => {
        return item.buildingId === this.selectedSite.id;
      },
    );
    if (selectStateErrorBuilding) {
      Array.prototype.push.apply(
        alertData.details.list,
        this.getAlertData(selectStateErrorBuilding),
      );
    }

    // 非選択物件
    // Non-selected building
    this.currStateErrorBuildings.buildingErrorItems
      ?.filter((item) => {
        return item.buildingId !== this.selectedSite.id;
      })
      .sort((a, b) => {
        if (a.buildingName > b.buildingName) return 1;
        if (a.buildingName < b.buildingName) return -1;
      })
      .forEach((stateErrorBuilding) => {
        Array.prototype.push.apply(alertData.details.list, this.getAlertData(stateErrorBuilding));
      });
    // エラーがある場合
    // If there is an error
    if (alertData.details.list.length > 0) {
      this.menuManagementService.toggleErrorIcon(true);
      alertData.summary = this.multiLanguageMessageService.dictionary('sidSomeControllerAttention');
      this.$alertDataStream.next(alertData);
    }
  }

  /**
   * 新たな異常が発生した場合に通知音を鳴らす
   * @returns 通知音を鳴らすかどうか
   */
  /**
   * Sounds a notification sound if a new abnormality occurs
   * @returns Whether to play notification sound
   */
  isNotificationSound(): boolean {
    // 通知音設定が有効なパッケージがない場合
    // If the notification sound setting is not a valid package
    if (!this.getNotificationSoundAvailable) {
      this.copyCurrentData();
      return false;
    }

    // 異常が発生していない
    // An error has not occurred
    if (!this.isErrorBuildings) {
      this.copyCurrentData();
      return false;
    }

    // 前回エラーが発生していない
    // Last error has not occurred
    if (!this.prevStateErrorBuildings) {
      this.copyCurrentData();
      return true;
    }

    // 新しい異常が発生
    // new abnormality occurs
    if (this.hasNewErrorBuildings()) {
      this.copyCurrentData();
      return true;
    }

    this.copyCurrentData();
    return false;
  }

  /**
   * 通知音を開始
   */
  /**
   * Play notification sound
   */
  playNotificationSound(audio: HTMLMediaElement) {
    const errorNotificationSetting = this.dataManagementService.person().errorNotificationSetting;
    if (!errorNotificationSetting) {
      return;
    }

    if (!errorNotificationSetting.notificationSound) {
      return;
    }
    if (this.isPlayNoticationSound) {
      this.stopNotificationSound();
    } else {
      if (!this.loadLastStoppedState()) {
        return;
      }
    }
    this.isPlayNoticationSound = true;
    this.intervalId = setInterval(() => {
      audio.play();
    }, 1000);

    if (!errorNotificationSetting.notificationSoundDuration) {
      return;
    }
    const notificationSoundDuration =
      errorNotificationSetting.notificationSoundDuration * 60 * 1000;
    this.timeoutId = setTimeout(() => {
      this.stopNotificationSound();
    }, notificationSoundDuration);
  }

  /**
   * 通知音停止
   */
  /**
   * Stop notification sound
   */
  stopNotificationSound() {
    this.isPlayNoticationSound = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  /**
   * 状態表示のデータの取得する
   * @param param.selectedBuildingId 選択物件ID
   * @returns 取得完了の Promise
   */
  /**
   * Fetch state errors
   * @param param.selectedBuildingId Selected property ID
   * @returns Promise upon completion
   */
  private fetchStateError(param: { selectedBuildingId: string }): Promise<void> {
    return new Promise((resolve) => {
      const stateErrorSubscription = this.restClientSharedService.getStateError(param).subscribe(
        (res) => {
          this.currStateErrorBuildings = res.data;
          this.currentDate = new Date(res.headers.get('currenttime'));
          if (this.currStateErrorBuildings && this.currStateErrorBuildings.buildingErrorItems) {
            this.notificationSoundAvailable = this.currStateErrorBuildings.buildingErrorItems.some(
              (item) => {
                return this.getAlertSoundAvailabilityPackage(item.buildingId);
              },
            );
          }
        },
        () => {
          this.currStateErrorBuildings = null;
          this.currentDate = null;
          this.$alertFetchStream.next(false);
        },
        () => resolve(),
      );
      this.stateErrorSubscriptionList.add(stateErrorSubscription);
    });
  }

  /**
   * アラート情報を取得
   * @param stateErrorBuilding エラー情報
   */
  /**
   * Get alert data
   * @param stateErrorBuilding Error information
   */
  private getAlertData(stateErrorBuilding: StateErrorBuilding): AlertBarListItem[] {
    // 非選択物件でエラーありの場合
    // If there is an error in non-selected properties
    if (
      stateErrorBuilding.items === undefined &&
      (stateErrorBuilding.emergencyStop.state ||
        stateErrorBuilding.systemErrorAddressDuplication.state ||
        stateErrorBuilding.systemErrorD3portDuplication.state ||
        stateErrorBuilding.systemErrorD3portAdapter.state ||
        stateErrorBuilding.systemErrorExTimeSyncSignal.state ||
        stateErrorBuilding.centralEquipmentError.state ||
        stateErrorBuilding.communicationErrorGpfEdge.state ||
        stateErrorBuilding.equipmentsErrorState.state)
    ) {
      return [
        {
          detail: this.multiLanguageMessageService.dictionary(
            'sidUnselectedBuildingErrorOccurred',
            stateErrorBuilding.buildingName,
          ),
          errorType: AlertBarErrorType.UnselectedBuildingError,
        },
      ];
      // 非選択物件でエラーなしの場合
      // Non-selected properties with no errors
    } else if (
      stateErrorBuilding.items === undefined &&
      !stateErrorBuilding.emergencyStop.state &&
      !stateErrorBuilding.systemErrorAddressDuplication.state &&
      !stateErrorBuilding.systemErrorD3portDuplication.state &&
      !stateErrorBuilding.systemErrorD3portAdapter.state &&
      !stateErrorBuilding.systemErrorExTimeSyncSignal.state &&
      !stateErrorBuilding.centralEquipmentError.state &&
      !stateErrorBuilding.communicationErrorGpfEdge.state &&
      !stateErrorBuilding.equipmentsErrorState.state
    ) {
      return [];
    } else {
      // 何もしない
      // do nothing
    }

    // 選択物件の場合
    // In the case of selected properties
    const alertDataDetailList: AlertBarListItem[] = [];

    if (this.currentDate) {
      // 緊急停止
      // Emergency stop
      if (stateErrorBuilding.emergencyStop.state) {
        stateErrorBuilding.items.forEach((item) => {
          item.emergencyStopList.forEach((emergencyStop) => {
            const errorDate = new Date(Utility.convertStringDate(emergencyStop.errorDate));
            this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate);
            const elapsedDays = this.getElapsedDays(errorDate);
            let restoredSId =
              elapsedDays <= 0
                ? 'sidForcedStopInProgressRestored'
                : 'sidForcedStopInProgressDateRestored';
            alertDataDetailList.push({
              detail: this.multiLanguageMessageService.dictionary(
                emergencyStop.state ? 'sidForcedStopInProgress' : restoredSId,
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
                elapsedDays,
              ),
              errorType: AlertBarErrorType.ForcedStop,
              errorId: emergencyStop.errorId,
              ...(emergencyStop.state ? { link: 'info' } : {}),
            });
          });
        });
      }

      // システム異常（アドレス重複異常）
      // System error (address duplication)
      if (stateErrorBuilding.systemErrorAddressDuplication.state) {
        this.getAddressOverlapErrorStateDetails(stateErrorBuilding).forEach((item) =>
          alertDataDetailList.push({
            detail: item.detail,
            errorId: item.errorId,
            errorType: AlertBarErrorType.EdgeSystemError,
          }),
        );
      }

      // システム異常（DⅢPort親重複異常）
      // System error (D3 port duplication)
      if (stateErrorBuilding.systemErrorD3portDuplication.state) {
        this.getPortOverlapErrorStateDetails(stateErrorBuilding).forEach((item) =>
          alertDataDetailList.push({
            detail: item.detail,
            errorId: item.errorId,
            errorType: AlertBarErrorType.EdgeSystemError,
          }),
        );
      }

      // システム異常（DⅢプラスアダプタ通信異常）
      // System error (D3 port adapter)
      if (stateErrorBuilding.systemErrorD3portAdapter.state) {
        this.getAdapterCommunicationErrorStateDetails(stateErrorBuilding).forEach((item) =>
          alertDataDetailList.push({
            detail: item.detail,
            errorId: item.errorId,
            errorType: AlertBarErrorType.EdgeSystemError,
          }),
        );
      }

      // 集中機器組み合わせ異常
      // Central equipment error
      if (stateErrorBuilding.centralEquipmentError.state) {
        this.getEquipmentCombinationErrorStateDetails(stateErrorBuilding).forEach((item) =>
          alertDataDetailList.push({
            detail: item.detail,
            errorId: item.errorId,
            errorType: AlertBarErrorType.EdgeSystemError,
          }),
        );
      }

      // 通信異常（GPFコア ⇔ GPFエッジ）
      // Communication error (GPF ⇔ Edge)
      if (stateErrorBuilding.communicationErrorGpfEdge.state) {
        this.getCommunicationErrorStateDetails(stateErrorBuilding).forEach(
          (communicationErrorState) =>
            alertDataDetailList.push({
              detail: communicationErrorState.detail,
              errorId: communicationErrorState.errorId,
              errorType: AlertBarErrorType.EdgeCommunicationError,
              ...(communicationErrorState.isRestored ? {} : { link: 'download' }),
            }),
        );
      }

      // システム異常（デマンド制御外部時刻同期信号異常）
      // System error (Demand control external time sync signal error)
      if (stateErrorBuilding.systemErrorExTimeSyncSignal.state) {
        this.getExTimeSyncSignalErrorStateDetails(stateErrorBuilding).forEach((item) =>
          alertDataDetailList.push({
            detail: item.detail,
            errorId: item.errorId,
            errorType: AlertBarErrorType.EdgeSystemError,
          }),
        );
      }
    }

    const errorEquipmentIdList = [];
    // 通信異常（エッジ⇔機器）
    // Communication error (edge ⇔ equipment)
    if (this.communicationErrorNotification && this.isCommunicationErrors(stateErrorBuilding)) {
      alertDataDetailList.push({
        detail: this.multiLanguageMessageService.dictionary('sidCommunicationErrorOccurred'),
        subDetails: this.getEquipmentErrorStateDetails(
          stateErrorBuilding,
          errorEquipmentIdList,
          EquipmentErrorType.EquipmentCommunicationError,
        ),
        link: 'subDetails',
        errorType: AlertBarErrorType.EquipmentCommunicationError,
      });
    }

    // 機器異常
    // Equipment error
    const equipmentErrorSubDetails = this.getEquipmentErrorStateDetails(
      stateErrorBuilding,
      errorEquipmentIdList,
      EquipmentErrorType.EquipmentError,
    );
    if (equipmentErrorSubDetails.length >= 1) {
      alertDataDetailList.push({
        detail: this.multiLanguageMessageService.dictionary('sidEquipmentErrorOccurred'),
        subDetails: equipmentErrorSubDetails,
        link: 'subDetails',
        errorType: AlertBarErrorType.EquipmentError,
      });
    }

    // R32冷媒漏れ
    // R32 refrigerant leak alarm
    const r32RefrigerantLeakAlarmSubDetails = this.getEquipmentErrorStateDetails(
      stateErrorBuilding,
      errorEquipmentIdList,
      EquipmentErrorType.R32RefrigerantLeakAlarm,
    );
    if (r32RefrigerantLeakAlarmSubDetails.length >= 1) {
      alertDataDetailList.push({
        detail: this.multiLanguageMessageService.dictionary('sidR32RefrigerantLeakOccurred'),
        subDetails: r32RefrigerantLeakAlarmSubDetails,
        link: 'subDetails',
        errorType: AlertBarErrorType.R32RefrigerantLeakAlarm,
      });
    }

    return alertDataDetailList;
  }

  /**
   * システム異常（アドレス重複異常）の詳細の取得
   * @param stateErrorBuilding エラー情報
   * @returns システム異常（アドレス重複異常）の詳細
   */
  /**
   * Get system error (address duplication) details
   * @param stateErrorBuilding Error information
   * @returns System error (address duplication) details
   */
  private getAddressOverlapErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
  ): { detail: string; errorId: string }[] {
    return stateErrorBuilding.items.reduce((newArray, edge) => {
      return newArray.concat(
        edge.systemErrorAddressDuplicationList.map((systemErrorAddressDuplication) => {
          const errorDate = new Date(
            Utility.convertStringDate(systemErrorAddressDuplication.errorDate),
          );
          const elapsedDays = this.getElapsedDays(errorDate);

          if (elapsedDays <= 0) {
            return {
              detail: this.multiLanguageMessageService.dictionary(
                systemErrorAddressDuplication.state
                  ? 'sidControllerSystemError'
                  : 'sidControllerSystemErrorRestored',
                edge.edgeName,
                this.multiLanguageMessageService.dictionary('sidAddressOverlap'),
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
              ),
              errorId: systemErrorAddressDuplication.errorId,
            };
          }

          return {
            detail: this.multiLanguageMessageService.dictionary(
              systemErrorAddressDuplication.state
                ? 'sidControllerSystemErrorDate'
                : 'sidControllerSystemErrorDateRestored',
              edge.edgeName,
              this.multiLanguageMessageService.dictionary('sidAddressOverlap'),
              this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate),
              elapsedDays,
            ),
            errorId: systemErrorAddressDuplication.errorId,
          };
        }),
      );
    }, []);
  }

  /**
   * システム異常（DⅢPort親重複異常）の詳細の取得
   * @param stateErrorBuilding エラー情報
   * @returns システム異常（DⅢPort親重複異常）の詳細
   */
  /**
   * Get system error (D3 port duplication) details
   * @param stateErrorBuilding Error information
   * @returns System error (D3 port duplication) details
   */
  private getPortOverlapErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
  ): { detail: string; errorId: string }[] {
    return stateErrorBuilding.items.reduce((newArray, edge) => {
      return newArray.concat(
        edge.systemErrorD3portDuplicationList.map((systemErrorD3portDuplication) => {
          const errorDate = new Date(
            Utility.convertStringDate(systemErrorD3portDuplication.errorDate),
          );
          const elapsedDays = this.getElapsedDays(errorDate);

          if (elapsedDays <= 0) {
            return {
              detail: this.multiLanguageMessageService.dictionary(
                systemErrorD3portDuplication.state
                  ? 'sidControllerSystemError'
                  : 'sidControllerSystemErrorRestored',
                edge.edgeName,
                this.multiLanguageMessageService.dictionary('sidD3PortOverlap'),
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
              ),
              errorId: systemErrorD3portDuplication.errorId,
            };
          }

          return {
            detail: this.multiLanguageMessageService.dictionary(
              systemErrorD3portDuplication.state
                ? 'sidControllerSystemErrorDate'
                : 'sidControllerSystemErrorDateRestored',
              edge.edgeName,
              this.multiLanguageMessageService.dictionary('sidD3PortOverlap'),
              this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate),
              elapsedDays,
            ),
            errorId: systemErrorD3portDuplication.errorId,
          };
        }),
      );
    }, []);
  }

  /**
   * システム異常（DⅢプラスアダプタ通信異常）の詳細の取得
   * @param stateErrorBuilding エラー情報
   * @returns システム異常（DⅢプラスアダプタ通信異常）の詳細
   */
  /**
   * Get system error (D3 port adapter) details
   * @param stateErrorBuilding Error information
   * @returns System error (D3 port adapter) details
   */
  private getAdapterCommunicationErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
  ): { detail: string; errorId: string }[] {
    return stateErrorBuilding.items.reduce((newArray, edge) => {
      return newArray.concat(
        edge.systemErrorD3portAdapterList.map((systemErrorD3portAdapter) => {
          const errorDate = new Date(Utility.convertStringDate(systemErrorD3portAdapter.errorDate));
          const elapsedDays = this.getElapsedDays(errorDate);

          if (elapsedDays <= 0) {
            return {
              detail: this.multiLanguageMessageService.dictionary(
                systemErrorD3portAdapter.state
                  ? 'sidControllerSystemError'
                  : 'sidControllerSystemErrorRestored',
                edge.edgeName,
                this.multiLanguageMessageService.dictionary('sidD3PlusAdapterCommunication'),
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
              ),
              errorId: systemErrorD3portAdapter.errorId,
            };
          }

          return {
            detail: this.multiLanguageMessageService.dictionary(
              systemErrorD3portAdapter.state
                ? 'sidControllerSystemErrorDate'
                : 'sidControllerSystemErrorDateRestored',
              edge.edgeName,
              this.multiLanguageMessageService.dictionary('sidD3PlusAdapterCommunication'),
              this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate),
              elapsedDays,
            ),
            errorId: systemErrorD3portAdapter.errorId,
          };
        }),
      );
    }, []);
  }

  /**
   * 集中機器組み合わせ異常の詳細の取得
   * @param stateErrorBuilding エラー情報
   * @returns 集中機器組み合わせ異常の詳細
   */
  /**
   * Get central equipment error details
   * @param stateErrorBuilding Error information
   * @returns Central equipment error details
   */
  private getEquipmentCombinationErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
  ): { detail: string; errorId: string }[] {
    return stateErrorBuilding.items.reduce((newArray, edge) => {
      return newArray.concat(
        edge.centralEquipmentErrorList.map((centralEquipmentError) => {
          const errorDate = new Date(Utility.convertStringDate(centralEquipmentError.errorDate));
          const elapsedDays = this.getElapsedDays(errorDate);

          if (elapsedDays <= 0) {
            return {
              detail: this.multiLanguageMessageService.dictionary(
                centralEquipmentError.state
                  ? 'sidControllerSystemError'
                  : 'sidControllerSystemErrorRestored',
                edge.edgeName,
                this.multiLanguageMessageService.dictionary('sidEquipmentCombination'),
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
              ),
              errorId: centralEquipmentError.errorId,
            };
          }

          return {
            detail: this.multiLanguageMessageService.dictionary(
              centralEquipmentError.state
                ? 'sidControllerSystemErrorDate'
                : 'sidControllerSystemErrorDateRestored',
              edge.edgeName,
              this.multiLanguageMessageService.dictionary('sidEquipmentCombination'),
              this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate),
              elapsedDays,
            ),
            errorId: centralEquipmentError.errorId,
          };
        }),
      );
    }, []);
  }

  /**
   * 通信異常（GPFコア ⇔ GPFエッジ）の詳細の取得
   * @param stateErrorBuilding エラー情報
   * @returns 通信異常（GPFコア ⇔ GPFエッジ）の詳細
   */
  /**
   * Get communication error (GPF ⇔ Edge) details
   * @param stateErrorBuilding Error information
   * @returns Communication error (GPF ⇔ Edge) details
   */
  private getCommunicationErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
  ): { detail: string; errorId: string; isRestored: boolean }[] {
    return stateErrorBuilding.items.reduce((newArray, edge) => {
      return newArray.concat(
        edge.communicationErrorGpfEdgeList.map((communicationErrorGpfEdge) => {
          const errorDate = new Date(
            Utility.convertStringDate(communicationErrorGpfEdge.errorDate),
          );
          const elapsedDays = this.getElapsedDays(errorDate);

          if (elapsedDays <= 0) {
            return {
              detail: this.multiLanguageMessageService.dictionary(
                communicationErrorGpfEdge.state
                  ? 'sidControllerCurrentlyOffline'
                  : 'sidControllerOfflineRestored',
                edge.edgeName,
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
              ),
              errorId: communicationErrorGpfEdge.errorId,
              isRestored: !communicationErrorGpfEdge.state,
            };
          }

          return {
            detail: this.multiLanguageMessageService.dictionary(
              communicationErrorGpfEdge.state
                ? 'sidControllerCurrentlyOfflineDate'
                : 'sidControllerOfflineDateRestored',
              edge.edgeName,
              this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate),
              elapsedDays,
            ),
            errorId: communicationErrorGpfEdge.errorId,
            isRestored: !communicationErrorGpfEdge.state,
          };
        }),
      );
    }, []);
  }

  /**
   * システム異常（デマンド制御外部時刻同期信号異常）の詳細の取得
   * @param stateErrorBuilding エラー情報
   * @returns システム異常（デマンド制御外部時刻同期信号異常）の詳細
   */
  /**
   * Get system error (Demand control external time sync signal error) details
   * @param stateErrorBuilding Error information
   * @returns System error (Demand control external time sync signal error) details
   */
  private getExTimeSyncSignalErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
  ): { detail: string; errorId: string }[] {
    return stateErrorBuilding.items.reduce((newArray, edge) => {
      return newArray.concat(
        edge.systemErrorExTimeSyncSignalList.map((systemErrorExTimeSyncSignal) => {
          const errorDate = new Date(
            Utility.convertStringDate(systemErrorExTimeSyncSignal.errorDate),
          );
          const elapsedDays = this.getElapsedDays(errorDate);

          if (elapsedDays <= 0) {
            return {
              detail: this.multiLanguageMessageService.dictionary(
                systemErrorExTimeSyncSignal.state
                  ? 'sidControllerSystemError'
                  : 'sidControllerSystemErrorRestored',
                edge.edgeName,
                this.multiLanguageMessageService.dictionary('sidExTimeSyncSignalError'),
                this.multiLanguageMessageService.formatDateToString(
                  DateFormats.YmdSlash,
                  errorDate,
                ),
              ),
              errorId: systemErrorExTimeSyncSignal.errorId,
            };
          }

          return {
            detail: this.multiLanguageMessageService.dictionary(
              systemErrorExTimeSyncSignal.state
                ? 'sidControllerSystemErrorDate'
                : 'sidControllerSystemErrorDateRestored',
              edge.edgeName,
              this.multiLanguageMessageService.dictionary('sidExTimeSyncSignalError'),
              this.multiLanguageMessageService.formatDateToString(DateFormats.YmdSlash, errorDate),
              elapsedDays,
            ),
            errorId: systemErrorExTimeSyncSignal.errorId,
          };
        }),
      );
    }, []);
  }

  /**
   * 機器異常または通信異常（エッジ⇔機器）の詳細を取得
   * @param stateErrorBuilding エラー情報
   * @param errorEquipmentIdList エラーが発生した機器IDの一覧
   * @param equipmentErrorFlg 機器異常発生フラグ
   * @returns 機器異常または通信異常（エッジ⇔機器）の詳細
   */
  /**
   * Get details of equipment error or communication error (edge ⇔ equipment)
   * @param stateErrorBuilding Error information
   * @Param errorEquipmentIdList List of device IDs where errors occurred
   * @Param equipmentErrorFlg Equipment Abnormality Flag
   * @returns Details of equipment error or communication error (edge ⇔ equipment)
   */
  private getEquipmentErrorStateDetails(
    stateErrorBuilding: StateErrorBuilding,
    errorEquipmentIdList: string[],
    errorType: EquipmentErrorType,
  ): { detail: string; errorId: string }[] {
    const EquipmentError = [
      {
        type: EquipmentErrorType.EquipmentCommunicationError,
        name: errorParamNameByEquipment[EquipmentErrorType.EquipmentCommunicationError],
      },
      {
        type: EquipmentErrorType.EquipmentError,
        name: errorParamNameByEquipment[EquipmentErrorType.EquipmentError],
      },
      {
        type: EquipmentErrorType.R32RefrigerantLeakAlarm,
        name: errorParamNameByEquipment[EquipmentErrorType.R32RefrigerantLeakAlarm],
      },
    ];
    const errorListParamName = EquipmentError.find((error) => error.type == errorType).name;
    const items = stateErrorBuilding.items.filter((edge) => edge[errorListParamName]);
    const details: { detail: string; errorId: string }[] = [];
    const sortDetails = [];

    items.forEach((edge) => {
      edge[errorListParamName].forEach((item) => {
        let equipmentName: string;
        if (!errorEquipmentIdList.includes(item.equipmentId)) {
          errorEquipmentIdList.push(item.equipmentId);
        }
        switch (errorType) {
          // 通信異常（エッジ⇔機器）の場合
          // In the case of communication error (edge ⇔ equipment)
          case EquipmentErrorType.EquipmentCommunicationError:
            equipmentName = `${item.equipmentName}`;
            break;
          // 機器異常、R32冷媒漏れの場合
          // In the case of equipment abnormality or R32 refrigerant leak alarm
          case EquipmentErrorType.EquipmentError:
          case EquipmentErrorType.R32RefrigerantLeakAlarm:
            equipmentName = `${item.equipmentName} ${item.errorState.errorCode}`;
            break;
        }
        sortDetails.push({
          equipmentName,
          date: item.errorState.errorDate.slice(0, -2),
          edgeName: edge.edgeName,
          errorId: item.errorState.errorId,
        });
      });
    });

    // エラー発生日時の新しい順に表示する
    // 同時刻に複数機器で機器異常が発生した場合、エッジの名称順(昇順)、機器の名称順(昇順)で表示する
    // Display error occurrence date in ascending order
    // If a equipment error occurs on multiple devices at the same time,
    // display in order of edge name(ascending order) and equipment name(ascending order)
    sortDetails.sort((a, b) => {
      if (a.date < b.date) return 1;
      if (a.date > b.date) return -1;
      if (a.edgeName < b.edgeName) return -1;
      if (a.edgeName > b.edgeName) return 1;
      if (a.equipmentName < b.equipmentName) return -1;
      if (a.equipmentName > b.equipmentName) return 1;
    });

    sortDetails.forEach((item) => {
      details.push({
        detail: `${this.getErrorDate(item.date)} ${item.edgeName} ${item.equipmentName}`,
        errorId: item.errorId,
      });
    });

    return details;
  }

  /**
   * 異常発生日時を取得をする
   * @param date 日付
   * @returns 多言語対応済みの異常発生日時の値
   */
  /**
   * Get the date of error
   * @param date Date
   * @returns The date of error ​​that are already in multiple languages
   */
  private getErrorDate(date: string): string {
    const convertedTime = this.convertTimeNotation(
      parseInt(date.slice(11, 13), 10),
      parseInt(date.slice(14, 16), 10),
    );
    const convertedDate = this.multiLanguageMessageService.formatDateToString(
      DateFormats.YmdSlash,
      null,
      parseInt(date.slice(5, 7), 10),
      parseInt(date.slice(8, 10), 10),
      parseInt(date.slice(0, 4), 10),
    );
    return `${convertedDate} ${convertedTime}`;
  }

  /**
   * 時間表記(24H/12H)を変換する
   * @param hours 時
   * @param minutes 分
   * @returns 変換後の時間
   */
  /**
   * Convert time notation (24H / 12H)
   * @param hours hours
   * @param minutes minutes
   * @returns Time after conversion
   */
  private convertTimeNotation(hours: number, minutes: number): string {
    let zeroPaddedHour;
    let zeroPaddedMinute;

    if (this.dataManagementService.person().timeDisplay === '24h') {
      zeroPaddedHour = hours.toString().padStart(2, '0');
      zeroPaddedMinute = minutes.toString().padStart(2, '0');
      return `${zeroPaddedHour}:${zeroPaddedMinute}`;
    }

    const amPm =
      hours < 12
        ? this.multiLanguageMessageService.dictionary('sidAm')
        : this.multiLanguageMessageService.dictionary('sidPm');
    let calcHours = hours;
    if (hours === 0) {
      calcHours += 12;
    } else if (hours > 12) {
      calcHours -= 12;
    }

    zeroPaddedHour = calcHours.toString().padStart(2, '0');
    zeroPaddedMinute = minutes.toString().padStart(2, '0');

    return `${zeroPaddedHour}:${zeroPaddedMinute}${amPm}`;
  }

  /**
   * エラー発生日から経過した日数の取得
   * @param errorDate エラー発生日
   * @returns 経過日
   */
  /**
   * Get elapsed days since error occured
   * @param errorDate Date which error occured on
   * @returns Elapsed days
   */
  private getElapsedDays(errorDate: Date): number {
    const elapsedDays = Math.floor((this.currentDate.valueOf() - errorDate.valueOf()) / 86400000);

    return elapsedDays < 7 ? elapsedDays : 7;
  }

  /**
   * サイドメニュー、アラートバーデータのクリア
   */
  /**
   * Clear side menu and alert bar data
   */
  private clearData() {
    this.$alertDataStream.next(null);
    this.menuManagementService.toggleErrorIcon(false);
  }

  /**
   * 新しい異常が発生したかどうかを判定する
   * @param prevStateErrorBuilding 前回の異常状態
   * @param currStateErrorBuilding 現在の異常状態
   * @returns 新しい異常が発生したかどうか
   */
  /**
   * Determine if a new abnormality has occurred
   * @param prevStateErrorBuilding Last abnormal condition
   * @param currStateErrorBuilding Current abnormal condition
   * @returns Whether a new abnormality occurred
   */
  private hasNewErrorBuilding(
    prevStateErrorBuilding: StateErrorBuilding | undefined,
    currStateErrorBuilding: StateErrorBuilding,
  ): boolean {
    // 非選択物件の場合
    // In the case of non -selected properties
    if (currStateErrorBuilding.items === undefined) {
      // 前回異常が発生していない場合、新しい異常が発生したと判定する
      // If no abnormality has occurred last time, it is determined that a new abnormality has occurred.
      return prevStateErrorBuilding === undefined;
    }

    // 選択物件の場合
    // In the case of selected properties

    // エッジごとの異常判定
    // Abnormality judgment per edge
    if (
      errorParamNameByEdge.some((errorParamName) =>
        this.hasNewEdgeError(errorParamName, prevStateErrorBuilding, currStateErrorBuilding),
      )
    ) {
      return true;
    }
    // 機器毎の異常判定
    // Anomaly determination for each device
    if (
      errorParamNameByEquipment.some((errorParamName) =>
        this.hasNewEquipmentError(errorParamName, prevStateErrorBuilding, currStateErrorBuilding),
      )
    ) {
      return true;
    }
    return false;
  }

  /**
   * 管理中の物件一覧で新しくエラーが発生したかどうかを判定する
   * @returns 管理中の物件一覧で新しくエラーが発生したかどうか
   */
  /**
   * Determine if a new error has occurred in the managed property list
   * @returns Whether a new error occurred in the managed property list
   */
  private hasNewErrorBuildings(): boolean {
    return this.currStateErrorBuildings.buildingErrorItems.some((currStateErrorBuilding) => {
      const prevStateErrorBuilding = this.prevStateErrorBuildings.buildingErrorItems.find(
        (prevStateErrorBuilding) => {
          return prevStateErrorBuilding.buildingId === currStateErrorBuilding.buildingId;
        },
      );
      return this.hasNewErrorBuilding(prevStateErrorBuilding, currStateErrorBuilding);
    });
  }

  /**
   * 新たに機器の異常が発生したか判定する
   * @param errorParam エラーパラメーター
   * @param prevStateErrorBuilding 前回の異常状態
   * @param currStateErrorBuilding 今回の異常状態
   * @returns 新たな機器異常
   */
  /**
   * Determine if an error has occurred newly
   * @param errorParam Error parameter
   * @param prevStateErrorBuilding Last abnormal condition
   * @param currStateErrorBuilding This abnormal condition
   * @returns New equipment error
   */
  private hasNewEquipmentError(
    errorParam: string,
    prevStateErrorBuilding: StateErrorBuilding,
    currStateErrorBuilding: StateErrorBuilding,
  ): boolean {
    const prevErrorEquipmentIdList = [];
    const currErrorEquipmentIdList = [];

    // 前回のデータが存在しない かつ （通信異常リストでない または 通信異常通知可否がON）
    // The last data does not exist (not in communication error list or communication abnormality notification availability is ON)
    if (
      prevStateErrorBuilding &&
      (errorParam !== communicationErrorList || this.prevCommunicationErrorNotification)
    ) {
      const items = prevStateErrorBuilding.items ?? [];
      for (const prevItem of items) {
        prevItem[errorParam].forEach((equipment) => {
          prevErrorEquipmentIdList.push(equipment.equipmentId);
        });
      }
    }

    // （通信異常リストでない または 通信異常通知可否がON）
    // (Not communication error list or communication error notification availability is ON)
    if (errorParam !== communicationErrorList || this.communicationErrorNotification) {
      for (const currItem of currStateErrorBuilding.items) {
        currItem[errorParam].forEach((equipment) => {
          currErrorEquipmentIdList.push(equipment.equipmentId);
        });
      }
    }

    for (const currErrorEquipmentId of currErrorEquipmentIdList) {
      if (
        !prevErrorEquipmentIdList.find(
          (prevErrorEquipmentId) => prevErrorEquipmentId === currErrorEquipmentId,
        )
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * 新たにエッジで異常が発生したか判定する
   * @param errorParam エラーパラメーター
   * @param prevStateErrorBuilding 前回の異常状態
   * @param currStateErrorBuilding 今回の異常状態
   * @returns 新たなエッジでの異常
   */
  /**
   * Determine if an abnormality has occurred newly
   * @param errorParam Error parameter
   * @param prevStateErrorBuilding Last abnormal condition
   * @param currStateErrorBuilding This abnormal condition
   * @returns Anomaly at new edges
   */
  private hasNewEdgeError(
    errorParam: string,
    prevStateErrorBuilding: StateErrorBuilding | undefined,
    currStateErrorBuilding: StateErrorBuilding,
  ): boolean {
    const prevErrorEdgeIdList = [];
    const currErrorEdgeIdList = [];

    if (prevStateErrorBuilding?.items !== undefined) {
      prevStateErrorBuilding.items.forEach((edge) => {
        if (!edge[errorParam].some((error) => error.state)) {
          return;
        }
        prevErrorEdgeIdList.push(edge.edgeId);
      });
    }

    currStateErrorBuilding.items.forEach((edge) => {
      if (!edge[errorParam].some((error) => error.state)) {
        return;
      }
      currErrorEdgeIdList.push(edge.edgeId);
    });

    for (const currErrorEdgeId of currErrorEdgeIdList) {
      if (!prevErrorEdgeIdList.find((prevErrorEdgeId) => prevErrorEdgeId === currErrorEdgeId)) {
        return true;
      }
    }

    return false;
  }

  /**
   * 通信異常（エッジ⇔機器）が発生しているかどうかを判定する
   * @param stateErrorBuilding エラー情報
   * @returns 通信異常（エッジ⇔機器）が発生しているかどうか
   */
  /**
   * Determine whether communication abnormality (edge ⇔ equipment) is generated
   * @param stateErrorBuilding Error information
   * @returns Whether communication abnormality (edge ⇔ equipment) has occurred
   */
  private isCommunicationErrors(stateErrorBuilding: StateErrorBuilding): boolean {
    return (
      stateErrorBuilding &&
      stateErrorBuilding.items.some((item) => item.communicationErrorList.length > 0)
    );
  }

  private get isErrorBuildings(): boolean {
    return (
      this.currStateErrorBuildings &&
      this.currStateErrorBuildings.buildingErrorItems &&
      this.currStateErrorBuildings.buildingErrorItems.some((item) => this.isErrorBuilding(item))
    );
  }

  /**
   * 引数で渡した物件のエラー情報にエラーが存在するかを判定
   * @param stateErrorBuilding エラー情報
   * @returns エラーが存在するかどうか
   */
  /**
   * Determines if an error exists in the error information of the property passed by the argument
   * @param stateErrorBuilding Error information
   * @returns Whether an error exists
   */
  private isErrorBuilding(stateErrorBuilding: StateErrorBuilding): boolean {
    return this.getAlertData(stateErrorBuilding).length >= 1 ? true : false;
  }

  /**
   * 通知音のクリア処理を行う
   */
  /**
   * Process the notification sound
   */
  clearNotificationSound(): void {
    this.prevStateErrorBuildings = undefined;
    this.prevCommunicationErrorNotification = undefined;
    this.stopNotificationSound();
  }

  private get communicationErrorNotification(): boolean {
    return this.dataManagementService.person().communicationErrorNotification;
  }

  private copyCurrentData(): void {
    this.prevStateErrorBuildings = Utility.deepCopy(this.currStateErrorBuildings);
    this.prevCommunicationErrorNotification = this.communicationErrorNotification;
  }

  /**
   * 指定物件に、通知音設定が有効なパッケージが導入されているか否かを取得
   *
   * @param   {string}     buildingId
   *
   * @return  {boolean}
   */
  /**
   * In the specified property,
   * it is possible to get whether the notification sound setting has a valid package installed
   *
   * @param   {string}     buildingId
   *
   * @return  {boolean}
   */
  private getAlertSoundAvailabilityPackage(buildingId: string): boolean {
    const targetBuilding = this.siteList.find((n) => n.id === buildingId);

    // 対象の物件が見つからない場合、パッケージ無し扱いとする。
    // If the target property is not found, it will be handled without a package.
    if (!targetBuilding) {
      return false;
    }

    // 有効期限内のパッケージを取得
    // Get the package Id within the expiration date
    const enabledPackage = targetBuilding.packageList.filter((item) => {
      return !item.expired;
    });

    // 通知音設定が有効なパッケージがあるか確認
    // Check if the notification sound setting is a valid package
    return this.packageList.some((item) => {
      if (enabledPackage.some((n) => n.packageId === item.id)) {
        return item.functionStatusList.some((functionStatus) => {
          const errorNotificationMenu = functionStatus.subMenu.find(
            (menu) => menu.screenId === ScreenId.ErrorNotification,
          );
          if (errorNotificationMenu && errorNotificationMenu.availability) {
            return true;
          }
          return false;
        });
      }
    });
  }

  /**
   * アラートバーを更新するタイマー処理をセットする
   */
  /**
   * Set the timer processing to update the allay bar
   */
  setUpdateAlertIntervalId(): void {
    this.updateAlertIntervalId = setInterval(() => {
      this.intervalUpdateAlertFlg = true;

      // 1分以内にアラーバーの更新処理が呼ばれていた場合
      // If Alert bar update is called for 1 minute
      if (this.reserveUpdateAlertFlg) {
        this.updateAlert(true, false);
      }
    }, updateAlertIntervalTime);
  }

  /**
   * アラートバーを更新するタイマー処理をクリアする
   */
  /**
   * Clear timer processing to update alertrobers
   */
  clearUpdateAlertIntervalId(): void {
    clearInterval(this.updateAlertIntervalId);
    this.updateAlertIntervalId = undefined;
  }

  /**
   * LocalStorageの通知音停止時最終異常発生日付が現在の異常状態の中の最終異常発生日付より大きい場合にLocalStorageに保持する
   */
  /**
   * If the last error occurrence date when the notification sound of LocalStorage is stopped
   * is larger than the last error occurrence date in the current error state,
   * it is retained in LocalStorage.
   */
  saveLastNotificationSoundStopErrorDate() {
    const beforeLastErrorDateUtc = this.getLastNotificationSoundStopErrorDate();
    const lastErrorDateUtc = this.getCurrentLastErrorDateUtc();

    if (!lastErrorDateUtc || lastErrorDateUtc <= beforeLastErrorDateUtc) {
      return;
    }

    // 通知音停止時最終異常発生日付を保存する
    // Save the date when the last error occurred when the notification sound stopped
    this.dataManagementService.setLocalStorage(
      localStorageKeys.LastNotificationSoundStopErrorDate,
      lastErrorDateUtc,
    );
  }

  /**
   * 前回停止状態に戻す
   * @returns {boolean} 通知音を再生するか
   */
  /**
   * Return to the previous stopped state
   * @returns {boolean} Do you want to play the notification sound?
   */
  private loadLastStoppedState(): boolean {
    const beforeLastErrorDateUtc = this.getLastNotificationSoundStopErrorDate();
    if (!beforeLastErrorDateUtc) {
      return true;
    }

    // 現在の異常状態の中の最終異常発生日付がLocalStorageの通知音停止時最終異常発生日付より大きい場合に通知音を再生する
    // Play the notification sound when the last error occurrence date in the current error state
    // is larger than the last error occurrence date when the notification sound
    // of Local Storage is stopped.
    const lastErrorDateUtc = this.getCurrentLastErrorDateUtc();
    if (beforeLastErrorDateUtc < lastErrorDateUtc) {
      return true;
    }

    // 停止されている状態にする
    // Put it in a stopped state
    this.stopNotificationSound();
    this.copyCurrentData();
    return false;
  }

  /**
   * ローカルストレージから通知音停止時最終異常発生日付を取得する
   * @returns {string} 通知音停止時最終異常発生日付
   */
  /**
   * Get the last error occurrence date when the notification sound
   * is stopped from the local storage
   * @returns {string} Date of last error when notification sound stopped
   */
  private getLastNotificationSoundStopErrorDate(): string {
    try {
      let utc = this.dataManagementService.localStorage(
        localStorageKeys.LastNotificationSoundStopErrorDate,
      );
      if (!utc || !utc.match(this.UTC_FORMAT)) {
        utc = '';
      }
      return utc;
    } catch {
      return '';
    }
  }

  /**
   * 現在の異常状態の最大の異常発生日付を取得する
   * @returns {string} 現在の異常状態の最大の異常発生日付
   */
  /**
   * Get the maximum anomaly date of the current anomaly
   * @returns {string} Maximum anomaly date of the current anomaly
   */
  private getCurrentLastErrorDateUtc(): string {
    const errorDateUtcs: string[] = [];
    const building = this.currStateErrorBuildings.buildingErrorItems.find(
      (building) => building.buildingId === this.selectedSite.id,
    );
    // 選択物件が見つかった場合、異常発生日を配列に入れる
    // 選択物件がない場合は基本的にないため、else不要
    // If a selection property is found, the abnormal occurrence date is put in an array
    // Else is not required because there is basically no selection property
    if (building !== undefined) {
      building.items.forEach((edge) => {
        // エッジの最終異常発生日付を取得
        // Get the date of the last anomaly of the edge
        const edgeErrors = [
          ...edge.emergencyStopList,
          ...edge.systemErrorAddressDuplicationList,
          ...edge.systemErrorD3portDuplicationList,
          ...edge.systemErrorD3portAdapterList,
          ...edge.systemErrorExTimeSyncSignalList,
          ...edge.centralEquipmentErrorList,
          ...edge.communicationErrorGpfEdgeList,
        ].map((error) => error.errorDateUtc || '');
        errorDateUtcs.push(...edgeErrors);
        // 機器異常の最終異常発生日付を取得
        // Get the last error occurrence date of the device error
        errorDateUtcs.push(
          ...edge.equipmentErrorList.map((equipment) => equipment.errorState.errorDateUtc || ''),
        );
        // R32冷媒漏れ警報の最終異常発生日付を取得
        // Get the last error occurrence date of the device r32 refrigerant leak alarm error
        errorDateUtcs.push(
          ...edge.r32RefrigerantLeakAlarmErrorList.map(
            (equipment) => equipment.errorState.errorDateUtc || '',
          ),
        );
        // 機器通信異常の最終異常発生日付を取得
        // Get the last error occurrence date of the device communication error
        errorDateUtcs.push(
          ...edge.communicationErrorList.map(
            (equipment) => equipment.errorState.errorDateUtc || '',
          ),
        );
      });
    }
    const lastErrorDateUtc = errorDateUtcs.reduce((a, b) => (a > b ? a : b), '');
    return lastErrorDateUtc;
  }

  /**
   * データ管理サービス関連のサブスクライブ
   */
  /**
   * Data management service related subscribe
   */
  dataManagementCombineLatest() {
    // 物件一覧、物件切り替え、パッケージ更新にサブスクライブ
    // Property List, Switching Property, Subscribing to Package Update
    // combineLatestに1度も値が流れていない要素が含まれると、subscribeが動かない場合があるため、
    // startWithで1回ダミーの値を流し、それをskipしておく
    // If Combinelatest contains elements that have never been flowing, subscribe may not work,
    // so set the dummy value once with startwith and skip it.
    this.dataManagementCombineSubscription = combineLatest([
      this.dataManagementService.buildingListStream.pipe(startWith(<BuildingEdgeStream>null)),
      this.dataManagementService.edgeStream
        .pipe(startWith({ type: StreamType.Building } as BuildingEdgeStream))
        .pipe(filter((stream: BuildingEdgeStream) => stream.type === StreamType.Building)),
      this.dataManagementService.packageListStream.pipe(startWith(<Package[]>null)),
    ])
      .pipe(skip(1))
      .pipe(
        filter(
          // 物件が選択されていない場合は処理をスキップする
          // If the property is not selected, skip the processing
          ([buildingList, selectedBuilding, packageList]) =>
            selectedBuilding.id !== '' && selectedBuilding.id != null,
        ),
      )
      .subscribe(() => {
        this.dataManagementCombineSubscribe();
      });

    // 人情報の更新にサブスクライブ(combineLatestに組み込むと正常に動かないためsubscribeに分割)
    // Subscribe to update human information (Split to Subscribe because incorporated into combineLatest)
    this.personSubscription = this.dataManagementService.personStream.subscribe(() => {
      this.dataManagementCombineSubscribe();
    });
  }

  /**
   * データ管理サービス関連のサブスクライブ時の処理
   */
  /**
   * Data management service-related subscribe processing
   */
  dataManagementCombineSubscribe() {
    // 物件一覧が変更されたか
    // Did the property list change?
    const isBuildingListChanged =
      this.siteList
        .map((building) => building.id)
        .sort()
        .toString() !==
      this.dataManagementService
        .buildingList()
        .map((building) => building.id)
        .sort()
        .toString();

    this.packageList = this.dataManagementService.packageList();
    this.siteList = this.dataManagementService.buildingList();
    this.selectedSite = this.dataManagementService.building();
    // カレント物件IDがNONEまたはALLの場合、以前選択していた物件情報を利用する
    // Use previously selected building when selected building id is either NONE or ALL
    if (this.selectedSite.id === 'NONE' || this.selectedSite.id === 'ALL') {
      this.selectedSite = this.dataManagementService.prevBuilding();
    }

    // 選択物件が変更されたか
    // Did the selection property change?
    const isSelectedSiteChanged = this.prevSelectedSiteId !== this.selectedSite.id;
    this.prevSelectedSiteId = this.selectedSite.id;

    // 物件一覧か選択物件が変更された場合のみ、異常状態確認APIを呼び出す
    // Call an abnormal status confirmation API only when the property list or the selected property is changed
    const fetchStateErrorFlg = isBuildingListChanged || isSelectedSiteChanged;

    this.updateAlert(fetchStateErrorFlg, true);
  }

  /**
   * データ管理サービス関連のサブスクライブを解除
   */
  /**
   * Cancel data management service related subscribe
   */
  clearDataManagementCombineSubscription() {
    if (this.dataManagementCombineSubscription) {
      this.dataManagementCombineSubscription.unsubscribe();
      this.dataManagementCombineSubscription = undefined;
    }
    if (this.personSubscription) {
      this.personSubscription.unsubscribe();
      this.personSubscription = undefined;
    }
  }

  /**
   * プロパティをクリアする
   */
  /**
   * Clear Property
   */
  private clearProperty() {
    this.siteList = [];
    this.stateErrorSubscriptionList = new Subscription();
    this.$alertFetchStream = new BehaviorSubject(false);
    this.zoomOutDateUtc = undefined;
  }

  /**
   * ローカルストレージから既読エラーIDを取得する
   * @returns {string[]} エラーID
   */
  /**
   * Get the read error ID from the local storage.
   * @returns {string[]} Error Ids
   */
  private getReadErrorIdsFromLocalStrage(): string[] {
    try {
      return JSON.parse(
        this.dataManagementService.localStorage(localStorageKeys.ErrorAlreadyReadIds) || '[]',
      );
    } catch {
      return [];
    }
  }

  /**
   * 異常状態更新API
   */
  /**
   * abnormal state update API
   */
  postErrorRead() {
    const errorRead = {
      errorIds: this.getReadErrorIdsFromLocalStrage(),
    };

    if (errorRead.errorIds.length === 0) {
      return;
    }

    this.restClientSharedService.postErrorRead(errorRead).subscribe(() => {
      this.dataManagementService.setLocalStorage(localStorageKeys.ErrorAlreadyReadIds, '[]');
      this.updateAlert(true, true);
    });
  }

  /**
   * 既読エラーIDが存在するか
   * @returns {boolean} 既読エラーIDがあるか
   */
  /**
   * Does the read error ID exist?
   * @returns {boolean} Is there a read error ID?
   */
  errorAlreadyReadIdExists(): boolean {
    return !!this.getReadErrorIdsFromLocalStrage().length;
  }

  /**
   * 既読エラーIDをローカルストレージに保存
   * @param newErrorIds エラーID
   */
  /**
   * Save read error ID in local storage
   * @param newErrorIds Error IDs
   */
  saveErrorAlreadyReadIds(newErrorIds: string[]) {
    let errorIds = [...this.getReadErrorIdsFromLocalStrage(), ...newErrorIds];
    errorIds = [...new Set(errorIds)];
    this.dataManagementService.setLocalStorage(
      localStorageKeys.ErrorAlreadyReadIds,
      JSON.stringify(errorIds),
    );
  }
  /**
   * 縮小かを取得
   *
   * @return 縮小か
   */
  /**
   * Acquired whether it is reduced
   *
   * @return Is it reduced?
   */
  get isZoomOut(): boolean {
    return this.zoomOutFlg;
  }

  /**
   * 縮小かを設定
   *
   * @param value 縮小か
   */
  /**
   * Set whether it is reduced
   *
   * @param value Is it reduced?
   */
  set isZoomOut(value: boolean) {
    this.zoomOutFlg = value;
    this.zoomOutDateUtc = value ? this.getCurrentLastErrorDateUtc() : '';
  }

  /**
   * アラートバー縮小状態を更新する
   * 縮小時の最終異常発生日付より最終異常発生日付が大きい場合、縮小を解除する
   */
  /**
   * Update alert bar collapsed state
   * If the final abnormal occurrence date is greater than the final abnormal occurrence date of reduced, cancel the reduction
   */
  updateZoomOut() {
    if (!this.isZoomOut) {
      return;
    }
    const lastErrorDateUtc = this.getCurrentLastErrorDateUtc();
    if (this.zoomOutDateUtc < lastErrorDateUtc) {
      this.isZoomOut = false;
    }
  }
}
