export enum AddUserItemId {
  AvailableEditUserTag = 'editableUserTag',
  AffiliateTag = 'showAffiliate',
  CompanyGroupTag = 'showCompanyGroup',
  CompanyTag = 'showCompany',
  TenantTag = 'showTenant',
  SelectableAffiliate = 'selectableAffiliate',
  SelectableCompanyGroup = 'selectableCompanyGroup',
  SelectableCompany = 'selectableCompany',
  SelectableTenant = 'selectableTenant',
  AssociatedSite = 'showAssociatedSite',
  TelephoneNumber = 'requiredTelephoneNumber',
  ShowAutomaticLogout = 'showAutomaticLogout',
  ShowErrorNotificationSetting = 'showErrorNotificationSetting',
  EditableAutomaticLogout = 'editableAutomaticLogout',
  EditableErrorNotificationSetting = 'editableErrorNotificationSetting',
  ShowCommunicationErrorNotification = 'showCommunicationErrorNotification',
  EditableCommunicationErrorNotification = 'editableCommunicationErrorNotification',
}
