import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { Building } from './classes/building-data';
import { Edge } from './classes/edge-data';
import { Person } from './classes/person-data';
import { Role } from './classes/role-data';
import { BuildingEdgeStream } from './classes/stream-data';
import { ApiKey } from './classes/apikey-data';
import { StreamType } from './enums/stream-type';
import { AgentType } from './enums/agent-type';
import { ErrorState } from '../../../main/enums';
import { Affiliate } from './classes/affiliate-data';
import { Utility } from '../../../shared-main/classes/utility';
import { Package } from './classes/package-data';
import { localStorageKeys } from 'src/app/shared-main/constants/local-storage-keys';
import { AuthorityManagerService } from 'src/app/shared-main/components/authority-control/authority-manager.service';
import { CachesLoginData } from 'src/app/login/interfaces/caches-login-data';
// import { CachesZoneZonesResponse } from 'src/app/monitoring-and-operation/equipment-list/interfaces/caches-zone-zones-response';
import { ZoneZonesResponse } from 'src/app/core/services/rest-client/interfaces/zone-service';
// import { CachesEquipmentBasicList } from 'src/app/monitoring-and-operation/equipment-list/interfaces';
import { CachesEdgeIconIds } from 'src/app/core/services/rest-client/interfaces/equipment-service/equipment-icon';
import { RestClientCaches } from 'src/app/core/services/rest-client/classes/rest-client-caches';
import { AppType } from 'src/app/common/enums/app-type';

@Injectable({
  providedIn: 'root',
})
export class DataManagementService {
  /**
   * コンストラクタ
   *
   * @param router ルータ情報
   * @param authorityManager 権限管理
   */
  /**
   * constructor
   *
   * @param router Router information
   * @param authorityManager Permission management
   */
  constructor(private router: Router, private authorityManager: AuthorityManagerService) {}

  private currentBuilding: Building = new Building('', '', '', []);
  private previousBuilding: Building = new Building('', '', '', []);
  private currentEdge: Edge = new Edge('', '');
  private currentPerson: Person = new Person('', '');
  private currentRole: Role = new Role('', '');
  private currentAffiliate: Affiliate = new Affiliate('', '');
  private currentBuildingList: Building[] = [];
  private currentPackageList: Package[] = [];
  private loginStatus: boolean = false;
  private apiKeyValue: ApiKey = new ApiKey('', '');
  private currentExcludedBuildingList: Building[] = [];
  private clearSelectBuildingFlg = false;

  static readonly appType: AppType = AppType.WebApp;

  cachesLoginData: CachesLoginData = new CachesLoginData();
  // cachesZoneZonesResponse: CachesZoneZonesResponse = new CachesZoneZonesResponse();
  personStream: Subject<Person> = new Subject();
  edgeStream: Subject<BuildingEdgeStream> = new Subject();
  buildingListStream: Subject<Building[]> = new Subject();
  excludedBuildingListStream: Subject<Building[]> = new Subject();
  packageListStream: Subject<Package[]> = new Subject();
  loginStatusStream: Subject<boolean> = new Subject();

  directAccessFlag: boolean = false;

  // キャッシュ済みの機器リスト基本情報
  // Cached Equipment List Basic Information
  // cachesEquipmentBasicList: CachesEquipmentBasicList = new CachesEquipmentBasicList();
  // キャッシュ済みのアイコンIDリスト
  // Cached Icon ID List
  cachesEdgeIconIds: CachesEdgeIconIds = new CachesEdgeIconIds();

  /**
   * 接続元機器を取得
   *
   * @return {number} 接続元機器種別
   */
  /**
   * Get connection source device
   *
   * @return {number} Source device type
   */
  static userAgentType() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.match('iphone')) {
      return AgentType.iPhone;
    }
    if (userAgent.match('ipad') || (userAgent.match('macintosh') && 'ontouchend' in document)) {
      return AgentType.iPad;
    }
    if (userAgent.match('macintosh')) {
      return AgentType.Macintosh;
    }
    if (userAgent.match('android')) {
      if (userAgent.match('mobile')) {
        return AgentType.AndroidPhone;
      }
      return AgentType.AndroidTablet;
    }
    if (userAgent.match('windows')) {
      return AgentType.WindowsPC;
    }
    return AgentType.Other;
  }

  /**
   * Excel出力に対応した機器か否かを判定
   *
   * @return {boolean} true: 対応 false: 非対応
   */
  /**
   * Judge whether the device is compatible with Excel output
   *
   * @return {boolean} true: Supported, false: Not supported
   */
  static isExcelSupport() {
    if (this.userAgentType() === AgentType.WindowsPC) {
      return true;
    }
    return false;
  }

  /**
   * IEからの接続か否かを判定
   *
   * @return {boolean} true: IEから false: IE以外から
   */
  /**
   * Determine if connection is from IE
   *
   * @return {boolean} true: From IE, false: From outside IE
   */
  static isIE() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.match(/(msie|trident)/i)) {
      return true;
    }
    return false;
  }

  /**
   * localStorage取得
   *
   * @param {string} key キー
   * @return {string} 値
   */
  /**
   * Get localStorage
   *
   * @param {string} key key
   * @return {string} value
   */
  localStorage(key: string): string {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      // cookie無効(WebStorageアクセス失敗)なら汎用エラー表示
      // If cookie is invalid (WebStorage access failure), general error display
      console.error(`Failed to get '${key}' from local storage.`);
      console.error(e);
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return '';
    }
  }

  /**
   * localStorage更新
   *
   * @param {string} key キー
   * @param {string} value 値
   * @return {boolean} 実行結果
   */
  /**
   * Update localStorage
   *
   * @param {string} key key
   * @param {string} value value
   * @return {boolean} Execution result
   */
  setLocalStorage(key: string, value: string): boolean {
    try {
      // localStorage.setItem(key, value);
      return true;
    } catch (e) {
      // cookie無効(WebStorageアクセス失敗)なら汎用エラー表示
      // If cookie is invalid (WebStorage access failure), general error display
      console.error(`Failed to set '${key}' to session storage.`);
      console.error(e);
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return false;
    }
  }

  /**
   * sessionStorage取得
   *
   * @param {string} key キー
   * @return {string} 値
   */
  /**
   * Get sessionStorage
   *
   * @param {string} key key
   * @return {string} value
   */
  sessionStorage(key: string): string {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      // cookie無効(WebStorageアクセス失敗)なら汎用エラー表示
      // If cookie is invalid (WebStorage access failure), general error display
      console.error(`Failed to get '${key}' from session storage.`);
      console.error(e);
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return '';
    }
  }

  /**
   * sessionStorage更新
   *
   * @param {string} key キー
   * @param {string} value 値
   * @return {boolean} 実行結果
   */
  /**
   * Update sessionStorage
   *
   * @param {string} key key
   * @param {string} value value
   * @return {boolean} Execution result
   */
  setSessionStorage(key: string, value: string): boolean {
    try {
      sessionStorage.setItem(key, value);
      return true;
    } catch (e) {
      // cookie無効(WebStorageアクセス失敗)なら汎用エラー表示
      // If cookie is invalid (WebStorage access failure), general error display
      console.error(`Failed to set '${key}' to session storage.`);
      console.error(e);
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return false;
    }
  }

  /**
   * sessionStorage削除
   *
   * @param {string} key キー
   * @return {boolean} 実行結果
   */
  /**
   * Remove sessionStorage
   *
   * @param {string} key key
   * @return {boolean} Execution result
   */
  removeSessionStorage(key: string): boolean {
    try {
      sessionStorage.removeItem(key);
      return true;
    } catch (e) {
      // cookie無効(WebStorageアクセス失敗)なら汎用エラー表示
      // If cookie is invalid (WebStorage access failure), general error display
      console.error(`Failed to set '${key}' to session storage.`);
      console.error(e);
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return false;
    }
  }

  /**
   * cookie情報取得
   *
   * @param {string} key cookie名
   * @return {string} 値
   */
  /**
   * Cookie information acquisition
   *
   * @param {string} key cookie name
   * @return {string} value
   */
  cookie(key: string): string {
    // ブラウザのcookieが無効なら汎用エラー画面表示
    // If the browser cookie is invalid, a general error screen is displayed.
    if (!navigator.cookieEnabled) {
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return '';
    }
    if (document.cookie !== '') {
      const cookieList = document.cookie.split('; ');
      for (const item of cookieList) {
        const cookie = item.split('=');
        if (cookie[0].trim() === key) {
          return cookie[1].trim();
        }
      }
    }
    return '';
  }

  /**
   * cookie保存
   *
   * @param {string} key cookie名
   * @param {string} value 値
   * @param {string} [age] 保存期間(秒)
   * @param {string} [domain] ドメイン
   * @param {string} [path] パス
   * @param {string} [sameSite] セキュリティレベル
   * @return {boolean} 保存結果
   */
  /**
   * Cookie storage
   *
   * @param {string} key cookie name
   * @param {string} value value
   * @param {string} [age] Retention period (seconds)
   * @param {string} [domain] domain
   * @param {string} [path] path
   * @param {string} [sameSite] Security Level
   * @return {boolean} Saved result
   */
  setCookie(
    key: string,
    value: string,
    age?: string,
    domain?: string,
    path?: string,
    sameSite?: string,
  ): boolean {
    // ブラウザのcookieが無効なら汎用エラー画面表示
    // If the browser cookie is invalid, a general error screen is displayed.
    if (!navigator.cookieEnabled) {
      this.router.navigate(['general-error', ErrorState.CookiesDisabled]);
      return false;
    }
    if (!key) {
      return false;
    }
    let cookie = `${key}=${value}`;
    if (age) {
      cookie += `;max-age=${age}`;
    }
    if (domain) {
      cookie += `;domain=${domain}`;
    }
    if (path) {
      cookie += `;path=${path}`;
    }
    if (sameSite) {
      cookie += `;sameSite=${sameSite}`;
    }
    document.cookie = cookie;
    return true;
  }

  /**
   * 物件情報取得
   *
   * @return {Building} 物件情報
   */
  /**
   * Acquisition of property information
   *
   * @return {Building} Property information
   */
  building(): Building {
    return this.currentBuilding;
  }

  /**
   * 物件情報更新
   *
   * @param {Building} building 物件情報
   * @return {boolean} 実行結果
   */
  /**
   * Property information update
   *
   * @param {Building} building Property information
   * @return {boolean} Execution result
   */
  setBuilding(building: Building): boolean {
    if (!building.isValid()) {
      return false;
    }
    this.clearSelectBuildingFlg = false;

    // 物件切り替え前に選択していた物件情報を保持
    // Set to previously selected building
    const oldBuilding = new Building(
      this.currentBuilding.id,
      this.currentBuilding.name,
      this.currentBuilding.currentTime,
      this.currentBuilding.edgeList,
      this.currentBuilding.packageList,
      this.currentBuilding.selectedId,
      this.currentBuilding.operationStatusColor,
    );

    if (this.currentBuilding.isUpdated(building)) {
      this.currentBuilding.id = building.id;
      this.currentBuilding.name = building.name;
      this.currentBuilding.currentTime = building.currentTime;
      this.currentBuilding.edgeList = [];
      this.currentBuilding.packageList = [];
      this.currentBuilding.selectedId = building.selectedId;
      this.currentBuilding.operationStatusColor = building.operationStatusColor;

      if (building.edgeList.length > 0) {
        Object.assign(this.currentBuilding.edgeList, building.edgeList);
      }
      if (building.packageList.length > 0) {
        Object.assign(this.currentBuilding.packageList, building.packageList);
      }

      // カレント物件IDがNONEまたはALLの場合、以前選択していた物件（NONE、ALL含まない）情報を保持
      // Set previously selected building (NONE, ALL excluded) when selected building id is either NONE or ALL
      if (
        oldBuilding.id !== 'NONE' &&
        oldBuilding.id !== 'ALL' &&
        (this.currentBuilding.id === 'NONE' || this.currentBuilding.id === 'ALL')
      ) {
        this.previousBuilding.id = oldBuilding.id;
        this.previousBuilding.name = oldBuilding.name;
        this.previousBuilding.currentTime = oldBuilding.currentTime;
        this.previousBuilding.edgeList = [];
        this.previousBuilding.packageList = [];
        this.previousBuilding.selectedId = oldBuilding.selectedId;
        this.previousBuilding.operationStatusColor = oldBuilding.operationStatusColor;

        if (oldBuilding.edgeList.length > 0) {
          Object.assign(this.previousBuilding.edgeList, oldBuilding.edgeList);
        }
        if (oldBuilding.packageList.length > 0) {
          Object.assign(this.previousBuilding.packageList, oldBuilding.packageList);
        }
      }

      // 選択中の物件をローカルストレージに保存
      // Save the selected building to local storage
      // localStorage.setItem('buildingId', this.currentBuilding.id);
      // 選択中の物件IDが実際の物件の場合、ローカルストレージに保存
      // If the selected property ID is the actual property, save it in the local storage.
      const currentBuildingId = this.currentBuilding.id;
      if (currentBuildingId !== 'ALL' && currentBuildingId !== 'NONE' && currentBuildingId !== '') {
        this.setLocalStorage(localStorageKeys.LastSelectedRealBuildingId, currentBuildingId);
      }

      // カレント物件のname変更を物件リスト内の同物件にも反映
      // Reflecting the name change of the current property to the same property in the property list
      const currentIndex = this.currentBuildingList.findIndex((item) => item.id === building.id);
      if (currentIndex >= 0) {
        if (this.currentBuildingList[currentIndex].name !== this.currentBuilding.name) {
          this.currentBuildingList[currentIndex].name = this.currentBuilding.name;
        }
        if (
          this.currentBuildingList[currentIndex].operationStatusColor !==
          this.currentBuilding.operationStatusColor
        ) {
          this.currentBuildingList[currentIndex].operationStatusColor =
            this.currentBuilding.operationStatusColor;
        }
      }

      // カレントのエッジが物件エッジリストに含まれない場合はリスト先頭のエッジをカレントに変更
      // If the current edge is not included in the property edge list, change the top edge of the list to current
      if (building.edgeList.length > 0) {
        if (!building.edgeList.find((item) => item.id === this.currentEdge.id)) {
          this.setEdge(building.edgeList[0]);
        }
      } else {
        // 物件のエッジリストが空ならカレントエッジを空に初期化
        // If the property edge list is empty, initialize the current edge to empty
        this.currentEdge = new Edge('', '');
        this.edgeStream.next({
          type: StreamType.Edge,
          id: this.currentEdge.id,
          name: this.currentEdge.name,
          currentTime: '',
        });
      }
      if (
        oldBuilding.checkStream(building) ||
        this.previousBuilding.checkStream(this.currentBuilding) ||
        this.directAccessFlag
      ) {
        // id, name, operationStatusColor が変更されているとき、または自動ログインによって物件情報を取得しているとき、設定情報をストリームに送信
        // Sends configuration information to the stream if id or name or operationStatusColor are changed or property information is got by automatic login
        this.edgeStream.next({
          type: StreamType.Building,
          id: this.currentBuilding.id,
          name: this.currentBuilding.name,
          currentTime: this.currentBuilding.currentTime,
        });
      }
    }
    return true;
  }

  /**
   * 以前選択していた物件情報取得
   *
   * @return {Building} 以前選択していた物件情報
   */
  /**
   * Acquisition of previously selected property information
   *
   * @return {Building} Previously selected property information
   */
  prevBuilding(): Building {
    return this.previousBuilding;
  }

  /**
   * 以前選択していた物件をクリア
   */
  /**
   * Previously selected property
   */
  clearPrevBuilding(): void {
    this.clearSelectBuildingFlg = true;
    this.previousBuilding = new Building('', '', '', []);
  }

  /**
   * 画面内選択物件設定
   *
   * @param {string} id 物件ID
   */
  /**
   * Building settings selected in the screen
   *
   * @param {string} id Building ID
   */
  selectedBuildingId(id: string) {
    this.currentBuilding.selectedId = id;
  }

  /**
   * 物件一覧取得
   *
   * @return {Building[]} 物件一覧
   */
  /**
   * Acquisition of property list
   *
   * @return {Building[]} List of properties
   */

  buildingList(): Building[] {
    return this.currentBuildingList;
  }

  /**
   * 物件一覧更新
   *
   * @param {Building[]} buildingList 物件一覧
   * @return {boolean} 実行結果
   */
  /**
   * Property list update
   *
   * @param {Building[]} buildingList List of properties
   * @return {boolean} Execution result
   */
  setBuildingList(buildingList: Building[]): boolean {
    if (buildingList.length <= 0) {
      // 空なら物件リストを空に初期化
      // If empty, initialize property list to empty
      if (this.currentBuildingList.length > 0) {
        this.currentBuildingList = [];
        this.buildingListStream.next(this.currentBuildingList);
      }
      if (this.currentBuilding.id !== '') {
        // カレント物件があれば空に初期化
        // If there is a current property, it is initialized to the empty
        this.currentBuilding = new Building('', '', '', []);
        this.edgeStream.next({
          type: StreamType.Building,
          id: this.currentBuilding.id,
          name: this.currentBuilding.name,
          currentTime: '',
        });
        if (this.currentEdge.id !== '') {
          // カレントエッジがあれば同様に空に初期化
          // If there is a current edge, initialize it to empty as well
          this.currentEdge = new Edge('', '');
          this.edgeStream.next({
            type: StreamType.Edge,
            id: this.currentEdge.id,
            name: this.currentEdge.name,
            currentTime: '',
          });
        }
      }
      return true;
    }

    if (JSON.stringify(this.currentBuildingList) !== JSON.stringify(buildingList)) {
      this.currentBuildingList = [];
      if (buildingList.length > 0) {
        for (const building of buildingList) {
          this.currentBuildingList.push(Building.copy(building));
        }
      }

      // this.currentBuildingList = JSON.parse(JSON.stringify(buildingList));
      this.buildingListStream.next(this.currentBuildingList);
    }

    // リストのname変更をカレントの物件にも反映
    // Reflect the name change of the list to the current property
    const currentIndex = this.currentBuildingList.findIndex(
      (item) => item.id === this.currentBuilding.id,
    );
    if (currentIndex >= 0) {
      if (this.currentBuilding.name !== this.currentBuildingList[currentIndex].name) {
        this.currentBuilding.name = this.currentBuildingList[currentIndex].name;
      }
      if (
        this.currentBuilding.operationStatusColor !==
        this.currentBuildingList[currentIndex].operationStatusColor
      ) {
        this.currentBuilding.operationStatusColor =
          this.currentBuildingList[currentIndex].operationStatusColor;
      }
    }

    // カレント物件が物件リストに含まれない場合はリスト先頭の物件をカレントに変更
    // If the current property is not included in the property list, change the property at the top of the list to current
    if (
      (this.currentBuilding.id !== 'NONE' || this.clearSelectBuildingFlg) &&
      !buildingList.find((item) => item.id === this.currentBuilding.id)
    ) {
      let targetBuilding;
      if (
        !this.clearSelectBuildingFlg &&
        this.currentBuilding.id === '' &&
        !this.directAccessFlag
      ) {
        // 前回ログイン時、最後に選択していた実物件を取得
        // Acquire the last selected real property when you logged in last time.
        const initialBuildingId: string = this.localStorage(
          localStorageKeys.LastSelectedRealBuildingId,
        );
        targetBuilding = this.buildingList().find((building) => building.id === initialBuildingId);
        if (targetBuilding !== undefined) {
          this.setBuilding(targetBuilding);
        }
      }

      if (targetBuilding === undefined) {
        const firstBuilding = this.getFirstBuilding();
        if (firstBuilding !== undefined) {
          this.setBuilding(firstBuilding);
        }
      }
    }

    // シーズン前遠隔点検の初期物件更新
    // Initial property update for remote inspection before season.
    this.setPreseasonInitialBuilding();

    return true;
  }

  /**
   * エッジ情報取得
   *
   * @return {Edge} エッジ情報
   */
  /**
   * Edge information acquisition
   *
   * @return {Edge} Edge information
   */
  edge(): Edge {
    return this.currentEdge;
  }

  /**
   * エッジ情報更新
   *
   * @param {Edge} edge エッジ情報
   * @return {boolean} 実行結果
   */
  /**
   * Edge information update
   *
   * @param {Edge} edge Edge information
   * @return {boolean} Execution result
   */
  setEdge(edge: Edge): boolean {
    if (!edge.isValid()) {
      return false;
    }

    if (this.currentEdge.isUpdated(edge)) {
      this.currentEdge.id = edge.id;
      this.currentEdge.name = edge.name;
      // 設定情報をストリームに送信
      // Send configuration information to stream
      this.edgeStream.next({
        type: StreamType.Edge,
        id: this.currentEdge.id,
        name: this.currentEdge.name,
        currentTime: '',
      });
    }
    return true;
  }

  /**
   * 人情報取得
   *
   * @return {Person} 人情報
   */
  /**
   * Person information acquisition
   *
   * @return {Person} Person information
   */
  person(): Person {
    return this.currentPerson;
  }

  /**
   * 人情報更新
   *
   * @param {Person} person 人情報
   * @return {boolean} 実行結果
   */
  /**
   * Person information update
   *
   * @param {Person} person Person information
   * @return {boolean} Execution result
   */
  setPerson(person: Person): boolean {
    if (!person.isValid()) {
      return false;
    }

    if (this.currentPerson.isUpdated(person)) {
      this.currentPerson.id = person.id;
      this.currentPerson.name = person.name;
      this.currentPerson.timeDisplay = person.timeDisplay;
      this.currentPerson.termsOfServiceState = person.termsOfServiceState;
      this.currentPerson.automaticLogoutTime = person.automaticLogoutTime;
      this.currentPerson.errorNotificationSetting = person.errorNotificationSetting;
      this.currentPerson.communicationErrorNotification = person.communicationErrorNotification;
      this.currentPerson.buildingIdList = person.buildingIdList;

      // 設定情報をストリームに送信
      // Send configuration information to stream
      this.personStream.next(this.currentPerson);
    }

    return true;
  }

  /**
   * 権限情報取得
   *
   * @return {Role} 権限情報
   */
  /**
   * Permission information acquisition
   *
   * @return {Role} Authority information
   */
  role(): Role {
    return this.currentRole;
  }

  /**
   * 権限情報更新
   *
   * @param {Role} role 権限情報
   * @return {boolean} 実行結果
   */
  /**
   * Permission information update
   *
   * @param {Role} role Authority information
   * @return {boolean} Execution result
   */
  setRole(role: Role): boolean {
    if (!role.isValid()) {
      return false;
    }

    if (this.currentRole.isUpdated(role)) {
      this.currentRole.id = role.id;
      this.currentRole.name = role.name;
    }
    return true;
  }

  /**
   * アフィリエイト情報取得
   *
   * @return {Affiliate} アフィリエイト情報
   */
  /**
   * Affiliate information acquisition
   *
   * @return {Affiliate} Affiliate information
   */
  affiliate(): Affiliate {
    return this.currentAffiliate;
  }

  /**
   * アフィリエイト情報更新
   *
   * @param {Affiliate} affiliate アフィリエイト情報
   * @return {boolean} 実行結果
   */
  /**
   * Affiliate information update
   *
   * @param {Affiliate} affiliate Affiliate information
   * @return {boolean} Execution result
   */
  setAffiliate(affiliate: Affiliate): boolean {
    if (!affiliate.isValid()) {
      return false;
    }

    if (this.currentAffiliate.isUpdated(affiliate)) {
      this.currentAffiliate.id = affiliate.id;
      this.currentAffiliate.name = affiliate.name;
    }
    return true;
  }

  /**
   * APIキー取得
   *
   * @return {ApiKey} APIキー情報
   */
  /**
   * API key acquisition
   *
   * @return {ApiKey} API key information
   */
  apiKey(): ApiKey {
    return this.apiKeyValue;
  }

  /**
   * APIキー保存
   *
   * @param {ApiKey} key APIキー情報
   * @return {boolean} 実行結果
   */
  /**
   * API key storage
   *
   * @param {ApiKey} key API key information
   * @return {boolean} Execution result
   */
  setApiKey(key: ApiKey): boolean {
    this.apiKeyValue.apiKey = key.apiKey;
    this.apiKeyValue.version = key.version;
    return true;
  }

  /**
   * ログイン状態情報取得
   *
   * @return {boolean} ログイン状態
   */
  /**
   * Get login status information
   *
   * @return {boolean} Login status
   */
  isLogin(): boolean {
    return this.loginStatus;
  }

  /**
   * ログイン状態情報更新
   *
   * @param {boolean} loginStatus ログイン状態
   * @param {boolean} cachesLoginDataFlg ログインデータをキャッシュするかどうか
   * @param {RestClientCaches} restClientCaches 通信サービスのキャッシュ
   */
  /**
   * Update login status information
   *
   * @param {boolean} loginStatus Login status
   * @param {boolean} cachesLoginDataFlg Whether to cache login data
   * @param {RestClientCaches} restClientCaches Communication service cache
   */
  setIsLogin(
    loginStatus: boolean,
    cachesLoginDataFlg: boolean = false,
    restClientCaches?: RestClientCaches,
  ) {
    if (!loginStatus) {
      cachesLoginDataFlg && restClientCaches
        ? this.setCachesLoginData(restClientCaches)
        : this.clearCaches();
    }

    this.loginStatusStream.next(loginStatus);
    if (!loginStatus) {
      // ログアウト時に保持データを初期化
      // Initialize retained data when logging out
      this.initializeData();
    }
    this.loginStatus = loginStatus;
  }

  /**
   * 除外物件取得
   *
   * @return {Building[]} 除外物件一覧
   */
  /**
   * Acquisition of excluded building list
   *
   * @return {Building[]} List of excluded building
   */

  excludedBuildingList(): Building[] {
    return this.currentExcludedBuildingList;
  }

  /**
   * 除外物件一覧更新
   *
   * @param {Building[]} buildingList 除外物件一覧
   * @return {boolean} 実行結果
   */
  /**
   * Excluded building list update
   *
   * @param {Building[]} buildingList List of excluded building
   * @return {boolean} Execution result
   */
  setExcludedBuildingList(buildingList: Building[]): boolean {
    this.currentExcludedBuildingList = [];
    if (buildingList.length > 0) {
      for (const building of buildingList) {
        this.currentExcludedBuildingList.push(Building.copy(building));
      }
    }
    this.excludedBuildingListStream.next(this.currentExcludedBuildingList);
    return true;
  }

  /**
   * 物件一覧から有効期限内のパッケージがある物件の内、一覧の先頭にある物件を取得する。
   * 全ての物件に有効期限内のパッケージがなければ一覧の先頭にある物件。
   * 物件一覧が空の場合はundefined。
   * @returns 物件
   */
  /**
   * From the property list, acquire the property at the top of the list among the properties
   * that have packages within the expiration date.
   * If all properties do not have a package within the expiration date,
   * the property at the top of the list.
   * Undefined if the property list is empty.
   * @returns building
   */
  getFirstBuilding(): Building | undefined {
    const buildings = this.buildingList();
    if (buildings.length === 0) {
      return undefined;
    }
    const validBuilding = buildings.find((building) =>
      building.packageList.some((p) => !p.expired),
    );
    if (validBuilding !== undefined) {
      return validBuilding;
    }
    return buildings[0];
  }

  /**
   * パッケージ一覧取得
   *
   * @return {Package[]} パッケージ一覧
   */
  /**
   * Acquisition of package list
   *
   * @return {Package[]} List of package
   */

  packageList(): Package[] {
    return this.currentPackageList;
  }

  /**
   * パッケージ一覧更新
   *
   * @param {Package[]} packageList パッケージ一覧
   * @return {boolean} 実行結果
   */
  /**
   * Package list update
   *
   * @param {Package[]} packageList List of package
   * @return {boolean} Execution result
   */
  setPackageList(packageList: Package[]): boolean {
    if (JSON.stringify(this.currentPackageList) === JSON.stringify(packageList)) {
      return false;
    }

    this.currentPackageList = [];
    Utility.deepMerge(this.currentPackageList, packageList);
    this.packageListStream.next(this.currentPackageList);
    return true;
  }

  /**
   * パッケージが有効になっているかを判定する
   *
   * @param {string} screenId 画面ID
   * @return {boolean} パッケージが有効になっているか
   */
  /**
   * Determine if the package is valid
   *
   * @param {string} screenId ScreenID
   * @return Is the {boolean} package enabled?
   */
  isPackageAvailable(screenId: string): boolean {
    const validPackageList: string[] = [];

    // 有効期限内のパッケージを取得
    // Get the package Id within the expiration date
    const enabledBuildingPackage = this.building().packageList.filter((item) => {
      return !item.expired;
    });
    const enabledPackageList = this.packageList().filter((item) => {
      return enabledBuildingPackage.some((n) => n.packageId === item.id);
    });

    enabledPackageList.forEach((packageInformation) => {
      packageInformation.functionStatusList.forEach((menuList) => {
        const validMainMenu =
          menuList.mainMenu.screenId === screenId && menuList.mainMenu.availability;
        const subMenuInformation = menuList.subMenu.find((sub) => sub.screenId === screenId);
        const validSubMenu = subMenuInformation && subMenuInformation.availability;
        if (validMainMenu || validSubMenu) {
          validPackageList.push(packageInformation.id);
        }
      });
    });
    return validPackageList.length > 0;
  }

  /**
   * シーズン前遠隔点検の初期物件更新
   */
  /**
   * Initial property update for remote inspection before season
   */
  private setPreseasonInitialBuilding(): void {
    // シーズン前遠隔点検の点検案内メールらのログイン時の場合、クエリパラメータの物件IDを初期表示とする。
    // When logging in to the inspection guide email for remote inspection before the season,
    // the property ID in the query parameter is initially displayed.
    const inspectionRequestBuildingId = this.sessionStorage('inspectionRequestBuildingId');
    if (inspectionRequestBuildingId !== null) {
      const targetBuilding = this.buildingList().find(
        (building) => building.id === inspectionRequestBuildingId,
      );
      this.removeSessionStorage('inspectionRequestBuildingId');
      if (targetBuilding) {
        this.setBuilding(targetBuilding);
      } else {
        this.router.navigateByUrl('');
      }
    }

    // シーズン前遠隔点検の点検結果メールからのログイン時の場合、クエリパラメータの物件IDを初期表示とする。
    // When logging in from the inspection result email of the pre-season remote inspection,
    // the property ID of the query parameter is initially displayed.
    const inspectionResultBuildingId = this.sessionStorage('inspectionResultBuildingId');
    if (inspectionResultBuildingId !== null) {
      const targetBuilding = this.buildingList().find(
        (building) => building.id === inspectionResultBuildingId,
      );
      this.removeSessionStorage('inspectionResultBuildingId');
      if (targetBuilding) {
        this.setBuilding(targetBuilding);
      } else {
        this.router.navigateByUrl('');
      }
    }
  }

  /**
   * データ管理部保持情報初期化
   *
   */
  /**
   * Data management section retained information initialization
   *
   */
  private initializeData() {
    this.currentBuilding = new Building('', '', '', []);
    this.currentEdge = new Edge('', '');
    this.currentPerson = new Person('', '');
    this.currentRole = new Role('', '');
    this.currentAffiliate = new Affiliate('', '');
    this.currentBuildingList = [];
    this.apiKeyValue = new ApiKey('', '');
    this.currentExcludedBuildingList = [];
    this.currentPackageList = [];
  }

  /**
   * 人データ取得APIのデータを全てキャッシュ済みかどうか
   */
  /**
   * Whether all data of human data acquisition API has been cached
   */
  get isCachesLoginDataPersonApi() {
    return [
      this.cachesLoginData.person,
      this.cachesLoginData.role,
      this.cachesLoginData.affiliate,
    ].every((item) => item);
  }

  /**
   * キャッシュデータをクリアする
   */
  /**
   * Clear cache data
   */
  clearCachesLoginData() {
    delete this.cachesLoginData;
    this.cachesLoginData = new CachesLoginData();
  }

  /**
   * キャッシュの人IDと同じかどうか
   * @param personId 人ID
   * @returns キャッシュの人IDと同じ場合、true
   */
  /**
   * Whether or not the cache person ID
   * @param personId People id
   * @returns TRUE if the cache person ID is the same
   */
  equalCachesLoginDataPersonId(personId: string) {
    const person: Person = this.cachesLoginData.person;
    if (!person) {
      return false;
    }
    return personId === person.id;
  }

  /**
   * ログインデータをキャッシュする
   * @param restClientCaches 通信サービスのキャッシュ
   */
  /**
   * Cache login data
   * @param restClientCaches Communication service cache
   */
  setCachesLoginData(restClientCaches: RestClientCaches) {
    if (!this.isLogin()) {
      return;
    }
    this.cachesLoginData.person = Utility.deepCopy(this.currentPerson);
    this.cachesLoginData.role = Utility.deepCopy(this.currentRole);
    this.cachesLoginData.affiliate = Utility.deepCopy(this.currentAffiliate);
    this.cachesLoginData.packageList = Utility.deepCopy(this.currentPackageList);
    this.cachesLoginData.buildingList = Utility.deepCopy(restClientCaches.buildingList);
    this.cachesLoginData.buildings = Utility.deepCopy(restClientCaches.buildings);
    this.cachesLoginData.accessLevel = Utility.deepCopy(this.authorityManager.getAccessLevel);
  }

  /**
   * ゾーンデータ取得(物件指定)APIデータをキャッシュする
   * @param buildingId 物件ID
   * @param zoneZonesResponse ゾーンデータ取得(物件指定)APIデータ
   */
  /**
   * Zone data acquisition (property specification) API data caches
   * @param buildingId Property ID
   * @param zoneZonesResponse Zone data acquisition (property specification) API data
   */
  setCachesZoneZonesResponse(buildingId: string, zoneZonesResponse: ZoneZonesResponse): void {
    // this.cachesZoneZonesResponse = new CachesZoneZonesResponse(buildingId, zoneZonesResponse);
  }

  /**
   * ゾーンデータ取得(物件指定)APIデータをキャッシュしているかを判定する
   * @param buildingId 物件ID
   * @returns キャッシュしている場合、true
   */
  /**
   * Zone data acquisition (property specification) determines whether API data is cached
   * @param buildingId Property ID
   * @returns True if you are caching
   */
  // isCachesZoneZonesResponse(buildingId: string): boolean {
    // return this.cachesZoneZonesResponse.buildingId === buildingId;
  // }

  /**
   * ゾーンデータ取得(物件指定)APIデータのキャッシュをクリアする
   */
  /**
   * Zone data acquisition (property specification) Clears the API data cache
   */
  clearCachesZoneZonesResponse() {
    // delete this.cachesZoneZonesResponse;
    // this.cachesZoneZonesResponse = new CachesZoneZonesResponse();
  }

  /**
   * キャッシュをクリアする
   */
  /**
   * Clear the cache
   */
  clearCaches() {
    this.clearCachesLoginData();
    this.clearCachesZoneZonesResponse();
    this.clearCachesEquipmentBasicList();
    this.clearCachesEdgeIconIds();
  }

  /**
   * 機器基本情報リストのキャッシュをクリアする
   */
  /**
   * Clear the cache of the device basic information list
   */
  clearCachesEquipmentBasicList(): void {
    // if (this.cachesEquipmentBasicList) {
    //   delete this.cachesEquipmentBasicList;
    // }
    // this.cachesEquipmentBasicList = new CachesEquipmentBasicList();
  }

  /**
   * アイコンIDリストのキャッシュをクリアする
   */
  /**
   * Icon Clear Cache of ID List
   */
  clearCachesEdgeIconIds(): void {
    if (this.cachesEdgeIconIds) {
      delete this.cachesEdgeIconIds;
    }
    this.cachesEdgeIconIds = new CachesEdgeIconIds();
  }
}
