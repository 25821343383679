import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  BuildingBuildingsPostRequest,
  BuildingBuildingsPutRequest,
  BuildingBuildingsItem,
  BuildingBuildingsGetResponse,
} from '../interfaces/building-service';
import { Utility } from '../../../../shared-main/classes/utility';
import { cacheMaxCountOfBuilding } from '../classes/rest-client-caches';
import { ScreenId } from '../../../../shared-main/enums/screen-id.enum';
import { LoaderService } from 'src/app/core/components/loader/loader.service';

const pathOfBuilding = `building/${apiVersion}/`;

@Injectable()
export class RestClientBuildingService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-2. 物件サービス
  //  3-2. Property services
  /////////////////////////////////////////////////////////////////////////////
  // 3-2-1. 物件管理
  // 3-2-1. Property management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 物件データ登録API
   * response body: BuildingBuildingsPostResponse
   *
   * @param {BuildingBuildingsPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Property data registration API
   * response body: BuildingBuildingsPostResponse
   *
   * @param {BuildingBuildingsPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postBuildingBuildings(param: BuildingBuildingsPostRequest): Observable<Response> {
    return this.restClientCommonService
      .request('post', `${this.endPoint}${pathOfBuilding}buildings`, param, this.screenId)
      .pipe(
        tap((response) => {
          if (response.status === 201) {
            // 物件リストのキャッシュクリア
            // Clear property list cash
            this.clearBuildingCache();
          }
        }),
      );
  }

  /**
   * 物件データ取得API 兼 物件データ一覧取得API
   * response body: BuildingBuildingGetResponse
   *                BuildingTenantTagListGetResponse
   *                BuildingBuildingsGetResponse
   *                BuildingsTenantTagListGetResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [isEdge = false] エッジ数取得フラグ
   * @param {string} [fields] 取得項目制限(tenantTagList)
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @param {boolean} [isPackageAll = false] 全パッケージ返却フラグ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Property Data Acquisition API and Property Data List Acquisition API
   * response body: BuildingBuildingGetResponse
   *                BuildingTenantTagListGetResponse
   *                BuildingBuildingsGetResponse
   *                BuildingsTenantTagListGetResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [isEdge = false] Edge number acquisition flag
   * @param {string} [fields] Acquisition item limit(tenantTagList)
   * @param {boolean} [force = false] Get information from backend without using cache
   * @param {boolean} [isPackageAll = false] All PackageA return flag
   * @return {Observable<Response>} status:HTTP status
   */
  getBuildingBuildings(
    buildingId?: string,
    isEdge: boolean = false,
    fields?: string,
    force: boolean = false,
    isPackageAll: boolean = false,
  ): Observable<Response> {
    const query = `?isEdge=${isEdge}&isPackageAll=${isPackageAll}`;
    if (!buildingId) {
      if (!force && this.caches && this.caches.buildingList.items.length > 0) {
        if (
          !isEdge ||
          (this.caches.buildingList.items[0] &&
            this.caches.buildingList.items[0].numberEdges !== null &&
            this.caches.buildingList.items[0].numberEdges !== undefined)
        ) {
          // cache利用条件に合致
          // meet cache usage conditions
          return new Observable((observer) => {
            observer.next(new Response(200, this.caches.buildingList));
            observer.complete();
          });
        }
      }
      return this.restClientCommonService
        .request(
          'get',
          `${this.endPoint}${pathOfBuilding}buildings${query}`,
          undefined,
          this.screenId,
        )
        .pipe(
          tap((response) => {
            // 画面が物件一覧の時のみキャッシュをしない
            // Do not cache only when the screen is a property list
            if (
              response.status === 200 &&
              response.data &&
              this.restClientCommonService.getScreenId() !== ScreenId.SiteList
            ) {
              Utility.deepMerge(this.caches.buildingList, response.data);
              // 物件リスト一時保存配列
              // Property list temporary storage array
              const temporaryBuildingList = [];
              // 物件削除の検出、削除用配列に格納
              // Property deletion detection
              for (let i = 0, len = this.caches.buildingList.items.length; i < len; i += 1) {
                if (
                  response.data.items.find(
                    (item) => item.buildingId === this.caches.buildingList.items[i].buildingId,
                  )
                ) {
                  temporaryBuildingList.push(Utility.deepCopy(this.caches.buildingList.items[i]));
                }
              }
              if (temporaryBuildingList.length > 0) {
                // 物件リストのキャッシュクリア
                // Clear property list cash
                this.clearBuildingCache();
                // 一時保存配列から物件リストに格納
                // Store in property list from temporary storage array
                for (let i = 0, len = temporaryBuildingList.length; i < len; i += 1) {
                  this.caches.buildingList.items.push(Utility.deepCopy(temporaryBuildingList[i]));
                }
              }
            }
          }),
        );
    }
    return this.getBuilding(buildingId, isEdge, fields, force);
  }

  /**
   * 物件一覧画面の物件データ一覧取得API。
   * response body:  BuildingBuildingsGetResponse
   * @param isEdge エッジ数取得フラグ
   * @param loaderService ローダーサービス。引数に指定した場合は、ローディングアニメーションの表示制御を行う。
   * @param {boolean} [isPackageAll = false] 全パッケージ返却フラグ
   * @returns 物件データ一覧取得結果
   */
  /**
   * Property data list acquisition API for property list screen.
   * response body:  BuildingBuildingsGetResponse
   * @param isEdge Edge count acquisition flag
   * @param loaderService Loader service. When specified as an argument,
   * @param {boolean} [isPackageAll = false] All PackageA return flag
   *  display control of the loading animation is performed.
   * @returns Property data list acquisition result
   */
  getSiteListBuildings(
    isEdge: boolean = false,
    loaderService?: LoaderService,
    isPackageAll: boolean = false,
  ): Observable<Response<BuildingBuildingsGetResponse>> {
    return this.restClientCommonService.requestAllForPagedAPI<BuildingBuildingsItem>(
      (nextKey?: string) => {
        let query = `?isEdge=${isEdge}&isPackageAll=${isPackageAll}`;
        if (nextKey) {
          query += `&nextKey=${nextKey}`;
        }
        return this.restClientCommonService.request(
          'get',
          `${this.endPoint}${pathOfBuilding}buildings${query}`,
          undefined,
          this.screenId,
        );
      },
      loaderService,
    );
  }

  /**
   * 物件データ取得API
   * response body: BuildingBuildingGetResponse
   *                BuildingTenantTagListGetResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [isEdge = false] エッジ数取得フラグ
   * @param {string} [fields] 取得項目制限(tenantTagList)
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @param {boolean} [isPackageAll = false] 全パッケージ返却フラグ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Property data acquisition API
   * response body: BuildingBuildingGetResponse
   *                BuildingTenantTagListGetResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [isEdge = false] Edge number acquisition flag
   * @param {string} [fields] Acquisition item limit(tenantTagList)
   * @param {boolean} [force = false] Get information from backend without using cache
   * @param {boolean} [isPackageAll = false] All PackageA return flag
   * @return {Observable<Response>} status:HTTP status
   */
  private getBuilding(
    buildingId: string,
    isEdge: boolean = false,
    fields: string,
    force: boolean,
    isPackageAll: boolean = false,
  ): Observable<Response> {
    let query = `?isEdge=${isEdge}&isPackageAll=${isPackageAll}`;
    if (fields) {
      query += `&fields=${fields}`;
    }

    const cache = this.caches.buildings.find((item) => item.buildingId === buildingId);
    // cache利用の確認
    // Confirm cache usage
    if (!force && cache) {
      if ((fields && cache[fields]) || (isEdge && cache.edgeList) || (!fields && !isEdge)) {
        // cache利用条件に合致
        // Meets cache usage conditions
        return new Observable((observer) => {
          observer.next(new Response(200, cache));
          observer.complete();
        });
      }
    }
    return this.restClientCommonService
      .request(
        'get',
        `${this.endPoint}${pathOfBuilding}buildings/${buildingId}${query}`,
        undefined,
        this.screenId,
      )
      .pipe(
        tap((response) => {
          if (response.status === 200 && response.data) {
            if (cache) {
              Utility.deepMerge(cache, response.data);
              // キャッシュリストから削除して、先頭に入れ直す
              // Remove from the cache list and return to the top
              const index = this.caches.buildings.findIndex(
                (item) => item.buildingId === cache.buildingId,
              );
              this.caches.buildings.splice(index, 1);
              this.caches.buildings.unshift(cache);
            } else {
              if (this.caches.buildings.length >= cacheMaxCountOfBuilding) {
                this.caches.buildings.pop();
              }
              this.caches.buildings.unshift({ buildingId, ...response.data });
            }
          }
        }),
      );
  }

  /**
   * 物件データ更新API
   * response body: BuildingBuildingsPutResponse
   *
   * @param {BuildingBuildingsPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Property data update API
   * response body: BuildingBuildingsPutResponse
   *
   * @param {BuildingBuildingsPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putBuildingBuildings(param: BuildingBuildingsPutRequest): Observable<Response> {
    const callback = (): Observable<Response> => {
      return this.restClientCommonService
        .request(
          'put',
          `${this.endPoint}${pathOfBuilding}buildings/${param.buildingId}`,
          param,
          this.screenId,
        )
        .pipe(
          tap((response) => {
            if (response.status === 200) {
              // 物件リストのキャッシュクリア
              // Clear property list cash
              this.clearBuildingCache();
              // 物件キャッシュクリア
              // Clear property cash
              this.clearBuildingsCache(param.buildingId);
            }
          }),
        );
    };
    return this.restClientCommonService.sqsRequest(callback);
  }

  /**
   * 物件データ削除API
   * response body: none
   *
   * @param {string} buildingId 物件ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Property data deletion API
   * response body: none
   *
   * @param {string} buildingId Property ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteBuildingBuildings(buildingId: string): Observable<Response> {
    return this.restClientCommonService
      .request(
        'delete',
        `${this.endPoint}${pathOfBuilding}buildings/${buildingId}`,
        undefined,
        this.screenId,
      )
      .pipe(
        tap((response) => {
          if (response.status === 204) {
            // 物件リストの対象物件クリア
            // Clear the target property in the property list
            this.clearBuildingCache(buildingId);
            // 物件キャッシュクリア
            // Clear property cash
            this.clearBuildingsCache(buildingId);
          }
        }),
      );
  }

  /**
   * 物件リストキャッシュクリア
   *
   * @param [buildingId] 物件ID
   */
  /**
   * Clear Building List cache
   *
   * @param [buildingId] Building ID
   */
  clearBuildingCache(buildingId?: string) {
    if (buildingId) {
      const index = this.caches.buildingList.items.findIndex(
        (item) => item.buildingId === buildingId,
      );
      if (index > -1) {
        this.caches.buildingList.items.splice(index, 1);
      }
    } else {
      this.caches.buildingList.items = [];
    }
  }

  /**
   * 物件キャッシュクリア
   *
   * @param [buildingId] 物件ID
   */
  /**
   * Clear Building cash
   *
   * @param [buildingId] Building ID
   */
  clearBuildingsCache(buildingId: string) {
    const index = this.caches.buildings.findIndex((item) => item.buildingId === buildingId);
    if (index > -1) {
      this.caches.buildings.splice(index, 1);
    }
  }
}
