export class AuthToken {
  accessToken: string;
  idToken: string;
  refreshToken: string;

  /**
   * コンストラクタ
   *
   * @param token 認証トークン
   */
  /**
   * constructor
   *
   * @param token Authentication token
   */
  constructor(token: AuthToken) {
    this.accessToken = token.accessToken;
    this.idToken = token.idToken;
    this.refreshToken = token.refreshToken;
  }
}
