import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import {
  EdgeEdgesPutRequest,
  EdgeEdgesPostRequest,
  EdgeEdgesLocalPutRequest,
  EdgeEdgeDeviceIdPutRequest,
  EdgeFirmwarePutRequest,
  EdgeCertificationStatesPutRequest,
} from '../interfaces/edge-service';
import { RestClient, apiVersion } from '../base/rest-client';

const pathOfEdge = `edge/${apiVersion}/`;

@Injectable()
export class RestClientEdgeService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-3. エッジサービス
  //  3-3. Edge service
  /////////////////////////////////////////////////////////////////////////////
  // 3-3-1. エッジ管理
  // 3-3-1. Edge management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * エッジ設定データ変更API
   * response body: edgeId(string)
   *
   * @param {string} edgeId エッジID
   * @param {EdgeEdgesPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge setting data change API
   * response body: edgeId(string)
   *
   * @param {string} edgeId Edge ID
   * @param {EdgeEdgesPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putEdgeEdges(edgeId: string, param: EdgeEdgesPutRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}`,
      param,
      this.screenId,
    );
  }

  /**
   * エッジ削除API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge deletion API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteEdgeEdges(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジ登録API
   * response body: EdgeEdgesPostResponse
   *
   * @param {EdgeEdgesPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge registration API
   * response body: EdgeEdgesPostResponse
   *
   * @param {EdgeEdgesPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postEdgeEdges(param: EdgeEdgesPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEdge}edges`,
      param,
      this.screenId,
    );
  }

  /**
   * エッジデバイスID紐付登録・変更・解除API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {EdgeEdgeDeviceIdPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge device ID linking registration / change / cancel API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {EdgeEdgeDeviceIdPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putEdgeEdgeDeviceId(edgeId: string, param: EdgeEdgeDeviceIdPutRequest) {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}/edgeDeviceId`,
      param,
      this.screenId,
    );
  }

  /**
   * エッジ設定データ取得(エッジ指定)API
   * response body: EdgeEdgesEdgeIdResponse
   *
   * @param {string} edgeId エッジID
   * @param {string[]} [param] 取得対象データ指定クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge setting data acquisition (edge specification) API
   * response body: EdgeEdgesEdgeIdResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string[]} [param] Acquisition target data specification query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeEdgesEdgeId(
    edgeId: string,
    param?: (
      | 'edgeDeviceId'
      | 'buildingId'
      | 'name'
      | 'edgeModel'
      | 'edgeSettingData'
      | 'autoCtrlRCAndSetLim'
    )[],
  ): Observable<Response> {
    let query = '';
    if (param && param.length > 0) {
      query = `?param=${param.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジ設定データ取得(物件指定)API
   * response body: EdgeEdgesResponse
   *
   * @param {string} buildingId 物件ID
   * @param {string[]} [param] 取得対象データ指定クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge setting data acquisition (property designation) API
   * response body: EdgeEdgesResponse
   *
   * @param {string} buildingId Property ID
   * @param {string[]} [param] Acquisition target data specification query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeEdges(
    buildingId: string,
    param?: ('id' | 'buildingId' | 'name' | 'edgeModel' | 'edgeSettingData' | 'edgeDeviceId')[],
  ): Observable<Response> {
    let query = '';
    if (param && param.length > 0) {
      query = `&param=${param.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edges?buildingId=${buildingId}${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジ設定データ取得(ローカル)API
   * response body: EdgeEdgesLocalGetResponse
   *
   * @param {string[]} [param] 	取得対象データ指定クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge setting data acquisition (local) API
   * response body: EdgeEdgesLocalGetResponse
   *
   * @param {string[]} [param] 	Acquisition target data specification query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeEdgesLocal(
    param?: ('networkConf' | 'd3portSetting' | 'maintenance' | 'equipmentIdList')[],
  ): Observable<Response> {
    let query = '';
    if (param && param.length > 0) {
      query = `?param=${param.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edges${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジ設定データ変更(ローカル)API
   * response body: none
   *
   * @param {EdgeEdgesLocalPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge setting data change (local) API
   * response body: none
   *
   * @param {EdgeEdgesLocalPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putEdgeEdgesLocal(param: EdgeEdgesLocalPutRequest) {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEdge}edges`,
      param,
      this.screenId,
    );
  }

  /**
   * ローカルAPI用パスワード発行API
   * response body: password(string)
   *
   * @param {string} edgeDeviceId エッジデバイスID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Password issuing API for local API
   * response body: password(string)
   *
   * @param {string} edgeDeviceId Edge device ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeLocalApiPassword(edgeDeviceId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edgeDevices/${edgeDeviceId}/localApiPasswords`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジ契約変更API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {EdgeCertificationStatesPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge contract change API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {EdgeCertificationStatesPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putEdgeCertificationStates(
    edgeId: string,
    param: EdgeCertificationStatesPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEdge}certificationStates/${edgeId}`,
      param,
      this.screenId,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-3-2. エッジ現在状態管理
  // 3-3-2. Edge current status management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * エッジ現在状態取得(エッジ指定)API
   * response body: CurrentEdgeStates
   *
   * @param {string} edgeId エッジID
   * @param {string[]} [param] 	取得対象データ指定クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge current status acquisition (edge specification) API
   * response body: CurrentEdgeStates
   *
   * @param {string} edgeId Edge ID
   * @param {string[]} [param] 	Acquisition target data specification query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeCurrentEdgeStates(
    edgeId: string,
    param?: ('certificationState' | 'edgeConnectState' | 'edgeModel')[],
  ): Observable<Response> {
    let query = ``;
    if (param && param.length > 0) {
      query = `?param=${param.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}currentEdgeStates/${edgeId}${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジ現在状態取得(物件指定)API
   * response body: CurrentEdgeStates
   *
   * @param {string} buildingId 物件ID
   * @param {string[]} [param] 取得対象データ指定クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge current status acquisition (property designation) API
   * response body: CurrentEdgeStates
   *
   * @param {string} buildingId Property ID
   * @param {string[]} [param] Acquisition target data specification query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeCurrentBuildingEdgeStates(
    buildingId: string,
    param?: ('certificationState' | 'edgeConnectState' | 'edgeModel')[],
  ): Observable<Response> {
    let query = `?buildingId=${buildingId}`;
    if (param && param.length > 0) {
      query += `&param=${param.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}currentEdgeStates${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 構成チェック開始指示API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Configuration check start instruction API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  postEdgeD3portCheck(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}/check`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 構成チェック結果確認API
   * response body: EdgeD3port
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Configuration check result confirmation API
   * response body: EdgeD3port
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeD3portCheck(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}/check`,
      undefined,
      this.screenId,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-3-3. エッジ指示管理
  // 3-3-3. Edge instruction management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * エッジファームウェアバージョンアップ指示API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {EdgeFirmwarePutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge firmware version upgrade instruction API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {EdgeFirmwarePutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putEdgeFirmwareUpgradeSchedule(
    edgeId: string,
    param: EdgeFirmwarePutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}/firmwareUpgradeSchedules`,
      param,
      this.screenId,
    );
  }

  /**
   * エッジファームウェアバージョンアップ状態取得(物件指定)API
   * response body: EdgeFirmwareBuildingGetResponse
   *
   * @param {string} buildingId 物件ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge firmware version upgrade status acquisition (property designation) API
   * response body: EdgeFirmwareBuildingGetResponse
   *
   * @param {string} buildingId Property ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeFirmwareUpgradeBuilding(buildingId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edges/firmwareUpgradeSchedules/${buildingId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * エッジファームウェアバージョンアップ状態取得API
   * response body: EdgeFirmwareGetRequest
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge firmware version update status acquisition API
   * response body: EdgeFirmwareGetRequest
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeFirmwareUpgradeSchedule(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edges/${edgeId}/firmwareUpgradeSchedules`,
      undefined,
      this.screenId,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-3-4. エッジ種管理
  // 3-3-4. Edge type management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * エッジファームウェア情報取得API
   * response body: FirmwareCode
   *
   * @param {string} edgeModelCode エッジ種コード
   * @param {string} firmwareCode ファームウェアコード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge firmware information acquisition API
   * response body: FirmwareCode
   *
   * @param {string} edgeModelCode Edge type code
   * @param {string} firmwareCode Firmware code
   * @return {Observable<Response>} status:HTTP status
   */
  getEdgeFirmwareCode(edgeModelCode: string, firmwareCode: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEdge}edgeModels/${edgeModelCode}/edgeFirmwares/${firmwareCode}`,
      undefined,
      this.screenId,
    );
  }
}
