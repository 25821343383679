import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment } from '@angular/router';
import { Observable, Observer, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { DataManagementService } from '../services/data-management/data-management.service';
import { RestClientCommonService } from 'src/app/core/services/rest-client/rest-client-common/rest-client-common.service';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
@Injectable()
export class AuthGuard {
  /**
   * windows.locationを取得する。
   */
  /**
   * Get Windows.Location.
   */
  private get location(): Location {
    return window.location;
  }

  /**
   * コンストラクター関数
   * @param router Angular のルーターサービス
   * @param dataManagementService データ管理サービス
   * @param restClientCommonService 通信部共通サービス
   * @param restClientAuthService 認証通信部サービス
   * @param loaderService ローダーサービス
   */
  /**
   * Constructor function
   * @param router Angular router service
   * @param dataManagementService Data management service
   * @param restClientCommonService Communication department common service
   * @param restClientAuthService Authorization connect service
   * @param loaderService Loader service
   */
  constructor(
    private router: Router,
    private dataManagementService: DataManagementService,
    private restClientCommonService: RestClientCommonService,
    private restClientAuthService: RestClientAuthService,
    private loaderService: LoaderService,
  ) {}

  /**
   * canLoad インタフェース
   * @param route ルート
   * @param segments URL のセグメント
   * @returns ロード状態（true: ロード可能、false: ロード不可能）
   */
  /**
   * canLoad interface
   * @param route Route
   * @param segments Url segments
   * @returns load state（true: enable load、false: disable load）
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    if (segments.length > 2) {
      this.router.navigate([`/${segments[0].path}/${segments[1].path}`]);
      return false;
    }

    if (this.dataManagementService.isLogin()) {
      return true;
    }

    if (segments.length <= 0) {
      this.router.navigate(['login']);
      return false;
    }

    // 遠隔監視操作画面（名称変更）への遷移時、機器一覧画面に遷移
    // If navigate to monitiring and operation (change name), navigate to equipment list.
    if (
      segments[0].path === 'monitoring-and-operation' &&
      segments.length > 1 &&
      segments[1].path === 'change-name'
    ) {
      this.router.navigate(['/monitoring-and-operation/equipment-list']);
      return false;
    }

    if (segments.length > 0 && segments[0].path === 'index.html') {
      const parameters = new URLSearchParams(this.location.search);
      const link = parameters.get('link');
      const code = parameters.get('code');
      const buildingId = parameters.get('buildingId');
      const edgeId = parameters.get('edgeId');
      const equipmentId = parameters.get('equipmentId');

      if (link) {
        // サインアップ画面への遷移
        // Navigate to sign-up confirmation page
        if (link === 'sign-up') {
          this.router.navigate(['/login/sign-up-confirmation']);
          return false;
        }

        // メールアドレス変更画面への遷移
        // Navigate to email-address confirmation page
        if (link === 'change-email' && code) {
          this.router.navigate(['/login/email-address-confirmation', code]);
          return false;
        }

        // 試運転からのログイン時の遷移
        // Navigate to login page from commissioning app
        if (link === 'login-from-commissioning') {
          this.router.navigate(['/login'], {
            queryParams: Object.assign({ commissioning: '1' }, { buildingId, edgeId }),
          });
          return false;
        }

        // 遠隔応急運転案内メールからのログイン時の遷移
        // Navigate to login page from emergency operation mail
        if (link === 'emergency-operation' && equipmentId !== null) {
          if (
            this.dataManagementService.setSessionStorage(
              'emergencyOperationEquipmentId',
              equipmentId,
            )
          ) {
            this.router.navigate(['/login']);
          }
          return false;
        }

        // シーズン前遠隔点検の点検案内メールからのログイン時の遷移
        // Transition at login from inspection email of pre-season remote inspection
        if (link === 'inspection-request' && buildingId !== null) {
          if (
            this.dataManagementService.setSessionStorage('inspectionRequestBuildingId', buildingId)
          ) {
            this.router.navigate(['/login']);
          }
          return false;
        }

        // シーズン前遠隔点検の点検結果メールからのログイン時の遷移
        // Transition at login from the inspection result email of the pre-season remote inspection
        if (link === 'inspection-result' && buildingId !== null) {
          if (
            this.dataManagementService.setSessionStorage('inspectionResultBuildingId', buildingId)
          ) {
            this.router.navigate(['/login']);
          }
          return false;
        }
      }
    }

    // 自動ログインを実行する。
    // Automatic login process
    this.loaderService.showLoader();
    return this.restClientAuthService.authAutoLogin().pipe(
      map((autoLoginResult) => {
        if (autoLoginResult === true) {
          // 自動ログイン認証に成功したとき、自動ログインを許可
          // Allow automatic login if automatic login authentication is successful
          this.dataManagementService.setIsLogin(true);
          this.dataManagementService.directAccessFlag = true;
          return true;
        }
        // 自動ログイン認証に失敗した場合、ログアウトさせる。
        // If automatic login authentication failed, logout process execute.
        this.autoLoginFailed();
        return false;
      }),
      catchError(() => {
        this.autoLoginFailed();
        return of(false);
      }),
    );
  }

  private autoLoginFailed(): void {
    this.loaderService.hideLoader();
    this.restClientCommonService.forceLogoutFlag = true;
    this.router.navigate(['/login']);
  }
}
