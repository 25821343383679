<div #comboBox class="filterable-combo-box">
  <div #anchor class="filterable-combo-box__selected-item" [class.disabled]="inactivatePopup"
    [tabIndex]="inactivatePopup ? -1 : 0" (click)="!inactivatePopup && onPopupToggle()"
    (keydown)="!inactivatePopup && onToggleKeyDown($event)">
    <div class="filterable-combo-box__selected-item-label">{{ label | translate }}</div>
    <div class="filterable-combo-box__selected-item-value" [title]="currentItem.name">
      {{ currentItem.name }}
    </div>
  </div>
  <kendo-contextmenu [alignToAnchor]="true" [popupAnimate]="false"
    [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"
    [popupAlign]="{ horizontal: 'left', vertical: 'top' }">
    <ng-template kendoContextMenuTemplate>
      <div #filter class="filterable-combo-box__item-list-container">
        <div class="filterable-combo-box__item-filter">
          <app-text-box [novalidation]="true" [ngModel]="filterValue"
            (ngModelChange)="onFilter($event)"></app-text-box>
        </div>
        <ul class="filterable-combo-box__item-list">
          <li *ngFor="let item of displayItems" class="filterable-combo-box__item"
            [class.selected]="item.id === currentItem.id" [title]="item.name" tabIndex="0"
            (click)="onItemSelect(item)" (keydown)="onSelectKeyDown($event, item)">
            <span class="filterable-combo-box__item-value">{{ item.name }}</span>
            <p *ngIf="checkExpiryStatus(item)" class="filterable-combo-box__expired-icon">
              <span class="filterable-combo-box__expired-text">{{ 'sidExpired' | translate }}</span>
            </p>
          </li>
        </ul>
      </div>
    </ng-template>
  </kendo-contextmenu>
</div>