<div class="checkbox" [class.disabled]="disabled">
  <div class="checkbox__input-wrapper" [tabIndex]="disabled ? '-1' : '0'"
    (keydown)="onKeyDown($event)" (click)="onClick($event)">
    <div class="checkbox__input" [ngClass]="{
        'checkbox__input--cooling': isSetPointMode && !isHeatingMode,
        'checkbox__input--heating': isSetPointMode && isHeatingMode,
        'border_white': isWhiteBorder,
        on: checked,
        indeterminate: indeterminate
      }">
      <div *ngIf="checked" class="checkbox__icon k-icon"
        [ngClass]="{ 'indeterminate k-i-stop': indeterminate, 'k-i-check': !indeterminate && checked !=='composite', 'k-i-minus': !indeterminate && checked ==='composite'}">
      </div>
    </div>
  </div>
  <div *ngIf="label" class="checkbox__label checkbox__label-{{spaceSize}}"
    (click)="onClick($event)">{{ label }}</div>
</div>