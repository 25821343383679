import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';

@Component({
  selector: 'app-service-unactivated',
  templateUrl: './service-unactivated.component.html',
  styleUrls: ['./service-unactivated.component.scss'],
})
export class ServiceUnactivatedComponent implements OnInit {
  template: string;

  /**
   * コンストラクター関数
   * @param http Angular Http クライアント
   * @param multiLanguageMessageService 多言語対応サービス
   */
  /**
   * Constructor function
   * @param http Angular Http Client
   * @param multiLanguageMessageService Multilingual service
   */
  constructor(
    private http: HttpClient,
    private multiLanguageMessageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // Assets から外部ファイルを取得
    // Grab HTML external file from assets
    this.http
      .get(this.multiLanguageMessageService.dictionary('sidServiceUnactivatedTemplate'), {
        responseType: 'text',
      })
      .subscribe(
        (template: string) => (this.template = template),
        () => (this.template = this.multiLanguageMessageService.localeId),
      );
  }
}
