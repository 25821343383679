import { Utility } from 'src/app/shared-main/classes/utility';
export class EquipmentIconResponse {
  zoneList: ZoneList[];
}

export class ZoneList {
  zoneId: string;
  items: Item[];
}

export class CachesEdgeIconIds {
  private cachesEdgeIds: string[] = [];
  private zoneList: ZoneList[] = [];

  get getZoneList(): ZoneList[] {
    return this.zoneList;
  }

  /**
   * zoneListを追加する
   * @param zoneList ゾーンのアイコン情報
   * @param edgeIds エッジIDのリスト
   */
  /**
   * Add zoneList
   * @param zoneList Zone Icon Information
   * @param edgeIds List of edge ID
   */
  addZoneList(zoneList: ZoneList[], edgeIds: string[]): void {
    // zoneListの追加
    // Add zoneList
    zoneList.forEach((zoneListItem) => {
      const zoneId = zoneListItem.zoneId;
      const thisZoneListItem = this.zoneList.find(
        (thisZoneListItem) => thisZoneListItem.zoneId === zoneId,
      );
      // 既に取得済みのゾーンの場合、上書き
      // In the case of an already acquired zone, overwrite
      if (thisZoneListItem) {
        thisZoneListItem.items = Utility.deepCopy(zoneListItem.items);
      } else {
        this.zoneList.push(zoneListItem);
      }
    });

    // cachesEdgeIdsの追加
    // Add cachesEdgeIds
    edgeIds.forEach((edgeId) => {
      if (!this.cachesEdgeIds.includes(edgeId)) {
        this.cachesEdgeIds.push(edgeId);
      }
    });
  }

  /**
   * エッジIDのリストがキャッシュ済みかを判定
   * @param edgeIds エッジIDのリスト
   * @returns キャッシュ済みの場合、true
   */
  /**
   * Determine whether the list of edge IDs is cached
   * @param edgeIds List of edge ID
   * @returns True if cached
   */
  isCachesEdges(edgeIds: string[]): boolean {
    return edgeIds.toString() === this.cachesEdgeIds.toString();
  }
}

export class Item {
  equipmentId: string;
  iconId: string;
}
