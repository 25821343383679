<div class="login-form">
  <div class="login-form__group" (keyup.enter)="onSubmit()">
    <app-text-box
      type="email"
      placeholder="{{ multiLanguage.dictionary('sidEmail') }}"
      [novalidation]="true"
      [isIconLeftAligned]="true"
      [formControl]="email"
      (ngModelChange)="onEmailValidation()"
      [enableAutoCompletion]="true"
      name="email"
      data-test="email"
    >
      <div #textBoxIcon class="k-icon k-i-user"></div>
    </app-text-box>
  </div>
  <div class="login-form__group" (keyup.enter)="onSubmit()">
    <app-text-box
      type="password"
      placeholder="{{ multiLanguage.dictionary('sidPassword') }}"
      [novalidation]="true"
      [isIconLeftAligned]="true"
      [formControl]="password"
      (ngModelChange)="onPasswordValidation()"
      data-test="password"
      [isIconRightAligned]="true"
      [passwordMaskIcon]="true"
    >
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>
  <!-- <div class="login-form__language">
    <app-select-box [data]="languages" [textField]="'displayName'" [valueField]="'localeId'"
      [valuePrimitive]="true" [(ngModel)]="localeId" (selectionChange)="onSelectionChange($event)">
    </app-select-box>
  </div> -->

  <div class="login-form__error-message">
    <app-form-inline-message *ngIf="errorMessage">
      {{ multiLanguage.dictionary(errorMessage) }}
    </app-form-inline-message>
  </div>

  <!-- <div class="login-form__link">
    <app-form-link (click)="onForgotPasswordClicked()" (keydown)="onKeydown($event)">
      {{ multiLanguage.dictionary('sidForgotPass') }}
    </app-form-link>
  </div> -->
  <div class="login-form__button">
    <app-button
      class="login-form__button"
      (generalButtonClick)="onSubmit()"
      data-test="login-button"
    >
      {{ multiLanguage.dictionary('sidLogin') }}
    </app-button>
  </div>
</div>
