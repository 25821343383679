export class Affiliate {
  id: string;
  name: string;

  /**
   * コンストラクタ
   *
   * @param id アフィリエイトID
   * @param name アフィリエイト名
   */
  /**
   * constructor
   *
   * @param id Affiliate id
   * @param name Affiliate name
   */
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  /**
   * IDが有効かチェックする
   *
   * @return チェック結果
   */
  /**
   * Check if id is valid
   *
   * @return Check result
   */
  isValid(): boolean {
    if (this.id) {
      return true;
    }
    return false;
  }

  /**
   * 更新が必要か判定する
   *
   * @param newAffiliate 新しいアフィリエイト情報
   * @return 判定結果
   */
  /**
   * Determine if an update is required
   *
   * @param newAffiliate New affiliate information
   * @return judgement result
   */
  isUpdated(newAffiliate: Affiliate): boolean {
    if (newAffiliate.id !== this.id || newAffiliate.name !== this.name) {
      return true;
    }
    return false;
  }
}
