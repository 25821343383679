import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { RestClientPersonService } from 'src/app/core/services/rest-client';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { Person } from 'src/app/core/services/data-management/classes/person-data';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { ApiType } from 'src/app/core/services/toast/api-type';
import { Utility } from 'src/app/shared-main/classes/utility';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  termsOfUseForm: UntypedFormGroup;
  template: string;

  /**
   * 初回ログインかどうか取得する
   * @returns true:初回ログイン false:それ以外
   */
  /**
   * Get the first login or not
   * @returns true:First login false:other than that
   */
  get isFirstLogin(): boolean {
    return (
      this.dataManagementService.isLogin() &&
      this.dataManagementService.person().termsOfServiceState === 0
    );
  }

  /**
   * コンストラクター関数
   * @param http Http クライアント
   * @param router Angular ルーター
   * @param formBuilder Angular フォームビルダー
   * @param multiLanguageMessageService 多言語対応サービス
   * @param dataManagementService データ管理サービス
   * @param restClientPersonService Restクライアント人サービス（共通）
   * @param loaderService ローダーアニメーションサービス
   * @param toastService　トーストサービス
   */
  /**
   * Constructor function
   * @param http Http Client
   * @param router Angular Router
   * @param formBuilder Angular Form Builder
   * @param multiLanguageMessageService Multilingual service
   * @param dataManagementService Data management service
   * @param restClientPersonService Rest client service (common)
   * @param loaderService Loader Animation Service
   * @param toastService Toast Service
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private dataManagementService: DataManagementService,
    private restClientPersonService: RestClientPersonService,
    private loaderService: LoaderService,
    private toastService: ToastService,
  ) {
    this.restClientPersonService.setScreenId(ScreenId.ScreenCommon);
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.termsOfUseForm = this.formBuilder.group({
      check: [false, Validators.pattern('true')],
    });

    // 初回ログイン時かそうでないかで利用規約の文章を切り替える
    // Switch the text of the Terms of Use depending on whether you log in for the first time or not
    const templateSid = this.isFirstLogin
      ? 'sidTermsOfUseForFirstLoginTemplate'
      : 'sidTermsOfUseTemplate';

    // Assets から外部ファイルを取得
    // Grab HTML external file from assets
    this.http
      .get(this.multiLanguageMessageService.dictionary(templateSid), {
        responseType: 'text',
      })
      .subscribe(
        (template: string) => (this.template = template),
        () => (this.template = this.multiLanguageMessageService.localeId),
      );
  }

  /**
   * 利用規約に同意したとき、ログインユーザの情報を更新する
   */
  /**
   * When you agree to the terms of use, update the logged-in user information
   */
  onAccept() {
    this.loaderService.showLoader();

    this.restClientPersonService.putPersonPersons('self', { termsOfServiceState: 1 }).subscribe(
      (res: Response) => {
        this.dataManagementService.setPerson(
          new Person(
            this.dataManagementService.person().id,
            this.dataManagementService.person().name,
            this.dataManagementService.person().timeDisplay,
            1,
          ),
        );

        this.router.navigate(['/']);

        this.loaderService.hideLoader();
      },
      (err: Response) => {
        if (err.data.errors) {
          switch (err.status) {
            case 400:
            case 403:
            case 409:
              const apiType = ApiType.register;
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary(
                  Utility.getErrorMessageSid(err, apiType),
                ),
              );
              break;
            case 404:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidNoUser'),
              );
              break;
            case 408:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidServerErrorOccurred'),
              );
              break;
          }
        }

        this.loaderService.hideLoader();
      },
    );
  }
}
