export enum EquipmentType {
  Indoor = 0,
  Ventilator = 1,
  Di = 2,
  Dio = 3,
  Ai = 4,
  Ao = 5,
  Pi = 6,
  Outdoor = 7,
  General = 8,
  Humidifier = 9,
  GeneralPurpose = 10,
  D3Chiller = 11,
}
