import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import {
  ScheduleCopyPostRequest,
  ScheduleProgramGetRequest,
  ScheduleCalendarGetRequest,
  ScheduleCalendarPutRequest,
  ScheduleExecutePutRequest,
  ScheduleActionTemplatePutRequest,
  ScheduleSpecialDaysPutRequest,
  Program,
} from '../interfaces/schedule-service';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  ScheduleProgramGetResponse,
  ScheduleProgramPutResponse,
} from '../interfaces/schedule-service/schedule-program';
import { ScheduleActionTemplateGetResponse } from '../interfaces/schedule-service/schedule-template';

const pathOfSchedule = `schedule/${apiVersion}/`;

@Injectable()
export class RestClientScheduleService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-11. スケジュール制御サービス
  //  3-11. Schedule Control Service
  /////////////////////////////////////////////////////////////////////////////
  //  3-11-1. スケジュール制御管理
  //  3-11-1. Schedule Control Management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * スケジュールデータコピー（ゾーン単位）
   * response body: none
   *
   * @param {ScheduleCopyPostRequest} param パスパラメータ・クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Schedule data copy (zone unit)
   * response body: none
   *
   * @param {ScheduleCopyPostRequest} param Path parameter and query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  postScheduleCopy(param: ScheduleCopyPostRequest): Observable<Response<{}>> {
    let query = '';
    const queryArray: string[] = [];
    if (param.srcEdgeId) {
      queryArray.push(`srcEdgeId=${param.srcEdgeId}`);
    }
    if (param.srcZoneId) {
      queryArray.push(`srcZoneId=${param.srcZoneId}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSchedule}edges/${param.edgeId}/zones/${param.zoneId}/copy${query}`,
    );
  }

  /**
   * スケジュールプログラム一覧取得（ゾーン単位）
   * response body: ScheduleProgramListZoneGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get schedule program list (zone unit)
   * response body: ScheduleProgramListZoneGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @return {Observable<Response>} status:HTTP status
   */
  getScheduleProgramListZone(edgeId: string, zoneId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/programs`,
    );
  }

  /**
   * スケジュールプログラム登録
   * response body: programId(string)
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {Program} param スケジュールプログラム情報
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Schedule program registration
   * response body: programId(string)
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {Program} param Schedule program information
   * @return {Observable<Response>} status:HTTP status
   */
  postScheduleProgram(edgeId: string, zoneId: string, param: Program): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/programs`,
      param,
    );
  }

  /**
   * スケジュールプログラム取得
   * response body: ScheduleProgramGetResponse
   *
   * @param {ScheduleProgramGetRequest} param パスパラメータ・クエリパラメータ
   * @return {Observable<Response<ScheduleProgramGetResponse>>} status:HTTPステータス
   */
  /**
   * Schedule program acquisition
   * response body: ScheduleProgramGetResponse
   *
   * @param {ScheduleProgramGetRequest} param Path parameter and query parameter
   * @return {Observable<Response<ScheduleProgramGetResponse>>} status:HTTP status
   */
  getScheduleProgram(
    param: ScheduleProgramGetRequest,
  ): Observable<Response<ScheduleProgramGetResponse>> {
    let query = '';
    if (param.fields) {
      query = `?fields=${param.fields}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSchedule}edges/${param.edgeId}/zones/${
        param.zoneId
      }/programs/${param.programId.join(',')}${query}`,
    );
  }

  /**
   * スケジュールプログラム更新
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {string} programId 	スケジュールプログラムID
   * @param {Program} param スケジュールプログラム
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Schedule program update
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {string} programId 	Schedule program ID
   * @param {Program} param Schedule program
   * @return {Observable<Response<ScheduleProgramPutResponse>>} status:HTTP status
   */
  putScheduleProgram(
    edgeId: string,
    zoneId: string,
    programId: string,
    param: Program,
  ): Observable<Response<ScheduleProgramPutResponse>> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/programs/${programId}`,
      param,
    );
  }

  /**
   * スケジュールプログラム削除
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {string} programId スケジュールプログラムID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Delete scheduled program
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {string} programId Schedule program ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteScheduleProgram(edgeId: string, zoneId: string, programId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/programs/${programId}`,
    );
  }

  /**
   * スケジュールプログラム上書き
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {string} programId スケジュールプログラムID
   * @param {string} srcEdgeId 上書き元エッジID
   * @param {string} srcZoneId 	上書き元ゾーンID
   * @param {string} srcProgramId 上書き元プログラムID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Schedule program overwrite
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {string} programId Schedule program ID
   * @param {string} srcEdgeId Overwrite source edge ID
   * @param {string} srcZoneId 	Overwrite source zone ID
   * @param {string} srcProgramId Overwrite source program ID
   * @return {Observable<Response>} status:HTTP status
   */
  postScheduleProgramOverwrite(
    edgeId: string,
    zoneId: string,
    programId: string,
    srcEdgeId: string,
    srcZoneId: string,
    srcProgramId: string,
  ): Observable<Response<{}>> {
    let query = '';
    const queryArray: string[] = [];
    if (srcEdgeId) {
      queryArray.push(`srcEdgeId=${srcEdgeId}`);
    }
    if (srcZoneId) {
      queryArray.push(`srcZoneId=${srcZoneId}`);
    }
    if (srcProgramId) {
      queryArray.push(`srcProgramId=${srcProgramId}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/programs/${programId}/overwrite${query}`,
    );
  }

  /**
   * スケジュールプログラムコピー
   * response body: Program
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {string} srcEdgeId コピー元エッジID
   * @param {string} srcZoneId 	コピー元ゾーンID
   * @param {string} srcProgramId コピー元プログラムID
   * @param {string} srcBuildingName コピー元物件名称
   * @param {string} srcZoneName コピー元ゾーン名称
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Schedule program copy
   * response body: Program
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {string} srcEdgeId Copy source edge ID
   * @param {string} srcZoneId 	Copy source zone ID
   * @param {string} srcProgramId Copy source program ID
   * @param {string} srcBuildingName Source property name
   * @param {string} srcZoneName Source zone name
   * @return {Observable<Response<Program>>} status:HTTP status
   */
  postScheduleProgramCopy(
    edgeId: string,
    zoneId: string,
    srcEdgeId: string,
    srcZoneId: string,
    srcProgramId: string,
    srcBuildingName: string,
    srcZoneName: string,
  ): Observable<Response<Program>> {
    let query = '';
    const queryArray: string[] = [];
    if (srcEdgeId) {
      queryArray.push(`srcEdgeId=${encodeURIComponent(srcEdgeId)}`);
    }
    if (srcZoneId) {
      queryArray.push(`srcZoneId=${encodeURIComponent(srcZoneId)}`);
    }
    if (srcProgramId) {
      queryArray.push(`srcProgramId=${encodeURIComponent(srcProgramId)}`);
    }
    if (srcBuildingName) {
      queryArray.push(`srcBuildingName=${encodeURIComponent(srcBuildingName)}`);
    }
    if (srcZoneName) {
      queryArray.push(`srcZoneName=${encodeURIComponent(srcZoneName)}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSchedule}edges/${encodeURIComponent(
        edgeId,
      )}/zones/${encodeURIComponent(zoneId)}/programs/copy${query}`,
    );
  }

  /**
   * 特別日カレンダー一覧取得
   * response body: ScheduleCalendarGetResponse
   *
   * @param {ScheduleCalendarGetRequest} param パスパラメータ・クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get special day calendar list
   * response body: ScheduleCalendarGetResponse
   *
   * @param {ScheduleCalendarGetRequest} param Path parameter and query parameter
   * @return {Observable<Response>} status:HTTP status
   */
  getScheduleCalendar(param: ScheduleCalendarGetRequest): Observable<Response> {
    let query = '';
    const queryArray: string[] = [];
    if (param.fields) {
      queryArray.push(`fields=${param.fields}`);
    }
    if (param.sort) {
      queryArray.push(`sort=${param.sort}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSchedule}edges/${param.edgeId}/zones/${param.zoneId}/calendars${query}`,
    );
  }

  /**
   * 特別日カレンダー更新
   * response body: ScheduleCalendarPutResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {ScheduleCalendarPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Special day calendar update
   * response body: ScheduleCalendarPutResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {ScheduleCalendarPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putScheduleCalendar(
    edgeId: string,
    zoneId: string,
    param: ScheduleCalendarPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/calendars`,
      param,
    );
  }

  /**
   * 実行スケジュール一覧取得
   * response body: ScheduleExecuteGetResponse
   *
   * @param {string[]} edgeId エッジID
   * @param {string[]} zoneId ゾーンID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get execution schedule list
   * response body: ScheduleExecuteGetResponse
   *
   * @param {string[]} edgeId Edge ID
   * @param {string[]} zoneId Zone ID
   * @return {Observable<Response>} status:HTTP status
   */
  getScheduleExecute(edgeId: string[], zoneId: string[]): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId.join(',')}/zones/${zoneId.join(
        ',',
      )}/executeSchedules`,
    );
  }

  /**
   * 実行スケジュール一覧更新
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} zoneId ゾーンID
   * @param {ScheduleExecutePutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Update execution schedule list
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} zoneId Zone ID
   * @param {ScheduleExecutePutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putScheduleExecute(
    edgeId: string,
    zoneId: string,
    param: ScheduleExecutePutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSchedule}edges/${edgeId}/zones/${zoneId}/executeSchedules`,
      param,
    );
  }

  /**
   * アクションテンプレート一覧取得
   * response body: ScheduleActionTemplateGetResponse
   *
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get action template list
   * response body: ScheduleActionTemplateGetResponse
   *
   * @return {Observable<Response>} status:HTTP status
   */
  getScheduleActionTemplate(): Observable<Response<ScheduleActionTemplateGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSchedule}persons/person/actionTemplates`,
    );
  }

  /**
   * アクションテンプレート更新
   * response body: ScheduleActionTemplatePutResponse
   *
   * @param {ScheduleActionTemplatePutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Update action template
   * response body: ScheduleActionTemplatePutResponse
   *
   * @param {ScheduleActionTemplatePutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putScheduleActionTemplate(param: ScheduleActionTemplatePutRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSchedule}persons/person/actionTemplates`,
      param,
    );
  }

  /**
   * アクションテンプレート削除
   * response body: none
   *
   * @param {string} actionTemplateId アクションテンプレートID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Delete action template
   * response body: none
   *
   * @param {string} actionTemplateId Action template ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteScheduleActionTemplate(actionTemplateId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfSchedule}persons/person/actionTemplates/${actionTemplateId}`,
    );
  }

  /**
   * 特別日カレンダーテンプレート一覧取得
   * response body: ScheduleSpecialDaysGetResponse
   *
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Get special day calendar template list
   * response body: ScheduleSpecialDaysGetResponse
   *
   * @return {Observable<Response>} status:HTTP status
   */
  getScheduleSpecialDays(): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSchedule}persons/person/specialDaysTemplates`,
    );
  }

  /**
   * 特別日カレンダーテンプレート更新
   * response body: ScheduleSpecialDaysPutResponse
   *
   * @param {ScheduleSpecialDaysPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Special day calendar template update
   * response body: ScheduleSpecialDaysPutResponse
   *
   * @param {ScheduleSpecialDaysPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putScheduleSpecialDays(param: ScheduleSpecialDaysPutRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSchedule}persons/person/specialDaysTemplates`,
      param,
    );
  }

  /**
   * 特別日カレンダーテンプレート削除
   * response body: none
   *
   * @param {string} specialDaysTemplatesId 特別日カレンダーテンプレートID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Delete special day calendar template
   * response body: none
   *
   * @param {string} specialDaysTemplatesId Special day calendar template ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteScheduleSpecialDays(specialDaysTemplatesId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfSchedule}persons/person/specialDaysTemplates/${specialDaysTemplatesId}`,
    );
  }
}
