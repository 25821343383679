import { LocatorService } from '../../locator/locator.service';
import {
  RestClientCommonService,
  apiVersionOrigin,
} from '../rest-client-common/rest-client-common.service';
import { environment } from '../../../../../environments/environment';
import { RestClientCaches } from '../classes/rest-client-caches';

export const apiVersion = apiVersionOrigin;

export class RestClient {
  protected restClientCommonService: RestClientCommonService;
  protected endPoint = environment.backend;
  protected screenId = '';

  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    this.restClientCommonService = LocatorService.injector.get(RestClientCommonService);
  }

  /**
   * スクリーンID設定
   *
   * @param screenId スクリーンID
   */
  /**
   * Screen ID setting
   *
   * @param screenId Screen ID
   */
  setScreenId(screenId: string) {
    this.screenId = screenId;
  }

  /**
   * キャッシュ取得
   *
   * @return キャッシュ情報
   */
  /**
   * Cache acquisition
   *
   * @return Cash information
   */
  get caches(): RestClientCaches {
    return this.restClientCommonService.caches;
  }
}
