<div class="code-confirmation-form">
  <div class="code-confirmation-form__title">
    {{ 'sidVerificationCode' | translate }}
  </div>
  <div class="code-confirmation-form__group" (keyup.enter)="onEnter()">
    <app-text-box type="text" placeholder="{{ 'sidVerificationCode' | translate }}"
      [novalidation]="false" [isIconLeftAligned]="true" [(ngModel)]="code">
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>

  <div class="code-confirmation-form__error-message">
    <app-form-inline-message *ngIf="errorMessage">
      {{errorMessage}}
    </app-form-inline-message>
  </div>

  <div class="code-confirmation-form__link-area">
    <app-form-link (click)="onBackToLoginClicked()" (keydown)="onKeydown($event)">
      {{ 'sidBackToLogin' | translate }}
    </app-form-link>
  </div>
  <div class="code-confirmation-form__button">
    <app-button [disabled]="!enableConfirmationButton" (generalButtonClick)="onSubmit()">
      {{ 'sidVerify' | translate }}
    </app-button>
  </div>
</div>