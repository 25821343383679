import { ScreenId } from '../../../../shared-main/enums/screen-id.enum';

export const screenIdList = [
  // 遠隔監視操作
  // Remote monitoring operation
  { id: ScreenId.EquipmentList, url: '/monitoring-and-operation/equipment-list' },
  // レイアウトビュー
  // Remote monitoring operation
  { id: ScreenId.LayoutView, url: '/monitoring-and-operation/layout-view' },
  // 実行スケジュール
  // execution schedule
  { id: ScreenId.ScheduleExecution, url: '/automatic-control/schedule-execution' },
  // スケジュール制御
  // Schedule control
  { id: ScreenId.Schedule, url: '/automatic-control/schedule' },
  // 連動制御
  // interlocking control
  { id: ScreenId.Interlocking, url: '/automatic-control/interlocking' },
  // 緊急停止制御
  // Emergency stop control
  { id: ScreenId.ForcedStop, url: '/automatic-control/forced-stop' },
  // 予実消費エネルギー量
  // Primnant energy consumption amount
  { id: ScreenId.EnergyConsumption, url: '/energy-management/energy-consumption' },
  // 多物件消費量比較
  // Multi-site comparison
  { id: ScreenId.MultiSiteComparison, url: '/energy-management/multi-site-comparison' },
  // 室外機消費量比較
  // Outdoor unit comparison
  { id: ScreenId.OutdoorUnitComparison, url: '/energy-management/outdoor-unit-comparison' },
  // 計量トレンド
  // Weighing trend
  { id: ScreenId.MeteringTrend, url: '/energy-management/metering-trend' },
  // 温熱環境トレンド
  // Heat environment trend
  { id: ScreenId.TemperatureMonitoring, url: '/energy-management/temperature-monitoring' },
  // 積算時間トレンド
  // Integration time trend
  { id: ScreenId.OperationMonitoring, url: '/energy-management/operation-monitoring' },
  // データ出力
  // Data output
  { id: ScreenId.OperationDataOutput, url: '/energy-management/operation-data-output' },
  // 出力データ一覧画面
  // Output data list
  {
    id: ScreenId.OperationDataOutput,
    url: '/energy-management/operation-data-output/operation-output-data-list',
  },
  // 出力データ情報画面
  // Output data information
  {
    id: ScreenId.OperationDataOutput,
    url: '/energy-management/operation-data-output/operation-output-data-info',
  },
  // パターン設定
  // Pattern setting
  { id: ScreenId.PatternSettings, url: '/energy-management/pattern-settings' },
  // パターン対象機器編集
  // Editing device eligible for patterns
  {
    id: ScreenId.PatternMeterSelection,
    url: '/energy-management/pattern-settings/pattern-meter-selection',
  },
  // 一覧表示
  // List
  { id: ScreenId.MeterList, url: '/energy-management/meter-list' },
  // 省エネシミュレーション
  // Energy saving simulation
  { id: ScreenId.EnergySavingSimulation, url: '/energy-management/energy-saving-simulation' },
  // 多物件遠隔監視
  // Multi-site remote monitoring
  { id: ScreenId.MapView, url: '/multi-site-management' },
  // 多店舗管理
  // Multi-store failure map
  { id: ScreenId.DashboardView, url: '/multi-store-management' },
  // ユーザ登録画面（新規登録）
  // User registration screen (new registration)
  { id: ScreenId.AddUser, url: '/user-administration/user-list/add-user' },
  // ユーザ登録画面（編集）
  // User registration screen (edit)
  { id: ScreenId.EditUser, url: '/user-administration/user-list/edit-user' },
  // ユーザー一覧画面
  // User list screen
  { id: ScreenId.UserList, url: '/user-administration/user-list' },
  // 物件登録画面（新規登録）
  // Property registration screen (new registration)
  { id: ScreenId.AddSite, url: '/building-administration/site-list/create-site' },
  // 物件登録画面（編集）
  // Property registration screen (edit)
  { id: ScreenId.EditSite, url: '/building-administration/site-list/edit-site' },
  // 物件一覧画面
  // Property list screen
  { id: ScreenId.SiteList, url: '/building-administration/site-list' },
  // ゾーン編集画面
  // Zone edit screen
  { id: ScreenId.ZoneList, url: '/building-administration/zone-list' },
  // エッジネットワーク情報画面
  // Edge network information screen
  { id: ScreenId.EdgeNetwork, url: '/building-administration/edges-network-information' },
  // エッジアップデート画面
  // Edge update screen
  { id: ScreenId.EdgeUpdate, url: '/building-administration/edge-update' },
  // エッジコントロール画面
  // Edge control screen
  { id: ScreenId.EdgeControl, url: '/building-administration/edge-control' },
  // レイアウト設定
  // Layout settings screen
  { id: ScreenId.LayoutSettings, url: '/building-administration/layout-settings' },
  // スクリーン追加・編集
  // Scree add edit
  { id: ScreenId.Screen, url: '/building-administration/screen' },
  // スクリーングループ設定
  // Screen group setting
  {
    id: ScreenId.ScreenGroupSettings,
    url: '/building-administration/layout-settings/screen-group',
  },
  // ゾーン/機器追加
  // Zone equipment
  {
    id: ScreenId.ZoneEquipment,
    url: '/building-administration/layout-settings/zone-equipment',
  },
  // プレビュー
  // Preview
  {
    id: ScreenId.Preview,
    url: '/building-administration/layout-settings/preview',
  },
  // パッケージ編集画面
  // Package edit screen
  { id: ScreenId.Package, url: '/system-settings/packages' },
  // 通知メッセージ編集画面
  // Notification message edit screen
  { id: ScreenId.NotificationMessage, url: '/system-settings/notification-message' },
  // アクセスレベル編集画面
  // Access level edit screen
  { id: ScreenId.AccessLevels, url: '/system-settings/access-levels' },
  // 利用規約
  // Terms of service
  { id: ScreenId.TermsOfUse, url: '/terms-of-use' },
  // プロフィール設定画面
  // Profile setting screen
  { id: ScreenId.MyProfile, url: '/account-settings/my-profile' },
  // ダウンロード画面
  // download screen
  { id: ScreenId.Download, url: '/download' },
  // 時報データ管理
  // Time signal data management
  { id: ScreenId.HourlyData, url: '/data-collection-settings/hourly-data' },
  // 機器データ管理
  // Device data management
  { id: ScreenId.EquipmentData, url: '/data-collection-settings/equipment-data' },
  // データ出力
  // Data output
  { id: ScreenId.DataOutput, url: '/data-collection-settings/data-output' },
  // 出力データ一覧画面
  // Output data list
  { id: ScreenId.DataOutput, url: '/data-collection-settings/data-output/output-data-list' },
  // 出力データ情報画面
  // Output data infomation
  { id: ScreenId.DataOutput, url: '/data-collection-settings/data-output/output-data-info' },
  // ログイン画面
  // Login screen
  { id: ScreenId.Login, url: '/login' },
  // 制御状態画面
  // Demand Control state
  { id: ScreenId.DemandControlState, url: '/demand-control/demand-control-state' },
  // デマンド制御
  // Demand Control Settings
  { id: ScreenId.DemandControlSettings, url: '/demand-control/demand-control-settings' },
  // 目標電力設定
  // Demand Target Power Settings
  { id: ScreenId.DemandTargetPowerSettings, url: '/demand-control/demand-target-power-settings' },
  // デマンドグラフ
  // Demand Graph
  { id: ScreenId.DemandGraph, url: '/demand-control/demand-graph' },
  // Demand control group settings
  { id: ScreenId.DemandControlGroupSettings, url: '/demand-control/demand-control-group-settings' },
  // Demand control group settings
  { id: ScreenId.DemandCurrentState, url: '/demand-control/demand-current-state' },
  // デマンドモード設定
  // Demand Mode Settings
  { id: ScreenId.DemandModeSettings, url: '/demand-control/demand-mode-settings' },
  // メール通知設定画面
  // Mail setting screen
  { id: ScreenId.MailSetting, url: '/air-conditioning-support/mail-setting' },
  // 異常通知設定画面
  // Error notification setting screen
  { id: ScreenId.ErrorNotification, url: '/error-notification/error-notification' },
  // 応急運転設定依頼画面
  // Emergency operation configulation request screen
  { id: ScreenId.EmergencyOperationConfigurationRequest, url: '/emergency-operation/request' },
  // 応急運転設定確認画面
  // Emergency operation configulation confirmation screen
  {
    id: ScreenId.EmergencyOperationConfigurationConfirmation,
    url: '/emergency-operation/confirmation',
  },
  // 設定依頼完了画面
  // Emergency operation request completion screen
  { id: ScreenId.EmergencyOperationRequestCompletion, url: '/emergency-operation/completion' },
  // エネルギー使用量集計画面
  // Energy usage total screen
  {
    id: ScreenId.PpdCollectionTotal,
    url: '/power-proportional-distribution/ppd-collection-total',
  },
  // 除外時間帯設定画面
  // Exclusion time zone setting screen
  {
    id: ScreenId.PpdCollectionPeriodSetup,
    url: '/power-proportional-distribution/ppd-collection-period-setup',
  },
  // エラー画面
  // Emergency operation error screen
  { id: ScreenId.EmergencyOperationError, url: '/emergency-operation/error' },
  // 異常メール通知先設定画面（新規登録）
  // User registration screen (new registration)
  {
    id: ScreenId.MailNotificationSetting,
    url: '/error-mail-notification/mail-notification-list/add-mail-notification',
  },
  // 異常メール通知先設定画面（編集）
  // User registration screen (edit)
  {
    id: ScreenId.MailNotificationSetting,
    url: '/error-mail-notification/mail-notification-list/edit-mail-notification',
  },
  // 異常メール通知先設定一覧画面
  // User list screen
  {
    id: ScreenId.MailNotificationSetting,
    url: '/error-mail-notification/mail-notification-list',
  },
  // 冷媒漏洩通知先設定登録画面(新規登録)
  // Add Refrigerant leak notification screen
  {
    id: ScreenId.AddRefrigerantLeakNotification,
    url:
      // eslint-disable-next-line max-len
      '/refrigerant-leak-notification/refrigerant-leak-notification-list/add-refrigerant-leak-notification',
  },
  // 冷媒漏洩通知先設定登録画面(編集)
  // Edit Refrigerant leak notification screen
  {
    id: ScreenId.EditRefrigerantLeakNotification,
    url:
      // eslint-disable-next-line max-len
      '/refrigerant-leak-notification/refrigerant-leak-notification-list/edit-refrigerant-leak-notification',
  },
  // 冷媒漏洩通知先設定一覧画面
  //  Refrigerant leak notification list screen
  {
    id: ScreenId.RefrigerantLeakNotificationList,
    url: '/refrigerant-leak-notification/refrigerant-leak-notification-list',
  },
  // シーズン前遠隔点検申込画面
  // Preseason remote inspection request screen
  {
    id: ScreenId.InspectionRequest,
    url: '/airnet-service/inspection-request',
  },
  // シーズン前遠隔点検結果画面
  // Preseason remote inspection result screen
  {
    id: ScreenId.InspectionResult,
    url: '/airnet-service/inspection-result',
  },
  // シーズン前遠隔点検通知先設定登録画面(新規登録)
  // Preseason remote inspection setting registration screen (new registration)
  {
    id: ScreenId.AddInspectionNotification,
    url: '/airnet-service/notification-settings/add',
  },
  // シーズン前遠隔点検通知先設定登録画面(編集)
  // Preseason remote inspection setting registration screen (Edit)
  {
    id: ScreenId.EditInspectionNotification,
    url: '/airnet-service/notification-settings/edit',
  },
  // シーズン前遠隔点検通知先設定一覧画面
  // Preseason remote inspection notification list screen
  {
    id: ScreenId.InspectionNotificationList,
    url: '/airnet-service/notification-settings',
  },
  // 履歴画面
  // history screen
  {
    id: ScreenId.History,
    url: '/history/history-list',
  },
];
