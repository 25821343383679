import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { CollectionFrequencyPutRequest } from '../interfaces/collection-service';
import { CollectionUpdateFrequencyGetResponse } from '../interfaces/collection-service/collection';

const pathOfCollection = `dataCollectionSettings/${apiVersion}/`;

@Injectable()
export class RestClientCollectionService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-22. データ蓄積設定サービス
  //  3-22. Data accumulation setting service
  /////////////////////////////////////////////////////////////////////////////
  //  3-22-1.時報データ蓄積設定管理
  //  3-22-1. Time signal data storage setting management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 時報データ蓄積設定取得API
   * response body: CollectionTimeSignalGetResponse
   *
   * @param {string} buildingId 物件ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Time signal data accumulation setting acquisition API
   * response body: CollectionTimeSignalGetResponse
   *
   * @param {string} buildingId Property ID
   * @return {Observable<Response>} status:HTTP status
   */
  getCollectionTimeSignal(buildingId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCollection}buildings/${buildingId}/hourlyData`,
    );
  }

  /**
   * 時報データ蓄積設定更新API
   * response body: none
   *
   * @param {string} buildingId 物件ID
   * @param {boolean} userValue 時報データ蓄積設定（ユーザー設定)
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Time signal data accumulation setting update API
   * response body: none
   *
   * @param {string} buildingId Property ID
   * @param {boolean} userValue Time signal data accumulation setting (user setting)
   * @return {Observable<Response>} status:HTTP status
   */
  putCollectionTimeSignal(buildingId: string, userValue: boolean): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfCollection}buildings/${buildingId}/hourlyData`,
      { userValue },
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-22-2.蓄積頻度設定管理
  //  3-22-2. Storage frequency setting management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 蓄積頻度設定取得API
   * response body: CollectionFrequencyGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Accumulation frequency setting acquisition API
   * response body: CollectionFrequencyGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} equipmentId Device ID
   * @return {Observable<Response>} status:HTTP status
   */
  getCollectionFrequency(edgeId: string, equipmentId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCollection}edges/${edgeId}/equipments/${equipmentId}/accumulationFrequency`,
    );
  }

  /**
   * 蓄積頻度設定更新API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} equipmentId 機器ID
   * @param {CollectionFrequencyPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Accumulation frequency setting update API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} equipmentId Device ID
   * @param {CollectionFrequencyPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putCollectionFrequency(
    edgeId: string,
    equipmentId: string,
    param: CollectionFrequencyPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfCollection}edges/${edgeId}/equipments/${equipmentId}/accumulationFrequency`,
      param,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-22-3.更新頻度設定管理
  //  3-22-3. Update frequency setting management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 更新頻度設定取得API
   * response body: userValue(number)
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response<CollectionUpdateFrequencyGetResponse>>} レスポンス
   */
  /**
   * Update frequency setting acquisition API
   * response body: userValue(number)
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response<CollectionUpdateFrequencyGetResponse>>} response
   */
  getCollectionUpdateFrequency(
    edgeId: string,
  ): Observable<Response<CollectionUpdateFrequencyGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfCollection}edges/${edgeId}/updateFrequency`,
    );
  }

  /**
   * 更新頻度設定更新API
   * response body: null
   *
   * @param {string} edgeId エッジID
   * @param {number} userValue 更新頻度設定値（ユーザ設定）
   * @return {Observable<Response<null>>} レスポンス
   */
  /**
   * Update frequency setting update API
   * response body: null
   *
   * @param {string} edgeId Edge ID
   * @param {number} userValue Update frequency setting value (user setting)
   * @return {Observable<Response<null>>} response
   */
  putCollectionUpdateFrequency(
    edgeId: string,
    userValue: number | null,
  ): Observable<Response<null>> {
    return this.restClientCommonService.sqsRequest(() => {
      return this.restClientCommonService.request(
        'put',
        `${this.endPoint}${pathOfCollection}edges/${edgeId}/updateFrequency`,
        { userValue },
      );
    });
  }
}
