import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import core from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);

const awsConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: '',
    // REQUIRED - Amazon Cognito Region
    region: environment.cognito.region,
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: '',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: environment.cognito.userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.cognito.userPoolWebClientId,
    // OPTIONAL - customized storage object
    storage: window.localStorage,
  },
};

// 設定
// Configuration
core.configure(awsConfig);
Auth.configure(awsConfig);
