import { Component, forwardRef, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PartialBaseComponent } from 'src/app/base/partial-base.component';
import { OperationMode, CheckboxSpaceSize } from 'src/app/shared-main/enums';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent
  extends PartialBaseComponent
  implements OnInit, ControlValueAccessor
{
  @Input() mode: number = OperationMode.Cooling;
  @Input() indeterminate: boolean = false;
  @Input() label?: string;
  @Input() isSetPointMode: boolean = false;
  @Input() spaceSize: CheckboxSpaceSize = CheckboxSpaceSize.Medium;
  @Input() whiteBorder: boolean = false;

  checked: boolean | 'composite';
  isHeatingMode: boolean;
  onChange: any;

  /**
   * 白枠を追加するかどうかを判定する
   * @return trueの場合、白枠を追加する
   */
  /**
   * Judge whether to add a white frame
   * @return In the case of true, add a white frame
   */
  get isWhiteBorder(): boolean {
    return this.whiteBorder && !!this.checked;
  }

  /**
   * コンストラクター
   */
  /**
   * Constructor
   */
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.isHeatingMode = this.mode === OperationMode.Heating;
  }

  /**
   * ControlValueAccessor Method
   * @param fn ControlValueAccessorに登録する関数
   */
  /**
   * ControlValueAccessor Method
   * @param fn Function registered in ControlValueAccessor
   */
  writeValue(checked: boolean | 'composite') {
    this.checked = this.indeterminate || checked;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * ControlValueAccessor Method
   * @param fn ControlValueAccessorに登録する関数
   */
  /**
   * ControlValueAccessor Method
   * @param fn Function registered in ControlValueAccessor
   */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * ControlValueAccessor Method
   */
  registerOnTouched() {}

  /**
   * ControlValueAccessor Method
   * @param isDisabled true: 無効化, false: 有効化
   */
  /**
   * ControlValueAccessor Method
   * @param isDisabled true: Invalidation, false: activation
   */
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * スペースキーまたはエンターキーの入力時にクリック処理をする
   * @param event キーボードイベント
   */
  /**
   * Click when entering space key or enter key
   * @param event Keyboard event
   */
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onClick(event);
    }
  }

  /**
   * チェックボックス部品をクリック時にイベントを発行する
   * @param event イベント
   */
  /**
   * Issue an event when a check box part is clicked
   * @param event An event
   */
  onClick(event: Event) {
    event.stopPropagation();
    if (!this.disabled) {
      this.checked = this.indeterminate || !this.checked;
      this.onChange(this.checked);
    }
  }
}
