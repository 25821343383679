import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthSmsAuthResponse } from 'src/app/core/services/rest-client/interfaces/auth-serivce';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';
import settingsJson from 'src/settings.json';

export class LoginConfirmationFormModel {
  constructor(public confirmationCode?: string, public session?: string) {}
}

@Component({
  selector: 'app-login-confirmation-form',
  templateUrl: './login-confirmation-form.component.html',
  styleUrls: ['./login-confirmation-form.component.scss'],
})
export class LoginConfirmationFormComponent implements OnInit {
  @Input() loginConfirmationFormModel: LoginConfirmationFormModel;

  loginConfirmationForm: UntypedFormGroup;
  errorMessage: string;
  submitted: boolean = false;
  flag: boolean = false;
  private isNavigateToCommissioning: boolean = false;
  // 省エネシミュレーションから遷移したかの判定フラグ
  // Flag to determine if a transition has been made from the energy savings simulation.
  private isNavigateToEnergySaving: boolean = false;
  private window = window;
  private commissioningParameter = {};

  get confirmationCode(): AbstractControl {
    return this.loginConfirmationForm.get('confirmationCode');
  }

  get isSubmitEnabled(): boolean {
    return this.confirmationCode.value;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private restClientAuthService: RestClientAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private multiLanguage: MultiLanguageMessageService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private dataManagementService: DataManagementService,
    private cookieService: CookieService,
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit() {
    // 試運転から呼び出されたかどうかの判定
    // Determine if it was called from a test run
    if (this.route.snapshot.queryParams.commissioning) {
      this.isNavigateToCommissioning = this.route.snapshot.queryParams.commissioning === '1';
      const parameters = new URLSearchParams(location.search);
      const buildingId = parameters.get('buildingId');
      const edgeId = parameters.get('edgeId');
      if (buildingId) {
        this.commissioningParameter = { buildingId };
      }
      if (edgeId) {
        this.commissioningParameter = Object.assign(this.commissioningParameter, { edgeId });
      }
    }

    // 省エネシミュレーションから呼び出されたかどうか判断する
    // Determine if it was called from an energy saving simulation
    if (this.route.snapshot.queryParams.energySaving) {
      if (
        settingsJson.showEnergyManagementFunction.EnergySavingSimulation &&
        environment['energySavingUrl']
      ) {
        // 省エネシミュレーションのアレンジが有効でかつ、environmentにenergySavingUrlが存在する場合の場合
        // If the energy saving simulation arrangement is valid and energySavingUrl is present in the environment
        this.isNavigateToEnergySaving = this.route.snapshot.queryParams.energySaving === '1';
      }
    }

    this.loginConfirmationForm = this.formBuilder.group({
      confirmationCode: [this.loginConfirmationFormModel.confirmationCode, [Validators.required]],
    });
  }

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    if (this.isSubmitEnabled) {
      this.onSubmit();
    }
  }

  /**
   * 確認ボタンのクリック時
   */
  /**
   * When the confirmation button is clicked
   */

  onSubmit() {
    this.errorMessage = '';
    this.submitted = true;

    // 省エネシミュレーションへの遷移の場合で、Cookieが未承認の時は、トーストを表示する
    // If the transition is to an energy saving simulation and the cookie is unauthorized, an error toast is displayed.
    if (this.isNavigateToEnergySaving && !this.cookieService.getCookieConsentState()) {
      this.toastService.openToast(
        'success',
        'center',
        this.multiLanguage.dictionary('sidCookieUseConsent'),
      );
      return;
    }

    this.loaderService.showLoader();
    this.restClientAuthService.authSmsAuth(this.confirmationCode.value).subscribe(
      () => this.navigateToAfterLoginPage(),
      (error: Response) => this.handleSubmitError(error),
    );
  }

  /**
   * authSmsAuthのエラーレスポンスを処理する
   *
   * @param response    エラーレスポンス
   */
  /**
   * Handle authSmsAuth error response
   *
   * @param response    Error response
   */
  private handleSubmitError(response: Response) {
    this.loaderService.hideLoader();
    const resErrors = response.data as ResponseErrors;
    const firstError = resErrors.errors[0];

    if (response.status === 0) {
      // 強制ログアウト実施
      // Forced logout
      return;
    }

    if (response.status === 400 && firstError.code === 'NotAuthorizedException') {
      this.errorMessage = this.multiLanguage.dictionary('sidExpireVerifyCode');
    } else if (response.status === 400 && firstError.code === 'CodeMismatchException') {
      this.errorMessage = this.multiLanguage.dictionary('sidWrongVerificationCode');
    } else if (response.status === 408) {
      this.errorMessage = this.multiLanguage.dictionary('sidServerErrorOccurred');
      this.toastService.openToast('error', 'center', this.errorMessage);
    } else {
      // その他のエラー
      // other errors
      this.errorMessage = this.multiLanguage.dictionary('sidServerErrorOccurred');
      this.toastService.openToast('error', 'center', this.errorMessage);
    }
  }

  /**
   * ログインに戻るボタンのクリック時
   */
  /**
   * When clicking the button to return to login
   */
  onBackToLoginClicked() {
    if (this.isNavigateToCommissioning) {
      // 試運転の場合
      // For trial operation
      this.router.navigate(['../'], {
        queryParams: Object.assign({ commissioning: 1 }, this.commissioningParameter),
        relativeTo: this.route,
      });
    } else if (this.isNavigateToEnergySaving) {
      // 省エネシミュレーションの場合
      // For trial operation
      this.router.navigate(['../'], {
        queryParams: Object.assign({ energySaving: 1 }),
        relativeTo: this.route,
      });
    } else {
      // 通常の場合
      // normal case
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  /**
   * ログイン後のページに遷移する
   */
  /**
   * Transition to the page after login
   */
  navigateToAfterLoginPage() {
    // // 試運転へ遷移
    // // Transition to comissioning
    // if (this.isNavigateToCommissioning) {
    //   this.loaderService.hideLoader();
    //   const queryParameter = Object.entries(this.commissioningParameter)
    //     .map((param) => `${param[0]}=${param[1]}`)
    //     .join('&');

    //   // 試運転web-appへ遷移する場合、ログイントークンをセッションストレージに保存
    //   // Store login token in session storage when transitioning to comissioning web-app
    //   for (let i = 0; i < localStorage.length; i += 1) {
    //     const key = localStorage.key(i);
    //     if (
    //       key !== 'dictionary' &&
    //       key !== 'dictionaryVersion' &&
    //       key !== 'localeId' &&
    //       key !== 'dictionaryLanguage'
    //     ) {
    //       sessionStorage.setItem(key, localStorage.getItem(key));
    //     }
    //   }

    //   this.window.location.href =
    //     environment.commissioningUrl + (queryParameter ? `index.html?${queryParameter}` : '');
    //   return;
    // } else if (this.isNavigateToEnergySaving) {
    //   this.loaderService.hideLoader();
    //   // environmentにcookieDomainが含まれているか確認する
    //   // Check if environment contains cookieDomain
    //   if (environment['cookieDomain']) {
    //     // 省エネシミュレーションへ遷移するため、ログイントークンをcookieに保存
    //     // Save log-in token in cookie for transition to energy saving simulation
    //     for (let i = 0; i < localStorage.length; i += 1) {
    //       const key = localStorage.key(i);
    //       if (
    //         key.indexOf('CognitoIdentityServiceProvider') != -1 ||
    //         key === 'amplify-signin-with-hostedUI' ||
    //         key === 'lastSelectedRealBuildingId' ||
    //         key === 'localeId'
    //       ) {
    //         this.dataManagementService.setCookie(
    //           key,
    //           localStorage.getItem(key),
    //           null,
    //           environment['cookieDomain'],
    //           '/',
    //           'Lax',
    //         );
    //       }
    //     }
    //   }
    //   // 省エネシミュレーションへ遷移
    //   // Transition to energy saving simulation
    //   this.window.location.href = environment['energySavingUrl'];
    //   return;
    // }

    // 通常のログイン後画面に遷移
    // Go to the normal login screen
    this.dataManagementService.setIsLogin(true);
    this.router.navigate(['/multi-store-management/dashboard']);
  }

  /**
   * 入力キーの確認
   *
   * @param   {[type]}  e  キー
   */
  /**
   * Confirm input key
   *
   * @param   {[type]}  e  Key
   */
  onKeydown(e) {
    if (e.keyCode === 13) {
      this.onBackToLoginClicked();
    }
  }
}
