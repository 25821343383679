import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

export enum ButtonVariant {
  Raised = 'raised',
  Outlined = 'outlined',
}

export enum ButtonAlignment {
  LeftAligned = 'leftAligned',
  CenterAligned = 'centerAligned',
}

export enum ButtonFlexibility {
  None = 'none',
  Full = 'full',
}

@Component({
  selector: 'app-general-button',
  templateUrl: './general-button.component.html',
  styleUrls: ['./general-button.component.scss'],
})
export class GeneralButtonComponent implements OnInit {
  @Input() variant: string = ButtonVariant.Raised;
  @Input() alignment: string = ButtonAlignment.CenterAligned;
  @Input() flexibility: string = ButtonFlexibility.None;
  @Input() disabled: boolean = false;

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @Output() generalButtonClick: EventEmitter<Event> = new EventEmitter<Event>();

  buttonClasses: any;

  constructor() {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.buttonClasses = {
      'button--outlined': this.variant === ButtonVariant.Outlined,
      'button--left-aligned': this.alignment === ButtonAlignment.LeftAligned,
      'button--full': this.flexibility === ButtonFlexibility.Full,
    };
  }

  /**
   * ボタン部品をクリック時にイベントを発行する
   * @param event イベント
   */
  /**
   * Issuing an event when a button part is clicked
   * @param event An event
   */
  onClick(event: Event) {
    this.generalButtonClick.emit(event);
  }
}
