/**
 * ObjectのDeepMerge
 *
 * @param {object}  target  マージ先
 * @param {object}  source  マージ元
 * @param {boolean} overwriteArray 配列上書きフラグ
 */
/**
 * DeepMerge of object
 *
 * @param {object}  target  Destination
 * @param {object}  source  Source
 * @param {boolean} overwriteArray Array overwrite flag
 */
export const deepMerge = (target: object, source: object, overwriteArray = false): object => {
  const localSource = {};
  Object.assign(localSource, source);

  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      if (!target[key]) {
        if (source[key] instanceof Array) {
          target[key] = [];
        } else {
          target[key] = {};
        }
      }
      localSource[key] = deepMerge(target[key], source[key], overwriteArray);
    }
  }

  let result = target;
  if (overwriteArray && result instanceof Array) {
    result = [];
  }

  Object.assign(result, localSource);
  return result;
};

/**
 * ObjectのDeepCopy
 *
 * @param {any}  obj  複製元
 * @return {T}  複製したオブジェクト
 */
/**
 * DeepCopy of object
 *
 * @param {any}  obj  Source
 * @return {T}  Dupliacted object
 */
export const deepCopy = <T>(obj: any): T => {
  if (!obj || 'object' !== typeof obj) return obj;
  if (obj instanceof Array) {
    const clone = [] as any;

    for (let i = 0, len = obj.length; i < len; i += 1) {
      clone.push(deepCopy(obj[i]));
    }
    return clone;
  }

  const clone = Object.create(obj);
  for (const key in obj) {
    const propertyDescriptor = Object.getOwnPropertyDescriptor(obj, key);
    // プロパティが書き込み可能か判定
    // Determine if the property is writable
    if (propertyDescriptor && propertyDescriptor.writable) {
      if (obj[key] instanceof Array) {
        clone[key] = [];
        for (let i = 0, len = obj[key].length; i < len; i += 1) {
          clone[key].push(deepCopy(obj[key][i]));
        }
      } else if (obj[key] instanceof Object && Object.keys(obj[key]).length > 0) {
        clone[key] = {};
        for (const key2 in obj[key]) {
          clone[key][key2] = deepCopy(obj[key][key2]);
        }
      } else {
        if (obj.hasOwnProperty(key)) {
          clone[key] = obj[key];
        }
      }
    }
  }
  return clone as T;
};
