<div class="menu" (click)="onSideMenuClick()" (keydown)="onMenuButtonKeyDown($event)">
  <ng-container
    *ngIf="isTabletMenuContentsInactive; then menuInactive; else menuActive"></ng-container>
  <ng-template #menuInactive>
    <div class="menu__header">
      <button class="menu__toggle-button menu__toggle-button--tablet">
        <div class="menu__toggle-button-bar menu__toggle-button-bar--top"></div>
        <div class="menu__toggle-button-bar menu__toggle-button-bar--center"></div>
        <div class="menu__toggle-button-bar menu__toggle-button-bar--bottom"></div>
      </button>
    </div>
  </ng-template>
  <ng-template #menuActive>
    <div class="menu__header">
      <span class="menu__brand">
        <a tabIndex="0" (click)="
            $event.stopPropagation();
            onMenuSelect(
              mainMenuLinks[0].submenus ? mainMenuLinks[0].submenus[0] : mainMenuLinks[0]
            )
          " (keydown)="
            $event.stopPropagation();
            onMenuKeyDown(
              $event,
              mainMenuLinks[0].submenus ? mainMenuLinks[0].submenus[0] : mainMenuLinks[0]
            )
          ">
          <img class="menu__brand-img" src="assets/icons/logo-s.svg" alt="logo" />
        </a>
      </span>
      <button type="button" class="menu__toggle-button menu__toggle-button--smartphone"
        (click)="isMenuActive = !isMenuActive">
        <div class="menu__toggle-button-bar menu__toggle-button-bar--top"
          [class.toggled]="isMenuActive"></div>
        <div class="menu__toggle-button-bar menu__toggle-button-bar--center"
          [class.toggled]="isMenuActive"></div>
        <div class="menu__toggle-button-bar menu__toggle-button-bar--bottom"
          [class.toggled]="isMenuActive"></div>
      </button>
    </div>
    <div class="menu__body">
      <div class="menu__list-container" [@expandCollapse]="isMenuActive ? 'expand' : 'collapse'">
        <ul class="menu__list">
          <ng-container *ngFor="let menu of mainMenuLinks">
            <li *ngIf="menu.isDisplay" class="menu__list-item">
              <ng-container
                *ngIf="!menu.submenus?.length; then withoutSubmenus; else withSubmenus"></ng-container>
              <ng-template #withoutSubmenus>
                <a class="menu__list-link" [class.active]="menu.id === activeMenu.id" tabIndex="0"
                  (click)="$event.stopPropagation(); onMenuSelect(menu)"
                  (keydown)="$event.stopPropagation(); onMenuKeyDown($event, menu)"
                  [attr.data-test]="'menu_item-' + menu.id">
                  <span class="menu__list-icon {{ menu.iconId }}"></span>
                  <span class="menu__list-name">{{ menu.name | translate }}</span>
                  <div *ngIf="menu.isError && !menu.needUpgrade" class="menu__list-error"
                    [class.active]="menu.id === activeMenu.id"></div>
                  <div *ngIf="menu.needUpgrade" class="menu__list-ribbon">
                    <span class="menu__list-ribbon-contents">{{ 'sidUCUpgrade' | translate }}</span>
                  </div>
                </a>
              </ng-template>
              <ng-template #withSubmenus>
                <a class="menu__list-link" tabIndex="0" (click)="onSubmenuOpen(menu)"
                  (keydown)="$event.stopPropagation(); onSubmenuKeyDown($event, menu)"
                  [attr.data-test]="'menu_list-' + menu.id">
                  <span class="menu__list-icon {{ menu.iconId }}"></span>
                  <span class="menu__list-name">{{ menu.name | translate }}</span>
                  <img *ngIf="menu.expanded" class="menu__list-caret"
                    src="assets/icons/triangle_w.svg" alt="icon" />
                  <img *ngIf="!menu.expanded" class="menu__list-caret"
                    src="assets/icons/triangl-down_w.svg" alt="icon" />
                </a>
                <ul class="menu__list-submenu submenu-{{ menu.id }}"
                  [@expandCollapse]="menu.expanded ? 'expand' : 'collapse'">
                  <ng-container *ngFor="let submenu of menu.submenus">
                    <li *ngIf="submenu.isDisplay" class="menu__list-item menu__list-item--submenu">
                      <a class="menu__list-link" [class.active]="submenu.id === activeMenu.id"
                        [tabIndex]="menu.expanded ? '0' : '-1'"
                        (click)="$event.stopPropagation(); onMenuSelect(submenu)"
                        (keydown)="$event.stopPropagation(); onMenuKeyDown($event, submenu)"
                        [attr.data-test]="'menu_item-' + submenu.id">
                        <span class="menu__list-name menu__list-name--submenu">
                          {{ submenu.name | translate }}
                        </span>
                        <div *ngIf="submenu.isError && !submenu.needUpgrade"
                          class="menu__list-error" [class.active]="submenu.id === activeMenu.id">
                        </div>
                        <div *ngIf="submenu.needUpgrade" class="menu__list-ribbon">
                          <span class="menu__list-ribbon-contents">
                            {{ 'sidUCUpgrade' | translate }}
                          </span>
                        </div>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </ng-template>
            </li>
          </ng-container>
          <li class="menu__list-item menu__list-item--account-panel">
            <app-account-panel (accountPanelSelect)="onMenuSelect($event)"></app-account-panel>
          </li>
        </ul>
      </div>
      <div class="menu__footer-container">
        <app-menu-footer (menuFooterSelect)="onMenuSelect($event)"></app-menu-footer>
      </div>
    </div>
  </ng-template>
</div>