import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, ElementRef, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { CookieService } from '../services/cookie/cookie.service';
import { HeaderService } from './services/header/header.service';
import { MainManagementService } from './services/main-management/main-management.service';

import { Building } from 'src/app/core/services/data-management/classes/building-data';
import { Link } from './services/menu-management/interfaces';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cookieBar', { read: ElementRef, static: true }) cookieBar: ElementRef;

  isSitesUnavailable: boolean = false;
  isServiceUnactivated: boolean = false;
  isServiceUnavailable: boolean = false;
  siteList: Building[] = this.dataManagementService.buildingList();
  activeMenu: Link;

  get offsetHeight(): string {
    const clientHeight =
      this.cookieBar.nativeElement.lastElementChild &&
      this.cookieBar.nativeElement.lastElementChild.clientHeight;
    const cookieBarElemHeight = !this.cookieService.getCookieConsentState() ? clientHeight || 0 : 0;

    return `calc(100vh - ${cookieBarElemHeight}px)`;
  }

  private $destroyStream: Subject<void> = new Subject<void>();

  /**
   * コンストラクター関数
   * @param dataManagementService データ管理サービス
   * @param cookieService クッキーサービス
   * @param headerService ヘッダサービス
   * @param mainManagementService メイン管理サービス
   */
  /**
   * Constructor function
   * @param dataManagementService Data management service
   * @param cookieService Cookie service
   * @param headerService Header service
   * @param mainManagementService Main Management Service
   */
  constructor(
    private dataManagementService: DataManagementService,
    private cookieService: CookieService,
    private headerService: HeaderService,
    private mainManagementService: MainManagementService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 物件なしの通知にサブスクライブ
    // Listen to notifications from sites unavailable trigger
    this.headerService.$sitesUnavailableStream
      .pipe(takeUntil(this.$destroyStream))
      .subscribe((isSitesUnavailable) => (this.isSitesUnavailable = isSitesUnavailable));

    // 未契約の通知にサブスクライブ
    // Listen to notifications from service unactivated trigger
    this.mainManagementService.$serviceUnactivatedStream
      .pipe(takeUntil(this.$destroyStream))
      .subscribe((isServiceUnactivated) => (this.isServiceUnactivated = isServiceUnactivated));

    // 利用不可の通知にサブスクライブ
    // Listen to notifications from service unavailable trigger
    this.mainManagementService.$serviceUnavailableStream
      .pipe(takeUntil(this.$destroyStream))
      .subscribe((isServiceUnavailable) => (this.isServiceUnavailable = isServiceUnavailable));
  }

  /**
   * Angular Life Cycle Method
   */
  ngAfterViewInit() {
    // // ログイン後にキャッシュをクリア
    // // Clear cache after login
    this.dataManagementService.clearCachesLoginData();
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    this.$destroyStream.next();
  }
}
