import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import {
  ZoneZonesPostRequest,
  ZoneZonesPutRequest,
  ZoneZonesDeleteRequest,
  ZoneStatusRequest,
  ZoneZonesResponse,
} from '../interfaces/zone-service';
import { Scope } from '../enums/scope.enum';
import { RestClient, apiVersion } from '../base/rest-client';
import { map } from 'rxjs/operators';
import { ZoneZoneRequestQuery } from '../interfaces/zone-service/zone-zones';

const pathOfZone = `zone/${apiVersion}/`;

@Injectable()
export class RestClientZoneService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-6. ゾーンサービス
  //  3-6. Zone Service
  /////////////////////////////////////////////////////////////////////////////
  // 3-6-1. ゾーン管理
  // 3-6-1. Zone management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * ゾーンデータ取得(物件指定)API
   * response body: ZoneZonesResponse
   * @deprecated 新規で使用する場合はgetZones()の方を使用することを推奨
   *
   * @param {Scope} scope 取得範囲
   * @param {string} buildingId 物件ID
   * @param {boolean} [includeOutdoorZone=false] アウトドアゾーン
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @param {boolean} [includeEquipmentData=false] 機器データ
   * @param {boolean} [includeHeatSourceZone=false] 熱源ゾーン
   * @param {boolean} [includeQAIndoorUnit=false] QA伝送室内機データ
   * @return {Observable<Response<ZoneZonesResponse>>} status:HTTPステータス
   */
  /**
   * Zone data acquisition (property designation) API
   * response body: ZoneZonesResponse
   * @deprecated If you want to use it newly, it is recommended to use Getzones ()
   *
   * @param {Scope} scope Acquisition range
   * @param {string} buildingId Property ID
   * @param {boolean} [includeOutdoorZone=false] Outdoor zone
   * @param {boolean} [force = false] Get information from backend without using cache
   * @param {boolean} [includeEquipmentData=false] Device data
   * @param {boolean} [includeHeatSourceZone=false] HeatSource zone
   * @param {boolean} [includeQAIndoorUnit=false] QAIndoor Unit
   * @return {Observable<Response<ZoneZonesResponse>>} status:HTTP status
   */
  getZoneZones(
    scope: Scope,
    buildingId: string,
    includeOutdoorZone: boolean = false,
    force: boolean = false,
    includeEquipmentData: boolean = false,
    includeHeatSourceZone: boolean = false,
    includeQAIndoorUnit: boolean = false,
  ): Observable<Response<ZoneZonesResponse>> {
    const query =
      `?scope=${scope}&buildingId=${buildingId}` +
      `&includeOutdoorZone=${includeOutdoorZone}` +
      `&includeEquipmentData=${includeEquipmentData}` +
      `&includeHeatSourceZone=${includeHeatSourceZone}` +
      `&includeQAIndoorUnit=${includeQAIndoorUnit}`;
    const callback = () =>
      this.restClientCommonService.request('get', `${this.endPoint}${pathOfZone}zones${query}`);
    return this.restClientCommonService
      .sqsRequest(callback, { dueTime: 300, periodTime: 1000 })
      .pipe(
        map((response) => {
          if (
            response.data &&
            'statusCode' in response.data &&
            Number(response.data.statusCode) === 204
          ) {
            return new Response(204, undefined, response.headers);
          }
          return response;
        }),
      );
  }

  /**
   * ゾーンデータ取得(物件指定)API
   * @param requestQuery リクエストパラメータ
   * @returns response
   */
  /**
   * Zone data acquisition (property designation) API
   * @param requestQuery Request parameter
   * @returns レスポンス
   */
  getZones(requestQuery: ZoneZoneRequestQuery): Observable<Response<ZoneZonesResponse>> {
    const query = Object.entries(requestQuery)
      .filter(([, value]) => value != null)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    const callback = () =>
      this.restClientCommonService.request('get', `${this.endPoint}${pathOfZone}zones?${query}`);
    return this.restClientCommonService
      .sqsRequest(callback, { dueTime: 300, periodTime: 1000 })
      .pipe(
        map((response) => {
          if (
            response.data &&
            'statusCode' in response.data &&
            Number(response.data.statusCode) === 204
          ) {
            return new Response(204, undefined, response.headers);
          }
          return response;
        }),
      );
  }

  /**
   * ゾーンデータ登録API
   * response body: ZoneZonesPostResponse
   *
   * @param {ZoneZonesPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Zone data registration API
   * response body: ZoneZonesPostResponse
   *
   * @param {ZoneZonesPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postZoneZones(param: ZoneZonesPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfZone}zones`,
      param,
    );
  }

  /**
   * ゾーンデータ更新API
   * response body: none
   *
   * @param {ZoneZonesPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Zone data update API
   * response body: none
   *
   * @param {ZoneZonesPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putZoneZones(param: ZoneZonesPutRequest): Observable<Response> {
    const callback = (): Observable<Response> => {
      return this.restClientCommonService.request(
        'put',
        `${this.endPoint}${pathOfZone}zones`,
        param,
      );
    };
    return this.restClientCommonService.sqsRequest(callback);
  }

  /**
   * ゾーンデータ削除API
   * response body: none
   *
   * @param {string} zoneId ゾーンID
   * @param {ZoneZonesDeleteRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Zone data deletion API
   * response body: none
   *
   * @param {string} zoneId Zone ID
   * @param {ZoneZonesDeleteRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  deleteZoneZones(zoneId: string, param: ZoneZonesDeleteRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfZone}zones/${zoneId}`,
      param,
    );
  }

  /**
   * ゾーン状態取得API
   * @param zoneIds ゾーン Id
   * @param polling ポーリングフラグ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Zone states acquisition API
   * response body:
   *
   * @param {} zoneIds Zone Ids
   * @param {boolean} polling polling flag
   * @returns {Observable<Response>} status: HTTP status
   */
  postZoneStates(param: ZoneStatusRequest, polling: boolean): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfZone}` + `currentZoneStates`,
      param,
      this.screenId,
      polling,
      polling,
    );
  }
}
