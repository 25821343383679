import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NotificationService, NotificationRef } from '@progress/kendo-angular-notification';
import { ToastType, ToastPosition } from './interfaces/toast-option';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements AfterViewInit {
  @ViewChild('toast', { read: TemplateRef, static: true }) toastRef: TemplateRef<any>;

  @Input() type: ToastType;
  @Input() position: ToastPosition;
  @Input() toastMessage: string;

  @Output() toastClose: EventEmitter<void> = new EventEmitter<void>();

  get isErrorType(): boolean {
    return this.type === 'error';
  }

  private timeout = null;
  private elementRef: NotificationRef;

  /**
   * コンストラクター
   * @param notificationService: 通知サービス
   */
  /**
   * Constructor
   * @param notificationService: Notification service
   */
  constructor(private notificationService: NotificationService) {}

  /**
   * Angular Life Cycle Method
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.elementRef = this.notificationService.show({
        cssClass: 'toast__notification',
        content: this.toastRef,
        position: { horizontal: this.position, vertical: 'top' },
        closable: false,
      });
    });

    // 5秒経過したら、トーストを閉じる
    // Closes toast after 5s
    this.timeout = setTimeout(() => {
      this.onClose();
    }, 5000);
  }

  /**
   * マウスオーバー時に、タイマーをクリアする
   */
  /**
   * Clears timer on hover
   */
  onHover() {
    clearTimeout(this.timeout);
  }

  /**
   * フォーカスアウト時に、タイマーを開始する
   */
  /**
   * Restarts timer on blur
   */
  onLeave() {
    // 2秒経過したら、トーストを閉じる
    // Closes toast after 2s
    this.timeout = setTimeout(() => {
      this.onClose();
    }, 2000);
  }

  /**
   * トーストを閉じる
   */
  /**
   * Closes toast
   */
  onClose() {
    this.elementRef.hide();
    this.toastClose.emit();
  }
}
