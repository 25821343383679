export enum ApiType {
  // 取得
  // Obtain
  get,

  // 登録
  // register
  register,

  // 更新
  // renew
  update,

  // 削除
  // delete
  delete,
}
