import 'hammerjs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';

import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CookieBarComponent } from '../main/components/cookie-bar/cookie-bar.component';
import { CookieDialogComponent } from '../main/components/cookie-dialog/cookie-dialog.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogOverlaySettingComponent } from './components/dialog-overlay-setting/dialog-overlay-setting.component';
import { SelectBoxComponent } from './components/select-box/select-box.component';
import { TextBoxComponent } from './components/text-box/text-box.component';

import { TranslatePipe } from './pipes/translate.pipe';
import { DateFormatYmdSlashPipe } from './pipes/date-format-ymd-slash.pipe';
import { DateFormatYmdPipe } from './pipes/date-format-ymd.pipe';
import { DateFormatMdSlashPipe } from './pipes/date-format-md-slash.pipe';
import { DateFormatMdPipe } from './pipes/date-format-md.pipe';
import { DateFormatYmSlashPipe } from './pipes/date-format-ym-slash.pipe';
import { DateFormatYmPipe } from './pipes/date-format-ym.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { embeddableRoutingComponents } from '../main/main-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    DropDownsModule,
    InputsModule,
    PopupModule,
  ],
  declarations: [
    embeddableRoutingComponents,
    ButtonComponent,
    CheckboxComponent,
    CookieBarComponent,
    CookieDialogComponent,
    DialogComponent,
    DialogOverlaySettingComponent,
    SelectBoxComponent,
    TextBoxComponent,
    TranslatePipe,
    DateFormatYmdSlashPipe,
    DateFormatYmdPipe,
    DateFormatMdSlashPipe,
    DateFormatMdPipe,
    DateFormatYmSlashPipe,
    DateFormatYmPipe,
    DecimalPipe,
  ],
  exports: [
    embeddableRoutingComponents,
    ButtonComponent,
    CheckboxComponent,
    CookieBarComponent,
    CookieDialogComponent,
    DialogComponent,
    DialogOverlaySettingComponent,
    SelectBoxComponent,
    TextBoxComponent,
    TranslatePipe,
    DateFormatYmdSlashPipe,
    DateFormatYmdPipe,
    DateFormatMdSlashPipe,
    DateFormatMdPipe,
    DateFormatYmSlashPipe,
    DateFormatYmPipe,
    DecimalPipe,
  ],
  providers: [
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
  ],
})
export class SharedPreparationModule {}
