import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GeneralErrorComponent } from './main/general-error/general-error.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AppLoadGuard } from 'src/app/core/guards/app-load.guard';

const routes: Routes = [
  {
    path: 'general-error/:state',
    component: GeneralErrorComponent,
    data: {
      breadcrumb: '',
    },
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    data: {
      breadcrumb: '',
    },
    canLoad: [AppLoadGuard],
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    data: {
      breadcrumb: '',
    },
    canLoad: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  providers: [],
})
export class AppRoutingModule {}
