import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';

import { PartialBaseComponent } from 'src/app/base/partial-base.component';
import { ButtonComponent } from 'src/app/shared-preparation/components/button/button.component';
import { Utility } from 'src/app/shared-main/classes/utility';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';

export enum DialogEventType {
  Decline = -1,
  Accept = 1,
}

export enum DialogFlexibility {
  None = 'none',
  Full = 'full',
}

export class DialogOutput {
  /**
   * コンストラクター
   * @param eventType ダイアログのイベントタイプ
   * @param data ダイアログの出力
   */
  /**
   * Constructor
   * @param eventType Dialog event type
   * @param data Dialog output
   */
  constructor(public eventType: DialogEventType, public data: any) {}
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent extends PartialBaseComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() messages: string;
  @Input() confirmButtonLabel: string;
  @Input() declineButtonLabel: string;
  @Input() flexibility: DialogFlexibility;
  @Input() componentPortal: ComponentPortal<any>;
  @Input() inputClassNames: string[] = [];

  @Output() output: EventEmitter<DialogOutput> = new EventEmitter<DialogOutput>();
  @Output() overlayclick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren(ButtonComponent, { read: ElementRef }) buttons: QueryList<ElementRef>;

  private embeddedComponent: ComponentRef<any>;

  get isFullFlexibility(): boolean {
    return this.flexibility === DialogFlexibility.Full;
  }
  get isNoneFlexibility(): boolean {
    return this.flexibility === DialogFlexibility.None;
  }
  get embeddedOutput(): any {
    if (!this.componentPortal || !this.embeddedComponent) return null;
    return this.embeddedComponent.instance.embeddedOutput();
  }
  get dialogMaxHeight(): number {
    // クッキーバー有無を判定しダイアログの最大高さを返却する
    // Determine the presence of a cookie bar and return the dialog max height
    const clientHeight =
      document.getElementsByClassName('cookie-bar')[1] &&
      document.getElementsByClassName('cookie-bar')[1].clientHeight;
    if (!this.cookieService.getCookieConsentState()) {
      // クッキーバー有の場合、ダイアログ最大高さをクッキーバーの分だけ小さくする
      // If there is cookie bar, reduce the maximum height of the dialog by the amount of the cookie bar.
      return window.innerHeight - clientHeight * 2 - 10;
    }
    // クッキーバー無の場合、ダイアログ最大高さを画面高さの90％にする
    // If there is NO cookie bar, set the maximum dialog height to 90% of the screen height.
    return window.innerHeight * 0.9;
  }

  /**
   * コンストラクター
   * @param elementRef エレメントのリファレンス
   * @param {CookieService} cookieService クッキーサービス
   */
  /**
   * Constructor
   * @param elementRef Element reference
   * @param {CookieService} cookieService Cookie service
   */
  constructor(private elementRef: ElementRef, private cookieService: CookieService) {
    super();
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // ダイアログ開く時にタブ可能な要素を無効にする（ダイアログ内の要素は対象外）
    // Disable tabbable elements when dialog opens (excluding dialog contents)
    Utility.disableTabbableElements();
    Utility.enableTabbableElements(this.elementRef.nativeElement);
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    // ダイアログ閉じる時にタブ可能な要素を有効にする
    // Enable tabbable elements when drawer closes
    Utility.enableTabbableElements();
  }

  /**
   * attach 押下時に、エンベデッドコンポーネントを設定する
   */
  /**
   * Sets embedded component when component is attached
   */
  onAttached(component: any) {
    this.embeddedComponent = component;
  }

  /**
   * OK 押下時に、accept イベントを検知する
   */
  /**
   * Emits accept event when OK is clicked
   */
  onAccept() {
    this.output.emit(new DialogOutput(DialogEventType.Accept, this.embeddedOutput));
  }

  /**
   * Cancel 押下時に、decline イベントを検知する
   */
  /**
   * Emits decline event when Cancel is clicked
   */
  onDecline() {
    this.output.emit(new DialogOutput(DialogEventType.Decline, this.embeddedOutput));
  }
}
