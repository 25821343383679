import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { PartialBaseComponent } from 'src/app/base/partial-base.component';

import {
  PasswordPolicy,
  PasswordPolicyService,
} from './services/password-policy/password-policy.service';
import {
  passwordMinLengthValidator,
  passwordMaxLengthValidator,
  passwordNumberValidator,
  passwordUpperCaseValidator,
  passwordLowerCaseValidator,
  passwordSpecialCharacterValidator,
} from './validators/password.validator';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

export enum TextBoxType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Number = 'number',
}

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
})
export class TextBoxComponent
  extends PartialBaseComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() type: string = TextBoxType.Text;
  @Input() placeholder: string = '';
  @Input() maxlength: number;
  @Input() step: number = 1;
  @Input() novalidation: boolean = false;
  @Input() usePasswordPolicies: boolean = false;
  @Input() isIconLeftAligned: boolean = false;
  @Input() isIconRightAligned: boolean = false;
  @Input() enableAutoCompletion: boolean = false;
  @Input() name: string = '';
  @Input() passwordMaskIcon: boolean = false;
  @Input() ngModelChangevalidater: boolean = false;
  @Input() customFormat: (value: string) => string | number;
  @Input() min: number;
  @Input() max: number;
  @Input() autoCorrect: boolean;
  @Output() textBoxBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() textBoxFocus: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild('textBoxIcon') textBoxIcon: ElementRef;
  @ViewChild('textboxInput') textBoxInput: ElementRef<HTMLInputElement>;
  @ViewChild('numericInput') numericInput: NumericTextBoxComponent;

  value: string | number;
  showPasswordPolicies: boolean = false;
  format: NumberFormatOptions = {};
  minimumFractionDigits: number;
  decimalDigit = 0;
  showPassword: boolean = false;

  get passwordPolicies(): PasswordPolicy[] {
    return this.passwordPolicyService.getPasswordPolicies(this.ngControl.control);
  }

  private onChange: any;
  private onTouch: any;

  /**
   * コンストラクター
   * @param ngControl フォームコントロールサービス
   * @param passwordPolicyService パスワードポリシーサービス
   */
  /**
   * Constructor
   * @param ngControl ngControl service
   * @param passwordPolicyService Password policy service
   */
  constructor(
    public ngControl: NgControl,
    private passwordPolicyService: PasswordPolicyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    ngControl.valueAccessor = this;
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    if (this.usePasswordPolicies) {
      this.ngControl.control.setValidators([
        passwordMinLengthValidator,
        passwordMaxLengthValidator,
        passwordNumberValidator,
        passwordUpperCaseValidator,
        passwordLowerCaseValidator,
        passwordSpecialCharacterValidator,
      ]);
    }

    if (this.type === TextBoxType.Number) {
      this.setNumberFormat();
    }
  }

  /**
   * 数値のフォーマットを設定する。
   */
  /**
   * Set a numerical format.
   */
  private setNumberFormat() {
    if (this.step === null || this.step == undefined) {
      return;
    }

    this.format.style = 'decimal';
    const dotPosition = this.step.toString().indexOf('.');
    if (dotPosition >= 0) {
      this.decimalDigit = this.step.toString().length - dotPosition - 1;
    } else {
      this.decimalDigit = 0;
    }
    this.format.minimumFractionDigits = this.decimalDigit;
    this.format.maximumFractionDigits = this.decimalDigit;

    // フォーマットの変更がそのままでは反映されないため、表示を更新する
    // Update the display because the format change is not reflected as it is
    if (this.numericInput) {
      this.numericInput.writeValue(Number(this.value));
    }
  }

  /**
   * カスタムフォーマットを元に入力された文字列を変更する
   * @param value 値
   */
  /**
   * Change the character string entered based on the custom format
   * @param value Value
   */
  private setCustomFormat(value: string | number): void {
    let stringValue = '';
    if (value !== undefined && value !== null) {
      stringValue = value.toString();
    }

    this.value = this.customFormat(stringValue);
    if (this.textBoxInput) {
      this.textBoxInput.nativeElement.value = this.value.toString();
    }
  }

  /**
   * Angular Life Cycle Method
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.step) {
      if (this.type === TextBoxType.Number) {
        this.setNumberFormat();
      }
    }
    if (this.customFormat !== undefined) {
      this.setCustomFormat(this.value);
    }
  }

  /**
   * ngModel(あるいはform control)で渡された値を設定する
   * @param value 画面から渡される値
   */
  /**
   * Set the value passed in ngModel (or form control)
   * @param value The value passed from the screen
   */
  writeValue(value: string | number) {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * ControlValueAccessor Method
   * @param fn ControlValueAccessorに登録する関数
   */
  /**
   * ControlValueAccessor Method
   * @param fn Function registered in ControlValueAccessor
   */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * ControlValueAccessor Method
   * @param fn ControlValueAccessorに登録する関数
   */
  /**
   * ControlValueAccessor Method
   * @param fn Function registered in ControlValueAccessor
   */
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  /**
   * ControlValueAccessor Method
   * @param disabled 部品の非活性フラグ
   */
  /**
   * ControlValueAccessor Method
   * @param disabled Part inactivity flag
   */
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * テキストボックスが変更されたら、入力値を変更する
   * @param value 入力値
   */
  /**
   * If the textbox changes, change the input value
   * @param value Input value
   */
  onInput(value: string | number) {
    let nextValue = value;
    let setValue = false;
    if (value !== undefined && value !== null && this.type === TextBoxType.Number) {
      const numberValue = typeof value === 'number' ? value : Number(value);
      if (!Number.isNaN(numberValue) && value !== '') {
        nextValue = Number(numberValue.toFixed(this.decimalDigit));
      } else {
        nextValue = value;
      }
    }
    if (this.customFormat !== undefined) {
      this.setCustomFormat(nextValue);
      setValue = true;
    }
    if (!setValue) {
      this.value = nextValue;
    }
    this.onChange(this.value);
  }

  /**
   * テキストボックスからフォーカスが外れたら、Blurイベントを発行する
   */
  /**
   * Issue a Blur event when the textbox is out of focus
   */
  onBlur() {
    if (this.usePasswordPolicies) this.showPasswordPolicies = false;
    this.onTouch();
    this.textBoxBlur.emit();
  }

  /**
   * テキストボックスにフォーカスしたら、Focusイベントを発行する
   */
  /**
   * Issue a Focus event when the textbox is focussed
   */
  onFocus() {
    if (this.usePasswordPolicies) this.showPasswordPolicies = true;
    this.textBoxFocus.emit();
  }

  /**
   * パスワードのマスク/マスク解除を切り替える
   */
  /**
   * Toggle masking/unmasking of passwords
   */
  onClickPasswordMaskIcon() {
    this.showPassword = !this.showPassword;
    if (this.type === TextBoxType.Password) {
      this.type = TextBoxType.Text;
    } else {
      this.type = TextBoxType.Password;
    }
  }
}
