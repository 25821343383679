import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestClientAuthService } from 'src/app/core/services/rest-client';
import { LoginService } from 'src/app/login/services/login.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { ResetPasswordModel } from 'src/app/login/interfaces/login-model';
import { emailFormatValidator } from 'src/app/shared-preparation/components/text-box/validators/email.validator';
import { CHARS_LENGTH } from 'src/app/shared-main/constants/chars-length';

@Component({
  selector: 'app-reset-password-mail-form',
  templateUrl: './reset-password-mail-form.component.html',
  styleUrls: ['./reset-password-mail-form.component.scss'],
})
export class ResetPasswordMailFormComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Output() linkClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  get email(): AbstractControl {
    return this.formGroup.get('email');
  }

  get isReset(): boolean {
    return this.checkEmail();
  }

  isEmail: boolean = true;

  errorMessage: string = null;

  resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();
  // メールアドレスの最大文字数
  // Maximum number of characters in an e-mail address
  private readonly emailMaxLength = CHARS_LENGTH.MAX_EMAIL;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private restClientAuthService: RestClientAuthService,
    private loginService: LoginService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private multiLanguageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      email: [
        this.resetPasswordModel.email,
        [Validators.maxLength(this.emailMaxLength), emailFormatValidator],
      ],
    });
  }

  /**
   * ログイン画面に戻る
   */
  /**
   * Return to login screen
   */
  onBackToLoginClick() {
    this.router.navigate(['/login'], { relativeTo: this.route });
  }

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    this.isEmail = this.checkEmail();
    if (this.isEmail) {
      this.onSubmit();
    }
  }

  /**
   * データ送信
   */
  /**
   * Data transmission
   */
  onSubmit() {
    this.loginService.email = null;
    this.loaderService.showLoader();
    this.restClientAuthService.authSendPasswordResetCode(this.email.value).subscribe(
      (response) => {
        this.loaderService.hideLoader();
        this.loginService.email = this.email.value;
        this.router.navigate(['../reset-password'], { relativeTo: this.route });
      },
      (err) => {
        this.loaderService.hideLoader();
        this.errorMessage = this.multiLanguageService.dictionary('sidServerErrorOccurred');
        this.toastService.openToast(
          'error',
          'center',
          this.multiLanguageService.dictionary('sidServerErrorOccurred'),
        );
      },
    );
  }

  /**
   * フォーム入力値チェック
   */
  /**
   * Check input value from form
   */
  onEmailChange() {
    this.isEmail = this.checkEmail();
  }

  /**
   * Emailアドレス形式チェック
   *
   * @return  {boolean}  true:エラー無し false:エラー有り
   */
  /**
   * Email address format check
   *
   * @return  {boolean}  true: No error false: Error
   */
  checkEmail(): boolean {
    const item = this.formGroup.get('email');
    if (!item.errors && item.value) {
      return true;
    }
    return false;
  }

  /**
   * 入力キーの確認
   *
   * @param   {[type]}  e  キー
   */
  /**
   * Confirm input key
   *
   * @param   {[type]}  e  Key
   */
  onKeydown(e) {
    if (e.keyCode === 13) {
      this.onBackToLoginClick();
    }
  }
}
