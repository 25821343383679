<div class="reset-password-mail-form">
  <div class="reset-password-mail-form__title">
    {{'sidResetPass' | translate}}
  </div>

  <div class="reset-password-mail-form__group" (keyup.enter)="onEnter()">
    <app-text-box placeholder="{{'sidEmail'| translate }}" name="email" [formControl]="email"
      [enableAutoCompletion]="true" [novalidation]="false" [isIconLeftAligned]="true"
      (ngModelChange)="onEmailChange()">
      <div #textBoxIcon class="k-icon k-i-user"></div>
    </app-text-box>
  </div>

  <div class="reset-password-mail-form__error-message">
    <app-form-inline-message *ngIf="errorMessage">
      {{errorMessage}}
    </app-form-inline-message>
  </div>

  <div class="reset-password-mail-form__link">
    <app-form-link (click)="onBackToLoginClick()" (keydown)="onKeydown($event)">
      {{'sidBackToLogin' | translate }}
    </app-form-link>
  </div>

  <app-button class="reset-password-mail-form__button" [disabled]="!isReset"
    (generalButtonClick)="onSubmit()">
    {{'sidResetPass' | translate}}
  </app-button>
</div>