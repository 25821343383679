import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PackageManagementService {
  $updatePackagesStream: Subject<void> = new Subject<void>();
  /**
   * コンストラクター関数
   */
  /**
   * Constructor function
   */
  constructor() {}

  /**
   * パッケージ更新を検知
   */
  /**
   * Emit package update notificiation
   */
  updatePackages() {
    this.$updatePackagesStream.next();
  }
}
