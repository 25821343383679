import { Component, Input, HostBinding, OnInit } from '@angular/core';

import { LoaderService } from './loader.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  providers: [LoaderService],
})
export class LoaderComponent implements OnInit {
  @HostBinding('class.on-part') useForParts: boolean;
  @Input() fullScreen: boolean = false;
  @Input() hide: boolean;

  /**
   * Angular life cycle method
   */
  ngOnInit() {
    this.useForParts = !this.fullScreen;
  }
}
