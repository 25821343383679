import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';

import maintenanceStateJson from 'src/assets/maintenance-state/maintenance-state.json';
import { ErrorState } from 'src/app/main/enums';

@Injectable()
export class AppLoadGuard {
  /**
   * コンストラクター関数
   * @param router Angular のルーターサービス
   */
  /**
   * Constructor function
   * @param router Angular router service
   */
  constructor(private router: Router) {}

  /**
   * canLoad インタフェース
   * @param route ルート
   * @param segments URL のセグメント
   * @returns ロード状態（true: ロード可能、false: ロード不可能）
   */
  /**
   * canLoad interface
   * @param route Route
   * @param segments Url segments
   * @returns load state（true: enable load、false: disable load）
   */
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    // メンテナンス中の場合、汎用エラー画面に遷移し、全モジュールの遅延ロードを止める
    // Navigate to general-error page and stop all modules from lazy-loading while under maintenance
    if (maintenanceStateJson.maintenanceState) {
      this.router.navigate(['general-error', ErrorState.Maintenance]);
      return false;
    }

    return true;
  }
}
