import '@progress/kendo-angular-intl/locales/en-GB/all';
import '@progress/kendo-angular-intl/locales/ja/all';
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import '@progress/kendo-angular-intl/locales/nl/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/el/all';
import '@progress/kendo-angular-intl/locales/pt-PT/all';
import '@progress/kendo-angular-intl/locales/ru/all';
import '@progress/kendo-angular-intl/locales/tr/all';
import '@progress/kendo-angular-intl/locales/cs/all';
import '@progress/kendo-angular-intl/locales/hr/all';
import '@progress/kendo-angular-intl/locales/hu/all';
import '@progress/kendo-angular-intl/locales/ro/all';
import '@progress/kendo-angular-intl/locales/sl/all';
import '@progress/kendo-angular-intl/locales/sk/all';
import '@progress/kendo-angular-intl/locales/bg/all';
import '@progress/kendo-angular-intl/locales/pl/all';
import '@progress/kendo-angular-intl/locales/da/all';
import '@progress/kendo-angular-intl/locales/sv/all';
import '@progress/kendo-angular-intl/locales/nb/all';
import '@progress/kendo-angular-intl/locales/fi/all';
import '@progress/kendo-angular-intl/locales/sr/all';
import '@progress/kendo-angular-intl/locales/th/all';
import '@progress/kendo-angular-intl/locales/zh/all';
import '@progress/kendo-angular-intl/locales/vi/all';
import '@progress/kendo-angular-intl/locales/id/all';