import { BehaviorSubject } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthorityManagerService } from 'src/app/shared-main/components/authority-control/authority-manager.service';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';

import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { ScreenCommonItemId } from 'src/app/shared-main/enums/item-id';
import { mainMenuLinks, menuFooterLinks, footerMenuLinks, myProfileLinks } from './links';
import {
  Link,
  MainMenuLink,
  MenuFooterLink,
  FooterMenuLink,
  MyProfileLink,
  SubMenuLink,
} from './interfaces';
import { AgentType } from 'src/app/core/services/data-management/enums/agent-type';
import settingsJson from 'src/settings.json';
import { Building } from 'src/app/core/services/data-management/classes/building-data';
import { Utility } from 'src/app/shared-main/classes/utility';
import { Package } from 'src/app/core/services/data-management/classes/package-data';

@Injectable({
  providedIn: 'root',
})
export class MenuManagementService {
  $mainMenuLinksStream: BehaviorSubject<MainMenuLink[]> = new BehaviorSubject([]);
  $menuFooterLinksStream: BehaviorSubject<MenuFooterLink[]> = new BehaviorSubject([]);
  $footerMenuLinksStream: BehaviorSubject<FooterMenuLink[]> = new BehaviorSubject([]);
  $myProfileLinksStream: BehaviorSubject<MyProfileLink[]> = new BehaviorSubject([]);
  $activeMenuStream: BehaviorSubject<Link> = new BehaviorSubject(null);
  $activeRouteStream: BehaviorSubject<Link> = new BehaviorSubject(null);
  $selectedMenuStream: BehaviorSubject<Link> = new BehaviorSubject(null);

  private mainMenuLinks: MainMenuLink[] = mainMenuLinks;
  private menuFooterLinks: MenuFooterLink[] = menuFooterLinks;
  private footerMenuLinks: FooterMenuLink[] = footerMenuLinks;
  private myProfileLinks: MyProfileLink[] = myProfileLinks;
  private activeMenu: Link = this.mainMenuLinks[0] ? this.mainMenuLinks[0] : null;
  private activeRoute: Link = this.mainMenuLinks[0] ? this.mainMenuLinks[0] : null;

  /**
   * コンストラクター関数
   * @param router Angularルーター
   * @param authorityManagerService 権限管理サービス
   * @param dataManagementService データ管理部（共通機能）
   */
  /**
   * Constructor function
   * @param router Angular router
   * @param authorityManagerService Authority manager service
   * @param dataManagementService Data management service（common）
   */
  constructor(
    private router: Router,
    private authorityManagerService: AuthorityManagerService,
    private dataManagementService: DataManagementService,
  ) {
    this.$mainMenuLinksStream.next(this.mainMenuLinks);
    this.$menuFooterLinksStream.next(this.menuFooterLinks);
    this.$footerMenuLinksStream.next(this.footerMenuLinks);
    this.$myProfileLinksStream.next(this.myProfileLinks);

    // ルーターの更新にサブスクライブ
    // Listen to changes from router navigation
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setActiveRoute();
      this.setActiveMenu();
    });

    // アクセスレベルの更新にサブスクライブ
    // Listen to changes from access level
    this.authorityManagerService.accessLevelStream().subscribe(() => this.setMenusAuthority());
  }

  /**
   * 遷移先のURLが利用可能かどうかをチェックする
   * @param url 遷移先の URL
   */
  /**
   * Check whether navigated page is accessible or not
   * @param url Navigated page's URL
   */
  checkUrl(url: string) {
    const activeMenus = this.mainMenuLinks.filter((link) => {
      if (link.url && link.url.indexOf(url) === 0) {
        return link;
      }

      if (link.submenus) {
        const activeSubmenus = link.submenus.filter((submenu) => submenu.url.indexOf(url) === 0);

        if (activeSubmenus.length > 0) {
          return link;
        }
      }
    });

    if (activeMenus.length > 0) {
      return url;
    }

    if (this.mainMenuLinks.length > 0) {
      if (this.mainMenuLinks[0].url) {
        return this.mainMenuLinks[0].url;
      }

      return this.mainMenuLinks[0].submenus[0].url;
    }

    return null;
  }

  /**
   * エラーアイコンの表示、非表示のトグル
   * @param toggled エラーアイコンのトグルフラグ
   */
  /**
   * Toggle between hiding and showing error icon
   * @param toggled Error icon toggle flag
   */
  toggleErrorIcon(toggled: boolean) {
    this.mainMenuLinks.forEach((menu) => {
      if (menu.submenus) {
        menu.submenus.forEach((submenu) => {
          if (submenu.enableAlert) {
            submenu.isError = toggled;
          }
        });
      } else {
        if (menu.enableAlert) {
          menu.isError = toggled;
        }
      }
    });

    this.$mainMenuLinksStream.next(this.mainMenuLinks);
  }

  /**
   * アップグレードアイコンの表示、非表示トグル
   * @param toggled アップグレードアイコンのトグルフラグ
   * @param allSitesAvailableScreenList 全物件の利用可能画面一覧
   * @param selectedSiteAvailableScreenList 選択中物件の利用可能画面一覧
   */
  /**
   * Toggle between hiding and showing upgrade icon
   * @param toggled Upgrade icon toggle flag
   * @param allSitesAvailableScreenList All sites' accessible screens
   * @param selectedSiteAvailableScreenList Selected site's accessible screens
   */
  toggleUpgradeIcon(
    toggled: boolean,
    allSitesAvailableScreenList: ScreenId[] = [],
    selectedSiteAvailableScreenList: ScreenId[] = allSitesAvailableScreenList,
  ) {
    this.mainMenuLinks
      .filter((menu) => menu.submenus || (!menu.submenus && menu.enablePackageEdit))
      .forEach((menu) => {
        if (menu.submenus) {
          menu.submenus
            .filter((submenu) => submenu.enablePackageEdit)
            .forEach((submenu) => {
              if (submenu.enableSiteSelect) {
                submenu.needUpgrade = toggled
                  ? !selectedSiteAvailableScreenList.includes(submenu.id)
                  : toggled;
              } else {
                submenu.needUpgrade = toggled
                  ? !allSitesAvailableScreenList.includes(submenu.id)
                  : toggled;
              }
            });
        } else {
          if (menu.enableSiteSelect) {
            menu.needUpgrade = toggled
              ? !selectedSiteAvailableScreenList.includes(menu.id)
              : toggled;
          } else {
            menu.needUpgrade = toggled ? !allSitesAvailableScreenList.includes(menu.id) : toggled;
          }
        }
      });

    this.$mainMenuLinksStream.next(this.mainMenuLinks);
  }

  /**
   * 表示中画面を検知
   */
  /**
   * Emit active route
   */
  private setActiveRoute() {
    this.mainMenuLinks.forEach((menu) => {
      if (menu.submenus) {
        menu.submenus.forEach((submenu) => {
          if (submenu.url && submenu.url === this.router.url) {
            this.activeRoute = submenu;
            return;
          }
        });
      }

      if (menu.url && menu.url === this.router.url) {
        this.activeRoute = menu;
        return;
      }
    });

    this.menuFooterLinks.forEach((menu) => {
      if (menu.url && menu.url === this.router.url) {
        this.activeRoute = menu;
        return;
      }
    });

    this.myProfileLinks.forEach((menu) => {
      if (menu.url && menu.url === this.router.url) {
        this.activeRoute = menu;
        return;
      }
    });

    this.$activeRouteStream.next(this.activeRoute);
  }

  /**
   * 選択中メニューを検知
   */
  /**
   * Emit selected menu
   */
  private setActiveMenu() {
    this.mainMenuLinks.forEach((menu) => {
      if (menu.submenus) {
        menu.submenus.forEach((submenu) => {
          if (submenu.isDisplay && submenu.url && submenu.url === this.router.url) {
            this.activeMenu = submenu;
            return;
          }
        });
      }

      if (menu.isDisplay && menu.url && menu.url === this.router.url) {
        this.activeMenu = menu;
        return;
      }
    });

    this.menuFooterLinks.forEach((menu) => {
      if (menu.url && menu.url === this.router.url) {
        this.activeMenu = menu;
        return;
      }
    });

    this.myProfileLinks.forEach((menu) => {
      if (menu.url && menu.url === this.router.url) {
        this.activeMenu = menu;
        return;
      }
    });

    this.$activeMenuStream.next(this.activeMenu);
  }

  /**
   * メニューの権限の設定
   */
  /**
   * Set menu authority
   */
  private setMenusAuthority() {
    this.mainMenuLinks = this.getAuthorizedMainMenuLinks();
    this.$mainMenuLinksStream.next(this.mainMenuLinks);

    this.menuFooterLinks = this.getAuthorizedMenuFooterLinks();
    this.$menuFooterLinksStream.next(this.menuFooterLinks);

    this.myProfileLinks = this.getAuthorizedMyProfileLinks();
    this.$myProfileLinksStream.next(this.myProfileLinks);
  }

  /**
   * 利用可能メインメニューのメニューの取得
   * @returns 利用可能メインメニュー
   */
  /**
   * Get authorized main menu links
   * @returns Authorized main menu links
   */
  private getAuthorizedMainMenuLinks(): MainMenuLink[] {
    return mainMenuLinks
      .map((menu) => {
        if (menu.submenus) {
          return {
            ...menu,
            submenus: menu.submenus.map((submenu) => submenu),
          };
        }
        return { ...menu };
      })
      .filter((menu) => {
        if (menu.id === ScreenId.AirnetService && !settingsJson.showAirNet.display) {
          // エアネット設定が無効の場合は、メインメニューにエアネットサービスを表示しない
          // If the air net setting is disabled, do not display the air net service on the main menu
          return;
        }
        if (menu.submenus) {
          menu.submenus = menu.submenus.filter((submenu) => {
            if (
              this.authorityManagerService.screenAvailable(submenu.id) &&
              this.availableTabletPhone(submenu.id) &&
              this.availableEnergyManagement(submenu.id) &&
              this.availableDemandControl(submenu.id)
            ) {
              if (submenu.id === ScreenId.ErrorNotification) {
                if (!this.dataManagementService.person().errorNotificationSetting) {
                  return;
                }
              }
              return submenu;
            }
          });

          if (menu.submenus.length > 0) {
            return menu;
          }
        } else {
          if (this.authorityManagerService.screenAvailable(menu.id)) {
            return menu;
          }
        }
      });
  }

  /**
   * 利用可能メニューフッターのメニューの取得
   * @returns 利用可能メニューフッター
   */
  /**
   * Get authorized menu footer links
   * @returns Authorized menu footer links
   */
  private getAuthorizedMenuFooterLinks(): MenuFooterLink[] {
    return menuFooterLinks.map((menu) => {
      if (menu.id === ScreenId.Commissioning) {
        menu.isDisplay = this.authorityManagerService.authorityState(
          ScreenId.ScreenCommon,
          ScreenCommonItemId.CommissioningLink,
        );
      }

      if (menu.id === ScreenId.Download) {
        menu.isDisplay = this.authorityManagerService.authorityState(
          ScreenId.ScreenCommon,
          ScreenCommonItemId.DownloadLink,
        );
      }

      return menu;
    });
  }

  /**
   * 利用可能マイプロフィールのメニューの取得
   * @returns 利用可能マイプロフィール
   */
  /**
   * Get authorized my profile links
   * @returns Authorized my profile links
   */
  private getAuthorizedMyProfileLinks(): MyProfileLink[] {
    return myProfileLinks.map((menu) => {
      if (menu.id === ScreenId.Version) {
        menu.isDisplay = this.authorityManagerService.authorityState(
          ScreenId.ScreenCommon,
          ScreenCommonItemId.DisplayVersion,
        );
      }

      return menu;
    });
  }

  /**
   * 対象のサブメニューがタブレット・スマホで利用可能か
   * @param submenuId サブメニューID
   * @returns タブレット・スマホで利用可能か
   */
  /**
   * Is the target submenu available on tablets and smartphones?
   * @param submenuId Submenu ID
   * @returns Can it be used on tablets and smartphones?
   */
  private availableTabletPhone(submenuId: string): boolean {
    if (submenuId === ScreenId.LayoutSettings) {
      if (
        DataManagementService.userAgentType() === AgentType.iPhone ||
        DataManagementService.userAgentType() === AgentType.AndroidPhone
      ) {
        return false;
      }
    }
    if (submenuId === ScreenId.DataOutput || submenuId === ScreenId.OperationDataOutput) {
      const userAgentType = DataManagementService.userAgentType();
      if (
        userAgentType === AgentType.iPhone ||
        userAgentType === AgentType.AndroidPhone ||
        userAgentType === AgentType.iPad ||
        userAgentType === AgentType.Macintosh ||
        userAgentType === AgentType.AndroidTablet
      ) {
        return false;
      }
    }

    return true;
  }

  /**
   * 対象のサブメニューが利用可能か
   * @param submenuId サブメニューID
   * @returns 多物件消費量比較・省エネシミュレーションが利用可能か
   */
  /**
   * Is the target submenu available?
   * @param submenuId Submenu ID
   * @returns Is multi-site consumption comparison and energy-saving simulation available??
   */
  private availableEnergyManagement(submenuId: string): boolean {
    if (submenuId === ScreenId.MultiSiteComparison) {
      return settingsJson.showEnergyManagementFunction.MultiSiteComparison;
    }
    if (submenuId === ScreenId.EnergySavingSimulation) {
      return settingsJson.showEnergyManagementFunction.EnergySavingSimulation;
    }
    return true;
  }

  /**
   * 対象のサブメニューが利用可能か
   * @param submenuId サブメニューID
   * @returns デマンドモード設定が利用可能か
   */
  /**
   * Is the target submenu available?
   * @param submenuId Submenu ID
   * @returns Is demand-mode-settings available??
   */
  private availableDemandControl(submenuId: string): boolean {
    if (submenuId === ScreenId.DemandModeSettings) {
      return settingsJson.showPowerLimitControl.ModeSettings;
    } else {
      return true;
    }
  }

  /**
   * 指定したスクリーンIDのサブメニューを取得する
   * @param screenId スクリーンID
   * @returns サブメニュー ない場合はundefined
   */
  /**
   * Get the submenu of the specified screen ID
   * @param screenId Screen ID
   * @returns Submenu If not, undefined
   */
  getSubMenuLink(screenId: ScreenId): SubMenuLink | undefined {
    return Utility.flatArray(mainMenuLinks.map((mainMenu) => mainMenu.submenus ?? [])).find(
      (subMenu) => subMenu.id === screenId,
    );
  }

  /**
   * メニューの表示状態を更新する
   * @param sites 物件一覧
   */
  /**
   * Update the display status of the menu
   * @param sites Property list
   */
  setDisplayMenu(sites: readonly Building[]) {
    // すべてエアネット専売物件の場合、エネルギーマネージメント 積算時間トレンドは非表示にする
    // Hide if the energy management accumulated time trend is not available
    const allAirNetOnlyBuildings =
      sites.length !== 0 && sites.every((site) => site.packageList.every((p) => p.isAirnet));

    const operationMonitoringMenu = this.getSubMenuLink(ScreenId.OperationMonitoring);
    if (operationMonitoringMenu !== undefined) {
      operationMonitoringMenu.isDisplay = !allAirNetOnlyBuildings;
    }
    this.$mainMenuLinksStream.next(this.mainMenuLinks);
  }
}
