import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import {
  AccessLevel,
  Screen,
  AuthorityState,
  ApiAttributeItem,
  QueryParameter,
  ExclusionParameter,
  AuthorityItem,
  ReferenceRole,
} from './classes';

@Injectable({
  providedIn: 'root',
})
export class AuthorityManagerService {
  private accessLevel: AccessLevel;
  private $accessLevelStream: Subject<void> = new Subject<void>();

  /**
   * アクセスレベルの取得
   * @returns アクセスレベル
   */
  /**
   * Acquisition of access level
   * @returns Access level
   */
  get getAccessLevel() {
    return this.accessLevel;
  }

  /**
   * 渡された権限情報を保存した後に、保存が完了したことを通知する
   * @param accessLevel ログインユーザの権限情報
   */
  /**
   * Save the authority information, and notify the completion of saving
   * @param accessLevel Authority information of login user
   */
  setAccessLevel(accessLevel: AccessLevel) {
    this.accessLevel = accessLevel;
    this.$accessLevelStream.next();
  }

  /**
   * setAccessLevel関数で発行される通知を受け取るためのイベントを取得する
   * @returns 保存完了イベント
   */
  /**
   * Get event to receive notification issued by setAccessLevel function
   * @returns Save complete event
   */
  accessLevelStream(): Observable<void> {
    return this.$accessLevelStream.asObservable();
  }

  /**
   * 部品の利用可否を判定する
   * @param screenId 画面ID
   * @param itemId 部品ID
   * @returns 部品の利用可否
   */
  /**
   * Determining the availability of parts
   * @param screenId Screen ID
   * @param itemId Part ID
   * @returns Availability of parts
   */
  authorityState(screenId: string, itemId: string): boolean {
    const screen: Screen = this.searchScreen(screenId);
    if (screen === null) {
      return false;
    }

    let state: AuthorityState = null;
    screen.items.some((item) => {
      const stateIndex = item.authorityStates.findIndex(
        (authorityState: AuthorityState) => authorityState.itemId === itemId,
      );

      if (stateIndex >= 0) {
        state = item.authorityStates[stateIndex];
        return true;
      }
      return false;
    });

    return state !== null ? state.itemEnable : false;
  }

  /**
   * APIの利用可否を判定する
   * @param screenId 画面ID
   * @param url APIのURL
   * @param method APIのメソッド種別
   * @param queryParams APIのクエリパラメータ
   * @returns APIの利用可否
   */
  /**
   * Determining the availability of API
   * @param screenId Screen ID
   * @param url API URL
   * @param method API method type
   * @param queryParams API query parameters
   * @returns Availability of API
   */
  apiAvailable(
    screenId: string,
    url: string,
    method: string,
    queryParams: QueryParameter[],
  ): boolean {
    const screen: Screen = this.searchScreen(screenId);
    if (screen === null) {
      return false;
    }

    const targetUrls = url.split('/').filter((path) => path !== '');
    const attribute: ApiAttributeItem = this.searchAttribute(
      screen.apiAttributes,
      targetUrls,
      method,
    );
    if (attribute === null) {
      return false;
    }

    let exclusionParameterAvailable = true;
    if (attribute.exclusionParameters.length > 0) {
      const exclusionParameter: ExclusionParameter = this.searchExclusionParameter(
        attribute.exclusionParameters,
        queryParams,
      );

      if (exclusionParameter !== null) {
        exclusionParameterAvailable = exclusionParameter.available;
      }
    }

    return attribute.urlAvailable && exclusionParameterAvailable;
  }

  /**
   * 画面の利用可否を判定する
   * @param screenId 画面ID
   * @returns 画面の利用可否
   */
  /**
   * Determine screen availability
   * @param screenId Screen ID
   * @returns Screen availability
   */
  screenAvailable(screenId: string): boolean {
    const screen: Screen = this.searchScreen(screenId);
    if (screen === null) {
      return true;
    }
    return screen.screenAvailable;
  }

  /**
   * 利用できる機能があるかどうかを判定する
   * @returns 利用できる機能がある場合: true、利用できる機能がない場合: false
   */
  /**
   * Determine if there is a feature available
   * @returns If there is a screen available: true, if there is no screen available: false
   */
  availableApplication(): boolean {
    return this.accessLevel.screens.some((screen) => {
      if (screen.screenType === 'main') {
        return screen.screenAvailable;
      }
    });
  }

  /**
   * ラジオグループで選択されているItemIdを返却する
   * （ラジオグループのみで使用される事を想定）
   * @param screenId 画面ID
   * @param itemName アイテム名
   * @returns 選択されているアイテムID　アイテムIDがなければnullを返却
   */
  /**
   * Return the ItemId selected in the radio group
   * (Assumed to be used only in radio groups)
   * @param screenId Screen ID
   * @param itemName Item name
   * @returns selected item ID returns null if there is no item ID
   */
  radioGroupSelectItemId(screenId: string, itemName: string): string {
    const screen: Screen = this.searchScreen(screenId);
    if (screen === null) {
      return null;
    }

    const authorityItem: AuthorityItem = screen.items.find((item) => {
      return item.itemName === itemName;
    });

    if (authorityItem.itemType !== 'radioGroup') {
      return null;
    }

    for (const authorityState of authorityItem.authorityStates) {
      if (authorityState.itemEnable) {
        return authorityState.itemId;
      }
    }
    return null;
  }

  /**
   * 参照可能なロールを返却する
   * @param screenId 画面ID
   * @returns 参照可能なロール
   */
  /**
   * Return the reference role
   * @param screenId Screen ID
   * @returns Reference role
   */
  getReferenceRole(screenId: string): string[] {
    const referenceRole: ReferenceRole = this.searchScreen(screenId) as ReferenceRole;
    if (referenceRole === null) {
      return null;
    }

    return referenceRole.roleIds ? referenceRole.roleIds : [];
  }

  /**
   * 画面を検索する
   * @param screenId 画面ID
   * @returns 画面ごとの制御項目をまとめたオブジェクト
   */
  /**
   * Search the screen
   * @param screenId Screen ID
   * @returns Object that summarizes the control items for screen
   */
  private searchScreen(screenId: string): Screen {
    const screen: Screen = this.accessLevel.screens.filter(
      (screen) => screen.screenId === screenId,
    )[0];

    if (screen === undefined) {
      return null;
    }
    return screen;
  }

  /**
   * apiAttributeを検索する
   * @param apiAttributes APIの制御項目一覧
   * @param targetUrls APIのURLを/で分解した配列
   * @param method HttpMethod種別
   * @returns apiAttribute
   */
  /**
   * Search for apiAttribute
   * @param apiAttributes List of API control items
   * @param targetUrls An array of API URLs separated by /
   * @param method HttpMethod type
   * @returns apiAttribute
   */
  private searchAttribute(
    apiAttributes: ApiAttributeItem[],
    targetUrls: string[],
    method: string,
  ): ApiAttributeItem {
    let attribute: ApiAttributeItem = null;
    const index = apiAttributes.findIndex((attribute) => {
      const urls = attribute.url.split('/').filter((path) => path !== '');
      if (urls.length !== targetUrls.length || method !== attribute.httpMethod) {
        return false;
      }

      const urlMatch = targetUrls.every((targetUrl, index) => {
        if (urls[index] === undefined) {
          return false;
        }

        if (this.isParameter(urls[index])) {
          return true;
        }
        return targetUrl === urls[index];
      });

      if (urlMatch) {
        return true;
      }
    });

    if (index !== -1) {
      attribute = apiAttributes[index];
    }
    return attribute;
  }

  /**
   * exclusionParameterを検索する
   * @param exclusionParameters クエリ制御項目の一覧
   * @param queryParams 実行クエリ
   * @returns 実行クエリの制御項目
   */
  /**
   * Search for exclusionParameter
   * @param exclusionParameters List of query control items
   * @param queryParams Execution query
   * @returns Control items of execution query
   */
  private searchExclusionParameter(
    exclusionParameters: ExclusionParameter[],
    queryParams: QueryParameter[],
  ): ExclusionParameter {
    let exclusionParameter: ExclusionParameter = null;
    const index: number = exclusionParameters.findIndex((param) => {
      const existQuery: boolean = queryParams.some((queryParam) => {
        if (queryParam.key === param.key) {
          return queryParam.values.some((queryValue) => {
            return param.values.some((value) => value === queryValue);
          });
        }
        return false;
      });

      if (existQuery) {
        return true;
      }
    });

    if (index !== -1) {
      exclusionParameter = exclusionParameters[index];
    }
    return exclusionParameter;
  }

  /**
   * 指定されたパスがパラメータかどうかを判定する
   * @param url APIのパス
   * @returns パスパラメータかどうかの判定結果
   */
  /**
   * Test whether the specified path is a parameter
   * @param url API path
   * @returns Judgment result of whether it is a path parameter
   */
  private isParameter(url: string): boolean {
    const parameterPattern = /\{.+\}/g;
    return parameterPattern.test(url);
  }
}
