import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-address-confirmation',
  templateUrl: './email-address-confirmation.component.html',
  styleUrls: ['./email-address-confirmation.component.scss'],
})
export class EmailAddressConfirmationComponent implements OnInit {
  showCodeView: boolean = false;

  constructor(private route: ActivatedRoute, private loginService: LoginService) {}

  ngOnInit() {
    this.loginService.changeEmailInit();
    this.loginService.changeEmailModel.hashCode = this.route.snapshot.paramMap.get('code');

    this.loginService.changeCodeViewDisplay.subscribe((n) => {
      this.showCodeView = n;
    });
  }
}
