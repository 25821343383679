import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { RestClientBuildingService } from 'src/app/core/services/rest-client';
import { AuthorityManagerService } from 'src/app/shared-main/components/authority-control/authority-manager.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';
import { PackageManagementService } from 'src/app/main/main/services/package-management/package-management.service';

import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { Building, Packages } from 'src/app/core/services/data-management/classes/building-data';
import { Edge } from 'src/app/core/services/data-management/classes/edge-data';
import {
  BuildingBuildingsItem,
  BuildingBuildingGetResponse,
} from 'src/app/core/services/rest-client/interfaces/building-service';
import { ScheduleItemId, UserListItemId } from 'src/app/shared-main/enums/item-id';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { Link } from 'src/app/main/main/services/menu-management/interfaces';
import { CheckActionMethods } from 'src/app/shared-main/interfaces/check-action-methods';
import { Utility } from 'src/app/shared-main/classes/utility';

@Injectable()
export class HeaderService {
  $sitesUnavailableStream: Subject<boolean> = new Subject<boolean>();
  // 物件変更前に行う処理
  // Processing to be performed before changing the property
  beforeUpdatingSite: CheckActionMethods[] = [];
  private siteList: Building[] = [];
  private selectedSite: Building = new Building('', '');
  private activeMenu: Link;
  private enabledDynamicSiteSelectedSetting?: { readonly id: ScreenId; readonly enabled: boolean };

  /**
   * 現在表示している画面の物件選択コンボボックスの表示状態
   * ある画面でsetEnabledDynamicSiteSelected()でtrueを設定した場合、
   * 対象画面ではtrueになる。
   * それ以外の画面ではfalseのまま。
   */
  /**
   * Display status of the currently displayed screen selection combo box
   * If True is set on a setEnabledDynamicSiteSelected () on a certain screen,
   * it becomes true on the target screen.
   * The other screen remains False.
   */
  get enabledDynamicSiteSelected(): boolean {
    return (
      this.activeMenu?.id === this.enabledDynamicSiteSelectedSetting?.id &&
      (this.enabledDynamicSiteSelectedSetting?.enabled ?? false)
    );
  }

  private get enableUserListSiteSelect(): boolean {
    return (
      this.activeMenu &&
      this.activeMenu.id === ScreenId.UserList &&
      this.siteList.length >= 5 &&
      this.authorityManagerService.authorityState(ScreenId.UserList, UserListItemId.TargetUser)
    );
  }

  private get enableSiteSelect(): boolean {
    return (
      !!this.enableUserListSiteSelect ||
      this.enabledDynamicSiteSelected ||
      (!!this.activeMenu && !!this.activeMenu.enableSiteSelect)
    );
  }

  private get enableScheduleAllSitesSelect(): boolean {
    return (
      this.activeMenu &&
      this.activeMenu.id === ScreenId.Schedule &&
      this.siteList.length > 0 &&
      this.authorityManagerService.authorityState(
        ScreenId.Schedule,
        ScheduleItemId.EditMultiSchedule,
      )
    );
  }

  /**
   * コンストラクター関数
   * @param dataManagementService データ管理サービス
   * @param multiLanguageMessageService 多言語対応サービス
   * @param toastService トーストサービス
   * @param loaderService ローダーサービス
   * @param restClientBuildingService Rest通信部 物件情報サービス
   * @param authorityManagerService 権限管理サービス
   * @param menuManagementService メニュー管理サービス
   * @param packageManagementService パッケージ管理サービス
   */
  /**
   * Constructor function
   * @param dataManagementService Data management service
   * @param multiLanguageMessageService Multilingual service
   * @param toastService Toast service
   * @param loaderService Loading animation service
   * @param restClientBuildingService RestClient Building Service
   * @param authorityManagerService Authority manager service
   * @param menuManagementService Menu Management Service
   * @param packageManagementService Package Management Service
   */
  constructor(
    private dataManagementService: DataManagementService,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private restClientBuildingService: RestClientBuildingService,
    private authorityManagerService: AuthorityManagerService,
    private menuManagementService: MenuManagementService,
    private packageManagementService: PackageManagementService,
  ) {
    this.restClientBuildingService.setScreenId(ScreenId.ScreenCommon);

    // 画面切り替えの更新にサブスクライブ
    // Listen to page navigation
    this.menuManagementService.$activeMenuStream.subscribe(
      (activeMenu: Link) => (this.activeMenu = activeMenu),
    );

    // パッケージ更新の通知にサブスクライブ
    // Listen to notifications from package update
    this.packageManagementService.$updatePackagesStream.subscribe(() => this.fetchSiteList());
  }

  /**
   * 物件一覧の取得
   * @param forceParam ログイン以外の場合、キャッシュを利用せずバックエンドから情報を取得する
   */
  /**
   * Fetch sites
   * @param forceParam Other than login, get information from backend without using cache
   */
  fetchSiteList(forceParam: boolean = true) {
    let force = forceParam;
    if (this.dataManagementService.cachesLoginData.buildingList) {
      this.restClientBuildingService.caches.buildingList = Utility.deepCopy(
        this.dataManagementService.cachesLoginData.buildingList,
      );
      force = false;
    }
    this.loaderService.showLoader();

    this.restClientBuildingService.getBuildingBuildings('', false, '', force, true).subscribe(
      (res: Response) => {
        const data: BuildingBuildingsItem[] = res.data ? res.data.items : [];
        this.updateSiteList(
          data.map(
            (site: BuildingBuildingsItem) =>
              new Building(
                site.buildingId,
                site.buildingName,
                '',
                [],
                site.packageList.map(
                  (packages) =>
                    new Packages(
                      packages.packageId,
                      packages.status,
                      packages.expirationDate,
                      packages.expired,
                      packages.isAirnet,
                    ),
                ),
                '',
                site.operationStatusColor,
              ),
          ),
        );

        this.loaderService.hideLoader();
      },
      (err: Response) => {
        if (err.data.errors) {
          switch (err.status) {
            case 400:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidNoSiteViewable'),
              );
              break;
            case 403:
              if (err.data.errors.some((error) => error.code === 'BEW90001')) {
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary('sidSystemError'),
                );
              } else {
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary('sidNoSiteViewable'),
                );
              }
              break;
            case 408:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidServerErrorOccurred'),
              );
              break;
          }
        }

        this.updateSiteList([]);

        this.loaderService.hideLoader();
      },
    );
  }

  /**
   * 物件一覧の更新
   * @param siteList 物件一覧
   */
  /**
   * Update sites
   * @param siteList sites
   */
  updateSiteList(siteList: Building[] = this.dataManagementService.buildingList()) {
    this.siteList = siteList;
    if (this.enableScheduleAllSitesSelect) {
      if (!this.siteList.find((site) => site.id === 'ALL')) {
        // 全物件選択できる画面に遷移時に、データ管理部の物件一覧の先頭にALLを追加
        // Prepend ALL to building list when navigating to all sites selectable pages
        this.siteList.unshift(
          new Building('ALL', this.multiLanguageMessageService.dictionary('sidUCAll')),
        );
      }
    } else {
      if (this.siteList.find((site) => site.id === 'ALL')) {
        // 全物件選択できない画面に遷移時に、データ管理部の物件一覧からALLを削除
        // Remove ALL from building list when navigating to all sites non-selectable pages
        this.siteList.shift();
      }
    }

    this.dataManagementService.setBuildingList(this.siteList);
    this.checkSiteAvailability();
  }

  /**
   * 物件の取得
   * @param selectedSite 選択中物件
   */
  /**
   * Fetch site
   * @param selectedSite Selected site
   */
  fetchSite(selectedSite: Building) {
    if (this.dataManagementService.cachesLoginData.buildings) {
      const building = this.dataManagementService.cachesLoginData.buildings.find(
        (building) => building.buildingId === selectedSite.id,
      );
      this.restClientBuildingService.caches.buildings = building
        ? [Utility.deepCopy(building)]
        : [];
    }

    if (!selectedSite.id || selectedSite.id === 'ALL' || selectedSite.id === 'NONE') {
      this.updateSelectedSite(selectedSite);
      return;
    }

    this.loaderService.showLoader();

    this.restClientBuildingService.getBuildingBuildings(selectedSite.id, true).subscribe(
      (res: Response) => {
        const data: BuildingBuildingGetResponse = res.data;
        const edges = data.edgeList.map((edge) => new Edge(edge.edgeId, edge.edgeName));
        edges.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        this.updateSelectedSite(
          new Building(
            selectedSite.id,
            selectedSite.name,
            data.currentTime,
            edges,
            selectedSite.packageList,
            '',
            data.operationStatusColor,
          ),
        );

        this.loaderService.hideLoader();
      },
      (err: Response) => {
        if (err.data.errors) {
          switch (err.status) {
            case 400:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidFailedGetSite'),
              );
              break;
            case 403:
              if (err.data.errors.some((error) => error.code === 'BEW90001')) {
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary('sidSystemError'),
                );
              } else {
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary('sidFailedGetSite'),
                );
              }
              break;
            case 404:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidFailedGetSite'),
              );
              break;
            case 408:
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidServerErrorOccurred'),
              );
              break;
          }
        }

        this.loaderService.hideLoader();
      },
    );
  }

  /**
   * 選択中物件の更新
   * @param selectedSite 選択中物件
   */
  /**
   * Update selected site
   * @param selectedSite Selected site
   */
  updateSelectedSite(selectedSite: Building = this.dataManagementService.building()) {
    this.selectedSite = selectedSite;
    // 物件選択できない画面に遷移時に、データ管理部のカレント物件をNONEとして保持(自動ログインによる物件情報取得時を除く)
    // Set current building to NONE when navigating to site non-selectable pages(Except when acquiring a building information by automatic login)
    if (!this.enableSiteSelect) {
      this.selectedSite = new Building('NONE', '');
    }

    // 物件選択できない画面から物件選択できる画面に遷移時に、データ管理部のカレント物件を以下の条件で保持する。
    // 1. 以前選択した物件が存在しNONEでない場合、以前選択していた物件をデータ管理部のカレント物件として保持。
    // 2. 1を満たさない場合、物件一覧の一番最初の物件が取得できればデータ管理部のカレント物件として保持。
    // When transitioning from a screen where you cannot select a property to a screen where you can select a property, hold the current property in the data management section under the following conditions.
    // 1. If the previously selected property exists and is not NONE, the previously selected property is retained as the current property in the data management section.
    // 2. If 1 is not satisfied, if the first property in the property list can be acquired, it will be retained as the current property of the data management department.
    if (this.enableSiteSelect && this.selectedSite.id === 'NONE') {
      const prevBuilding = this.dataManagementService.prevBuilding();
      if (prevBuilding.id && prevBuilding.id !== 'NONE') {
        this.selectedSite = prevBuilding;
      } else {
        const firstBuilding = this.dataManagementService.getFirstBuilding();
        if (firstBuilding !== undefined) {
          this.selectedSite = firstBuilding;
        }
      }
    }
    this.dataManagementService.setBuilding(this.selectedSite);
  }

  /**
   * 物件がないか確認
   */
  /**
   * Check sites availability
   */
  private checkSiteAvailability() {
    const isSiteUnavailable =
      (!!this.activeMenu.enableSiteSelect || !!this.activeMenu.enablePackageEdit) &&
      this.siteList.length <= 0;
    this.$sitesUnavailableStream.next(isSiteUnavailable);
  }

  /**
   * コンポーネント内で動的に物件選択コンボボックスの表示切替をする。
   * この関数を使用して切り替える場合はLinkのenableSiteSelectはfalseを設定する必要がある。
   * @param enabled 物件選択コンボボックスの表示状態
   */
  /**
   * Dynamically switch the display of the property selection combo box in the component.
   * When switching using this function, Link's enableSiteSelect needs to set up.
   * @param enabled Property selection combo box display status
   */
  setEnabledDynamicSiteSelected(enabled: boolean): void {
    this.enabledDynamicSiteSelectedSetting = {
      id: this.activeMenu.id,
      enabled,
    };
    this.updateSelectedSite();
  }
}
