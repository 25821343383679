import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { DemandHistoryGetRequest } from '../interfaces/demand-service/demand-history';

const pathOfDemand = `demand/${apiVersion}/`;

@Injectable()
export class RestClientDemandService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /**
   * デマンド制御設定取得API
   * response body: Observable<Response>
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Demand control settings acquisition API
   * response body: Observable<Response>
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getDemandControlSettings(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDemand}edges/${edgeId}/demandData`,
    );
  }

  /**
   * デマンド制御設定変更API
   * response body: Observable<Response>
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Demand control settings update API
   * response body: Observable<Response>
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  putDemandControlSettings(edgeId: string, param: any): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfDemand}edges/${edgeId}/demandData`,
      param,
    );
  }

  /**
   * デマンド履歴取得API
   * @param edgeId エッジID
   * @param period 期間
   * @param targetDate 取得対象期間
   * @return API response
   */
  /**
   * Demand history acquisition API
   * @param edgeId Edge ID
   * @param period period
   * @param targetDate Acquisition target period
   * @return API response
   */
  getDemandHistory(request: DemandHistoryGetRequest): Observable<Response> {
    let query = `?period=${request.period}`;
    if (request.targetDate) {
      query += `&targetDate=${request.targetDate}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDemand}edges/${request.edgeId}/demandHistory${query}`,
    );
  }

  /**
   * 制御状態取得API
   * response body: Observable<Response>
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Current Control State acquisition API
   * response body: Observable<Response>
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getCurrentControlState(edgeId: string, polling: boolean = false): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDemand}edges/${edgeId}/currentControlState`,
      {},
      '',
      polling,
      polling,
    );
  }

  /**
   * デマンド予測状態送信要求API
   * response body: Observable<Response>
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Prediction State Request To Send API
   * response body: Observable<Response>
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  requestToSendPredictionState(
    edgeId: string,
    sendingPeriod: Number,
    polling: boolean = false,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfDemand}edges/${edgeId}/predictionState/requestToSend`,
      { sendingPeriod },
      '',
      polling,
      polling,
    );
  }

  /**
   * 現在状態取得API
   * response body: Observable<Response>
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * get Now Data API
   * response body: Observable<Response>
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getCurrentPredictionState(edgeId: string, polling: boolean = false): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfDemand}edges/${edgeId}/currentPredictionState`,
      {},
      '',
      polling,
      polling,
    );
  }
}
