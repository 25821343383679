import { Injectable } from '@angular/core';

import { RestClient, apiVersion } from '../base/rest-client';

@Injectable()
export class RestClientAdvDiagnosisService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }
}
