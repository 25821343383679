import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import {
  ResponseErrors,
  ResponseError,
} from 'src/app/core/services/rest-client/interfaces/common/response-errors';

/**
 * APIのエラーレスポンスの判定用。
 * APIから返却されるデータ構造が複数パターンあり、その差を考慮して安全に判定できるようにする。
 */
/**
 * For judgment of API error response.
 * There are multiple patterns of data structures returned from the API,
 * and it is possible to make a safe judgment considering the difference.
 */
export class ErrorResponseParser {
  private response: Response;

  /**
   * タイムアウトかどうか
   */
  /**
   * Whether timeout
   */
  public get isTimeout(): boolean {
    return this.response.status === 408;
  }

  /**
   * システムエラーかどうか
   */
  /**
   * Whether it is a system error
   */
  public get isSystemError(): boolean {
    const firstError = this.firstApplicationError;
    if (firstError) {
      if (this.response.status === 403) {
        return firstError.code === 'EZ90030' || firstError.code === 'EZ90031';
      }
    }

    return false;
  }

  /**
   * エラー情報の一覧。
   * Lambdaオーソライザー、API Gatewayのエラーの場合はnullを返す。
   */
  /**
   * A list of error information.
   * If the Lambda authorizer or API Gateway error, return null.
   */
  public get applicationErrors(): ResponseError[] {
    const resErrors = this.response.data as ResponseErrors;
    if (resErrors) {
      return resErrors.errors;
    }

    return null;
  }

  /**
   * 最初のエラー情報。
   * Lambdaオーソライザー、API Gatewayのエラーの場合はnullを返す。
   */
  /**
   * First error information.
   * If the Lambda authorizer or API Gateway error, return null.
   */
  public get firstApplicationError(): ResponseError {
    const errors = this.applicationErrors;
    if (errors && errors.length > 0) {
      return errors[0];
    }

    return null;
  }

  /**
   * コンストラクタ
   * @param response 通信レスポンス
   */
  /**
   * constructor
   * @param response Communication response
   */
  constructor(response: Response) {
    this.response = response;
  }

  /**
   * 指定したエラーコードが最初のエラー情報に一致するかどうか。
   * Lambdaオーソライザー、API Gatewayのエラーの場合はfalseを返す。
   * @param code エラーコード
   */
  /**
   * Whether the specified error code matches the first error information.
   * If the Lambda authorizer or API Gateway error, false is returned.
   * @param code Error code
   */
  public hasErrorCode(code: string): boolean {
    const firstError = this.firstApplicationError;
    if (firstError) {
      return firstError.code === code;
    }

    return false;
  }
}
