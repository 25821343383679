<div class="text-box" [class.disabled]="disabled">
  <ng-container *ngIf="type === 'number'; then number; else text"></ng-container>
  <ng-template #text>
    <input #textboxInput kendoTextBox class="text-box__input" [ngClass]="{
        'has-icon': textBoxIcon || passwordMaskIcon,
        'has-icon--left-aligned': isIconLeftAligned,
        'has-icon--right-aligned': isIconRightAligned,
        'no-validation': novalidation,
        'frame-color' : ngModelChangevalidater
      }" [type]="type" [placeholder]="placeholder" [maxlength]="maxlength" [step]="step"
      [disabled]="disabled" [autocomplete]="enableAutoCompletion ? 'on' : 'off'" [attr.name]="name"
      [ngModel]="value" (ngModelChange)="onInput($event)" (blur)="onBlur()" (focus)="onFocus()" />
    <kendo-popup *ngIf="showPasswordPolicies" class="text-box__password-policies"
      [anchor]="textboxInput" [anchorAlign]="{ horizontal: 'middle', vertical: 'bottom' }"
      [popupAlign]="{ horizontal: 'middle', vertical: 'top' }" [margin]="{ vertical: 15 }"
      [animate]="false" positionMode="absolute"
      (anchorViewportLeave)="showPasswordPolicies = false">
      <div>
        <p>{{ 'sidNeedMeetRequirement' | translate }}</p>
        <div *ngFor="let policy of passwordPolicies" class="text-box__policy"
          [class.invalid]="policy.invalid">
          <div class="text-box__policy-icon k-icon"
            [ngClass]="{ 'k-i-close': policy.invalid, 'k-i-check': !policy.invalid }"></div>
          <p class="text-box__policy-label">{{ policy.label | translate }}</p>
        </div>
      </div>
    </kendo-popup>
  </ng-template>
  <ng-template #number>
    <kendo-numerictextbox class="text-box__input" [ngClass]="{
      'has-icon': textBoxIcon,
      'has-icon--left-aligned': isIconLeftAligned,
      'no-validation': novalidation
    }" [placeholder]="placeholder" [maxlength]="maxlength" [step]="step" [disabled]="disabled"
      [format]="format" [ngModel]="value" [min]="min" [max]="max" [autoCorrect]="autoCorrect"
      (ngModelChange)="onInput($event)" (blur)="onBlur()"
      (focus)="onFocus()"></kendo-numerictextbox>
  </ng-template>
  <div *ngIf="textBoxIcon" class="text-box__icon"
    [class.text-box__icon--left-aligned]="isIconLeftAligned">
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="passwordMaskIcon">
    <div class="text-box__icon" [class.password-mask__icon--right-aligned]="isIconRightAligned">
      <button class="icon-button" (click)="onClickPasswordMaskIcon()">
        <img class="eye-icon"
          [attr.src]="showPassword ? 'assets/icons/eye.svg' : 'assets/icons/eye-closed.svg'"
          alt="eye-icon" />
      </button>
    </div>
  </ng-container>
</div>