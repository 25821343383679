export const localStorageKeys = {
  // 選択実物件ID
  // Selected real buliding ID
  LastSelectedRealBuildingId: 'lastSelectedRealBuildingId',
  // 選択ゾーン情報
  // Selected zone information
  LastSelectedZoneInfo: 'lastSelectedZoneInfo',
  // 収束ゾーン情報
  // Convergence zone information
  LastConvergenceZoneInfo: 'lastConvergenceZoneInfo',
  // 選択スクリーン情報
  // Selection screen information LocalStorage key
  LastSelectedLayoutScreenInfo: 'lastSelectedLayoutScreenInfo',
  // 通知音停止時最終異常発生日付
  // Last error occurrence date when notification sound stops
  LastNotificationSoundStopErrorDate: 'lastNotificationSoundStopErrorDate',
  // 既読エラーIDリスト
  // Read error ID list
  ErrorAlreadyReadIds: 'errorAlreadyReadIds',
  // エネルギーマネージメント 前回選択した情報
  // Energy management Last time selected information
  LastSelectEnergyManagement: 'lastSelectEnergyManagement',
} as const;
