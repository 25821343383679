<form novalidate class="login-confirmation-form form" [formGroup]="loginConfirmationForm">
  <div class="login-confirmation-form form-group title">
    {{ 'sidVerificationCode' | translate }}
  </div>
  <div class="login-confirmation-form form-group" (keyup.enter)="onEnter()">
    <app-text-box type="text" placeholder="{{ 'sidVerificationCode' | translate }}"
      [novalidation]="false" [isIconLeftAligned]="true" [formControl]="confirmationCode">
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>
  <app-form-inline-message *ngIf="submitted && errorMessage"
    class="login-form__group error-message">
    {{ errorMessage }}
  </app-form-inline-message>
  <div class="login-confirmation-form__text-link-area form-group">
    <app-form-link (click)="onBackToLoginClicked()" (keydown)="onKeydown($event)">
      {{ 'sidBackToLogin' | translate }}
    </app-form-link>
  </div>
  <app-button class="login-confirmation-form__button form-button" [disabled]="!isSubmitEnabled"
    (generalButtonClick)="onSubmit()">
    {{ 'sidVerify' | translate }}
  </app-button>
</form>