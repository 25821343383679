<div class="select-box" [class.disabled]="disabled">
  <kendo-dropdownlist #dropdownlist [ngModel]="value" [data]="data" [defaultItem]="defaultItem"
    [disabled]="disabled" [listHeight]="listHeight"
    [popupSettings]="{ popupClass:'select-box__popup' }" [textField]="textField"
    [valueField]="valueField" [valuePrimitive]="valuePrimitive" (ngModelChange)="onChange($event)"
    (blur)="onBlur()" (focus)="selectBoxFocus.emit($event)" (open)="selectBoxOpen.emit($event)"
    (selectionChange)="selectionChange.emit($event)">
    <ng-template kendoDropDownListNoDataTemplate>
      <div class="no-item-display">{{ 'sidNoItemDisplay' | translate }}</div>
    </ng-template>
  </kendo-dropdownlist>
</div>