import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { addYears } from '@progress/kendo-date-math';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';

import settingsJson from 'src/settings.json';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  $showCookieStream: Subject<boolean> = new Subject<boolean>();

  /**
   * コンストラクター関数
   * @param dataManagementService データ管理サービス
   */
  /**
   * Constructor function
   * @param dataManagementService Data management service
   */
  constructor(private dataManagementService: DataManagementService) {}

  /**
   * cookie利用同意状態であることをブラウザのcookieに設定する
   */
  /**
   * Set the cookie usage agreement status in the browser cookie
   */
  setCookieConsentState() {
    if (!(this.dataManagementService.cookie('cookies') === 'true')) {
      this.dataManagementService.setCookie('cookies', 'true', this.calculateAge(), '', '/');
    }

    // 同意状態を設定したときから、GoogleTagManagerのタグをページに設定する
    // Set the tag of GoogleTagManager on the page after setting the consent status
    this.setGoogleTagManager();
  }

  /**
   * 現在のcookie同意有無の状態を取得する
   */
  /**
   * Get current cookie consent status
   */
  getCookieConsentState(): boolean {
    return this.dataManagementService.cookie('cookies') === 'true';
  }

  /**
   * GoogleTagManagerのタグをページに設定する
   */
  /**
   * Set the tag of GoogleTagManager to the page
   */
  setGoogleTagManager() {
    const googleTagScript = document.createElement('script');
    googleTagScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push\
        ({'gtm.start': new Date().getTime(),event:'gtm.js'});\
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s)\
      ,dl=l!='dataLayer'?'&l='+l:'';\
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='\
      +i+dl;f.parentNode.insertBefore(j,f);})\
      (window,document,'script','dataLayer','${settingsJson.accountInfo.id}');`;
    const headTag = document.getElementsByTagName('head').item(0);
    headTag.appendChild(googleTagScript);

    const googleTagNoScript = document.createElement('noscript');
    googleTagNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${settingsJson.accountInfo.id}"\
       class="google-tag-manager"></iframe>`;
    const bodyTag = document.getElementsByTagName('body').item(0);
    bodyTag.appendChild(googleTagNoScript);
  }

  /**
   * cookie利用同意状態をストリームに流す
   */
  /**
   * Cookie usage agreement is streamed to the stream
   */
  showCookieDialog() {
    this.$showCookieStream.next(true);
  }

  /**
   * クッキーの有効期限の計算
   * @returns クッキーの有効期限
   */
  /**
   * Calculate cookie age
   * @returns Cookie age
   */
  calculateAge(): string {
    const today: Date = new Date();
    return `${(addYears(today, 1).valueOf() - today.valueOf()) / 1000}`;
  }
}
