import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { MultiSiteBuildingStatusGetResponse } from '../interfaces/multisite-service/multisite';

const pathOfMultisite = `multisite/${apiVersion}/`;

@Injectable()
export class RestClientMultiSiteService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-15. 多物件遠隔監視サービス
  //  3-15. Multi-Property Remote Monitoring Service
  /////////////////////////////////////////////////////////////////////////////
  //  3-15-1.多物件遠隔監視管理
  //  3-15-1. Remote monitoring and management of multiple properties
  /////////////////////////////////////////////////////////////////////////////

  /**
   * マップキー取得API
   * response body: apiKey(string)
   *
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Map key acquisition API
   * response body: apiKey(string)
   *
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiSiteMapKey(): Observable<Response> {
    return this.restClientCommonService.request('get', `${this.endPoint}${pathOfMultisite}mapkey`);
  }

  /**
   * 物件状態確認API
   * response body: MultiSiteBuildingStatusGetResponse
   *
   * @param {string} personId 人ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Property status confirmation API
   * response body: MultiSiteBuildingStatusGetResponse
   *
   * @param {string} personId Person ID
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiSiteBuildingStatus(
    personId: string,
  ): Observable<Response<MultiSiteBuildingStatusGetResponse>> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfMultisite}siteStates/${personId}`,
    );
  }

  /**
   * エッジ状態確認API
   * response body: MultiSiteEdgeStatusGetResponse
   *
   * @param {string} buildingId 物件ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge status confirmation API
   * response body: MultiSiteEdgeStatusGetResponse
   *
   * @param {string} buildingId Property ID
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiSiteEdgeStatus(buildingId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfMultisite}edgeStates/${buildingId}`,
    );
  }

  /**
   * 機器状態確認API
   * response body: MultiSiteEquipmentStatusGetResponse
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device status confirmation API
   * response body: MultiSiteEquipmentStatusGetResponse
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getMultiSiteEquipmentStatus(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfMultisite}equipmentStates/${edgeId}`,
    );
  }
}
