import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChangeEmailModel, LanguageModel } from '../interfaces/login-model';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';

@Injectable()
export class LoginService {
  email: string;

  // メール変更情報
  // Email change information
  changeEmailModel: ChangeEmailModel;

  // コード変更
  // change code
  changeCodeViewDisplay: Subject<boolean> = new Subject();

  constructor() {}

  /**
   * メール変更情報の初期化
   */
  /**
   * Initializing email change information
   */
  changeEmailInit() {
    this.changeEmailModel = new ChangeEmailModel();
  }

  /**
   * 言語一覧の取得
   *
   * @param   {MultiLanguageMessageService}  multiLanguage  多言語サービス
   *
   * @return  {[type]}                                      言語一覧
   */
  /**
   * Get language list
   *
   * @param   {MultiLanguageMessageService}  multiLanguage  Multilingual service
   *
   * @return  {[type]}                                      Language list
   */
  asyncGetLanguage(multiLanguage: MultiLanguageMessageService): Promise<LanguageModel> {
    return new Promise<LanguageModel>((resolve) =>
      multiLanguage.getLanguageList().subscribe((response) => {
        const language = new LanguageModel();
        language.languages = response.map((n) => {
          return { localeId: n.localeId, displayName: `${n.languageName}（${n.localeId}）` };
        });
        language.localeId = multiLanguage.localeId;
        resolve(language);
      }),
    );
  }

  /**
   * 言語変更
   *
   * @param   {string}                       localeId       言語ID
   * @param   {MultiLanguageMessageService}  multiLanguage  多言語サービス
   *
   * @return  {Promise<string>}                             言語ID
   */
  /**
   * Change language
   *
   * @param   {string}                       localeId       Language ID
   * @param   {MultiLanguageMessageService}  multiLanguage  Multilingual service
   *
   * @return  {Promise<string>}                             Language ID
   */
  changeLocale(localeId: string, multiLanguage: MultiLanguageMessageService): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      setTimeout(() => {
        multiLanguage.changeLocale(localeId).subscribe(
          (response) => {
            resolve(localeId);
          },
          (err) => {
            resolve(multiLanguage.defaultLocaleId);
          },
        );
      }, 0);
    });
  }
}
