/**
 * 数値判定
 *
 * @param {any}  target 検査対象
 * @return {boolean} 検査結果 true: 数値, false: 非数値
 */
/**
 * Numerical judgment
 *
 * @param {any}  target Inspected target
 * @return {boolean} Test result true: numeric, false: non-numeric
 */
export const isNumber = (target: any): boolean => {
  if (target || target === 0) {
    return true;
  }
  return false;
};
