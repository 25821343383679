import { Component } from '@angular/core';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { LoginService } from '../../services/login.service';
import { ErrorResponseParser } from '../../interfaces/error-response-parser';
import { Utility } from 'src/app/shared-main/classes/utility';
import { ApiType } from 'src/app/core/services/toast/api-type';

@Component({
  selector: 'app-code-confirmation-form',
  templateUrl: './code-confirmation-form.component.html',
  styleUrls: ['./code-confirmation-form.component.scss'],
})
export class CodeConfirmationFormComponent {
  code: string = null;
  errorMessage: string = null;
  submitted: boolean = false;
  flag: boolean = false;

  get enableConfirmationButton(): boolean {
    if (this.code) {
      return true;
    }
    return false;
  }

  constructor(
    private restClientAuthService: RestClientAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private multiLanguage: MultiLanguageMessageService,
    private loginService: LoginService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private multiLanguageService: MultiLanguageMessageService,
  ) {}

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    if (this.enableConfirmationButton) {
      this.onSubmit();
    }
  }

  /**
   * 確認ボタンがクリックされたとき
   */
  /**
   * When the confirmation button is clicked
   */
  onSubmit() {
    this.errorMessage = null;
    this.submitted = true;

    this.loaderService.showLoader();
    this.restClientAuthService.authSmsAuth(this.code).subscribe(
      () => {
        this.restClientAuthService
          .postAuthConfirmChangeEmail(this.loginService.changeEmailModel.hashCode)
          .subscribe(
            () => {
              this.loaderService.hideLoader();
              this.navigateToLoginPage();
            },
            (error: Response) => {
              this.loaderService.hideLoader();
              this.handlePostAuthConfirmChangeEmailError(error);
            },
          );
      },
      (error: Response) => {
        this.loaderService.hideLoader();
        this.handleAuthSmsAuthError(error);
      },
    );
  }

  /**
   * authSmsAuthのエラーレスポンスを処理する
   *
   * @param  response    エラーレスポンス
   */
  /**
   * Handle authSmsAuth error response
   *
   * @param  response  Error response
   */
  private handleAuthSmsAuthError(response: Response) {
    const resErrors = response.data as ResponseErrors;
    const firstError = resErrors.errors[0];

    if (response.status === 408) {
      this.errorMessage = this.multiLanguage.dictionary('sidServerErrorOccurred');
      this.showErrorToast('sidServerErrorOccurred');
      return;
    }

    if (response.status === 400 && firstError.code === 'NotAuthorizedException') {
      this.errorMessage = this.multiLanguage.dictionary('sidExpireVerifyCode');
    } else if (response.status === 400 && firstError.code === 'CodeMismatchException') {
      this.errorMessage = this.multiLanguage.dictionary('sidWrongVerificationCode');
    } else {
      // その他のエラー
      // Other errors
      const apiType = ApiType.update;
      const sid = Utility.getErrorMessageSid(response, apiType);
      this.errorMessage = this.multiLanguage.dictionary(sid);
      this.showErrorToast(sid);
    }
  }

  /**
   * postAuthConfirmChangeEmailのエラーレスポンスを処理する
   * @param responseエラーレスポンス
   */
  /**
   * Handle postAuthConfirmChangeEmail error response
   * @param response Error response
   */
  private handlePostAuthConfirmChangeEmailError(response: Response) {
    const errorParser = new ErrorResponseParser(response);
    if (errorParser.isTimeout) {
      this.errorMessage = this.multiLanguage.dictionary('sidServerErrorOccurred');
      this.showErrorToast('sidServerErrorOccurred');
    } else if (errorParser.hasErrorCode('BER00011')) {
      // 有効期限切れの場合
      // if expired
      this.errorMessage = this.multiLanguage.dictionary('sidLinkExpired');
    } else {
      const apiType = ApiType.register;
      const sid = Utility.getErrorMessageSid(response, apiType);
      this.errorMessage = this.multiLanguage.dictionary(sid);
      this.showErrorToast(sid);
    }
  }

  /**
   * ログインに戻るボタンのクリック時
   */
  /**
   * When clicking the button to return to login
   */
  onBackToLoginClicked() {
    // 通常の場合
    // Normal case
    this.navigateToLoginPage();
  }

  /**
   * ログインページに遷移する
   */
  /**
   * Transition to the login page
   */
  private navigateToLoginPage() {
    this.router.navigate(['/login'], { relativeTo: this.route });
  }

  /**
   * エラートースト表示
   *
   * @param   {string}  resourceId  リソースID
   */
  /**
   * Error toast display
   *
   * @param   {string}  resourceId  Resource ID
   */
  private showErrorToast(resourceId: string) {
    this.toastService.openToast(
      'error',
      'center',
      this.multiLanguageService.dictionary(resourceId),
    );
  }

  /**
   * 入力キーの確認
   *
   * @param   {[type]}  e  キー
   */
  /**
   * Confirm input key
   *
   * @param   {[type]}  e  Key
   */
  onKeydown(e) {
    if (e.keyCode === 13) {
      this.onBackToLoginClicked();
    }
  }
}
