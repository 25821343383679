<div class="service-unavailable">
  <div class="service-unavailable__text-wrapper">
    <p class="service-unavailable__text service-unavailable__text--header">
      {{ 'sidNotAccessFeature' | translate }}
    </p>
    <p class="service-unavailable__text service-unavailable__text--subheader">
      <ng-container *ngIf="isUpgradePackageSite">
        {{ 'sidUpgradePackageSite' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradePackageEnergy">
        {{ 'sidUpgradePackageEnergy' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradePackageGeography">
        {{ 'sidUpgradePackageGeography' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeDataCollection">
        {{ 'sidUpgradeDataCollection' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeDataOutput">
        {{ 'sidUpgradeDataOutput' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeDemandControl">
        {{ 'sidUpgradeDemandControl' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeHistory">
        {{ 'sidUpgradeHistory' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradePpdCollection">
        {{ 'sidUpgradePPD' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeMailNotification">
        {{ 'sidUpgradePackageEmergencyOperation' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeMailNotificationSetting">
        {{ 'sidUpgradeMailNotificationSetting' | translate }}
      </ng-container>
      <ng-container *ngIf="isUpgradeRefrigerantLeakNotification">
        {{ 'sidUpgradePacakgeRefrigerantLeakNotification' | translate}}
      </ng-container>
      <ng-container *ngIf="isUpgradeToUsePreseasonRemoteInspection">
        {{ 'sidUpgradeYourPackageToUsePreseasonRemoteInspection' | translate}}
      </ng-container>
    </p>
    <p class="service-unavailable__text">{{ 'sidSwitchLocalFromCloud' | translate }}</p>
  </div>
  <div class="service-unavailable__image {{ activeMenu.iconId }}" [ngClass]="{
      'service-unavailable__image--pc': isAgentTypePC,
      'service-unavailable__image--tab': isAgentTypeTablet,
      'service-unavailable__image--sp': isAgentTypeSmartPhone
    }"></div>
</div>