import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Breadcrumb } from 'src/app/main/main/components/breadcrumb/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  $breadcrumbsStream: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject([]);

  /**
   * コンストラクター関数
   * @param router Angularルーター
   * @param activatedRoute Angular アクティベートルーター
   */
  /**
   * Constructor function
   * @param router Angular router
   * @param activatedRoute Angular activatedRoute
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setBreadcrumbs(this.generateBreadcrumbs().filter((breadcrumb) => breadcrumb.label));
    });
  }

  /**
   * パンくずリストを設定する
   * @param breadcrumbs パンくずリスト
   */
  /**
   * Set breadcrumbs list
   * @param breadcrumbs Breadcrumbs
   */
  private setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.$breadcrumbsStream.next(breadcrumbs);
  }

  /**
   * 設定したパンくずリストにラベルとURLを付随して生成する
   * @param route ルーター
   * @param baseUrl 遷移先のURL
   * @param breadcrumbs パンくずリスト
   * @returns パンくずリスト
   */
  /**
   * Generate a breadcrumb list with a label and URL attached
   * @param route route
   * @param baseUrl Base URL
   * @param breadcrumbs Breadcrumbs
   * @returns Breadcrumbs
   */
  private generateBreadcrumbs(
    route: ActivatedRoute = this.activatedRoute.root,
    baseUrl: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const routeChildren: ActivatedRoute[] = route.children;

    if (routeChildren.length === 0) {
      return breadcrumbs;
    }

    // パンくずリストにラベルとURLを付随する
    // Attaching labels and URLs to breadcrumbs
    for (const routeChild of routeChildren) {
      const label = routeChild.snapshot.data.breadcrumb;
      const url = `${baseUrl}/${routeChild.snapshot.url}`;

      const hasParent = routeChild.parent.snapshot.url.length > 0;
      const hasChildren = routeChild.children.length > 0;

      breadcrumbs.push({
        label,
        url: hasParent && hasChildren ? url : '',
      });

      return this.generateBreadcrumbs(routeChild, url, breadcrumbs);
    }
  }
}
