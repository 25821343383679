import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ResetPasswordMailComponent } from './reset-password-mail/reset-password-mail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginConfirmationComponent } from './login-confirmation/login-confirmation.component';
import { SignUpConfirmationComponent } from './sign-up-confirmation/sign-up-confirmation.component';
import { PasswordConfirmationComponent } from './password-confirmation/password-confirmation.component';
import { EmailAddressConfirmationComponent } from './email-address-confirmation/email-address-confirmation.component';
import { MainLoginComponent } from '../main/main-login/main-login.component';
import { LoginFormComponent } from './login/components/login-form/login-form.component';
import { ResetPasswordFormComponent } from './reset-password/components/reset-password-form/reset-password-form.component';
import { ResetPasswordMailFormComponent } from './reset-password-mail/components/reset-password-mail-form/reset-password-mail-form.component';
import { UserConfirmationComponent } from './email-address-confirmation/user-confirmation-form/user-confirmation.component';
import { CodeConfirmationFormComponent } from './email-address-confirmation/code-confirmation-form/code-confirmation-form.component';

const routes: Routes = [
  {
    path: '',
    component: MainLoginComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'login-confirmation',
        component: LoginConfirmationComponent,
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'reset-password-mail',
        component: ResetPasswordMailComponent,
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'sign-up-confirmation',
        component: SignUpConfirmationComponent,
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'password-confirmation',
        component: PasswordConfirmationComponent,
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'email-address-confirmation/:code',
        component: EmailAddressConfirmationComponent,
        data: {
          breadcrumb: '',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}

export const routingComponents = [
  LoginComponent,
  LoginConfirmationComponent,
  ResetPasswordMailComponent,
  ResetPasswordComponent,
  SignUpConfirmationComponent,
  PasswordConfirmationComponent,
  EmailAddressConfirmationComponent,
  MainLoginComponent,
  ResetPasswordMailFormComponent,
  ResetPasswordFormComponent,
];
