import { last } from 'rxjs/operators';
import { Component, OnInit, DoCheck } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Language } from 'src/app/core/services/multi-language-message/classes/language';

import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit, DoCheck {
  template: string;

  private localeId: string = this.dataManagementService.localStorage('localeId');
  private language: string = this.dataManagementService.localStorage('dictionaryLanguage');
  private localeIds: string[] = [];

  /**
   * コンストラクター関数
   * @param http Http クライアント
   * @param multiLanguageMessageService 多言語対応サービス
   * @param dataManagementService データ管理サービス
   */
  /**
   * Constructor function
   * @param http Http Client
   * @param multiLanguageMessageService Multilingual service
   * @param dataManagementService Data management service
   */
  constructor(
    private http: HttpClient,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private dataManagementService: DataManagementService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 言語の更新にサブスクライブ（最終更新）
    // Listen to changes from language data (last change)
    this.multiLanguageMessageService
      .getLanguageList()
      .pipe(last())
      .subscribe((languages: Language[]) => {
        this.localeIds = languages.map((language: Language) => language.localeId);
        this.setTemplate();
      });
  }

  /**
   * Angular Life Cycle Method
   */
  ngDoCheck() {
    if (
      this.localeId !== this.dataManagementService.localStorage('localeId') ||
      this.language !== this.dataManagementService.localStorage('dictionaryLanguage')
    ) {
      this.localeId = this.dataManagementService.localStorage('localeId');
      this.language = this.dataManagementService.localStorage('dictionaryLanguage');
      this.setTemplate();
    }
  }

  /**
   * 現在選択されている言語に応じて外部ファイルから画面を設定する
   */
  /**
   * Set the screen from an external file according to the currently selected language
   */
  setTemplate() {
    const templateUrl: string =
      this.multiLanguageMessageService.dictionary('sidDescriptionTemplate');

    // Assets から外部ファイルを取得
    // Grab HTML external file from assets
    if (templateUrl !== '-' && this.localeIds.length > 0 && !this.localeIds.includes(templateUrl)) {
      this.http.get(templateUrl, { responseType: 'text' }).subscribe(
        (template: string) => (this.template = template),
        () => (this.template = this.localeId),
      );
    }
  }
}
