import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';

import { LoginRoutingModule, routingComponents } from './login-routing.module';
import { SharedPreparationModule } from 'src/app/shared-preparation/shared-preparation.module';

import { FooterMenuComponent } from 'src/app/main/main-login/components/footer-menu/footer-menu.component';
import { DescriptionComponent } from 'src/app/main/main-login/components/description/description.component';
import { LoginFormComponent } from './login/components/login-form/login-form.component';
import { ResetPasswordMailFormComponent } from './reset-password-mail/components/reset-password-mail-form/reset-password-mail-form.component';
import { ResetPasswordFormComponent } from './reset-password/components/reset-password-form/reset-password-form.component';
import { FormComponent } from './components/form/form.component';
import { FormInlineMessageComponent } from './components/form-inline-message/form-inline-message.component';
import { FormLinkComponent } from './components/form-link/form-link.component';
import { LoginConfirmationFormComponent } from './login-confirmation/components/login-confirmation-form/login-confirmation-form.component';
import { RestClientAuthService } from '../core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { LoginRestClientService } from './services/login-rest-client.service';
import { LoginService } from './services/login.service';
import { CodeConfirmationFormComponent } from './email-address-confirmation/code-confirmation-form/code-confirmation-form.component';
import { UserConfirmationComponent } from './email-address-confirmation/user-confirmation-form/user-confirmation.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    InputsModule,
    LoginRoutingModule,
    PopupModule,
    ReactiveFormsModule,
    SharedPreparationModule,
  ],
  declarations: [
    routingComponents,
    LoginFormComponent,
    ResetPasswordMailFormComponent,
    ResetPasswordFormComponent,
    FormComponent,
    FormInlineMessageComponent,
    FormLinkComponent,
    LoginConfirmationFormComponent,
    FooterMenuComponent,
    DescriptionComponent,
    CodeConfirmationFormComponent,
    UserConfirmationComponent,
  ],
  exports: [
    LoginFormComponent,
    ResetPasswordMailFormComponent,
    ResetPasswordFormComponent,
    FormComponent,
    FormInlineMessageComponent,
    FormLinkComponent,
    FooterMenuComponent,
    DescriptionComponent,
    CodeConfirmationFormComponent,
    UserConfirmationComponent,
  ],
  providers: [
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
    RestClientAuthService,
    LoginRestClientService,
    LoginService,
  ],
})
export class LoginModule {}
