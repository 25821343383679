<div class="code-confirmation-form">
  <div class="code-confirmation-form__title">
    {{ multiLanguageService.dictionary('sidPleaseInputPass') }}
  </div>

  <div class="code-confirmation-form__group" (keyup.enter)="onEnter()">
    <app-text-box
      type="password"
      placeholder="{{ multiLanguageService.dictionary('sidPassword') }}"
      [novalidation]="false"
      [isIconLeftAligned]="true"
      [formControl]="password"
      [isIconRightAligned]="true"
      [passwordMaskIcon]="true"
    >
      <div #textBoxIcon class="k-icon k-i-lock"></div>
    </app-text-box>
  </div>

  <!-- <div class="code-confirmation-form__language">
    <app-select-box [data]="languages" [textField]="'displayName'" [valueField]="'localeId'"
      [valuePrimitive]="true" [ngModel]="localeId" (selectionChange)="onSelectionChange($event)">
    </app-select-box>
  </div> -->

  <div class="code-confirmation-form__error-message">
    <app-form-inline-message *ngIf="errorMessage">
      {{ multiLanguageService.dictionary(errorMessage) }}
    </app-form-inline-message>
  </div>
  <div class="code-confirmation-form__button">
    <app-button [disabled]="!enableConfirmButton" (generalButtonClick)="onConfirmClick()">
      {{ multiLanguageService.dictionary('sidConfirm') }}
    </app-button>
  </div>
</div>
