export enum ScreenCommonItemId {
  CommissioningLink = 'commissioningLink',
  DownloadLink = 'downloadLink',
  DisplayError = 'alertBar',
  DisplayVersion = 'version',
  AlertBar = 'forcedStopAtAlertBar',
  DownloadAtAlertBar = 'downloadAtAlertBar',
  MultiFactorAuthentication = 'multiFactorAuthentication',
  DisplayErrorNotification = 'errorNotification',
}
