import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { RestClientAuthService } from 'src/app/core/services/rest-client';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

import { MyProfileLink } from 'src/app/main/main/services/menu-management/interfaces/index';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { Person } from 'src/app/core/services/data-management/classes/person-data';
import settingsJson from 'src/settings.json';
import { UserInfo } from './interfaces';
import { AccountPanelService } from '../../services/account-panel/account-panel.service';

@Component({
  selector: 'app-account-panel',
  animations: [
    trigger('expandCollapse', [
      state('expand', style({ height: '*' })),
      state('collapse', style({ height: 0, padding: 0 })),
      transition('expand <=> collapse', [animate('.3s ease')]),
    ]),
  ],
  templateUrl: './account-panel.component.html',
  styleUrls: ['./account-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountPanelComponent implements OnInit, OnDestroy {
  @ViewChild('accountPanel', { static: true }) private accountPanel: ElementRef;
  @Output() accountPanelSelect: EventEmitter<MyProfileLink> = new EventEmitter<MyProfileLink>();

  userInfo: UserInfo = {
    name: this.dataManagementService.person().name,
    roleName: this.dataManagementService.role().name,
  };
  version: string = settingsJson.versionInfo.version;
  isExpanded: boolean = false;
  myProfileLinks: MyProfileLink[];

  private $destroyStream: Subject<void> = new Subject<void>();

  /**
   * コンストラクター関数
   * @param router Angularルーター
   * @param dataManagementService データ管理サービス
   * @param multiLanguageMessageService 多言語対応サービス
   * @param restClientAuthService Rest通信部 認証サービス
   * @param menuManagementService メニュー管理サービス
   * @param loaderService ローダーサービス
   * @param toastService トーストサービス
   * @param accountPanelService アカウントパネルサービス
   */
  /**
   * Constructor function
   * @param router Angular router
   * @param dataManagementService Data management service
   * @param multiLanguageMessageService Multilingual service
   * @param restClientAuthService RestClient Authentication Service
   * @param menuManagementService Menu Management Service
   * @param loaderService Loading animation service
   * @param toastService Toast service
   * @param accountPanelService account panel service
   */
  constructor(
    private router: Router,
    private dataManagementService: DataManagementService,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private restClientAuthService: RestClientAuthService,
    private menuManagementService: MenuManagementService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private accountPanelService: AccountPanelService,
  ) {
    this.restClientAuthService.setScreenId(ScreenId.ScreenCommon);
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 人情報の更新にサブスクライブ
    // Listen to changes from person data
    this.dataManagementService.personStream.pipe(takeUntil(this.$destroyStream)).subscribe(
      (person: Person) =>
        (this.userInfo = {
          name: person.name,
          roleName: this.dataManagementService.role().name,
        }),
    );

    // マイプロフィールメニューの更新にサブスクライブ
    // Listen to changes from my profile menus
    this.menuManagementService.$myProfileLinksStream
      .pipe(takeUntil(this.$destroyStream))
      .subscribe((myProfileLinks: MyProfileLink[]) => (this.myProfileLinks = myProfileLinks));

    // 画面切り替えの更新にサブスクライブ
    // Listen to page navigation
    this.menuManagementService.$activeMenuStream.subscribe(() => {
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    });
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    this.$destroyStream.next();
  }

  /**
   * クリックされたメニュー項目に応じて処理を実施する
   * @param menu クリックされたメニューの項目
   */
  /**
   * Processing is performed according to the clicked menu item.
   * @param menu Clicked menu item
   */
  async onSelect(menu: MyProfileLink) {
    this.isExpanded = !this.isExpanded;

    if (menu.id === ScreenId.Logout) {
      // ログアウトを中止させるかのチェックを行う
      // Check whether to cancel the log out
      for (const methods of this.accountPanelService.beforeLogout) {
        const result = await methods.checkMethod().toPromise();
        // どれか一つ通らなかった場合、ログアウトを中止する
        // If not pass any one, cancel the building change
        if (!result) {
          return;
        }
        methods.actionMethod();
      }
      this.loaderService.showLoader();

      this.restClientAuthService.authLogout(true).subscribe(
        (res: Response) => {
          this.dataManagementService.setIsLogin(false, true, this.restClientAuthService.caches);

          this.router.navigate(['login']);

          this.loaderService.hideLoader();
        },
        (err: Response) => {
          if (err.data.errors) {
            switch (err.status) {
              case 408:
                this.toastService.openToast(
                  'error',
                  'center',
                  this.multiLanguageMessageService.dictionary('sidServerErrorOccurred'),
                );
            }
          }

          this.loaderService.hideLoader();
        },
      );
    } else {
      this.accountPanelSelect.emit(menu);
    }
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、フォーカスの当たっているメニュー項目に応じて処理を実施する
   * @param event キーダウンイベント
   * @param menu メニュー項目
   */
  /**
   * When the enter key or space key is pressed,
   * processing is performed according to the focused menu item
   * @param event Key down event
   * @param menu menu item
   */
  onSelectKeyDown(event: any, menu: MyProfileLink) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onSelect(menu);
    }
  }

  /**
   * ユーザ情報メニュー以外の要素をクリックしたとき、メニューを閉じる
   * @param event マウスイベント
   */
  /**
   * When an element other than the user information menu is clicked, the menu is closed
   * @param event MouseEvent
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if ((this.accountPanel.nativeElement as HTMLElement).contains(event.target as Node)) {
      return;
    }

    this.isExpanded = false;
  }

  /**
   * ユーザ情報メニュー以外の要素でエンターキーもしくはスペースキーが押下されたとき、メニューを閉じる
   * @param event キーダウンイベント
   */
  /**
   * Close the menu when the enter key or space key is pressed on an element other
   * than the user information menu
   * @param event Key down event
   */
  @HostListener('document:keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (
      (event.code === 'Space' || event.code === 'Enter') &&
      !(this.accountPanel.nativeElement as HTMLElement).contains(event.target as Node)
    ) {
      this.isExpanded = false;
    }
  }

  /**
   * マウスクリックされたとき、ユーザ情報メニューの開閉を行う
   */
  /**
   * Opens / closes the user information menu when the mouse is clicked
   */
  onToggleMenu() {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * エンターキーもしくはスペースキーが押下されたとき、ユーザ情報メニューの開閉を行う
   * @param event キーダウンイベント
   */
  /**
   * Opens / closes the user information menu when the enter key or space key is pressed
   * @param event Key down event
   */
  onToggleKeyDown(event: any) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.onToggleMenu();
    }
  }
}
