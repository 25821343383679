import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';

import { ToastComponent } from '../../components/toast/toast.component';
import { ToastType, ToastPosition } from '../../components/toast/interfaces/toast-option';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  overlayRef: OverlayRef = null;
  componentRef: ComponentRef<ToastComponent> = null;

  /**
   * コンストラクター
   * @param overlay オーバーレイ
   * @param injector インジェクター
   */
  /**
   * Constructor
   * @param overlay Overlay
   * @param injector Injector
   */
  constructor(private overlay: Overlay, private injector: Injector) {}

  /**
   * トーストを開く
   * @param type トーストのタイプ
   * @param position トーストの配置
   * @param toastMessage トーストのメッセージ
   * @returns トーストを閉じた時の Observable
   */
  /**
   * Opens toast
   * @param type Toast type
   * @param position Toast position
   * @param toastMessage Toast message
   * @returns Observable when toast closes
   */
  openToast(
    type: ToastType = 'success',
    position: ToastPosition = 'center',
    toastMessage: string = '',
  ): Observable<void> {
    const toastComponentPortal = new ComponentPortal(ToastComponent, null, this.injector);

    this.overlayRef = this.overlay.create();
    this.componentRef = this.overlayRef.attach(toastComponentPortal);

    this.componentRef.instance.type = type;
    this.componentRef.instance.position = position;
    this.componentRef.instance.toastMessage = toastMessage;

    this.componentRef.instance.toastClose.subscribe(() => this.closeToast());

    return this.componentRef.instance.toastClose;
  }

  /**
   * トーストを閉じる
   */
  /**
   * Closes toast
   */
  closeToast() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
