import { ErrorNotificationSettingPutRequest } from 'src/app/core/services/rest-client/interfaces/person-service/error-notification-setting-put';

export class Person {
  id: string;
  name: string;
  timeDisplay: string;
  termsOfServiceState: number;
  automaticLogoutTime: number | null;
  errorNotificationSetting: ErrorNotificationSettingPutRequest;
  communicationErrorNotification: boolean;
  buildingIdList: string[];

  /**
   * コンストラクタ
   *
   * @param id 人ID
   * @param name 人名
   * @param timeDisplay 時間表示設定
   * @param termsOfServiceState 利用規約状態
   * @param automaticLogoutTime 自動ログアウト時間
   * @param errorNotificationSetting 異常通知設定
   * @param communicationErrorNotification 通信異常通知設定
   * @param buildingIdList 物件IDリスト
   */
  /**
   * constructor
   *
   * @param id Person ID
   * @param name Person name
   * @param timeDisplay Time display setting
   * @param termsOfServiceState Terms of service status
   * @param automaticLogoutTime Auto logout time
   * @param errorNotificationSetting Error notification
   * @param communicationErrorNotification Communication error notification
   * @param buildingIdList Building ID list
   */
  constructor(
    id: string,
    name: string,
    timeDisplay: string = '',
    termsOfServiceState: number = 0,
    automaticLogoutTime: number | null = 60,
    errorNotificationSetting: ErrorNotificationSettingPutRequest = null,
    communicationErrorNotification: boolean = false,
    buildingIdList: string[] = [],
  ) {
    this.id = id;
    this.name = name;
    this.timeDisplay = timeDisplay;
    this.termsOfServiceState = termsOfServiceState;
    this.automaticLogoutTime = automaticLogoutTime;
    this.errorNotificationSetting = errorNotificationSetting;
    this.communicationErrorNotification = communicationErrorNotification;
    this.buildingIdList = buildingIdList;
  }

  /**
   * IDが有効かチェックする
   *
   * @return チェック結果
   */
  /**
   * Check if id is valid
   *
   * @return Check result
   */
  isValid(): boolean {
    if (this.id) {
      return true;
    }
    return false;
  }

  /**
   * 更新が必要か判定する
   *
   * @param newPerson 新しい人情報
   * @return 判定結果
   */
  /**
   * Determine if an update is required
   *
   * @param newPerson New person information
   * @return judgement result
   */
  isUpdated(newPerson: Person): boolean {
    if (
      newPerson.id !== this.id ||
      newPerson.name !== this.name ||
      newPerson.timeDisplay !== this.timeDisplay ||
      newPerson.termsOfServiceState !== this.termsOfServiceState ||
      newPerson.automaticLogoutTime !== this.automaticLogoutTime ||
      newPerson.errorNotificationSetting !== this.errorNotificationSetting ||
      newPerson.communicationErrorNotification !== this.communicationErrorNotification ||
      this.isUpdatedBuildingList(newPerson.buildingIdList)
    ) {
      return true;
    }
    return false;
  }

  /**
   * 物件リストが更新されたか判定する
   *
   * @param newBuildingList 新しい物件リスト
   * @return 判定結果
   */
  /**
   * Determine if the property list has been updated
   *
   * @param newBuildingList New building list
   * @return judgement result
   */
  private isUpdatedBuildingList(newBuildingList: string[]): boolean {
    if (newBuildingList.length !== this.buildingIdList.length) {
      return true;
    }
    for (let i = 0; i < newBuildingList.length; i += 1) {
      if (newBuildingList.indexOf(this.buildingIdList[i]) < 0) {
        return true;
      }
    }
    return false;
  }
}
