<div class="dialog dialog-overlay-setting"
  [class.dialog-overlay-setting--overlay]="overlayContents">
  <div class="dialog__overlay" (click)="overlayclick.emit()"></div>
  <div [ngStyle]="dialogPopupStyle" class="dialog__popup"
    [ngClass]="{ 'dialog__popup--full': isFullFlexibility, 'dialog__popup--none': isNoneFlexibility }">
    <div *ngIf="title" class="dialog__header">{{ title }}</div>
    <div class="dialog__body"
      [ngClass]="{ 'dialog__body--full': isFullFlexibility, 'dialog__body--none': isNoneFlexibility }">
      <div *ngIf="!componentPortal" class="dialog__body-contents">{{ messages }}</div>
      <div *ngIf="componentPortal" class="dialog__body-contents">
        <ng-template #content [cdkPortalOutlet]="componentPortal"
          (attached)="onAttached($event)"></ng-template>
      </div>
      <ng-content></ng-content>
    </div>
    <div class="dialog__actions">
      <app-button *ngIf="declineButtonLabel" class="dialog__decline" variant="outlined"
        (generalButtonClick)="onDecline()">
        {{ declineButtonLabel }}
      </app-button>
      <app-button *ngIf="confirmButtonLabel" class="dialog__accept"
        [disabled]="disabledConfirmButton" (generalButtonClick)="onAccept()">
        {{ confirmButtonLabel }}
      </app-button>
    </div>
  </div>
</div>