import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe as CommonTranslatePipe } from 'src/app/common/pipes/translate.pipe';
import { MultiLanguageMessageService } from '../../core/services/multi-language-message/multi-language-message.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe extends CommonTranslatePipe implements PipeTransform {
  /**
   * コンストラクタ
   *
   * @param multiLanguageMessageService 多言語サービス
   */
  /**
   * constructor
   *
   * @param multiLanguageMessageService Multi language Service
   */
  constructor(multiLanguageMessageService: MultiLanguageMessageService) {
    super(multiLanguageMessageService);
  }

  /**
   * パイプメイン関数
   *
   * @param value 指定値
   * @param args オプション
   * @return 翻訳結果
   */
  /**
   * Pipe main method
   *
   * @param value Value
   * @param args Option
   * @return Translation wording
   */
  transform(value: string, ...args: (string | number)[]): string {
    return super.transform(value, ...args);
  }
}
