import { Pipe, PipeTransform } from '@angular/core';
import { MultiLanguageMessageService } from '../../core/services/multi-language-message/multi-language-message.service';
import { DateFormats } from '../../core/services/multi-language-message/enums/date-formats.enum';

@Pipe({
  name: 'dateFormatMd',
})
export class DateFormatMdPipe implements PipeTransform {
  /**
   * コンストラクタ
   *
   * @param multiLanguageMessageService 多言語サービス
   */
  /**
   * constructor
   *
   * @param multiLanguageMessageService Multi language Service
   */
  constructor(private multiLanguageMessageService: MultiLanguageMessageService) {}

  /**
   * パイプメイン関数
   *
   * @param value 指定値
   * @param args オプション
   * @return 整形した日付
   */
  /**
   * Pipe main method
   *
   * @param value Value
   * @param args Option
   * @return Formatted date
   */
  transform(value: any, args?: any): any {
    return this.multiLanguageMessageService.formatDateToString(DateFormats.Md, value);
  }
}
