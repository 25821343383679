export enum ErrorState {
  /** メンテナンス */
  /** Maintenance */
  Maintenance = 'maintenance',
  /** システム障害 */
  /** System error */
  SystemError = 'system-error',
  /** クッキーが無効 */
  /** Cookie disabled */
  CookiesDisabled = 'cookies-disabled',
}
