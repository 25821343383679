import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { AuthInitiateAuthResponse } from 'src/app/core/services/rest-client/interfaces/auth-serivce';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { LanguageListItem } from '../interfaces/login-model';
import { LoginService } from '../services/login.service';
import { emailFormatValidator } from 'src/app/shared-preparation/components/text-box/validators/email.validator';
import { CHARS_LENGTH } from 'src/app/shared-main/constants/chars-length';

export class SignUpFormModel {
  constructor(public email?: string, public temporaryPassword?: string, public language?: string) {}
}

@Component({
  selector: 'app-sign-up-confirmation',
  templateUrl: './sign-up-confirmation.component.html',
  styleUrls: ['./sign-up-confirmation.component.scss'],
})
export class SignUpConfirmationComponent implements OnInit {
  signUpForm: UntypedFormGroup;
  signUpFormModel: SignUpFormModel;
  errorMessage: string;
  submitted: boolean = false;
  isLoading: boolean = false;
  languages: LanguageListItem[] = [];
  localeId: string;
  defaultLanguageItem: string = '';
  // メールアドレスの最大文字数
  // Maximum number of characters in an e-mail address
  private readonly emailMaxLength = CHARS_LENGTH.MAX_EMAIL;

  get email(): AbstractControl {
    return this.signUpForm.get('email');
  }

  get temporaryPassword(): AbstractControl {
    return this.signUpForm.get('temporaryPassword');
  }

  get isConfirmEnabled(): boolean {
    return !this.email.errors && this.temporaryPassword.value;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private restClientAuthService: RestClientAuthService,
    private loaderService: LoaderService,
    private loginService: LoginService,
    public multiLanguage: MultiLanguageMessageService,
    private toastService: ToastService,
  ) {}

  /**
   *　データの取得・初期化
   */
  /**
   *　Data acquisition / initialization
   */
  ngOnInit() {
    this.signUpFormModel = new SignUpFormModel();
    this.signUpForm = this.formBuilder.group({
      email: [
        this.signUpFormModel.email,
        [Validators.required, Validators.maxLength(this.emailMaxLength), emailFormatValidator],
      ],
      temporaryPassword: [this.signUpFormModel.temporaryPassword, [Validators.required]],
    });
    this.loadData();
  }

  /**
   * データの読み込み
   * 言語一覧の取得
   */
  /**
   * Loading data
   * Get a list of languages
   */
  async loadData() {
    this.loaderService.showLoader();

    // 言語一覧の取得
    // Get language list
    const languageItem = await this.loginService.asyncGetLanguage(this.multiLanguage);
    this.languages = languageItem.languages;
    this.localeId = languageItem.localeId;
    this.loaderService.hideLoader();
  }

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    if (this.isConfirmEnabled) {
      this.onSubmit();
    }
  }

  /**
   * サインアップ
   */
  /**
   * Sign up
   */
  onSubmit() {
    this.errorMessage = '';
    this.submitted = true;

    this.loaderService.showLoader();

    // 認証開始処理をリクエストする
    // Request authentication start processing
    this.restClientAuthService
      .authInitiateAuth(this.email.value, this.temporaryPassword.value)
      .subscribe(
        (response) => {
          this.loaderService.hideLoader();

          const responseData = response.data as AuthInitiateAuthResponse;
          if (responseData.accountSts === 2) {
            // ユーザー登録確定（パスワード登録）画面に遷移する
            // Transition to the user registration confirmation (password registration) screen
            this.router.navigate(['../password-confirmation'], {
              relativeTo: this.route,
            });
          } else {
            // すでにサインアップ済みのユーザーのためエラーを表示する
            // Show error for already signed up user
            this.errorMessage = 'sidSignUpFailed';
            this.toastService.openToast(
              'error',
              'center',
              this.multiLanguage.dictionary('sidSignUpFailed'),
            );
          }
        },
        (err) => {
          this.loaderService.hideLoader();

          const errorResponse = err as Response;
          this.handleErrorResponse(errorResponse);
        },
      );
  }

  /**
   * ロケールを変更した時
   *
   * @param   {any}  e
   */
  /**
   * When changing locale
   *
   * @param   {any}  e
   */
  async onSelectionChange(e: any) {
    this.loaderService.showLoader();
    this.localeId = await this.loginService.changeLocale(e.localeId, this.multiLanguage);
    this.loaderService.hideLoader();
  }

  /**
   * サインインリクエストのサーバーエラー処理
   *
   * @private
   * @param {Response} response　サーバーレスポンス
   */
  /**
   * Server error handling for sign-in requests
   *
   * @private
   * @param {Response} response　Server response
   */
  private handleErrorResponse(response: Response) {
    const resErrors = response.data as ResponseErrors;
    const firstError = resErrors.errors[0];
    if (
      firstError.code === 'NotAuthorizedException' &&
      firstError.message === 'Temporary password has expired and must be reset by an administrator.'
    ) {
      this.errorMessage = 'sidLinkExpired';
      return;
    }

    if (response.status === 408) {
      this.errorMessage = 'sidServerErrorOccurred';
      this.toastService.openToast(
        'error',
        'center',
        this.multiLanguage.dictionary(this.errorMessage),
      );
    } else {
      this.errorMessage = 'sidSignUpFailed';
      this.toastService.openToast(
        'error',
        'center',
        this.multiLanguage.dictionary(this.errorMessage),
      );
    }
  }
}
