import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewChecked,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { AuthorityManagerService } from 'src/app/shared-main/components/authority-control/authority-manager.service';
import { MainManagementService } from 'src/app/main/main/services/main-management/main-management.service';
import { AlertManagementService } from 'src/app/main/main/services/alert-management/alert-management.service';

import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { ScreenCommonItemId } from 'src/app/shared-main/enums/item-id';
import { AlertBarData, AlertBarErrorType } from './interfaces';

import { EquipmentEvents } from 'src/app/core/services/rest-client/interfaces/equipment-service/equipment-events';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';

const eventCodeUpdateAlert = [
  // 緊急停止
  // Emergency stop
  'USR_NOTICE_EMERGENCY_8301',
  // 集中機器通信状態変化（集中機器組み合わせ異常）
  // Centralized device communication status change (abnormal centralized device combination)
  'SYS_COV_NORMAL_50492',
  // GPFクラウド⇔GPFエッジ間の通信異常
  // Communication error between GPF cloud and GPF edge
  'SYS_COV_EMERGENCY_5016',
  // アドレス重複異常
  // Address duplication error
  'SYS_COV_EMERGENCY_5017',
  // DⅢPort 親重複異常
  // DⅢPort parent duplicate error
  'SYS_COV_EMERGENCY_5018',
  // DⅢプラスアダプタ通信異常発生
  // DⅢ plus adapter communication error
  'SYS_COV_EMERGENCY_5019',
  // 機器通信異常
  // Equipment communication error
  'GPF_COV_NORMAL_6016',
  // 機器異常
  // Equipment error
  'GPF_COV_NORMAL_6017',
  // 外部時刻同期信号異常状態変化イベント
  // External time sync signal error change event
  'SYS_COV_NORMAL_9008',
  // 点検中設定
  // Setting during inspection
  'GPF_COV_NORMAL_6043',
  // エラーコード変化
  // Error code change
  'GPF_COV_NORMAL_6041',
  // R32冷媒漏れ警報状態変化
  // R32 refrigerant leak alarm condition change
  'SYS_NOTICE_NORMAL_E004',
];

@Component({
  selector: 'app-alert-bar',
  animations: [
    trigger('expandCollapse', [
      state('expand', style({ height: '*' })),
      state('collapse', style({ height: 0 })),
      transition('expand <=> collapse', [animate('.3s ease')]),
    ]),
  ],
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
})
export class AlertBarComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('audio', { read: ElementRef }) audio: ElementRef;

  data: AlertBarData = null;
  isShowDetails: boolean = false;
  screenCommonId = ScreenId.ScreenCommon;
  downloadAtAlertBarId = ScreenCommonItemId.DownloadAtAlertBar;
  notificationMessage: string = this.multiLanguageMessageService.dictionary(
    'sidErrorOccurredSoundStop',
  );

  private detailButtonDisabled = false;

  // 異常状態確認API呼び出しの並列処理数
  // Abnormal condition confirmation Number of parallel processing of API calls
  private fetchingStateErrorParallel: number = 0;

  get isDetailButtonDisabled() {
    return this.detailButtonDisabled && this.isTypeError;
  }

  get showEdgeSystemError(): boolean {
    return this.authorityManagerService.authorityState(
      ScreenId.ScreenCommon,
      ScreenCommonItemId.DisplayError,
    );
  }

  get showForcedStopError(): boolean {
    return this.authorityManagerService.authorityState(
      ScreenId.ScreenCommon,
      ScreenCommonItemId.AlertBar,
    );
  }

  get enableStopSoundLink(): boolean {
    return this.alertManagementService.isPlayNoticationSound;
  }
  get showStopSoundLink(): boolean {
    if (!this.alertManagementService.getNotificationSoundAvailable) {
      return false;
    }
    if (this.dataManagementService.person().errorNotificationSetting) {
      return this.dataManagementService.person().errorNotificationSetting.notificationSound;
    }
    return false;
  }
  get isTypeError(): boolean {
    return this.data.type === 'error';
  }
  get hasDetails(): boolean {
    return this.data.details && (!!this.data.details.title || this.data.details.list.length > 0);
  }
  get disableNavigation(): boolean {
    return this.dataManagementService.person().termsOfServiceState === 0;
  }

  /**
   * 縮小かを取得
   *
   * @return 縮小か
   */
  /**
   * Acquired whether it is reduced
   *
   * @return Is it reduced?
   */
  get isZoomOut(): boolean {
    if (!this.data || !this.data.hasOwnProperty('type')) {
      return false;
    }
    const managementService = this.isTypeError
      ? this.alertManagementService
      : this.mainManagementService;
    return !!managementService.isZoomOut;
  }

  /**
   * 縮小かを設定
   *
   * @param value 縮小か
   */
  /**
   * Set whether it is reduced
   *
   * @param value Is it reduced?
   */
  set isZoomOut(value: boolean) {
    if (!this.data || !this.data.hasOwnProperty('type')) {
      return;
    }
    const managementService = this.isTypeError
      ? this.alertManagementService
      : this.mainManagementService;
    managementService.isZoomOut = value;
  }

  private $destroyStream: Subject<void> = new Subject<void>();

  // push通知オブジェクト
  // push notification object
  private notificationEventSubscription: Subscription;

  // ナビゲーション正常終了サブスクライブ
  // Navigation normal end subscribe
  private navigationEndSubscription: Subscription;

  // 異常状態確認API呼び出しサブスクライブ
  // Abnormal condition confirmation API call subscribe
  private alertFetchStreamSubscription: Subscription;

  /**
   * コンストラクター関数
   * @param router Angularルーター
   * @param dataManagementService データ管理サービス
   * @param authorityManagerService 権限管理サービス
   * @param mainManagementService メイン管理サービス
   * @param alertManagementService アラート管理サービス
   * @param toastService トーストサービス
   * @param multiLanguageMessageService 多言語メッセージサービス
   */
  /**
   * Constructor function
   * @param router Angular router
   * @param dataManagementService Data management service
   * @param authorityManagerService Authority manager service
   * @param mainManagementService Main management service
   * @param alertManagementService Alert management service
   * @param toastService ToastService
   * @param multiLanguageMessageService MultiLanguageMessageService
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dataManagementService: DataManagementService,
    private authorityManagerService: AuthorityManagerService,
    private mainManagementService: MainManagementService,
    private alertManagementService: AlertManagementService,
    private toastService: ToastService,
    private multiLanguageMessageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 初期表示時はアラートバーの縮小なし
    // No alert bar reduction at the time of initial display
    this.isZoomOut = false;
    // データ管理サービス関連のサブスクライブ
    // Data management service related subscribe
    this.alertManagementService.dataManagementCombineLatest();

    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // 機器イベント通知API
    // Device event notification API
    // this.notificationEventSubscription = this.alertManagementService
    //   .wssEquipmentEventsReport()
    //   .subscribe(
    //     (response) => {
    //       const responseEvents = response as EquipmentEvents;
    //       if (!responseEvents.events) {
    //         return;
    //       }
    //       for (const event of responseEvents.events) {
    //         if (eventCodeUpdateAlert.find((item) => item === event.code)) {
    //           // アラートバー更新通知
    //           // Alert bar update notification
    //           this.alertManagementService.updateAlert(true, false);
    //           break;
    //         }
    //       }
    //     },
    //     (error) => {
    //       if (error.status === 408) {
    //         this.toastService.openToast(
    //           'error',
    //           'center',
    //           this.multiLanguageMessageService.dictionary('sidServerErrorOccurred'),
    //         );
    //       }
    //     },
    //   );

    // ナビゲーション正常終了サブスクライブ
    // Navigation normal end subscribe
    this.navigationEndSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.alertManagementService.updateAlert(true, true);
      });

    // 異常状態確認APIの呼び出しサブスクライブ
    // Abnormal condition confirmation API call subscribe
    this.alertFetchStreamSubscription = this.alertManagementService.$alertFetchStream.subscribe(
      (result) => {
        // 異常状態確認APIの呼び出し時、並列処理追加
        // Abnormal condition confirmation of parallel processing when calling API
        if (result) {
          this.fetchingStateErrorParallel += 1;

          // 最新のメッセージが反映された場合、並列処理完了
          // Parallel processing is completed if the latest message is reflected
        } else if (this.fetchingStateErrorParallel > 0) {
          this.fetchingStateErrorParallel -= 1;
        }

        // 並列処理されていない場合は活性
        // Active if not parallel processed
        if (this.fetchingStateErrorParallel <= 0) {
          this.detailButtonDisabled = false;

          // 並列処理中の場合は非活性
          // Inactive if parallel processing is in progress
        } else {
          this.detailButtonDisabled = true;
        }
      },
    );
    // パッケージアラート、エラーアラートの検知にサブスクライブ
    // Listen to notifications from package and error alert
    combineLatest([
      this.mainManagementService.$alertDataStream,
      this.alertManagementService.$alertDataStream,
    ])
      .pipe(takeUntil(this.$destroyStream))
      .subscribe(([packageStream, alertStream]) => {
        this.isShowDetails = false;
        this.data = packageStream ? packageStream : alertStream;
        if (this.data && this.data.details && this.data.details.list.length > 0) {
          // 権限設定でエッジのシステムエラーを表示しない設定であれば、除外する
          // Exclude edge's system error when it doesn't set show for authority settings.
          // 権限設定で緊急停止を表示しない設定であれば、除外する
          // Forced stop error when it doesn't set show for authority settings.
          if (!this.showEdgeSystemError || !this.showForcedStopError) {
            this.data.details.list = this.data.details.list.filter(
              (item) =>
                (this.showEdgeSystemError ||
                  item.errorType !== AlertBarErrorType.EdgeSystemError) &&
                (this.showForcedStopError || item.errorType !== AlertBarErrorType.ForcedStop),
            );

            // 除外した結果、表示するエラーが無くなった場合はサマリーを初期化する
            // As a result of exclusion, if there are no errors to display, initialize the summary
            if (!this.data.details.title && this.data.details.list.length <= 0) {
              this.data.summary = '';
            }
          }
          this.alertManagementService.updateZoomOut();
        }
      });

    // 自動ログイン時にデータ管理サービス関連がサブスクライブされないため追加
    // Data management service related is not subscribed during auto login
    this.alertManagementService.dataManagementCombineSubscribe();
    this.alertManagementService.setUpdateAlertIntervalId();
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    // wss通信を無条件で切断する。
    // Disconnect wss communication unconditionally.
    this.alertManagementService.wssEquipmentEventsReportClose();
    // push通知オブジェクトを削除する。
    // Delete push notification object.
    if (this.notificationEventSubscription) {
      this.notificationEventSubscription.unsubscribe();
    }

    // データ管理サービス関連のサブスクライブ解除
    // Data management service related subscribe cancellation
    this.alertManagementService.clearDataManagementCombineSubscription();

    // ナビゲーション正常終了サブスクライブ解除
    // Navigation normal end Unsubscribe
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
    }

    // 異常状態確認API呼び出しサブスクライブ解除
    // Abnormal condition confirmation API call subscribe cancellation
    if (this.alertFetchStreamSubscription) {
      this.alertFetchStreamSubscription.unsubscribe();
    }
    this.$destroyStream.next();
    this.alertManagementService.clearNotificationSound();
    this.alertManagementService.clearUpdateAlertIntervalId();
  }

  /**
   * Angular life cycle method
   */
  ngAfterViewChecked() {
    // 機器異常発生時、通知音を鳴らす
    // Play a notification sound when a equipment error occurs
    if (this.audio && this.alertManagementService.isNotificationSound()) {
      this.alertManagementService.playNotificationSound(
        this.audio.nativeElement as HTMLMediaElement,
      );

      // 契約関連アラートバーの場合、通知音をクリアする
      // In the case of a contract related alert bar, clear the notification sound
    } else if (!this.audio) {
      this.alertManagementService.clearNotificationSound();
    }
  }

  /**
   * 詳細の表示と非表示を切り替える
   */
  /**
   * Show or hide details
   */
  onDetailsButtonClick() {
    if (this.isShowDetails) {
      this.alertManagementService.saveErrorAlreadyReadIds(this.extractCurrentErrorIds());
    }
    if (!this.enableStopSoundLink && this.isShowDetails) {
      this.alertManagementService.postErrorRead();
    }

    this.isShowDetails = !this.isShowDetails;
    this.changeDetectorRef.detectChanges();
    // アニメーション後に再描画を実行する
    // perform redraw after animation
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 400);
  }

  /**
   * 現在詳細に表示されている異常のエラーIDを抽出する
   * @returns {string[]} 抽出されたエラーID
   */
  /**
   * Extract the error ID of the abnormalities currently displayed in detail
   * @returns {string[]} Extracted error ID
   */
  private extractCurrentErrorIds(): string[] {
    const errorIds = [];

    if (!this.data || !this.data.details || this.data.details.list.length <= 0) {
      return errorIds;
    }

    this.data.details.list.forEach((item) => {
      if (item.errorId) {
        errorIds.push(item.errorId);
      } else if (item.subDetails) {
        item.subDetails.forEach((subDetail) => {
          if (subDetail.errorId) {
            errorIds.push(subDetail.errorId);
          }
        });
      }
    });

    return errorIds;
  }

  /**
   * 遷移できる場合に、緊急停止画面に遷移する
   */
  /**
   * Navigate to Forced Stop page if navigation is enabled
   */
  onInfoButtonClick() {
    if (!this.disableNavigation) {
      this.router.navigate(['automatic-control/forced-stop']);
    }

    this.isShowDetails = false;
  }

  /**
   * 遷移できる場合に、ダウンロード画面に遷移する
   */
  /**
   * Navigate to Download page if navigation is enabled
   */
  onDownloadButtonClick() {
    if (!this.disableNavigation) {
      this.router.navigate(['download']);
    }

    this.isShowDetails = false;
  }

  /**
   * 通知音を停止する
   */
  /**
   * Stop notification sound
   */
  onStopSound() {
    this.alertManagementService.stopNotificationSound();
    this.alertManagementService.saveLastNotificationSoundStopErrorDate();
    if (this.alertManagementService.errorAlreadyReadIdExists()) {
      this.alertManagementService.postErrorRead();
    }
  }
}
