import { Injectable } from '@angular/core';
import { Observable, throwError, timer, from, TimeoutError, of } from 'rxjs';
import { map, mergeMap, retryWhen, catchError, timeout } from 'rxjs/operators';
import { default as Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';

import { NameValue } from '../interfaces/common/name-value';
import { Response } from '../interfaces/common/response';
import { AuthInitiateAuthResponse } from '../interfaces/auth-serivce/';
import { RestClient, apiVersion } from '../base/rest-client';
import { ResponseError, ResponseErrors } from '../interfaces/common/response-errors';
import { limitOfTimeout, limitOfRetryCount, retryTriggerCode } from '../constants/retry';

const pathOfAuth = `auth/${apiVersion}/`;

@Injectable()
export class RestClientAuthService extends RestClient {
  /**
   * コンストラクタ
   *
   * @param router ルータ情報
   */
  /**
   * constructor
   *
   * @param router Router information
   */
  constructor(private router: Router) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-21. 認証サービス
  //  3-21. Authentication Service
  /////////////////////////////////////////////////////////////////////////////
  //  cognito
  /////////////////////////////////////////////////////////////////////////////

  /**
   * ユーザ認証
   *
   * @param {string} email 認証対象のメールアドレス
   * @param {string} password 認証対象のパスワード
   * @return {Observable<Response>} status:HTTPステータス data:AuthInitiateAuthResponse
   */
  /**
   * User authentication
   *
   * @param {string} email Email address to be authenticated
   * @param {string} password Password to be authenticated
   * @return {Observable<Response>} status:HTTP status data:AuthInitiateAuthResponse
   */
  authInitiateAuth(email: string, password: string): Observable<Response> {
    return from(Auth.signIn(email, password))
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map((user) => {
          this.restClientCommonService.authenticatedUser = user;
          const data = new AuthInitiateAuthResponse();
          if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            data.accountSts = 1;
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            data.accountSts = 2;
          } else {
            data.accountSts = 0;
          }
          if (user.attributes) {
            data.personId = user.attributes['custom:personId'];
          }
          return new Response(200, data);
        }),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount)),
      );
  }

  /**
   * SMS認証API
   *
   * @param {string} confirmationCode SMSで送付された認証コード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * SMS authentication API
   *
   * @param {string} confirmationCode Authentication code sent via SMS
   * @return {Observable<Response>} status:HTTP status
   */
  authSmsAuth(confirmationCode: string): Observable<Response> {
    return from(
      Auth.confirmSignIn(
        this.restClientCommonService.authenticatedUser,
        confirmationCode,
        'SMS_MFA',
      ),
    )
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map((response) => new Response(200, null)),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount, true)),
      );
  }

  /**
   * ログアウト
   *
   * @param cachesLoginDataFlg ログインデータをキャッシュするかどうか
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Logout
   *
   * @param cachesLoginDataFlg Whether to cache login data
   * @return {Observable<Response>} status:HTTP status
   */
  authLogout(cachesLoginDataFlg: boolean = false): Observable<Response> {
    return this.restClientCommonService.authLogout(cachesLoginDataFlg);
  }

  /**
   * パスワードリセット確認コード送信
   *
   * @param {string} username パスワードリセット対象のユーザ名
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Send password reset confirmation code
   *
   * @param {string} username User name for password reset
   * @return {Observable<Response>} status:status:HTTP status
   */
  authSendPasswordResetCode(username: string): Observable<Response> {
    return from(Auth.forgotPassword(username))
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map(() => new Response(200, null)),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount)),
      );
  }

  /**
   * パスワードリセット
   *
   * @param {string} username パスワードリセット対象のユーザ名
   * @param {string} code パスワードリセット要求で送付されたコード
   * @param {string} password 新しいパスワード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Password reset
   *
   * @param {string} username User name for password reset
   * @param {string} code Code sent in password reset request
   * @param {string} password new password
   * @return {Observable<Response>} status:HTTP status
   */
  authResetPassword(username: string, code: string, password: string): Observable<Response> {
    return from(Auth.forgotPasswordSubmit(username, code, password))
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map(() => new Response(200, null)),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount)),
      );
  }

  /**
   * パスワード変更
   *
   * @param {string} oldPassword 変更前のパスワード
   * @param {string} newPassword 変更後のパスワード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Change Password
   *
   * @param {string} oldPassword Password before change
   * @param {string} newPassword Changed password
   * @return {Observable<Response>} status:HTTP status
   */
  authChangePassword(oldPassword: string, newPassword: string): Observable<Response> {
    if (!this.restClientCommonService.authenticatedUser) {
      this.router.navigate(['']).then().catch();
      return this.restClientCommonService.handleError(400, 'is not login');
    }
    return from(
      Auth.changePassword(this.restClientCommonService.authenticatedUser, oldPassword, newPassword),
    )
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map(() => new Response(200, null)),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount)),
      );
  }

  /**
   * 新パスワード登録
   *
   * @param {string} password 新しいパスワード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * New password registration
   *
   * @param {string} password new password
   * @return {Observable<Response>} status:HTTP status
   */
  authRegistNewPassword(password: string): Observable<Response> {
    if (!this.restClientCommonService.authenticatedUser) {
      this.router.navigate(['']).then().catch();
      return this.restClientCommonService.handleError(400, 'is not login');
    }
    return from(
      Auth.completeNewPassword(this.restClientCommonService.authenticatedUser, password, {}),
    )
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map((user) => {
          user.clearCachedUser();
          return new Response(200, null);
        }),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount, true)),
      );
  }

  /**
   * 情報更新
   *
   * @param {NameValue[]} attributes 新しいユーザ属性設定
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Information update
   *
   * @param {NameValue[]} attributes New user attribute settings
   * @return {Observable<Response>} status:HTTP status
   */
  authUpdateUserAttributes(attributes: NameValue[]): Observable<Response> {
    if (!this.restClientCommonService.authenticatedUser) {
      this.router.navigate(['']).then().catch();
      return this.restClientCommonService.handleError(400, 'is not login');
    }
    return from(
      Auth.updateUserAttributes(this.restClientCommonService.authenticatedUser, attributes),
    )
      .pipe(timeout(limitOfTimeout))
      .pipe(
        map(() => new Response(200, null)),
        retryWhen((errors) => this.errorStatusCheck(errors, limitOfRetryCount)),
      );
  }

  /**
   * 自動ログイン
   *
   * @return {Observable<Boolean>} true:自動ログイン可能
   */
  /**
   * Auto login
   *
   * @return {Observable<Boolean>} true:Automatic login possible
   */
  authAutoLogin(): Observable<Boolean | void> {
    return from(Auth.currentUserPoolUser()).pipe(
      map((user) => {
        if (user.preferredMFA === 'SMS_MFA') {
          return false;
        }
        this.restClientCommonService.authenticatedUser = user;
        return true;
      }),
      catchError((err) => {
        return of(false);
      }),
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-21-2. 電話番号変更管理
  //  3-21-2. Phone number change management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 電話番号変更確認SMS送信API
   * response body: none
   *
   * @param {string} telephoneNumber 変更予定電話番号
   * @param {string} emailNotificationLanguage 	メール通知言語
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Phone number change confirmation SMS sending API
   * response body: none
   *
   * @param {string} telephoneNumber Scheduled phone number
   * @param {string} emailNotificationLanguage 	Email notification language
   * @return {Observable<Response>} status:HTTP status
   */
  postAuthTelephoneNumberSend(
    telephoneNumber: string,
    emailNotificationLanguage: string,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfAuth}changeTelephoneNumber/send`,
      {
        telephoneNumber,
        emailNotificationLanguage,
      },
      this.screenId,
    );
  }

  /**
   * 電話番号変更確認SMS認証API
   * response body: none
   *
   * @param {string} confirmationCode 確認コード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Phone number change confirmation SMS authentication API
   * response body: none
   *
   * @param {string} confirmationCode Confirmation code
   * @return {Observable<Response>} status:HTTP status
   */
  postAuthTelephoneNumberExecute(confirmationCode: string): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfAuth}changeTelephoneNumber/execute`,
      {
        confirmationCode,
      },
      this.screenId,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-21-3. メールアドレス管理
  //  3-21-3. Email address management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * メールアドレス変更情報取得API
   * response body: email(string)
   *
   * @param {string} code ハッシュコード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Email address change information acquisition API
   * response body: email(string)
   *
   * @param {string} code Hash code
   * @return {Observable<Response>} status:HTTP status
   */
  getAuthChangeEmail(code: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfAuth}changeEmail?code=${code}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * メールアドレス変更確定API
   * response body: none
   *
   * @param {string} code ハッシュコード
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Email address change confirmation API
   * response body: none
   *
   * @param {string} code Hash code
   * @return {Observable<Response>} status:Hash code
   */
  postAuthConfirmChangeEmail(code: string): Observable<Response> {
    return this.restClientCommonService.request(
      'POST',
      `${this.endPoint}${pathOfAuth}changeEmail/execute`,
      { code },
      this.screenId,
    );
  }

  /**
   * エラーレスポンスチェック処理
   *
   * @param {Observable<any>} errors エラー
   * @param {number} retryCount リトライ回数
   * @param {boolean} logout ログアウトフラグ
   * @param {boolean} retrySkip リトライ無効フラグ
   * @return {Observable<Response>} エラーレスポンス
   */
  /**
   * Error response check process
   *
   * @param {Observable<any>} errors error
   * @param {number} retryCount Number of retries
   * @param {boolean} logout Logout flg
   * @param {boolean} retrySkip Retry invalid flag
   * @return {Observable<Response>} Error response
   */
  private errorStatusCheck(
    errors: Observable<any>,
    retryCount: number,
    logout: boolean = false,
    retrySkip: boolean = false,
  ) {
    let retryTime: number = 0;
    return errors.pipe(
      mergeMap((error) => {
        // リトライ条件を満たしていればリトライ実施
        // If retry conditions are met, retry is performed
        if (this.retryTriggerCheck(error) && !retrySkip) {
          if (retryTime < retryCount) {
            retryTime += 1;
            return timer(0);
          }
        }
        if (logout) {
          this.restClientCommonService.authLogout();
        }
        // タイムアウト、疎通不可時は408エラーとしてフロントに返却
        // When timeout or communication is impossible, return to the front as 408 error
        if (error instanceof TimeoutError || retryTriggerCode.indexOf(error.code) >= 0) {
          return this.restClientCommonService.handleError(408, 'Timeout Error');
        }

        // 画面側に通知する
        // Notify the screen
        return throwError(
          new Response(400, new ResponseErrors(new ResponseError(error.code, error.message))),
        );
      }),
    );
  }

  /**
   * リトライ条件チェック
   *
   * @param {error} error エラー
   * @return {Observable<boolean>} リトライ有無
   */
  /**
   * Retry condition check
   *
   * @param {error} error error
   * @return {Observable<boolean>} Whether to retry
   */
  private retryTriggerCheck(error) {
    if (error instanceof TimeoutError) {
      return true;
    }
    if (retryTriggerCode.indexOf(error.code) >= 0) {
      return true;
    }
    return false;
  }
}
