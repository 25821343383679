export class ResponseErrors {
  errors: ResponseError[] = [];

  /**
   * コンストラクタ
   *
   * @param error レスポンスエラー
   */
  /**
   * constructor
   *
   * @param error Response error
   */
  constructor(error: ResponseError) {
    this.errors.push(error);
  }
}

export class ResponseError {
  code: string;
  message: string;

  /**
   * コンストラクタ
   *
   * @param code エラーコード
   * @param message エラーメッセージ
   */
  /**
   * constructor
   *
   * @param code Error code
   * @param message Error message
   */
  constructor(code: string, message: string) {
    this.code = code;
    this.message = message;
  }
}
