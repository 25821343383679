export class Role {
  id: string;
  name: string;

  /**
   * コンストラクタ
   *
   * @param id ロールID
   * @param name ロール名
   */
  /**
   * constructor
   *
   * @param id Role id
   * @param name Role name
   */
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  /**
   * IDが有効かチェックする
   *
   * @return チェック結果
   */
  /**
   * Check if id is valid
   *
   * @return Check result
   */
  isValid() {
    if (this.id) {
      return true;
    }
    return false;
  }

  /**
   * 更新が必要か判定する
   *
   * @param newRole 新しいロール情報
   * @return 判定結果
   */
  /**
   * Determine if an update is required
   *
   * @param newRole New role information
   * @return judgement result
   */
  isUpdated(newRole: Role) {
    if (newRole.id !== this.id || newRole.name !== this.name) {
      return true;
    }
    return false;
  }
}
