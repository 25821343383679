import { Subject } from 'rxjs';
import { filter, first, takeUntil, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { AuthorityManagerService } from 'src/app/shared-main/components/authority-control/authority-manager.service';
import { MenuManagementService } from 'src/app/main/main/services/menu-management/menu-management.service';
import { HeaderService } from 'src/app/main/main/services/header/header.service';

import { Building } from 'src/app/core/services/data-management/classes/building-data';
import { Edge } from 'src/app/core/services/data-management/classes/edge-data';
import { BuildingEdgeStream } from 'src/app/core/services/data-management/classes/stream-data';
import { StreamType } from 'src/app/core/services/data-management/enums/stream-type';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { UserListItemId } from 'src/app/shared-main/enums/item-id';
import { Link, MyProfileLink } from 'src/app/main/main/services/menu-management/interfaces';
import { Utility } from 'src/app/shared-main/classes/utility';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  siteList: Building[] = [];
  selectedSite: Building = new Building('', '', '', [], []);
  selectedController: Edge = new Edge('', '');
  activeMenu: Link;
  activeRoute: Link;
  private prevSelectedSite: Building = new Building('', '', '', [], []);

  private get enableUserListSiteSelect(): boolean {
    return (
      this.activeMenu &&
      this.activeMenu.id === ScreenId.UserList &&
      this.siteList.length >= 5 &&
      this.authorityManagerService.authorityState(ScreenId.UserList, UserListItemId.TargetUser)
    );
  }
  get showSiteSelection(): boolean {
    return (
      (this.activeMenu && !!this.activeMenu.enableSiteSelect && this.siteList.length > 0) ||
      this.enableUserListSiteSelect ||
      this.headerService.enabledDynamicSiteSelected
    );
  }
  get showControllerSelection(): boolean {
    return (
      this.activeMenu &&
      !!this.activeMenu.enableControllerSelect &&
      this.selectedSite.edgeList.length > 1
    );
  }
  get inactivateSiteSelection(): boolean {
    return this.activeRoute && !!this.activeRoute.inactivateSiteSelect;
  }
  get disableNavigation(): boolean {
    return this.dataManagementService.person().termsOfServiceState === 0;
  }

  private $destroyStream: Subject<void> = new Subject<void>();

  /**
   * コンストラクター関数
   * @param router Angularルーター
   * @param dataManagementService データ管理サービス
   * @param authorityManagerService 権限管理サービス
   * @param menuManagementService メニュー管理サービス
   * @param headerService ヘッダーサービス
   */
  /**
   * Constructor function
   * @param router Angular router
   * @param dataManagementService Data management service
   * @param authorityManagerService Authority manager service
   * @param menuManagementService Menu Management Service
   * @param headerService Header service
   */
  constructor(
    private router: Router,
    private dataManagementService: DataManagementService,
    private authorityManagerService: AuthorityManagerService,
    private menuManagementService: MenuManagementService,
    private headerService: HeaderService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // 物件一覧の更新にサブスクライブ
    // Listen to changes from building list
    // this.dataManagementService.buildingListStream
    //   .pipe(takeUntil(this.$destroyStream))
    //   .subscribe((buildingList: Building[]) => (this.siteList = buildingList));

    // 物件の更新にサブスクライブ
    // Listen to changes from building
    // this.dataManagementService.edgeStream
    //   .pipe(
    //     takeUntil(this.$destroyStream),
    //     filter((stream: BuildingEdgeStream) => stream.type === StreamType.Building),
    //   )
    //   .subscribe(() => {
    //     if (this.prevSelectedSite.isUpdated(this.dataManagementService.building())) {
    //       this.headerService.fetchSite(this.dataManagementService.building());
    //     }
    //     Utility.deepMerge(this.prevSelectedSite, this.selectedSite);
    //     this.selectedSite = this.dataManagementService.building();
    //   });

    // エッジの更新にサブスクライブ
    // Listen to changes from edge
    // this.dataManagementService.edgeStream
    //   .pipe(
    //     takeUntil(this.$destroyStream),
    //     filter((stream: BuildingEdgeStream) => stream.type === StreamType.Edge),
    //   )
    //   .subscribe(() => {
    //     if (this.selectedController.isUpdated(this.dataManagementService.edge())) {
    //       this.selectedController = this.dataManagementService.edge();
    //     }
    //   });

    // ルート切り替えの更新にサブスクライブ
    // Listen to route chnage
    this.menuManagementService.$activeRouteStream
      .pipe(takeUntil(this.$destroyStream))
      .subscribe((activeRoute: Link) => (this.activeRoute = activeRoute));

    // 画面切り替えの更新にサブスクライブ（初回遷移を除く）
    // Listen to page navigation (skip the first navigation)
    this.menuManagementService.$activeMenuStream
      .pipe(skip(1), takeUntil(this.$destroyStream))
      .subscribe((activeMenu: Link) => {
        this.activeMenu = activeMenu;

        // this.headerService.updateSelectedSite();
        // this.headerService.updateSiteList();
        // this.headerService.fetchSiteList(false);
      });

    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // 画面切り替えの更新にサブスクライブ（初回遷移のみ）
    // Listen to page navigation (first navigation)
    // this.menuManagementService.$activeMenuStream.pipe(first()).subscribe((activeMenu: Link) => {
    //   this.siteList = this.dataManagementService.buildingList();
    //   this.activeMenu = activeMenu;

      // if (!this.dataManagementService.directAccessFlag) {
        // this.headerService.updateSelectedSite();
        // this.headerService.fetchSiteList(true);
      // } else {
        // this.headerService.fetchSiteList(false);
        // this.dataManagementService.edgeStream.next({
        //   type: StreamType.Building,
        //   id: this.dataManagementService.building().id,
        //   name: this.dataManagementService.building().name,
        //   currentTime: this.dataManagementService.building().currentTime,
        // });
        // this.dataManagementService.edgeStream.next({
        //   type: StreamType.Edge,
        //   id: this.dataManagementService.edge().id,
        //   name: this.dataManagementService.edge().name,
        //   currentTime: '',
        // });
      // }
    // });
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    this.$destroyStream.next();
  }

  /**
   * 選択された物件を更新
   * @param selectedSite 選択された物件
   */
  /**
   * Update selected site
   * @param selectedSite Selected site
   */
  async onSiteSelect(selectedSite: Building) {
    if (selectedSite.id !== this.selectedSite.id) {
      // 物件変更を中止させるかのチェックを行う
      // Check whether to cancel the building change
      for (const methods of this.headerService.beforeUpdatingSite) {
        const result = await methods.checkMethod().toPromise();
        // どれか一つ通らなかった場合、物件変更を中止する
        // If not pass any one, cancel the building change
        if (!result) {
          return;
        }
        methods.actionMethod();
      }
      this.headerService.fetchSite(selectedSite);
    }
  }

  /**
   * 選択されたエッジを更新
   * @param selectedController 選択されたエッジ
   */
  /**
   * Update selected controller
   * @param selectedController Selected controller
   */
  onControllerSelect(selectedController: Edge) {
    if (selectedController.id !== this.selectedController.id) {
      this.dataManagementService.setEdge(selectedController);
    }
  }

  /**
   * 遷移できる場合に、選択されたメニューの画面に遷移する
   * @param menu 選択されたメニュー
   */
  /**
   * Navigate to selected menu's page if navigation is enabled
   * @param menu Selected menu
   */
  onMenuSelect(menu: MyProfileLink) {
    if (!this.disableNavigation && menu.url) {
      this.router.navigate([menu.url]);
    }
  }
}
