import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestClient, apiVersion } from '../base/rest-client';
import { Observable } from 'rxjs';

const pathOfHistory = `history/${apiVersion}/`;

@Injectable()
export class RestClientHistoryService extends RestClient {
  /**
   * コンストラクタ
   */
  /**
   * constructor
   */

  constructor(private http: HttpClient) {
    super();
  }
  getHistoryData(
    siteId: string,
    periodFrom: string,
    periodTo: string,
    cursor?: string,
  ): Observable<any> {
    if (cursor) {
      const query: string = `periodFrom=${periodFrom}&periodTo=${periodTo}&cursor=${cursor}`;
      return this.restClientCommonService.request(
        'get',
        `${this.endPoint}${pathOfHistory}buildings/${siteId}/historyData?${query}`,
        undefined,
        'history',
      );
    }

    const query: string = `periodFrom=${periodFrom}&periodTo=${periodTo}`;
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfHistory}buildings/${siteId}/historyData?${query}`,
      undefined,
      'history',
    );
  }
}
