import { Pipe, PipeTransform } from '@angular/core';
import { MultiLanguageMessageService } from '../../core/services/multi-language-message/multi-language-message.service';

@Pipe({
  name: 'decimal',
  pure: false,
})
export class DecimalPipe implements PipeTransform {
  /**
   * コンストラクタ
   *
   * @param multiLanguageMessageService 多言語サービス
   */
  /**
   * constructor
   *
   * @param multiLanguageMessageService Multi language Service
   */
  constructor(private multiLanguageMessageService: MultiLanguageMessageService) {}

  /**
   * パイプメイン関数
   *
   * @param value 指定値
   * @param isDefaultLanguage デフォルト言語の設定有無
   * @return 変換結果
   */
  /**
   * Pipe main method
   *
   * @param value Value
   * @param isDefaultLanguage デフォルト言語の設定有無
   * @return Conversion number
   */
  transform(value: string | number, isDefaultLanguage: boolean = false): string {
    let target: string;
    switch (typeof value) {
      case 'string':
        target = value as string;
        break;
      case 'number':
        target = value.toString();
        break;
      default:
        return value as string;
    }
    return this.multiLanguageMessageService.decimal(target, isDefaultLanguage);
  }
}
