import { Component, OnInit, Input } from '@angular/core';
import { AuthorityManagerService } from './authority-manager.service';

@Component({
  selector: 'app-authority-control',
  templateUrl: './authority-control.component.html',
  styleUrls: [],
})
export class AuthorityControlComponent implements OnInit {
  @Input() screenId: string = '';
  @Input() itemId: string = '';

  enabled = false;

  /**
   * コンストラクタ
   * @param authorityManager 権限管理サービス（共通機能）
   */
  /**
   * Constructor
   * @param authorityManager Authority management service（common）
   */
  constructor(private authorityManager: AuthorityManagerService) {}

  /**
   * Angular life cycle method
   */
  ngOnInit() {
    this.enabled = this.authorityManager.authorityState(this.screenId, this.itemId);
  }
}
