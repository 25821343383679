/**
 * 数値を指定桁単位になるよう丸め込む
 * ※指定桁未満の値を四捨五入する
 *
 * @param target 補正対象値
 * @param digit 指定桁(10の累乗数であること)
 * @return string 丸めた値
 */
/**
 * Round numbers to specified digits
 * (Rounds values less than the specified digit)
 *
 * @param target Correction target value
 * @param digit Specified digit (must be a power of 10)
 * @return string Rounded value
 */
export const roundedDigit = (target: number, digit: number): string => {
  if (isNaN(target) || target === null) {
    return '';
  }
  if (!isNaN(digit) && digit !== null) {
    const rounded = Math.round(target / digit) * digit;
    // 少数計算では丸め誤差が発生するため、四捨五入して丸めておく
    // Since rounding errors occur in decimal calculations, round off to the nearest whole number.
    const dotPosition = digit.toString().indexOf('.');
    let roundedDigit = 0;
    if (dotPosition >= 0) {
      roundedDigit = digit.toString().length - dotPosition - 1;
    }
    return rounded.toFixed(roundedDigit);
  }
  return target.toFixed();
};
