import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import {
  SystemPackagePostRequest,
  SystemPackagePutRequest,
  SystemNotify,
  SystemAccessLevelPostRequest,
  SystemAccessLevelPutRequest,
} from '../interfaces/system-service';
import { RestClient, apiVersion } from '../base/rest-client';

const pathOfSystem = `systemSettings/${apiVersion}/`;

@Injectable()
export class RestClientSettingService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-20. システム設定サービス
  //  3-20. System setting service
  /////////////////////////////////////////////////////////////////////////////
  //  3-20-1. パッケージ管理
  //  3-20-1. Package Management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * パッケージ一覧取得API
   * response body: SystemPackageGetResponse or SystemPackagePackagesGetResponse
   *
   * @param {string} fields リクエストボディ
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @param {boolean} [isPackageAll = false] 全パッケージ返却フラグ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Package list acquisition API
   * response body: SystemPackageGetResponse or SystemPackagePackagesGetResponse
   *
   * @param {string} fields Request body
   * @param {boolean} [force = false] Get information from backend without using cache
   * @param {boolean} [isPackageAll = false] All PackageA return flag
   * @return {Observable<Response>} status:HTTP status
   */
  getSystemPackage(
    fields?: string,
    force: boolean = false,
    isPackageAll: boolean = false,
  ): Observable<Response> {
    let query = `?isPackageAll=${isPackageAll}`;
    if (fields) {
      query += `&fields=${fields}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSystem}packages${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * パッケージ取得API
   * response body: SystemSpecifiedPackageGetResponse
   *
   * @param {string} packageId パッケージID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Package acquisition API
   * response body: SystemSpecifiedPackageGetResponse
   *
   * @param {string} packageId Package ID
   * @return {Observable<Response>} status:HTTP status
   */
  getSystemSpecifiedPackage(packageId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSystem}packages/${packageId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * パッケージ登録API
   * response body: none
   *
   * @param {SystemPackagePostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Package registration API
   * response body: none
   *
   * @param {SystemPackagePostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postSystemPackage(param: SystemPackagePostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSystem}packages`,
      param,
      this.screenId,
    );
  }

  /**
   * パッケージ更新API
   * response body: none
   *
   * @param {string} packageId パッケージID
   * @param {SystemPackagePutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Package update API
   * response body: none
   *
   * @param {string} packageId Package ID
   * @param {SystemPackagePutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putSystemPackage(packageId: string, param: SystemPackagePutRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSystem}packages/${packageId}`,
      param,
      this.screenId,
    );
  }

  /**
   * パッケージ削除API
   * response body: none
   *
   * @param {string} packageId パッケージID
   * @param {string} updatedAt 	更新日時
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Package removal API
   * response body: none
   *
   * @param {string} packageId Package ID
   * @param {string} updatedAt 	Update date
   * @return {Observable<Response>} status:HTTP status
   */
  deleteSystemPackage(packageId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfSystem}packages/${packageId}`,
      undefined,
      this.screenId,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-20-2. 通知メッセージ管理
  //  3-20-2. Notification message management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 通知メッセージ取得API
   * response body: SystemNotifyResponse
   *
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Notification message acquisition API
   * response body: SystemNotifyResponse
   *
   * @return {Observable<Response>} status:HTTP status
   */
  getSystemNotify(): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSystem}notificationMessage`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 通知メッセージ登録API
   * response body: none
   *
   * @param {SystemNotify} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Notification message registration API
   * response body: none
   *
   * @param {SystemNotify} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postSystemNotify(param: SystemNotify): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSystem}notificationMessage`,
      param,
      undefined,
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-20-3. アクセスレベル管理
  //  3-20-3. Access level management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * アクセスレベル一覧取得API
   * response body: SystemAccessLevelGetResponse
   *
   * @param {string} fields クエリパラメータ
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Access level list acquisition API
   * response body: SystemAccessLevelGetResponse
   *
   * @param {string} fields Query parameters
   * @param {boolean} [force = false] Get information from backend without using cache
   * @return {Observable<Response>} status:HTTP status
   */
  getSystemAccessLevel(fields?: string, force: boolean = false): Observable<Response> {
    let query = '';
    if (fields) {
      query = `?fields=${fields}`;
    }

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfSystem}accessLevels${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * アクセスレベル登録API
   * response body: none
   *
   * @param {SystemAccessLevelPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Access level registration API
   * response body: none
   *
   * @param {SystemAccessLevelPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postSystemAccessLevel(param: SystemAccessLevelPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfSystem}accessLevels`,
      param,
      this.screenId,
    );
  }

  /**
   * アクセスレベル更新API
   * response body: none
   *
   * @param {string} roleId 役割ID
   * @param {SystemAccessLevelPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Access level update API
   * response body: none
   *
   * @param {string} roleId Role ID
   * @param {SystemAccessLevelPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putSystemAccessLevel(roleId: string, param: SystemAccessLevelPutRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfSystem}accessLevels/${roleId}`,
      param,
      this.screenId,
    );
  }

  /**
   * アクセスレベル削除API
   * response body: none
   *
   * @param {string} roleId 役割ID
   * @param {string} updatedAt 	更新日時
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Access level deletion API
   * response body: none
   *
   * @param {string} roleId Role ID
   * @param {string} updatedAt 	Update date
   * @return {Observable<Response>} status:HTTP status
   */
  deleteSystemAccessLevel(roleId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfSystem}accessLevels/${roleId}`,
      undefined,
      this.screenId,
    );
  }
}
