import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthInitiateAuthResponse } from 'src/app/core/services/rest-client/interfaces/auth-serivce';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { Router, ActivatedRoute } from '@angular/router';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { RestClientCommonService } from 'src/app/core/services/rest-client/rest-client-common/rest-client-common.service';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { LoginService } from 'src/app/login/services/login.service';
import { environment } from 'src/environments/environment';
import { localStorageKeys } from 'src/app/shared-main/constants/local-storage-keys';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';
import settingsJson from 'src/settings.json';

export class LoginFormModel {
  constructor(public email?: string, public password?: string, public language?: string) {}
}

interface LanguageListItem {
  localeId: string;
  displayName: string;
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() loginFormModel: LoginFormModel = new LoginFormModel();

  loginForm: UntypedFormGroup;
  errorMessage: string;
  languages: LanguageListItem[] = [];
  localeId: string;
  private window = window;

  private isNavigateToCommissioning: boolean = false;
  private commissioningParameter = {};
  // 省エネシミュレーションから遷移したかの判定フラグ
  // Flag to determine if a transition has been made from the energy savings simulation.
  private isNavigateToEnergySaving: boolean = false;

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }
  get isSubmitEnabled(): boolean {
    return this.email.value && this.password.value;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private restClientCommonService: RestClientCommonService,
    private restClientAuthService: RestClientAuthService,
    private router: Router,
    private route: ActivatedRoute,
    public multiLanguage: MultiLanguageMessageService,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private toastService: ToastService,
    private dataManagementService: DataManagementService,
    private tabTitle: Title,
    private cookieService: CookieService,
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.dataManagementService.directAccessFlag = false;
    // 試運転から呼び出されたかどうかの判定
    // Judgment whether or not it was called from a test run
    if (this.route.snapshot.queryParams.commissioning) {
      this.isNavigateToCommissioning = this.route.snapshot.queryParams.commissioning === '1';
      const parameters = new URLSearchParams(location.search);
      const buildingId = parameters.get('buildingId');
      const edgeId = parameters.get('edgeId');
      if (buildingId) {
        this.commissioningParameter = { buildingId };
      }
      if (edgeId) {
        this.commissioningParameter = Object.assign(this.commissioningParameter, { edgeId });
      }
    }

    // 省エネシミュレーションから呼び出されたかどうか判断する
    // Determine if it was called from an energy saving simulation
    if (this.route.snapshot.queryParams.energySaving) {
      if (
        settingsJson.showEnergyManagementFunction.EnergySavingSimulation &&
        environment['energySavingUrl']
      ) {
        // 省エネシミュレーションのアレンジが有効でかつ、environmentにenergySavingUrlが存在する場合の場合
        // If the energy saving simulation arrangement is valid and energySavingUrl is present in the environment
        this.isNavigateToEnergySaving = this.route.snapshot.queryParams.energySaving === '1';
      }
    }

    this.loginForm = this.formBuilder.group({
      email: [this.loginFormModel.email, [Validators.required]],
      password: [this.loginFormModel.password, [Validators.required]],
      language: [this.loginFormModel.language],
    });

    this.loadData();
    if (this.restClientCommonService.forceLogoutFlag) {
      this.restClientCommonService.forceLogoutFlag = false;
    } else if (!this.isNavigateToCommissioning && !this.isNavigateToEnergySaving) {
      // 自動ログイン処理
      // automatic login process
      this.loaderService.showLoader();
      this.restClientAuthService.authAutoLogin().subscribe(
        (res) => {
          if (res === true) {
            this.navigateToAfterLoginPage();
          } else {
            this.loaderService.hideLoader();
          }
        },
        (err) => {
          this.loaderService.hideLoader();
        },
      );
    }
  }

  /**
   * 言語の取得
   */
  /**
   * Get language
   */
  async loadData() {
    this.loaderService.showLoader();
    const languageItem = await this.loginService.asyncGetLanguage(this.multiLanguage);
    this.languages = languageItem.languages;
    this.localeId = languageItem.localeId;
    this.loaderService.hideLoader();
  }

  /**
   * ログインボタンのクリック時
   */
  /**
   * When the login button is clicked
   */
  onSubmit() {
    // 入力値をチェックする
    // Check input value
    this.errorMessage = '';
    if (!this.email.value) {
      this.errorMessage = 'sidCannotUsernameEmpty';
      return;
    }

    if (!this.password.value) {
      this.errorMessage = 'sidCannotPasswordEmpty';
      return;
    }

    // ログインのAPIを呼び出す
    // Call the login API
    this.loaderService.showLoader();
    this.clearToken();
    this.restClientAuthService.authInitiateAuth(this.email.value, this.password.value).subscribe(
      (response) => {
        const responseData = response.data as AuthInitiateAuthResponse;
        if (!this.dataManagementService.equalCachesLoginDataPersonId(responseData.personId)) {
          this.dataManagementService.clearCaches();
        }
        if (responseData.accountSts !== 1 && responseData.accountSts !== 2) {
          this.navigateToAfterLoginPage();
          return;
        }

        this.loaderService.hideLoader();

        // 多要素認証なので確認コード画面に遷移
        // Since it is multi-factor authentication, transition to the confirmation code screen
        if (responseData.accountSts === 1) {
          this.navigateToLoginConfirmationPage();
          return;
        }

        // 新パスワード必要状態のためエラーにする
        // Make an error because the new password is required
        if (responseData.accountSts === 2) {
          this.showErrorOnNeedsNewPassword();
          return;
        }
      },
      (err) => {
        this.loaderService.hideLoader();
        const response = err as Response;
        this.clearToken();
        this.handleSubmitError(response);
      },
    );
  }

  /**
   * ローカルストレージのキャッシュをクリア
   */
  /**
   * Clear cashes in local storage
   */
  private clearToken() {
    for (let i = 0; i < localStorage.length; i += 1) {
      const key = localStorage.key(i);
      if (
        key !== 'dictionary' &&
        key !== 'dictionaryVersion' &&
        key !== 'localeId' &&
        key !== 'dictionaryLanguage' &&
        key !== 'totalPeriod' &&
        key !== localStorageKeys.LastSelectedRealBuildingId &&
        key !== localStorageKeys.LastSelectedZoneInfo &&
        key !== localStorageKeys.LastSelectedLayoutScreenInfo &&
        key !== localStorageKeys.LastNotificationSoundStopErrorDate &&
        key !== localStorageKeys.LastConvergenceZoneInfo &&
        key !== localStorageKeys.LastSelectEnergyManagement
      ) {
        localStorage.removeItem(key);
      }
    }
  }

  /**
   * 新パスワード必要状態の場合のエラー表示
   */
  /**
   * Error display when a new password is required
   */
  private showErrorOnNeedsNewPassword() {
    this.errorMessage = 'sidFailedLogin';
    this.toastService.openToast(
      'error',
      'center',
      this.multiLanguage.dictionary(this.errorMessage),
    );
  }

  /**
   * authSmsAuthのエラーレスポンスを処理する
   *
   * @param response    エラーレスポンス
   */
  /**
   * Handle authSmsAuth error response
   *
   * @param response    Error response
   */
  private handleSubmitError(response: Response) {
    const resErrors = response.data as ResponseErrors;
    const firstError = resErrors.errors[0];

    if (
      firstError.code === 'PasswordResetRequiredException' &&
      firstError.message.includes('Password reset required for')
    ) {
      this.errorMessage = 'sidFailedLoginSec';
      return;
    }

    if (response.status === 408) {
      this.errorMessage = 'sidServerErrorOccurred';
      this.toastService.openToast(
        'error',
        'center',
        this.multiLanguage.dictionary(this.errorMessage),
      );
      return;
    }

    if (response.status === 400 && firstError.message === 'Password attempts exceeded') {
      this.errorMessage = 'sidAccountTemporaryLocked';
    } else {
      this.errorMessage = 'sidFailedLogin';
    }
  }

  /**
   * ForgotPasswordのクリック時
   */
  /**
   * When ForgotPassword is clicked
   */
  onForgotPasswordClicked() {
    this.router.navigate(['./reset-password-mail'], { relativeTo: this.route });
  }

  /**
   * SMS認証画面に遷移する
   */
  /**
   * Transition to the SMS authentication screen
   */
  navigateToLoginConfirmationPage() {
    if (this.isNavigateToCommissioning) {
      this.router.navigate(['./login-confirmation'], {
        queryParams: Object.assign({ commissioning: 1 }, this.commissioningParameter),
        relativeTo: this.route,
      });
    } else if (this.isNavigateToEnergySaving) {
      this.router.navigate(['./login-confirmation'], {
        queryParams: Object.assign({ energySaving: '1' }),
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(['./login-confirmation'], { relativeTo: this.route });
    }
  }

  /**
   * ログイン後のページに遷移する
   */
  /**
   * Transition to the page after login
   */
  navigateToAfterLoginPage() {
    // if (this.isNavigateToCommissioning) {
    //   // 試運転web-appへ遷移する場合、ログイントークンをセッションストレージに保存
    //   // Store login token in session storage when transitioning to comissioning web-app
    //   for (let i = 0; i < localStorage.length; i += 1) {
    //     const key = localStorage.key(i);
    //     if (
    //       key !== 'dictionary' &&
    //       key !== 'dictionaryVersion' &&
    //       key !== 'localeId' &&
    //       key !== 'dictionaryLanguage'
    //     ) {
    //       sessionStorage.setItem(key, localStorage.getItem(key));
    //     }
    //   }

    //   // 試運転へ遷移
    //   // Transition to comissioning
    //   const queryParameter = Object.entries(this.commissioningParameter)
    //     .map((param) => `${param[0]}=${param[1]}`)
    //     .join('&');

    //   this.window.location.href =
    //     environment.commissioningUrl + (queryParameter ? `index.html?${queryParameter}` : '');
    // } else if (this.isNavigateToEnergySaving) {
    //   // Cookieが承認されているか確認する
    //   // Check to see if cookies are approved
    //   if (!this.cookieService.getCookieConsentState()) {
    //     // Cookieが未承認の場合は、トーストを表示し、遷移しないようにする
    //     // If cookie is unauthorized, display error toast and do not transition
    //     this.loaderService.hideLoader();
    //     this.toastService.openToast(
    //       'success',
    //       'center',
    //       this.multiLanguage.dictionary('sidCookieUseConsent'),
    //     );
    //     return;
    //   }

    //   // environmentにcookieDomainが含まれているか確認する
    //   // Check if environment contains cookieDomain
    //   if (environment['cookieDomain']) {
    //     // 省エネシミュレーションへ遷移するため、ログイントークンをcookieに保存
    //     // Save log-in token in cookie for transition to energy saving simulation
    //     for (let i = 0; i < localStorage.length; i += 1) {
    //       const key = localStorage.key(i);
    //       if (
    //         key.indexOf('CognitoIdentityServiceProvider') != -1 ||
    //         key === 'amplify-signin-with-hostedUI' ||
    //         key === 'lastSelectedRealBuildingId' ||
    //         key === 'localeId'
    //       ) {
    //         this.dataManagementService.setCookie(
    //           key,
    //           localStorage.getItem(key),
    //           null,
    //           environment['cookieDomain'],
    //           '/',
    //           'Lax',
    //         );
    //       }
    //     }
    //   }
    //   // 省エネシミュレーションへ遷移
    //   // Transition to energy saving simulation
    //   this.window.location.href = environment['energySavingUrl'];
    // } else {
    // 通常のログイン後画面に遷移
    // Go to the normal login screen
    this.dataManagementService.setIsLogin(true);
    // Failure Map API
    this.router.navigate(['/multi-store-management/dashboard']);
    // }
  }

  /**
   * 言語の変更
   *
   * @param   {any}  e
   */
  /**
   * Change language
   *
   * @param   {any}  e
   */
  async onSelectionChange(e: any) {
    this.loaderService.showLoader();
    this.localeId = await this.loginService.changeLocale(e.localeId, this.multiLanguage);
    // ブラウザのタブを多言語に対応
    // Support browser tabs in multiple languages
    this.tabTitle.setTitle(this.multiLanguage.dictionary('sidWebAppTabTitle'));
    this.loaderService.hideLoader();
  }

  /**
   * Emailの入力値変更時
   */
  /**
   * When focus is on email
   */
  onEmailValidation() {
    this.errorMessage = '';
    if (!this.email.value) {
      this.errorMessage = 'sidCannotUsernameEmpty';
    }
  }

  /**
   * Passwordの入力値変更時
   */
  /**
   * When focus out of Password
   */
  onPasswordValidation() {
    this.errorMessage = '';
    if (!this.password.value) {
      this.errorMessage = 'sidCannotPasswordEmpty';
    }
  }

  /**
   * 入力キーの確認
   *
   * @param   {[type]}  e  キー
   */
  /**
   * Confirm input key
   *
   * @param   {[type]}  e  Key
   */
  onKeydown(e) {
    if (e.keyCode === 13) {
      this.onForgotPasswordClicked();
    }
  }
}
