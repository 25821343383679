import { Component, OnDestroy, OnInit } from '@angular/core';

import { RestClientSettingService } from 'src/app/core/services/rest-client/rest-client-setting/rest-client-setting.service';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';

import { SystemNotifyResponse } from 'src/app/core/services/rest-client/interfaces/system-service/system-notify';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { ScreenId } from 'src/app/shared-main/enums/screen-id.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent implements OnInit, OnDestroy {
  isDisplay: boolean = false;
  notificationMessage: SystemNotifyResponse;
  private onDestroy: Subject<void> = new Subject<void>();

  /**
   * コンストラクター関数
   * @param restClientSettingService Rest通信部　システム設定サービス
   * @param dataManagementService データ管理サービス
   */
  /**
   * Constructor function
   * @param restClientSettingService Rest Client System Setting Service
   * @param dataManagementService Data management service
   */
  constructor(
    private restClientSettingService: RestClientSettingService,
    private dataManagementService: DataManagementService,
  ) {
    this.restClientSettingService.setScreenId(ScreenId.ScreenCommon);
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
    // multi-store-app: unnecessary process
    // this.setNotification();
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    this.onDestroy.next();
  }

  /**
   * 通知メッセージを設定
   */
  /**
   * Set notification message
   */
  private setNotification() {
    this.restClientSettingService
      .getSystemNotify()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res: Response) => {
        this.notificationMessage = res.data;

        if (this.notificationMessage) {
          const startDate = new Date(
            this.convertDate(this.notificationMessage.item.displayFrom).setHours(0, 0, 0, 0),
          );
          const endDate = new Date(
            this.convertDate(this.notificationMessage.item.displayTo).setHours(23, 59, 59, 999),
          );
          const today = new Date(res.headers.get('currenttimeutc'));
          this.isDisplay = startDate <= today && today <= endDate;
        }
      });
  }

  /**
   * 取得した string 型の日付情報を Date 型に変換
   * @param date 日付
   * @returns 変換後の日付
   */
  /**
   * Convert acquired string type date information to Date type
   * @param date Date
   * @returns Date after conversion
   */
  private convertDate(date: string): Date {
    return new Date(`${date.substring(0, 4)}/${date.substring(4, 6)}/${date.substring(6, 8)}`);
  }
}
