import { Component } from '@angular/core';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { AgentType } from 'src/app/core/services/data-management/enums/agent-type';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss'],
})
export class CookieBarComponent {
  get isDisplay(): boolean {
    return !this.cookieService.getCookieConsentState();
  }

  /**
   * 2021/9/8
   * Androidスマートフォンでは、CookieBarの高さが56px(日本語や英語、etc)のときノックバックが起こり、
   * フッターとCookieBarが重なり合ってしまうため、CookieBarのpadding-top(及びbottom)に＋0.5pxし、
   * 高さが56pxにならないようにしている。(※今後のChromeアップデートにより解消される可能性はある)
   */
  /**
   * On Android smartphones, when the height of CookieBar is 56px (Japanese, English, etc.),
   * knock back occurs and the footer and CookieBar overlap,
   * so the padding-top (and bottom) of CookieBar is + 0.5px, which is high. I try not to get 56px.
   * (* It may be resolved by a future Chrome update)
   */
  get cookieBarPadding(): string {
    if (DataManagementService.userAgentType() === AgentType.AndroidPhone) {
      return '5.5px 5px';
    }
    return `5px`;
  }

  /**
   * コンストラクター関数
   * @param multiLanguageMessageService 多言語対応サービス
   * @param dataManagementService データ管理部（共通）
   * @param cookieService クッキーサービス
   */
  /**
   * Constructor function
   * @param multiLanguageMessageService Multilingual service
   * @param dataManagementService Data management service (common)
   * @param cookieService cookie service
   */
  constructor(
    public multiLanguageMessageService: MultiLanguageMessageService,
    private dataManager: DataManagementService,
    private cookieService: CookieService,
  ) {}

  /**
   * 同意ボタンを押下したときの動作
   */
  /**
   * Operation when the consent button is pressed
   */
  clickButton() {
    this.cookieService.setCookieConsentState();
    this.dataManager.setCookie(
      'roleId',
      this.dataManager.role().id,
      this.cookieService.calculateAge(),
      '',
      '/',
      '',
    );
  }

  /**
   * リンクを押下したときの動作
   */
  /**
   * Operation when a link is pressed
   */
  onLinkClick() {
    this.cookieService.showCookieDialog();
  }
}
