import { PersonPersonGetResponse } from '../interfaces/person-service/person-person-get';
import {
  BuildingBuildingGetResponse,
  BuildingBuildingsGetResponse,
  BuildingBuildingsItem,
} from '../interfaces/building-service/building-buildings-get';
import { EquipmentZonesList } from '../interfaces/equipment-service/equipment-management';
import { ZoneZonesResponse } from '../interfaces/zone-service/zone-zones';
import { Scope } from '../enums/scope.enum';
import { Utility } from '../../../../shared-main/classes/utility';
import { UserTagAffiliateListGetResponse } from '../interfaces/role-service/role-user-tags';
import { RoleRolesResponse } from '../interfaces/role-service/role-roles';
import { SystemPackageGetResponse } from '../interfaces/system-service/system-package';
import { SystemAccessLevelGetResponse } from '../interfaces/system-service/system-accesslevel';

export const cacheMaxCountOfBuilding = 50;
export const cacheMaxCountOfZone = 250;
export const cacheMaxCountOfEdge = 50;

export class RestClientCaches {
  persons: PersonPersonGetResponse[] = [];
  buildings: RestClientCacheBuilding[] = [];
  buildingList: BuildingBuildingsGetResponse = {
    items: [] as BuildingBuildingsItem[],
  } as BuildingBuildingsGetResponse;
  equipmentList: EquipmentZonesList = {
    zoneList: [],
    edgeList: [],
  } as EquipmentZonesList;
  zones: RestClientCacheZone[] = [];
  affiliateList: UserTagAffiliateListGetResponse = {
    items: [],
  } as UserTagAffiliateListGetResponse;
  roleList: RoleRolesResponse = {
    items: [],
  } as RoleRolesResponse;
  packageList: SystemPackageGetResponse = {
    items: [],
  } as SystemPackageGetResponse;
  accessLevelList: SystemAccessLevelGetResponse = {
    items: [],
  } as SystemAccessLevelGetResponse;
}

export class RestClientCacheZone {
  scope: Scope;
  buildingId: string;
  includeOutdoorZone: boolean;
  includeEquipmentData: boolean;
  data: ZoneZonesResponse = {} as ZoneZonesResponse;

  /**
   * コンストラクタ
   *
   * @param scope 取得範囲
   * @param buildingId 物件ID
   * @param includeOutdoorZone アウトドアゾーン含む
   * @param includeEquipmentData 機器データ含む
   * @param data ゾーン情報
   */
  /**
   * constructor
   *
   * @param scope Acquisition range
   * @param buildingId Building ID
   * @param includeOutdoorZone Include outdoor zone
   * @param includeEquipmentData Include equipment data
   * @param data Zone information
   */
  constructor(
    scope: Scope,
    buildingId: string,
    includeOutdoorZone: boolean,
    includeEquipmentData: boolean,
    data: ZoneZonesResponse,
  ) {
    this.scope = scope;
    this.buildingId = buildingId;
    this.includeOutdoorZone = includeOutdoorZone;
    this.includeEquipmentData = includeEquipmentData;
    Utility.deepMerge(this.data, data);
  }
}

export interface RestClientCacheBuilding extends BuildingBuildingGetResponse {
  buildingId: string;
}
