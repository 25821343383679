import { Inject, LOCALE_ID, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IntlService, CldrIntlService, setData } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';
import { sprintf } from 'sprintf-js';
import dayjs from 'dayjs';
import 'src/app/locales/dayjs-locales';
import localesJson from 'src/app/locales/locales.json';
import {
  LanguageList,
  Language,
  Dictionary,
  DictionaryFile,
  LanguageCode,
} from './classes/language';
import webApp_defaultJson from '../../../../assets/languages/web-app_default.json';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { DataManagementService } from '../data-management/data-management.service';
import { DateFormats } from './enums/date-formats.enum';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class MultiLanguageMessageService extends MessageService {
  dictionaries: Dictionary[] = [];
  private languages: Language[] = [];
  public defaultLocaleId = webApp_defaultJson.defaultLanguage.localeId;

  /**
   * コンストラクタ
   *
   * @param localeId ロケールID
   * @param intlService 国際化サービス
   * @param http HTTPクライアント
   * @param dataManagement データ管理サービス
   * @param toastService トーストサービス
   */
  /**
   * constructor
   *
   * @param localeId Locale ID
   * @param intlService Internationalization service
   * @param http HTTP client
   * @param dataManagement Data management service
   * @param toastService Toast service
   */
  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    private http: HttpClient,
    private dataManagement: DataManagementService,
    private toastService: ToastService,
  ) {
    super();
    // デフォルト言語をリストに追加
    // Add default language to list
    this.languages.push(
      new Language(
        webApp_defaultJson.defaultLanguage.localeId,
        webApp_defaultJson.defaultLanguage.languageName,
        webApp_defaultJson.defaultLanguage.version,
        null,
        webApp_defaultJson.defaultLanguage.temperature as 'Fahrenheit' | 'Celsius',
        webApp_defaultJson.defaultLanguage.dateFormats,
        webApp_defaultJson.defaultLanguage.decimal as '.' | ',',
        webApp_defaultJson.defaultLanguage.csvDelimiter as ',' | ';',
      ),
    );
    // KendoUIのロケール情報を上書きする
    // Overwrite locale information of KendoUI
    setData({
      name: localesJson.locales.find(
        (locale) => locale.localeId === webApp_defaultJson.defaultLanguage.localeId,
      ).kendoLocale,
      numbers: {
        symbols: {
          decimal: webApp_defaultJson.defaultLanguage.decimal,
          group: '',
        },
      },
    });

    // デフォルト辞書をロード
    // Load default dictionary
    this.dictionaries[webApp_defaultJson.defaultLanguage.localeId] = new Dictionary(
      webApp_defaultJson.defaultDictionary.version,
      webApp_defaultJson.defaultDictionary.language,
      [],
    );
    for (let index = 0; index < webApp_defaultJson.defaultDictionary.items.length; index += 1) {
      this.dictionaries[webApp_defaultJson.defaultLanguage.localeId].items[index] = Object.assign(
        {},
        webApp_defaultJson.defaultDictionary.items[index],
      );
    }
  }

  /**
   * 言語リストファイルを読み込む
   *
   * @param {boolean} force リスト読み込み可否
   * @return {Language[]} 言語リスト
   */
  /**
   * Read language list file
   *
   * @param {boolean} force List readability
   * @return {Language[]} Language list
   */
  getLanguageList(force: boolean = true): Observable<Language[]> {
    // 起動時の言語に合わせた辞書ファイルを取得する
    // Get dictionary file according to the language at startup
    this.localeId = this.dataManagement.localStorage('localeId');
    if (!this.localeId) {
      this.localeId = webApp_defaultJson.defaultLanguage.localeId;
    }
    const storageDictionaryVersion = this.dataManagement.localStorage('dictionaryVersion');
    const storageDictionaryLanguage = this.dataManagement.localStorage('dictionaryLanguage');
    const storageDictionary = this.dataManagement.localStorage('dictionary');

    if (force) {
      return this.http
        .get<LanguageList>(
          `./assets/languages/web-app_list_v${webApp_defaultJson.defaultLanguage.version.replace(
            '.',
            '_',
          )}.json`,
        )
        .pipe(
          tap((data) => {
            let version = '';
            for (const item of data.items) {
              // 既存のリストに同じロケールがあるか確認
              // Check if existing list has same locale
              const language = this.languages.find((element) => {
                return element.localeId === item.localeId;
              });

              if (language) {
                // 同じロケールがあれば上書き
                // Overwrite if same locale
                language.version = item.version;
                language.dictionary = item.dictionary;
                language.temperature = item.temperature;
                language.decimal = item.decimal;
                language.csvDelimiter = item.csvDelimiter;
              } else {
                this.languages.push(
                  new Language(
                    item.localeId,
                    item.languageName,
                    item.version,
                    item.dictionary,
                    item.temperature,
                    item.dateFormats,
                    item.decimal,
                    item.csvDelimiter,
                  ),
                );
                // KendoUIのロケール情報を上書きする
                // Overwrite locale information of KendoUI
                setData({
                  name: localesJson.locales.find((locale) => locale.localeId === item.localeId)
                    .kendoLocale,
                  numbers: {
                    symbols: {
                      decimal: item.decimal,
                      group: '',
                    },
                  },
                });
              }
            }
            if (
              this.localeId !== webApp_defaultJson.defaultLanguage.localeId &&
              storageDictionaryVersion &&
              storageDictionaryLanguage &&
              storageDictionary
            ) {
              // WebStorageの辞書バージョンと最新の辞書バージョンを比較する
              // Compare WebStorage dictionary version with latest dictionary version
              const lang = data.items.find((item) => item.localeId === this.localeId);
              if (lang) {
                version = lang.version;
              }
              // 辞書バージョンが変わっていなければWebStorageの辞書データを読み込む
              // If the dictionary version has not changed, load WebStorage dictionary data
              if (parseFloat(version) === parseFloat(storageDictionaryVersion)) {
                this.dictionaries[this.localeId] = new Dictionary(
                  storageDictionaryVersion,
                  storageDictionaryLanguage,
                  JSON.parse(storageDictionary),
                );
              }
            }
            this.changeLocale(this.localeId).subscribe();
          }),
          map((data) => this.languages),
          // 言語リスト取得失敗時はデフォルト言語のみの言語リストを返却
          // Return language list of default language only when language list acquisition fails
          catchError(() => {
            this.localeId = webApp_defaultJson.defaultLanguage.localeId;
            this.toastService.openToast('error', 'center', this.dictionary('sidPleaseRedisplay'));
            const errorLanguages = [];
            errorLanguages.push(
              new Language(
                webApp_defaultJson.defaultLanguage.localeId,
                webApp_defaultJson.defaultLanguage.languageName,
                webApp_defaultJson.defaultLanguage.version,
                null,
                webApp_defaultJson.defaultLanguage.temperature as 'Fahrenheit' | 'Celsius',
                webApp_defaultJson.defaultLanguage.dateFormats,
                webApp_defaultJson.defaultLanguage.decimal as '.' | ',',
                webApp_defaultJson.defaultLanguage.csvDelimiter as ',' | ';',
              ),
            );
            return new Observable<Language[]>((observer) => {
              observer.next(errorLanguages);
              observer.complete();
            });
          }),
        );
    }

    return new Observable((observer) => {
      observer.next(this.languages);
      observer.complete();
    });
  }

  /**
   * 言語切り替え時にロケールと辞書ファイルを変更する
   *
   * @param {string} localeId ロケールID
   */
  /**
   * Change locale and dictionary files when switching languages
   *
   * @param {string} localeId Locale ID
   */
  changeLocale(localeId: string): Observable<void> {
    this.localeId = localeId;
    this.dataManagement.setLocalStorage('localeId', localeId);

    (<CldrIntlService>this.intlService).localeId = localeId;

    // html要素のlang属性に言語を設定する
    // Set language to LANG attribute of HTML element
    document.documentElement.lang = localeId.split('-')[0];

    const language = this.languages.find((item) => item.localeId === localeId);

    if (!language) {
      return new Observable((observer) => {
        observer.error();
        observer.complete();
      });
    }
    // メモリに辞書データが存在し、かつエラーによりデフォルト辞書が読み込まれている状態でなければメモリからロード
    // If dictionary data exists in memory and the default dictionary is not loaded due to an error, load from memory
    if (
      this.dictionaries[this.localeId] &&
      (this.localeId === webApp_defaultJson.defaultLanguage.localeId ||
        this.dictionaries[this.localeId].language !== webApp_defaultJson.defaultDictionary.language)
    ) {
      // デフォルト言語以外はWebStorageに辞書データを格納
      // Store dictionary data in WebStorage for languages other than the default language
      if (
        this.localeId !== webApp_defaultJson.defaultLanguage.localeId &&
        this.dictionaries[this.localeId].language !== webApp_defaultJson.defaultDictionary.language
      ) {
        this.dataManagement.setLocalStorage(
          'dictionaryVersion',
          this.dictionaries[language.localeId].version,
        );
        this.dataManagement.setLocalStorage(
          'dictionaryLanguage',
          this.dictionaries[language.localeId].language,
        );
        this.dataManagement.setLocalStorage(
          'dictionary',
          JSON.stringify(this.dictionaries[language.localeId].items),
        );
      } else {
        // デフォルト言語に変更した場合は変更前言語の辞書データを削除
        // If you change to the default language, delete the dictionary data of the language before the change
        this.dataManagement.setLocalStorage('dictionaryVersion', '');
        this.dataManagement.setLocalStorage('dictionaryLanguage', '');
        this.dataManagement.setLocalStorage('dictionary', '');
      }
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }
    // 辞書データが存在しない、またはエラーによりデフォルト辞書が読み込まれている場合は辞書ファイルから取得
    // If the dictionary data does not exist or the default dictionary is loaded due to an error, it is retrieved from the dictionary file
    if (this.localeId === webApp_defaultJson.defaultLanguage.localeId) {
      return this.setDefaultDictionary(language);
    }
    return this.getDictionaries(language);
  }

  /**
   * リソースIDに対応する現在の言語での翻訳内容を返す
   *
   * @param {string} id リソースID
   * @return {string} 翻訳語の文言
   */
  /**
   * Returns the translation contents in the current language corresponding to the resource ID
   *
   * @param {string} id Resource ID
   * @return {string} Translation wording
   */
  dictionary(id: string, ...args: (string | number)[]): string {
    if (this.dictionaries[this.localeId]) {
      const resource = this.dictionaries[this.localeId].items.find((item) => item.id === id);
      if (resource) {
        if ('value' in resource) {
          let sentence = resource.value;
          if (args.length > 0) {
            sentence = sprintf(sentence, ...args);
          }
          return sentence;
        }
      }
    }
    return '-';
  }

  /**
   * 摂氏をロケールに合わせて単位付きに変換して返す
   *
   * @param {number} degree 摂氏
   * @param {boolean} withUnit 単位を付与する
   * @return {string} 変換した気温
   */
  /**
   * Returns Celsius converted to unit with locale
   *
   * @param {number} degree Celsius
   * @param {boolean} withUnit Grant units
   * @return {string} Converted temperature
   */
  temperature(degree: number, withUnit: boolean = true): string {
    let unit = '';
    let ret: number;
    const language = this.languages.find((item) => item.localeId === this.localeId);

    if (language && language.temperature === 'Fahrenheit') {
      // 華氏に変換し、小数点第2位を四捨五入
      // Convert to Fahrenheit and round to the first decimal place
      ret = (degree * 9) / 5 + 32;
      ret = Math.round(ret * 10) / 10;
      unit = '℉';
    } else {
      ret = degree;
      unit = '℃';
    }

    return ret.toString() + (withUnit ? unit : '');
  }

  /**
   * 日付整形
   * 言語毎に用意された日付フォーマットのうち、指定された形式に文字列として整形して返す。
   *
   * @param {DateFormats} format 整形フォーマット指定
   * @param {Date} dateObject 変換元の日付情報
   * @param {number} month 変換元の月情報(dateObjectが無効の場合のみ有効)
   * @param {number} day   変換元の日情報(dateObjectが無効の場合のみ有効)
   * @param {number} year  変換元の年情報(dateObjectが無効の場合のみ有効)
   * @return {string} 整形した日付
   */
  /**
   * Date formatting
   * Returns the date format prepared for each language as a
   * character string in the specified format.
   *
   * @param {DateFormats} format Formatting format specification
   * @param {Date} dateObject Source date information
   * @param {number} month Source month information (valid only when dateObject is invalid)
   * @param {number} day   Date information of conversion source
   *                       (valid only when dateObject is invalid)
   * @param {number} year  Source year information (valid only when dateObject is invalid)
   * @return {string} Formatted date
   */
  formatDateToString(
    format: DateFormats | string,
    dateObject?: Date,
    month?: number,
    day?: number,
    year?: number,
  ): string {
    const language = this.languages.find((item) => item.localeId === this.localeId);
    if (!language) {
      return '';
    }

    let source = dateObject;
    if (!source) {
      source = new Date(year ? year : new Date().getFullYear(), month - 1, day ? day : 1);
    }

    return dayjs(source)
      .locale(this.localeId.slice(0, 2))
      .format(typeof format === 'string' ? format : language.dateFormats[format]);
  }

  /**
   * 日付フォーマット取得
   * 言語毎に用意された日付フォーマットを返す。
   *
   * @param {DateFormats} format 整形フォーマット指定
   * @return {string} 日付フォーマット
   */
  /**
   * Get date format
   * Returns the date format prepared for each language.
   *
   * @param {DateFormats} format Formatting format specification
   * @return {string} Date format
   */
  dateFormat(format: DateFormats): string {
    const language = this.languages.find((item) => item.localeId === this.localeId);
    if (!language) {
      return '';
    }

    return language.dateFormats[format];
  }

  /**
   * 言語コード取得
   * 言語リスト情報から言語コード（ロケールを含まず）と言語名称を取り出し返却する。
   *
   * @return 言語コードと名称の配列
   */
  /**
   * Language code acquisition
   * Gets and returns the language code (excluding locale) and language name
   * from the language list information.
   *
   * @return Language coe and name array
   */
  languageCodes(): LanguageCode[] {
    const list: LanguageCode[] = [];
    // デフォルト言語の情報を格納
    // Stores default language information
    if (webApp_defaultJson.defaultLanguage && webApp_defaultJson.defaultLanguage.localeId) {
      list.push({
        code: webApp_defaultJson.defaultLanguage.localeId.slice(0, 2),
        name: webApp_defaultJson.defaultLanguage.languageName,
      });
    }
    // 追加辞書の情報を格納
    // 同じ言語コードは先勝ちとして纏める
    // Store additional dictionary information.
    // If it has the same language code, only the first information is valid.
    for (const language of this.languages) {
      const appendCode = language.localeId.slice(0, 2);
      if (!list.find((item) => item.code === appendCode)) {
        list.push({
          code: appendCode,
          name: language.languageName,
        });
      }
    }
    return list;
  }

  /**
   * 小数点変換
   * 言語毎に設定された小数点表記に変換する
   *
   * @param {string} value 変換対象数値
   * @param {boolean} isDefaultLanguage デフォルト言語の設定有無
   * @return {string} 小数点変換後の数値
   */
  /**
   * Decimal point conversion
   * Convert to decimal notation set for each language
   *
   * @param {string} value Numerical value to be converted
   * @param {boolean} isDefaultLanguage Whether to set the default language
   * @return {string} Number after decimal point conversion
   */
  decimal(value: string, isDefaultLanguage: boolean = false): string {
    let decimal: string;
    if (isDefaultLanguage) {
      decimal = webApp_defaultJson.defaultLanguage.decimal;
    } else {
      decimal = this.languages.find((item) => item.localeId === this.localeId).decimal;
    }
    if (!decimal || !value) {
      return value;
    }
    // 「.」を指定言語の小数点に変換して返す
    // Convert "." To the decimal point of the specified language and return
    return value.replace('.', decimal);
  }

  /**
   * 選択中の言語情報取得
   *
   * @returns {Language} ログイン時に選択された言語情報を返却する。
   */
  /**
   * Get selected language information
   *
   * @returns {Language} Returns the language information selected at login.
   */
  currentLanguage() {
    return this.languages.find((item) => item.localeId === this.localeId);
  }

  /**
   * 選択された言語の辞書ファイルを取得する
   *
   * @param {Language} language 言語情報
   */
  /**
   * Get dictionary file for selected language
   *
   * @param {Language} language Language information
   */
  private getDictionaries(language: Language): Observable<void> {
    const url = `./assets/languages/${language.dictionary}`;
    return this.http.get<DictionaryFile>(url).pipe(
      tap((data) => {
        this.dictionaries[language.localeId] = new Dictionary(data.version, data.language, []);
        for (let index = 0; index < data.items.length; index += 1) {
          this.dictionaries[language.localeId].items[index] = Object.assign({}, data.items[index]);
        }
        if (language.localeId !== webApp_defaultJson.defaultLanguage.localeId) {
          // デフォルト言語以外はWebStorageに辞書データを格納
          // Store dictionary data in WebStorage for languages other than the default language
          this.dataManagement.setLocalStorage(
            'dictionaryVersion',
            this.dictionaries[language.localeId].version,
          );
          this.dataManagement.setLocalStorage(
            'dictionaryLanguage',
            this.dictionaries[language.localeId].language,
          );
          this.dataManagement.setLocalStorage(
            'dictionary',
            JSON.stringify(this.dictionaries[language.localeId].items),
          );
        }
      }),
      map((data) => {}),
      // 辞書ファイル取得失敗時はデフォルト言語の辞書をロード
      // If dictionary file acquisition fails, load the default language dictionary
      catchError(() => {
        this.localeId = webApp_defaultJson.defaultLanguage.localeId;
        this.toastService.openToast('error', 'center', this.dictionary('sidPleaseRerun'));
        this.setDefaultDictionary(language);
        throw webApp_defaultJson.defaultLanguage.localeId;
      }),
    );
  }

  /**
   * 使用する辞書ファイルをデフォルト辞書に変更する
   *
   * @param {Language} language 言語情報
   */
  /**
   * Change the dictionary file to be used to the default dictionary
   *
   * @param {Language} language Language information
   */
  private setDefaultDictionary(language: Language): Observable<void> {
    this.dictionaries[language.localeId] = new Dictionary(
      webApp_defaultJson.defaultDictionary.version,
      webApp_defaultJson.defaultDictionary.language,
      webApp_defaultJson.defaultDictionary.items,
    );
    // デフォルト辞書から読み込むためWebStorageを消去
    // Clear WebStorage to read from default dictionary
    this.dataManagement.setLocalStorage('localeId', webApp_defaultJson.defaultLanguage.localeId);
    this.dataManagement.setLocalStorage('dictionaryVersion', '');
    this.dataManagement.setLocalStorage('dictionaryLanguage', '');
    this.dataManagement.setLocalStorage('dictionary', '');
    return new Observable((observer) => {
      observer.next();
    });
  }
}
