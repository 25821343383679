<app-form>
  <div class="password-confirmation-form">
    <div class="password-confirmation-form__title">
      {{ multiLanguageService.dictionary('sidNewPass') }}
    </div>

    <div class="password-confirmation-form__group" (keyup.enter)="onEnter()">
      <app-text-box type="password"
        placeholder="{{ multiLanguageService.dictionary('sidEnterNewPass') }}"
        [usePasswordPolicies]="true" [novalidation]="false" [isIconLeftAligned]="true"
        [formControl]="password" (ngModelChange)="onChangePassword()" [isIconRightAligned]="true"
        [passwordMaskIcon]="true">
        <div #textBoxIcon class="k-icon k-i-lock"></div>
      </app-text-box>
    </div>

    <div class="password-confirmation-form__group" (keyup.enter)="onEnter()">
      <app-text-box type="password"
        placeholder="{{ multiLanguageService.dictionary('sidVerifyNewPass') }}"
        [usePasswordPolicies]="false" [novalidation]="false" [isIconLeftAligned]="true"
        [formControl]="passwordVerify" (ngModelChange)="onChangePassword()"
        [isIconRightAligned]="true" [passwordMaskIcon]="true">
        <div #textBoxIcon class="k-icon k-i-lock"></div>
      </app-text-box>
    </div>

    <div class="password-confirmation-form__group-last">
      <app-select-box [data]="languages" [textField]="'displayName'" [valueField]="'localeId'"
        [valuePrimitive]="true" [ngModel]="localeId" (selectionChange)="onSelectionChange($event)">
      </app-select-box>
    </div>

    <div class="password-confirmation-form__error-message">
      <app-form-inline-message *ngIf="errorMessage">
        {{ multiLanguageService.dictionary(errorMessage) }}
      </app-form-inline-message>
    </div>

    <div class="password-confirmation-form__button">
      <app-button [disabled]="!isRegistrationEnabled" (generalButtonClick)="onSubmit()">
        {{ multiLanguageService.dictionary('sidRegistration') }}
      </app-button>
    </div>
  </div>
</app-form>