export class LanguageList {
  version: number;
  items: Language[];
}

export class Language {
  localeId: string;
  languageName: string;
  version: string;
  dictionary: string;
  temperature: 'Celsius' | 'Fahrenheit';
  dateFormats: string[];
  decimal: '.' | ',';
  csvDelimiter?: ',' | ';';

  /**
   * コンストラクタ
   *
   * @param localeId ロケールID
   * @param languageName 言語名
   * @param version 辞書バージョン
   * @param dictionary 辞書情報
   * @param temperature 温度単位
   * @param dateFormats 日付表示形式
   * @param decimal 小数点表記
   * @param csvDelimiter CSV区切り文字
   */
  /**
   * constructor
   *
   * @param localeId Locale ID
   * @param languageName Language name
   * @param version Dictionary version
   * @param dictionary Dictionary
   * @param temperature Temperature unit
   * @param dateFormats Date formats
   * @param decimal Decimal point notation
   * @param csvDelimiter csv separator
   */
  constructor(
    localeId: string,
    languageName: string,
    version: string,
    dictionary?: string,
    temperature?: 'Celsius' | 'Fahrenheit',
    dateFormats?: string[],
    decimal?: '.' | ',',
    csvDelimiter?: ',' | ';',
  ) {
    this.localeId = localeId;
    this.languageName = languageName;
    this.version = version;
    this.dictionary = dictionary;
    this.temperature = temperature;
    this.dateFormats = dateFormats;
    this.decimal = decimal;
    this.csvDelimiter = csvDelimiter;
  }
}

export class Dictionary {
  version: string;
  language: string;
  items: TranslationResource[];

  /**
   * コンストラクタ
   *
   * @param version 辞書バージョン
   * @param language 言語
   * @param items 辞書情報
   */
  /**
   * constructor
   *
   * @param version Dictionary version
   * @param language Language
   * @param items Dictionary information
   */
  constructor(version: string, language: string, items: TranslationResource[]) {
    this.version = version;
    this.language = language;
    this.items = items;
  }
}

export class DictionaryFile {
  version: string;
  language: string;
  items: TranslationResource[];
}

export class TranslationResource {
  id: string;
  value: string;
}

export class LanguageCode {
  code: string;
  name: string;
}
