import { Injectable } from '@angular/core';
import { Observable, from, timer, throwError, of } from 'rxjs';
import { map, retryWhen, mergeMap, tap } from 'rxjs/operators';
import { default as Auth } from '@aws-amplify/auth';

import { environment } from '../../../../../environments/environment';
import { Response } from '../interfaces/common/response';
import {
  EquipmentNamesRequest,
  EquipmentOperation,
  EquipmentEventPostRequest,
  EquipmentStatusRequest,
} from '../interfaces/equipment-service';
import { EquipmentEvents } from '../interfaces/equipment-service/equipment-events';
import { RestClient, apiVersion } from '../base/rest-client';
import { DataManagementService } from '../../data-management/data-management.service';
import { AuthorityManagerService } from '../../../../shared-main/components/authority-control/authority-manager.service';
import { ScreenId } from '../../../../shared-main/enums/screen-id.enum';

const pathOfEquipment = `equipment/${apiVersion}/`;
const limitOfRetryCount = 5;
@Injectable()
export class RestClientMonitoringService extends RestClient {
  private wss: WebSocket = null;
  private wssRetry = 0;
  private pingPongInterval = null as NodeJS.Timer;
  private pongResponseTimer = null as NodeJS.Timer;

  /**
   * コンストラクタ
   *
   * @param dataManagementService データ管理サービス
   * @param authorityManager 認証管理サービス
   */
  /**
   * constructor
   *
   * @param dataManagementService Data management service
   * @param authorityManager Authority management service
   */
  constructor(
    private dataManagementService: DataManagementService,
    private authorityManager: AuthorityManagerService,
  ) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-10. 遠隔監視サービス
  //  3-10. Remote monitoring service
  /////////////////////////////////////////////////////////////////////////////
  //  3-10-1. 機器管理
  //  3-10-1. Device management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * エッジ操作可否API
   * response body: EquipmentEdgeOperationGetResponse
   *
   * @param {string[]} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Edge operation availability API
   * response body: EquipmentEdgeOperationGetResponse
   *
   * @param {string[]} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEquipmentEdgeOperation(edgeId: string[]): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEquipment}edges/operation`,
      { edgeIds: edgeId },
    );
  }

  /**
   * 機器名称変更API
   * response body: none
   *
   * @param {EquipmentNamesRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device name change API
   * response body: none
   *
   * @param {EquipmentNamesRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postEquipmentNames(param: EquipmentNamesRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEquipment}names`,
      param,
    );
  }

  /**
   * 機器現在状態取得指示API
   * response body: EquipmentStatusResponse
   *
   * @param {EquipmentStatusRequest} param リクエストボディ
   * @param {boolean} [polling=false] ポーリングフラグ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device current status acquisition instruction API
   * response body: EquipmentStatusResponse
   *
   * @param {EquipmentStatusRequest} param Request body
   * @param {boolean} [polling=false] Polling flag
   * @return {Observable<Response>} status:HTTP status
   */
  postEquipmentStatus(
    param: EquipmentStatusRequest,
    polling: boolean = false,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEquipment}equipments/currentEquipmentStates/get`,
      param,
      '',
      polling,
      polling,
    );
  }

  /**
   * 機器操作設定API
   * response body: none
   *
   * @param {string} equipmentId 	機器ID
   * @param {EquipmentOperation} operation 機器操作指令項目
   * @param {string[]} queryParams クエリパラメータ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device operation setting API
   * response body: none
   *
   * @param {string} equipmentId 	Device ID
   * @param {EquipmentOperation} operation Equipment operation command items
   * @param {string[]} queryParams Query parameters
   * @return {Observable<Response>} status:HTTP status
   */
  postEquipmentEquipments(
    equipmentId: string,
    operation: EquipmentOperation,
    queryParams: string[],
  ): Observable<Response> {
    const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEquipment}equipments/${equipmentId}${query}`,
      operation,
    );
  }

  /**
   * 機器操作一括設定(機器IDリスト指定)API
   * response body: none
   *
   * @param {string[]} equipmentIdList 機器IDリスト
   * @param {EquipmentOperation} operation 機器操作指令項目
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Equipment operation batch setting (device ID list specification) API
   * response body: none
   *
   * @param {string[]} equipmentIdList Device ID List
   * @param {EquipmentOperation} operation Equipment operation command items
   * @return {Observable<Response>} status:HTTP status
   */
  postBatchEquipmentEquipments(
    equipmentIdList: string[],
    operation: EquipmentOperation,
  ): Observable<Response> {
    const requestBody = {
      equipmentIdList,
      operations: operation.operations,
    };
    if (operation.forcedOperation) {
      requestBody['forcedOperation'] = true;
    }
    const callback = (): Observable<Response> => {
      return this.restClientCommonService.request(
        'post',
        `${this.endPoint}${pathOfEquipment}equipments/equipments`,
        requestBody,
      );
    };
    return this.restClientCommonService.sqsRequest(callback);
  }

  /**
   * 機器アイコン取得API
   * response body: EquipmentIconResponse
   *
   * @param {string[]} edgeId エッジID
   * @param {string[]} zoneId ゾーンID
   * @param {string[]} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device icon acquisition API
   * response body: EquipmentIconResponse
   *
   * @param {string[]} edgeId Edge ID
   * @param {string[]} zoneId Zone ID
   * @param {string[]} equipmentId Device ID
   * @return {Observable<Response>} status:HTTP status
   */
  getEquipmentIcon(
    edgeId: string[],
    zoneId: string[],
    equipmentId: string[],
  ): Observable<Response> {
    const param = {
      edgeIds: edgeId,
    };

    if (zoneId.length > 0) {
      param['zoneIds'] = zoneId;
    }
    if (equipmentId.length > 0) {
      param['equipmentIds'] = equipmentId;
    }

    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEquipment}edges/icon`,
      param,
    );
  }

  /**
   * 機器アイコン変更API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} equipmentId 機器ID
   * @param {string} iconId アイコンID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device icon change API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} equipmentId Device ID
   * @param {string} iconId Icon ID
   * @return {Observable<Response>} status:HTTP status
   */
  putEquipmentIcon(edgeId: string, equipmentId: string, iconId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEquipment}edges/${edgeId}/equipments/${equipmentId}/icon`,
      { iconId },
    );
  }

  /**
   * 機器操作一括設定API
   * response body: none
   *
   * @param {string[]} zoneIds ゾーンIDリスト
   * @param {EquipmentOperation} operation 機器操作指令項目
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device operation batch setting API
   * response body: none
   *
   * @param {string[]} zoneIds Zone ID list
   * @param {EquipmentOperation} operation Equipment operation command items
   * @return {Observable<Response>} status:HTTP status
   */
  postEquipmentZones(zoneIds: string[], operation: EquipmentOperation): Observable<Response> {
    const requestBody = {
      zoneList: zoneIds,
      operations: operation.operations,
    };
    if (operation.forcedOperation) {
      requestBody['forcedOperation'] = true;
    }
    const callback = (): Observable<Response> => {
      return this.restClientCommonService.request(
        'post',
        `${this.endPoint}${pathOfEquipment}zones`,
        requestBody,
      );
    };
    return this.restClientCommonService.sqsRequest(callback);
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-10-2. イベント通知
  //  3-10-2. Event notification
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 機器イベント受信API
   * response body: none
   *
   * @param {EquipmentEventPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Device event reception API
   * response body: none
   *
   * @param {EquipmentEventPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postEquipmentEvents(param: EquipmentEventPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEquipment}events`,
      param,
    );
  }

  /**
   * 機器イベント通知API
   * response body: EquipmentEvents
   *
   * @param {string[]} edgeIds エッジID
   * @param {boolean} alertBarFlg アラートバーフラグ （アラートバーの呼び出しのみtrue）
   * @return {Observable<EquipmentEvents>} レスポンスボディ
   */
  /**
   * Device event notification API
   * response body: EquipmentEvents
   *
   * @param {string[]} edgeIds Edge ID
   * @param {boolean} alertBarFlg Alert Bar Flag (TRUE only for alert bar calls)
   * @return {Observable<EquipmentEvents>} Response body
   */
  wssEquipmentEventsReport(
    edgeIds: string[],
    alertBarFlg: boolean = false,
  ): Observable<EquipmentEvents> {
    // wss接続前に権限チェックを実施し、権限が無ければ強制ログアウト
    // Perform authority check before wss connection, forcibly logout if you do not have authority
    if (
      !this.authorityManager.apiAvailable(
        alertBarFlg ? ScreenId.ScreenCommon : this.restClientCommonService.getScreenId(),
        'equipmentEventNotification',
        'SOCKET',
        [],
      )
    ) {
      this.restClientCommonService.forceLogout('sidAccessDenied');
      return throwError(new Response(401, 'Unauthorized'));
    }
    return this.wssConnectAndRetry(edgeIds, alertBarFlg).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap(() => {
            this.wssRetry += 1;
            if (this.wssRetry <= limitOfRetryCount) {
              return timer(5000);
            }
            this.wssRetry = 0;
            return throwError(new Response(408, 'Request Timeout'));
          }),
        );
      }),
    );
  }

  /**
   * WebSocket接続処理
   *
   * @param {string[]} edgeIds エッジID
   * @param {boolean} alertBarFlg アラートバーフラグ （アラートバーの呼び出しのみtrue）
   * @return boolean
   */
  /**
   * WebSocket connection processing
   *
   * @param {string[]} edgeIds Edge ID
   * @param {boolean} alertBarFlg Alert Bar Flag (TRUE only for alert bar calls)
   * @return boolean
   */
  wssConnectAndRetry(edgeIds: string[], alertBarFlg: boolean): Observable<EquipmentEvents> {
    return new Observable((observer) => {
      this.wssClose();
      // 多物件管理アプリ対応: 必要ない処理なのでコメントアウト
      // multi-store-app: unnecessary process
      // this.wss = new WebSocket(environment.wss);

      // WebSocket接続を要求して1分以内にonopenが通知されない場合
      // 失敗と見なして接続リトライを行う
      // If onopen is not notified within 1 minute after requesting WebSocket connection,
      // it will be considered as a failure and connection retry will be performed
      const connectionTimer = setTimeout(() => {
        this.wssClose();
        // closeしたあとoncloseが呼ばれるが正常終了するため
        // リトライトリガとなるエラーを発生させる
        // Onclose is called after close, but since it ends normally,
        // an error that causes a retry trigger is generated.
        observer.error('connection error');
      }, 60 * 1000) as NodeJS.Timer;

      this.wss.onopen = (event) => {
        clearTimeout(connectionTimer);
        // 接続時
        // When connected
        this.wssRetry = 0;
        this.sendMessage(edgeIds, 'sendedge', alertBarFlg).catch(() => {
          this.wssClose();
          observer.error('user error');
        });
      };

      // 受信時
      // When receiving
      this.wss.onmessage = (event: MessageEvent) => {
        try {
          const eventData = JSON.parse(event.data);
          if (eventData.connectionId) {
            // sendedgeに対する返信
            // Reply to sendedge

            // 5分のping/pongインターバルを発行する(アイドル接続のタイムアウトが10分のため)
            // Issue a 5 minute ping / pong interval (because the idle connection timeout is 10 minutes)
            if (!this.pingPongInterval) {
              this.pingPongInterval = setInterval(() => {
                this.sendMessage(edgeIds, 'ping', alertBarFlg)
                  .then(() => {
                    if (this.pongResponseTimer) {
                      clearTimeout(this.pongResponseTimer);
                    }
                    // pingが10秒間応答なければ切断と見なす
                    // If ping does not respond for 10 seconds, it is considered disconnected
                    this.pongResponseTimer = setTimeout(() => {
                      this.wssClose();
                      observer.error('Pong response could not receive after sent ping message.');
                    }, 1000 * 10);
                  })
                  .catch(() => {
                    this.wssClose();
                    observer.error('user error');
                  });
              }, 1000 * 60 * 5);
            }
          } else if (eventData.pong) {
            // pingに対する返信
            // Reply to ping
            clearTimeout(this.pongResponseTimer);
            this.pongResponseTimer = null;
          } else if (
            eventData.message &&
            eventData.message ===
              'User is not authorized to access this resource with an explicit deny'
          ) {
            // 権限エラー通知が届いた場合は強制ログアウト
            // Force logout when permission error notification is received
            this.restClientCommonService.forceLogout('sidAccessDenied');
          } else if (eventData.events && eventData.events.length > 0) {
            // イベント通知
            // Event notification
            observer.next(eventData);
          }
        } catch (error) {}
      };

      // 切断時
      // When cutting
      this.wss.onclose = (event) => {
        clearTimeout(connectionTimer);
        if (
          event &&
          (1000 === event.code ||
            (1002 <= event.code && event.code <= 1005) ||
            (1007 <= event.code && event.code <= 1011) ||
            event.code === 1015)
        ) {
          this.wssRetry = 0;
        } else {
          observer.error('closed');
        }
      };

      this.wss.onerror = (event) => {
        console.error('websocket onerror', event);
      };
    });
  }

  /**
   * WebSocketクローズ
   *
   */
  /**
   * WebSocket close
   *
   */
  wssClose() {
    if (this.wss) {
      this.wss.close(1000);
      this.wss = null;
    }
    clearInterval(this.pingPongInterval);
    this.pingPongInterval = null;
    clearTimeout(this.pongResponseTimer);
    this.pongResponseTimer = null;
  }

  /**
   * WebSocketメッセージ送信処理
   *
   * @param {string[]} edgeIds エッジID
   * @param {string} action 操作
   * @param {boolean} alertBarFlg アラートバーフラグ （アラートバーの呼び出しのみtrue）
   * @return Promise<void>
   */
  /**
   * WebSocket message transmission processing
   *
   * @param {string[]} edgeIds Edge IDs
   * @param {string} action Operation
   * @param {boolean} alertBarFlg Alert Bar Flag (TRUE only for alert bar calls)
   * @return Promise<void>
   */
  private sendMessage(edgeIds: string[], action: string, alertBarFlg: boolean): Promise<void> {
    let buildingId = 'NONE';
    if (this.dataManagementService.building().id) {
      buildingId = this.dataManagementService.building().id;
    }

    if (alertBarFlg) {
      buildingId = 'ALL';
    }

    return Auth.currentAuthenticatedUser().then((user) => {
      const data = {
        action,
        data: {
          buildingId,
          edgeIds,
          idToken: user.signInUserSession.idToken.jwtToken,
          screenId: alertBarFlg
            ? ScreenId.ScreenCommon
            : this.restClientCommonService.getScreenId(),
          url: 'equipmentEventNotification',
          httpMethod: 'SOCKET',
        },
      };
      this.wss.send(JSON.stringify(data));
    });
  }
}
