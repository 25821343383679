import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * メール形式のバリデーター
 * @param control フォームコントロール
 * @returns メール形式のバリデーション
 */
/**
 * Email format validator
 * @param control Form control
 * @returns Email format validation
 */
export function emailFormatValidator(control: AbstractControl): ValidationErrors {
  // すべて半角文字、最小1文字 + 「@」 + 最小1文字 + 「.(ドット)」 + 最小2文字
  // All are half -width characters, minimum 1 character + 「@」 + Minimum character + '.' + Minimum 2 characters
  const validEmailFormat: boolean = /^[ -~]+@[ -~]+\.[ -~]{2,}$/.test(control.value);
  return validEmailFormat ? null : { invalidEmailFormat: true };
}
