<app-form>
  <div class="sign-up-form">
    <div class="sign-up-form__title">{{ multiLanguage.dictionary('sidConfirmTemporaryPass') }}</div>

    <div class="sign-up-form__group" (keyup.enter)="onEnter()">
      <app-text-box
        placeholder="{{ multiLanguage.dictionary('sidEmail') }}"
        [novalidation]="false"
        [isIconLeftAligned]="true"
        [formControl]="email"
      >
        <div #textBoxIcon class="k-icon k-i-user"></div>
      </app-text-box>
    </div>
    <div class="sign-up-form__group" (keyup.enter)="onEnter()">
      <app-text-box
        type="password"
        placeholder="{{ multiLanguage.dictionary('sidTemporaryPass') }}"
        [novalidation]="false"
        [isIconLeftAligned]="true"
        [formControl]="temporaryPassword"
        [isIconRightAligned]="true"
        [passwordMaskIcon]="true"
      >
        <div #textBoxIcon class="k-icon k-i-lock"></div>
      </app-text-box>
    </div>
    <!-- <div class="sign-up-form__language">
      <app-select-box [data]="languages" [textField]="'displayName'" [valueField]="'localeId'"
        [valuePrimitive]="true" [ngModel]="localeId" (selectionChange)="onSelectionChange($event)">
      </app-select-box>
    </div> -->
    <div class="sign-up-form__error-message">
      <app-form-inline-message *ngIf="submitted && errorMessage">
        {{ multiLanguage.dictionary(errorMessage) }}
      </app-form-inline-message>
    </div>

    <div class="sign-up-form__button">
      <app-button [disabled]="!isConfirmEnabled" (generalButtonClick)="onSubmit()">
        {{ multiLanguage.dictionary('sidConfirm') }}
      </app-button>
    </div>
  </div>
</app-form>
