import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import {
  ErrorMailNotificationPutRequest,
  ErrorMailNotificationPostRequest,
  ErrorMailNotificationStateRequest,
  TrialMailRequest,
} from '../interfaces/mail-notification-service';
import { RestClient, apiVersion } from '../base/rest-client';

const pathOfmailNotification = `mailNotification/${apiVersion}/buildings/`;

@Injectable()
export class RestClientMailNotificationService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-27. 異常メール通知サービス(Abnormal email notification service)
  /////////////////////////////////////////////////////////////////////////////
  // 3-27-1. 異常メール通知(Abnormal email notification)
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 異常メール通知先設定登録API
   * response body: ErrorMailNotificationPostResponse
   *
   * @param {string} buildingId 物件ID
   * @param {ErrorMailNotificationPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Abnormal email notification destination setting registration API
   * response body: ErrorMailNotificationPostResponse
   *
   * @param {string} buildingId Property ID
   * @param {ErrorMailNotificationPostRequest} param Request body
   * @return {Observable <Response>} status: HTTP status
   */
  postMailNotification(
    buildingId: string,
    param: ErrorMailNotificationPostRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/mailNotifications`,
      param,
      this.screenId,
    );
  }

  /**
   * 異常メール通知先設定取得API
   * response body: ErrorMailNotificationGetResponse
   *
   * @param {string} buildingId 物件ID
   * @param {string} mailNotificationId 異常メール通知先設定ID
   * @param {boolean} [copyFlg = false] 複製フラグ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Abnormal email notification destination setting acquisition API
   * response body: ErrorMailNotificationGetResponse
   *
   * @param {string} buildingId Property ID
   * @param {string} mailNotificationId Abnormal mail notification destination setting ID
   * @param {boolean} [copyFlg = false] Duplicate flag
   * @return {Observable <Response>} status: HTTP status
   */
  getMailNotification(
    buildingId: string,
    mailNotificationId: string,
    copyFlg: boolean = false,
  ): Observable<Response> {
    let query = '';
    if (copyFlg) {
      query = `?copyFlg=1`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/mailNotifications/${mailNotificationId}${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 異常メール通知先設定一覧取得API
   * response body: ErrorMailNotificationListResponse
   *
   * @param {string} buildingId 物件ID
   * @param {boolean} [copyFlg = false] 複製フラグ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Abnormal email notification destination setting list acquisition API
   * response body: ErrorMailNotificationListResponse
   *
   * @param {string} buildingId Property ID
   * @param {boolean} [copyFlg = false] Duplicate flag
   * @return {Observable <Response>} status: HTTP status
   */
  getMailNotificationList(buildingId: string, copyFlg: boolean = false): Observable<Response> {
    let query = '';
    if (copyFlg) {
      query = `?copyFlg=1`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/mailNotifications${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 異常メール通知先設定変更API
   * response body: none
   *
   * @param {string} buildingId 物件ID
   * @param {string} errorMailNotificationId 異常メール通知先設定ID
   * @param {ErrorMailNotificationPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Abnormal email notification destination setting change API
   * response body: none
   *
   * @param {string} buildingId Property ID
   * @param {string} errorMailNotificationId Abnormal mail notification destination setting ID
   * @param {ErrorMailNotificationPutRequest} param Request body
   * @return {Observable <Response>} status: HTTP status
   */
  putMailNotification(
    buildingId: string,
    errorMailNotificationId: string,
    param: ErrorMailNotificationPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/mailNotifications/${errorMailNotificationId}`,
      param,
      this.screenId,
    );
  }

  /**
   * 異常メール通知先設定削除API
   * response body: none
   *
   * @param {string} personId 人ID
   * @param {string} errorMailNotificationId 異常メール通知先設定ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Abnormal email notification destination setting deletion API
   * response body: none
   *
   * @param {string} personId personID
   * @param {string} errorMailNotificationId Abnormal mail notification destination setting ID
   * @return {Observable <Response>} status: HTTP status
   */
  deleteMailNotification(
    buildingId: string,
    errorMailNotificationId: string,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/mailNotifications/${errorMailNotificationId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 異常メール通知先設定の有効/無効状態更新API
   * response body: none
   *
   * @param {string} buildingId 物件ID
   * @param {string} errorMailNotificationId 異常メール通知先設定ID
   * @param {ErrorMailNotificationStateRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Enable / disable status update API for abnormal email notification destination settings
   * response body: none
   *
   * @param {string} buildingId Property ID
   * @param {string} errorMailNotificationId Abnormal mail notification destination setting ID
   * @param {ErrorMailNotificationStateRequest} param Request body
   * @return {Observable <Response>} status: HTTP status
   */
  stateMailNotification(
    buildingId: string,
    errorMailNotificationId: string,
    param: ErrorMailNotificationStateRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/mailNotifications/${errorMailNotificationId}/state`,
      param,
      this.screenId,
    );
  }

  /**
   * 試送メールAPI
   * response body: none
   *
   * @param {string} buildingId 物件ID
   * @param {TrialMailRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Trial email API
   * response body: none
   *
   * @param {string} buildingId Property ID
   * @param {TrialMailRequest} param Request body
   * @return {Observable <Response>} status: HTTP status
   */
  trialMail(buildingId: string, param: TrialMailRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfmailNotification}${buildingId}/trialMail`,
      param,
      this.screenId,
    );
  }
}
