import { Component } from '@angular/core';

import { GeneralButtonComponent } from 'src/app/common/components/general-button/general-button.component';

export enum ButtonVariant {
  Raised = 'raised',
  Outlined = 'outlined',
}

export enum ButtonAlignment {
  LeftAligned = 'leftAligned',
  CenterAligned = 'centerAligned',
}

export enum ButtonFlexibility {
  None = 'none',
  Full = 'full',
}

@Component({
  selector: 'app-button',
  templateUrl: '../../../common/components/general-button/general-button.component.html',
  styleUrls: ['../../../common/components/general-button/general-button.component.scss'],
})
export class ButtonComponent extends GeneralButtonComponent {
  /**
   * コンストラクター
   */
  /**
   * Constructor
   */
  constructor() {
    super();
  }
}
