import { Injectable } from '@angular/core';
import { Observable, catchError, from, map, of } from 'rxjs';

import { Response } from '../interfaces/common/response';
import {
  PersonPersonsPutRequest,
  PersonPersonsPostRequest,
  ErrorNotificationSettingPutRequest,
  PersonPersonsGetResponse,
} from '../interfaces/person-service';
import { RestClient, apiVersion } from '../base/rest-client';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { Item } from '../interfaces/person-service/person-persons-get';
import { HttpHeaders } from '@angular/common/http';
import Auth from '@aws-amplify/auth';

const pathOfPerson = `person/${apiVersion}/`;

@Injectable()
export class RestClientPersonService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-1. 人サービス
  //  3-1. Human service
  /////////////////////////////////////////////////////////////////////////////
  // 3-1-1. 人管理
  // 3-1-1. Personnel management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 人データ登録API
   * response body: PersonPersonsPostResponse
   *
   * @param {PersonPersonsPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Person data registration API
   * response body: PersonPersonsPostResponse
   *
   * @param {PersonPersonsPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postPersonPersons(param: PersonPersonsPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfPerson}persons`,
      param,
      this.screenId,
    );
  }

  /**
   * 人データ取得API
   * response body: PersonPersonGetResponse
   *
   * @param {string} personId 人ID
   * @param {boolean} [buildingList = false] 当該人IDに関連する物件一覧の取得有無
   * @param {boolean} [edgeIdList = false] 当該人IDに関連するエッジ一覧の取得有無
   * @param {boolean} [equipmentIdList = false] 当該人IDに関連する機器一覧の取得有無
   * @param {boolean} [zoneIdList = false] 当該人IDに関連するゾーン一覧の取得有無
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Human data acquisition API
   * response body: PersonPersonGetResponse
   *
   * @param {string} personId 人ID
   * @param {boolean} [buildingList = false] Acquisition of property list related to the person ID
   * @param {boolean} [edgeIdList = false] Whether to obtain an edge list related to the person ID
   * @param {boolean} [equipmentIdList = false] Acquisition of device list related to the person ID
   * @param {boolean} [zoneIdList = false] Whether to obtain a list of zones
   *                                       related to the person ID
   * @param {boolean} [force = false] Get information from backend without using cache
   * @return {Observable<Response>} status:HTTP status
   */
  getPersonPerson(
    personId: string,
    buildingList: boolean = false,
    edgeIdList: boolean = false,
    equipmentIdList: boolean = false,
    zoneIdList: boolean = false,
    force: boolean = false,
  ): Observable<Response<any>> {
    // Observableとして返す
    // Return as an Observable
    return new Observable<Response<any>>((observer) => {
      // 多物件管理アプリ対応: アプリで利用する箇所のみcognitoから発行された情報を利用し、利用しない箇所は固定のダミー情報を返す
      // multi-store-app:  Use only the information issued by Cognito for the parts of the app that require it,
      //                   and return fixed dummy information for parts that do not use it.
      // NOTE: このアプリでは自身のユーザー情報を取得するためにしか利用しない
      // NOTE: This application only uses it to obtain its own user information.
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const token = user.signInUserSession.idToken.jwtToken;
          const [_, payload] = token.split('.');
          const customPersonId = JSON.parse(atob(payload))['custom:personId'];
          const email = JSON.parse(atob(payload))['email'];

          // レスポンスボディを設定
          // setting response body
          const responseBody = {
            personId: customPersonId,
            // Eメールを名前として設定
            // Set email as the name
            name: email,
            email: email,
            emailNotificationLanguage: 'en',
            telephoneNumber: '170123578897',
            timeDisplay: '12h',
            roleId: '002',
            roleName: 'dummy admin',
            affiliateId: '001',
            affiliateName: 'dummy',
            errorNotificationSetting: {
              notificationSound: true,
              notificationSoundDuration: 1,
            },
            termsOfServiceState: 1,
            communicationErrorNotification: true,
            buildingIdList: [],
            companyName: '',
          };

          // レスポンス返却
          // return response
          observer.next({
            status: 200,
            data: responseBody,
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          });
        })
        .catch((error) => {
          observer.next({
            status: 500,
            data: { message: 'Error fetching Person info' },
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          });
        });
    });
  }

  /**
   * 人データ一覧取得API
   * response body: PersonPersonsGetResponse
   *
   * @param {string} nextKey 再開キー
   * @param {string} name ユーザ名指定
   * @param {string} roleId 役割指定
   * @param {string} affiliateId アフィリエイト指定
   * @param {string} companyName カンパニー名指定
   * @param {string} buildingId 物件指定
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Human data list acquisition API
   * response body: PersonPersonsGetResponse
   *
   * @param {string} nextKey Resume key
   * @param {string} name User name specification
   * @param {string} roleId Role designation
   * @param {string} affiliateId Affiliate designation
   * @param {string} companyName Company name designation
   * @param {string} buildingId Property designation
   * @return {Observable<Response>} status:HTTP status
   */
  getPersonPersons(
    nextKey: string = '',
    name: string = '',
    roleId: string = '',
    affiliateId: string = '',
    companyName: string = '',
    buildingId: string = '',
    includeLoginUser: boolean = false,
  ): Observable<Response<PersonPersonsGetResponse>> {
    const body = {};
    if (nextKey) {
      Object.assign(body, { nextKey });
    }
    if (name) {
      Object.assign(body, { name });
    }
    if (roleId) {
      Object.assign(body, { roleId });
    }
    if (affiliateId) {
      Object.assign(body, { affiliateId });
    }
    if (companyName) {
      Object.assign(body, { companyName });
    }
    if (buildingId) {
      Object.assign(body, { buildingId });
    }
    if (includeLoginUser) {
      Object.assign(body, { includeLoginUser });
    }
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfPerson}persons/get`,
      body,
      this.screenId,
    );
  }

  /**
   * 人データ一覧取得APIを繰り返し呼び出し、人データをすべて取得する。
   * 人データ一覧取得APIのレスポンスのnextKeyがなくなるまで繰り返す。
   * @param params 人データ一覧取得APIのリクエストボディ
   * @param loaderService ローダーサービス。引数に指定した場合は、ローディングアニメーションの表示制御を行う。
   * @returns 人データ一覧
   */
  /**
   * Call the person data list acquisition API repeatedly and acquire all human data.
   * Person Data List Acquisition Repeat until the nextKey of the API response disappears.
   * @param params Person Data List Acquisition API Request Body
   * @param loaderService Loader service.When designated as an argument,
   *  display control of loading animation is performed.
   * @returns People data list
   */
  getAllPersons(
    params: {
      name?: string;
      roleId?: string;
      affiliateId?: string;
      companyName?: string;
      buildingId?: string;
      includeLoginUser?: boolean;
    },
    loaderService?: LoaderService,
  ): Observable<Response<PersonPersonsGetResponse>> {
    return this.restClientCommonService.requestAllForPagedAPI<Item>((nextKey?: string) => {
      return this.getPersonPersons(
        nextKey,
        params.name,
        params.roleId,
        params.affiliateId,
        params.companyName,
        params.buildingId,
        params.includeLoginUser,
      );
    }, loaderService);
  }

  /**
   * 人データ変更API
   * response body: none
   *
   * @param {string} personId 人ID
   * @param {PersonPersonsPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Person data change API
   * response body: none
   *
   * @param {string} personId Person ID
   * @param {PersonPersonsPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putPersonPersons(personId: string, param: PersonPersonsPutRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfPerson}persons/${personId}`,
      { param },
      this.screenId,
    );
  }

  /**
   * 人データ削除API
   * response body: none
   *
   * @param {string} personId 人ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Human data deletion API
   * response body: none
   *
   * @param {string} personId Person ID
   * @return {Observable<Response>} status:HTTP status
   */
  deletePersonPersons(personId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfPerson}persons/${personId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 人データ取得API
   * response body: PersonPersonGetResponse
   *
   * @param {string} personId 人ID
   * @param {boolean} [buildingList = false] 当該人IDに関連する物件一覧の取得有無
   * @param {boolean} [edgeIdList = false] 当該人IDに関連するエッジ一覧の取得有無
   * @param {boolean} [equipmentIdList = false] 当該人IDに関連する機器一覧の取得有無
   * @param {boolean} [zoneIdList = false] 当該人IDに関連するゾーン一覧の取得有無
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Human data acquisition API
   * response body: PersonPersonGetResponse
   *
   * @param {string} personId Person ID
   * @param {boolean} [buildingList = false] Acquisition of property list related to the person ID
   * @param {boolean} [edgeIdList = false] Whether to obtain an edge list related to the person ID
   * @param {boolean} [equipmentIdList = false] Acquisition of device list related to the person ID
   * @param {boolean} [zoneIdList = false] Whether to obtain a list of zones
   *                                       related to the person ID
   * @return {Observable<Response>} status:HTTP status
   */
  private getPersons(
    personId: string,
    buildingList: boolean = false,
    edgeIdList: boolean = false,
    equipmentIdList: boolean = false,
    zoneIdList: boolean = false,
  ): Observable<Response> {
    let query = '';
    const queryArray: string[] = [];
    if (buildingList) {
      queryArray.push('buildingIdList');
    }
    if (edgeIdList) {
      queryArray.push('edgeIdList');
    }
    if (equipmentIdList) {
      queryArray.push('equipmentIdList');
    }
    if (zoneIdList) {
      queryArray.push('zoneIdList');
    }
    if (queryArray.length > 0) {
      query = `?param=${queryArray.join(',')}`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfPerson}persons/${personId}${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * 異常通知設定更新API
   * response body: none
   *
   * @param {string} personId 人ID
   * @param {ErrorNotificationSettingPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Error notification setting change API
   * response body: none
   *
   * @param {string} personId Person ID
   * @param {ErrorNotificationSettingPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putErrorNotificationSetting(
    personId: string,
    param: ErrorNotificationSettingPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfPerson}persons/errorNotificationSetting/${personId}`,
      param,
      'errorNotification',
    );
  }
}
