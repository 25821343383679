import { Component, OnInit } from '@angular/core';

import { BreadcrumbService } from 'src/app/main/main/services/breadcrumb/breadcrumb.service';

import { Breadcrumb } from './interfaces';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[];

  /**
   * コンストラクター関数
   * @param breadcrumbService パンくずリストサービス
   */
  /**
   * Constructor function
   * @param breadcrumbService Bread Crumb Service
   */
  constructor(private breadcrumbService: BreadcrumbService) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.breadcrumbService.$breadcrumbsStream.subscribe(
      (breadcrumbs: Breadcrumb[]) => (this.breadcrumbs = breadcrumbs),
    );
  }
}
