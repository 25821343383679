import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { LoginFormModel } from './components/login-form/login-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginFormModel: LoginFormModel;

  constructor() {}

  ngOnInit() {
    this.loginFormModel = new LoginFormModel();
  }
}
