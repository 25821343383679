import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CHARS_LENGTH } from 'src/app/shared-main/constants/chars-length';

/**
 * パスワードの最小値バリデーター
 * @param control フォームコントロール
 * @returns パスワードの最小値バリデーション
 */
/**
 * Password min length validator
 * @param control Form control
 * @returns Password min length validation
 */
export function passwordMinLengthValidator(control: AbstractControl): ValidationErrors {
  const validMinLength: boolean =
    control.value && control.value.length >= CHARS_LENGTH.MIN_PASSWORD;
  return validMinLength ? null : { invalidMinLength: true };
}

/**
 * パスワードの最大値バリデーター
 * @param control フォームコントロール
 * @returns パスワードの最大値バリデーション
 */
/**
 * Password max length validator
 * @param control Form control
 * @returns Password max length validation
 */
export function passwordMaxLengthValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  const validMaxLength: boolean =
    control.value && control.value.length <= CHARS_LENGTH.MAX_PASSWORD;
  return validMaxLength ? null : { invalidMaxLength: true };
}

/**
 * パスワードの整数バリデーター
 * @param control フォームコントロール
 * @returns パスワードの整数バリデーション
 */
/**
 * Password integer validator
 * @param control Form control
 * @returns Password integer validation
 */
export function passwordNumberValidator(control: AbstractControl): ValidationErrors {
  const hasNumber: boolean = new RegExp(/\d/).test(control.value);
  return hasNumber ? null : { requiresNumber: true };
}

/**
 * パスワードの大文字バリデーター
 * @param control フォームコントロール
 * @returns パスワードの大文字バリデーション
 */
/**
 * Password upper case validator
 * @param control Form control
 * @returns Password upper case validation
 */
export function passwordUpperCaseValidator(control: AbstractControl): ValidationErrors {
  const hasUpperCase: boolean = new RegExp(/[A-Z]/).test(control.value);
  return hasUpperCase ? null : { requiresUpperCase: true };
}

/**
 * パスワードの小文字バリデーター
 * @param control フォームコントロール
 * @returns パスワードの小文字バリデーション
 */
/**
 * Password lower case validator
 * @param control Form control
 * @returns Password lower case validation
 */
export function passwordLowerCaseValidator(control: AbstractControl): ValidationErrors {
  const hasLowerCase: boolean = new RegExp(/[a-z]/).test(control.value);
  return hasLowerCase ? null : { requiresLowerCase: true };
}

/**
 * パスワードの記号バリデーター
 * @param control フォームコントロール
 * @returns パスワードの記号バリデーション
 */
/**
 * Password special character validator
 * @param control Form control
 * @returns Password special character validation
 */
export function passwordSpecialCharacterValidator(control: AbstractControl): ValidationErrors {
  const hasSpecialCharacter: boolean = new RegExp(
    /[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]/,
  ).test(control.value);
  return hasSpecialCharacter ? null : { requiresSpecialCharacter: true };
}

/**
 * パスワード比較バリデーター
 * @param otherControl フォームコントロール
 * @returns パスワード比較バリデーション
 */
/**
 * Password match validator
 * @param otherControl Form control
 * @returns Password match validation
 */
export function passwordMatchValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const isMatch: boolean = control.value === otherControl.value;
    return isMatch ? null : { passwordsMismatch: true };
  };
}
