import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoaderVisible } from './interfaces/loader-visible-stream';
import { LoaderVisibleType } from './enums/loader-visible-type';

export const loaderMaxTime = 150000;

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loadingStateSubject: Subject<boolean> = new Subject<boolean>();
  private timeoutSubject: Subject<void> = new Subject<void>();
  private count: number = 0;
  private timeoutId: number | null = null;
  /**
   * ローダー表示時にshowLaoder()が複数呼ばれた場合、timeoutはshowLoader()で指定された値の最大値を設定する。
   */
  /**
   * When showLaoder() is called multiple times while displaying the loader,
   *  timeout sets the maximum value specified by showLoader().
   */
  private currentTimeout: number = 0;

  /**
   * ローダタイムアウト通知を購読する
   */
  /**
   * Subscribe to loader timeout notification
   */
  timeoutStream(): Observable<void> {
    return this.timeoutSubject.asObservable();
  }

  /**
   * ローディング状態を購読する
   */
  /**
   * Subscribe to the loading state
   */
  loadingState(): Observable<boolean> {
    return this.loadingStateSubject.asObservable();
  }

  /**
   * ローダーを表示する
   * @param timeout タイムアウト(ミリ秒)
   */
  /**
   * Show the loading animation
   * @param timeout Timeout (milliseconds)
   */
  showLoader(timeout: number = loaderMaxTime) {
    this.currentTimeout = Math.max(timeout, this.currentTimeout);
    this.count += 1;
    this.loadingStateSubject.next(false);
    this.setTimer(this.currentTimeout);
  }

  /**
   * ローダーを非表示にする
   */
  /**
   * Hide the loading animation
   */
  hideLoader() {
    if (this.count <= 0) {
      return;
    }
    this.count -= 1;
    if (this.count === 0) {
      this.currentTimeout = 0;
      this.loadingStateSubject.next(true);
      this.clearTimeout();
    }
  }

  /**
   * loaderVisibleStreamの変更通知を元にローダの表示を切り替える。
   * @param loaderVisibleStream ローダー表示切り替え要求
   */
  /**
   * Switch the loader display based on the change notification of loaderVisibleStream.
   * @param loaderVisibleStream Loader display switching request
   */
  updateLoaderFromStream(loaderVisibleStream: Observable<LoaderVisible>): void {
    loaderVisibleStream.subscribe((state) => {
      if (state.type === LoaderVisibleType.Show) {
        this.showLoader(state.timeout);
      } else if (state.type === LoaderVisibleType.Hide) {
        this.hideLoader();
      } else if (state.type === LoaderVisibleType.Restart) {
        this.hideLoader();
        this.showLoader(state.timeout);
      }
    });
  }

  /**
   * 表示されている全てのローダーを強制的にリセットする
   */
  /**
   * Reset all visible loading animations
   */
  forcedReset() {
    this.count = 0;
    this.clearTimeout();
    this.loadingStateSubject.next(true);
  }

  /**
   * ローダーが呼び出されたときにタイマーをセットする
   * @param timeout タイムアウト(ミリ秒)
   */
  /**
   * Set a timer when the loader is called
   * @param timeout Timeout (milliseconds)
   */
  private setTimer(timeout: number) {
    this.clearTimeout();
    this.timeoutId = window.setTimeout(() => this.loaderTimeOut(), timeout);
  }

  /**
   * タイムアウト処理を解除する。
   */
  /**
   * Cancel timeout processing.
   */
  private clearTimeout() {
    if (this.timeoutId !== null) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = null;
  }

  /**
   * タイムアウトが発生したとき、タイムアウトイベントを発行する。
   */
  /**
   * When a timeout occurs, issue a timeout event.
   */
  private loaderTimeOut() {
    console.error('LoaderService timeout.');
    this.timeoutSubject.next();
  }

  /**
   * ローダーが非表示かどうか
   * @returns ローダーが非表示の場合、true
   */
  /**
   * Whether the loader is hidden
   * @returns True if the loader is hidden
   */
  get hideLoaderStatus() {
    return this.count === 0;
  }
}
