import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  ErrorRead,
  StateErrorGetResponse,
} from 'src/app/core/services/rest-client/interfaces/shared-service/shared';

@Injectable()
export class RestClientSharedService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-7. 画面共通サービス
  //  3-7. Screen common service
  /////////////////////////////////////////////////////////////////////////////
  // 3-7-1. 異常状態管理
  // 3-7-1. Abnormal state management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 異常状態確認API
   * @param param.selectedBuildingId 選択物件ID
   * @return レスポンス
   */
  /**
   * Abnormal state confirmation API
   * @param param.selectedBuildingId Selected property ID
   * @return response
   */
  getStateError(param: {
    selectedBuildingId: string;
  }): Observable<Response<StateErrorGetResponse>> {
    const callback = (): Observable<Response> => {
      const selectedBuildingId = encodeURIComponent(param.selectedBuildingId);
      return this.restClientCommonService.request(
        'get',
        `${this.endPoint}state/${apiVersion}/states/error?selectedBuildingId=${selectedBuildingId}`,
        undefined,
        this.screenId,
      );
    };
    return this.restClientCommonService.sqsRequest(callback);
  }

  /**
   * 異常状態既読更新API
   * response body: none
   *
   * @param {ErrorRead} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Abnormal state read update API
   * response body: none
   *
   * @param {ErrorRead} param Request body
   * @return {Observable<Response>} status: HTTP status
   */
  postErrorRead(param: ErrorRead): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}state/${apiVersion}/states/read`,
      param,
      this.screenId,
    );
  }
}
