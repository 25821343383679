export enum OperationStatusColor {
  /** 混合色：運転青 */
  /** Mixed color: Operation blue */
  MixedColorOperationBlue,
  /** 混合色：運転緑 */
  /** Mixed color: Operation green */
  MixedColorOperationGreen,
  /** 混合色：運転赤、停止緑 */
  /** Mixed color: Operation red, Stop green */
  MixedColorOperationRedStopGreen,
  /** 混合色：運転緑、停止赤 */
  /** Mixed color: Operation green, Stop red */
  MixedColorOperationGreenStopRed,
  /** 単一色：運転緑 */
  /** Single color: Operation green */
  SingleColorOperationGreen,
  /** 単一色：運転赤 */
  /** Single color: Operation red */
  SingleColorOperationRed,
}
