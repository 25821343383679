import {
  deepMerge as commonUtilDeepMerge,
  deepCopy as commonUtilDeepCopy,
} from 'src/app/common/utils/clone';
import { roundedDigit as commonUtilRoundedDigit } from 'src/app/common/utils/round';
import { isNumber as commonUtilIsNumber } from 'src/app/common/utils/type';
import { keyboardCodes } from '../constants/keyboard-codes';
import {
  TreeListStructure,
  TreeListItem,
  ZoneListStructure,
  ZoneListItem,
  ZoneListType,
} from '../interfaces/tree-list';
import {
  TABLE_ROW_HEIGHT,
  SMARTPHONE_MAX_WIDTH,
  SMARTPHONE_HEADER_HEIGHT,
  OTHER_HEADER_HEIGHT,
} from 'src/app/shared-main/constants/breakpoints';
import { Injectable } from '@angular/core';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { ErrorCodes, ErrorCodePattern, ErrorCodeSetting } from '../interfaces/error-code-pattern';
import { BuildingBuildingGetResponse } from 'src/app/core/services/rest-client/interfaces/building-service';
import { SystemPackageGetResponse } from 'src/app/core/services/rest-client/interfaces/system-service/system-package';
import { ScreenId } from '../enums/screen-id.enum';
import { DataManagementService } from 'src/app/core/services/data-management/data-management.service';
import { AgentType } from 'src/app/common/enums/agent-type';
import { ResponseError } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { ApiType } from 'src/app/core/services/toast/api-type';
import { ErrorCodePatternSid } from 'src/app/shared-main/interfaces/error-code-pattern';

@Injectable()
export class Utility {
  // デフォルトエラーのSID
  // Default error SID
  static readonly defaultErrorSid = {
    [ApiType.get]: 'sidFailedAcquire',
    [ApiType.register]: 'sidFailedConfirmedSave',
    [ApiType.update]: 'sidFailedConfirmedSave',
    [ApiType.delete]: 'sidFailedDelete',
  };

  // システムエラーのエラーコード
  // System error error code
  static readonly systemErrorCodes = [
    // GPFクラウドAPIに接続エラー
    // Connection error to GPF cloud API
    'BEZ90002',
    'EZ90030',
    'EZ90031',

    // DB接続エラー
    // DB connection error
    'BEZ90001',

    // 権限エラー・処理エラー
    // Authorization error / processing error
    'BEW90001',
  ];

  // 通信エラーのステータスコード
  // Communication error status code
  static readonly communicationErrorStatus = [0, 408];
  /**
   * ObjectのDeepMerge
   *
   * @param {object}  target  マージ先
   * @param {object}  source  マージ元
   * @param {boolean} overwriteArray 配列上書きフラグ
   */
  /**
   * DeepMerge of object
   *
   * @param {object}  target  Destination
   * @param {object}  source  Source
   * @param {boolean} overwriteArray Array overwrite flag
   */
  static deepMerge(target: object, source: object, overwriteArray = false): object {
    return commonUtilDeepMerge(target, source, overwriteArray);
  }

  /**
   * ObjectのDeepCopy
   *
   * @param {any}  obj  複製元
   * @return {T}  複製したオブジェクト
   */
  /**
   * DeepCopy of object
   *
   * @param {any}  obj  Source
   * @return {T}  Dupliacted object
   */
  static deepCopy<T>(obj: any): T {
    return commonUtilDeepCopy(obj);
  }

  /**
   * 日付形式の変換
   *
   * @param {string}  dateFormat  変換前の日付形式
   * @return {string}  変換後の日付形式
   */
  /**
   * Date format conversion
   *
   * @param {string}  dateFormat  Date format before conversion
   * @return {string}  Date format after conversion
   */
  static convertDateFormat(dateFormat: string): string {
    return dateFormat.replace(/Y/gi, 'y').replace(/D/gi, 'd');
  }

  /**
   * 数値判定
   *
   * @param {any}  target 検査対象
   * @return {boolean} 検査結果 true: 数値, false: 非数値
   */
  /**
   * Numerical judgment
   *
   * @param {any}  target Inspected target
   * @return {boolean} Test result true: numeric, false: non-numeric
   */
  static isNumber(target: any): boolean {
    return commonUtilIsNumber(target);
  }

  /**
   * Array.prototype.flat()と同様の機能。
   * @param array 配列
   * @returns フラット化した配列
   */
  /**
   * Same function as Array.prototype.flat().
   * @param array Array
   * @returns Flattened array
   */
  static flatArray<T>(array: ReadonlyArray<ReadonlyArray<T>>): T[] {
    const result = [];
    for (const items of array) {
      for (const item of items) {
        result.push(item);
      }
    }
    return result;
  }

  /**
   * Array.prototype.flatMap()と同様の機能。
   * @param array 配列
   * @param callback 新しい配列の要素を生成する関数。
   * @returns 変換された配列
   */
  /**
   * The same function as Array.prototype.flatMap().
   * @param array Array
   * @param callback A function that creates a new array element.
   * @returns Converted array
   */
  static flatMapArray<T, V>(array: ReadonlyArray<T>, callback: (item: T) => V[]): V[] {
    return Utility.flatArray(array.map(callback));
  }

  /**
   * タブ可能要素を無効にする
   * @param parentElement 親要素
   */
  /**
   * Disable tabbable elements
   * @param parentElement Parent Element
   */
  static disableTabbableElements(parentElement: Document | HTMLElement = document) {
    parentElement
      .querySelectorAll(
        'button:not(:disabled), input:not(:disabled), ' +
          'select:not(:disabled), textarea:not(:disabled)',
      )
      .forEach((element: Element) => {
        element.setAttribute('disabled', '');
        element.setAttribute('data-disabled', '');
      });
    parentElement.querySelectorAll('[tabindex="0"]').forEach((element: Element) => {
      element.setAttribute('tabindex', '-2');
      element.setAttribute('data-disabled', '');
    });
  }

  /**
   * タブ可能要素を有効にする
   * @param parentElement 親要素
   */
  /**
   * Enable tabbable elements
   * @param parentElement Parent Element
   */
  static enableTabbableElements(parentElement: Document | HTMLElement = document) {
    parentElement
      .querySelectorAll(
        'button:disabled[data-disabled], input:disabled[data-disabled], ' +
          'select:disabled[data-disabled], textarea:disabled[data-disabled]',
      )
      .forEach((element: Element) => {
        element.removeAttribute('disabled');
        element.removeAttribute('data-disabled');
      });
    parentElement.querySelectorAll('[tabindex="-2"][data-disabled]').forEach((element: Element) => {
      element.setAttribute('tabindex', '0');
      element.removeAttribute('data-disabled');
    });
  }

  /**
   * 数値を指定桁単位になるよう丸め込む
   * ※指定桁未満の値を四捨五入する
   *
   * @param target 補正対象値
   * @param digit 指定桁(10の累乗数であること)
   * @return string 丸めた値
   */
  /**
   * Round numbers to specified digits
   * (Rounds values less than the specified digit)
   *
   * @param target Correction target value
   * @param digit Specified digit (must be a power of 10)
   * @return string Rounded value
   */
  static roundedDigit(target: number, digit: number): string {
    return commonUtilRoundedDigit(target, digit);
  }

  /**
   * スクロールを初期状態にリセットする
   */
  /**
   * Reset scroll to initial
   */
  static resetScroll() {
    const mainElement = document.getElementsByClassName('main');
    if (mainElement && mainElement[0]) {
      mainElement[0].scrollTo(0, 0);
    }
  }

  /**
   * キーボード有効キー判定メソッド
   *
   * @param code キーコード
   * @return {boolean} 有効キー判定
   */
  /**
   * Keyboard valid key determination method
   *
   * @param code Key code
   * @return {boolean} Valid key judgment
   */
  static isKeybordSelected(code: string): boolean {
    return keyboardCodes.find((item) => item === code) ? true : false;
  }

  /**
   * ツリーリストをツリーの階層ごとに名称昇順でソートする。
   * @param structures ツリーリスト構成。ソートする対象。
   * @param items ツリーリストアイテム
   */
  /**
   * Sort the tree list by name in ascending order by tree hierarchy.
   * @param structures Tree list structure. What to sort.
   * @param items Tree list item
   */
  static sortTreeListStructureByName(
    structures: TreeListStructure[],
    items: ReadonlyArray<TreeListItem>,
  ): void {
    const nameMap = new Map(items.map((item) => [item.id, item.name] as [string, string]));
    Utility.sortTreeListStructureByNameMap(structures, nameMap);
  }

  /**
   * ツリーリストをnameMapを元に階層ごとに名称昇順でソートする。
   * @param structures ツリーリスト構成。ソートする対象。
   * @param nameMap idがキーで名前が値のMap
   */
  /**
   * Sort the tree list by name in ascending order based on nameMap.
   * @param structures Tree list structure. What to sort.
   * @param nameMap Map with id as key and name as value
   */
  private static sortTreeListStructureByNameMap(
    structures: TreeListStructure[],
    nameMap: ReadonlyMap<string, string>,
  ): void {
    structures.sort((s1: TreeListStructure, s2: TreeListStructure): number => {
      const name1 = nameMap.get(s1.id);
      const name2 = nameMap.get(s2.id);
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
    for (const structure of structures) {
      if (structure.children) {
        Utility.sortTreeListStructureByNameMap(structure.children, nameMap);
      }
    }
  }

  /**
   * ゾーンをゾーンタイプ、ゾーン名でソートする。
   * ゾーンの種類がデフォルトゾーン、カスタムゾーンのみの場合は、
   * ゾーン名のみのソートでいいのでUtility.sortTreeListStructureByName()を使用すればよい。
   * ゾーンの種類が熱源ゾーン等を含む場合は、
   * ゾーンのタイプ順でもソートが必要なのでこの関数を使用する。
   * ソートの順はtypeOrderを省略した場合はデフォルト、熱源、カスタム、室外機の順。
   * 同じゾーンタイプは名前順でソートされる。
   * @param structures ツリーリスト構成。ソートする対象。
   * @param items ツリーリストアイテム
   * @param typeOrder ゾーンタイプのソート順
   */
  /**
   * Sort the zone with a zone type and zone name.
   * If the type of zone is only the default zone or a custom zone,
   * you can use the utility.sortTreeListStructureByName()
   * because you can use the sort only with the zone name.
   * If the type of the zone contains a heat source zone,
   * etc., this function is used because sorting is required in the type of zone type.
   * The order of the sort is the default, heat source, custom, and outdoor unit.
   * The same zone type is sorted in order of name.
   * @param structures Tree list configuration.Sort to sort.
   * @param items Tree list item
   * @param typeOrder Sort order of zone type
   */
  static sortZoneListStructure(
    structures: ZoneListStructure[],
    items: ReadonlyArray<ZoneListItem>,
    typeOrder: ReadonlyArray<ZoneListType> = ['default', 'heatsource', 'custom', 'outdoor'],
  ): void {
    const nameMap = new Map(items.map((item) => [item.id, item.name] as [string, string]));
    Utility.sortZoneListStructureByNameMap(structures, nameMap, typeOrder);
  }

  /**
   * ゾーンリストをnameMapを元に階層ごとに名称昇順でソートする。
   * @param structures ゾーンリスト構成。ソートする対象。
   * @param items ゾーンリストアイテム
   * @param typeOrder ゾーンタイプのソート順
   */
  /**
   * The zone list is sorted by name as a namemap, based on the name as an ascending order.
   * @param structures Zone list configuration.Sort to sort.
   * @param items Zone list item
   * @param typeOrder Sort order of zone type
   */
  private static sortZoneListStructureByNameMap(
    structures: ZoneListStructure[],
    nameMap: ReadonlyMap<string, string>,
    typeOrder: ReadonlyArray<ZoneListType>,
  ): void {
    structures.sort((s1: ZoneListStructure, s2: ZoneListStructure): number => {
      const typeIndex1 = typeOrder.indexOf(s1.type);
      const typeIndex2 = typeOrder.indexOf(s2.type);

      if (typeIndex1 > typeIndex2) {
        return 1;
      }
      if (typeIndex1 < typeIndex2) {
        return -1;
      }
      const name1 = nameMap.get(s1.id);
      const name2 = nameMap.get(s2.id);
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
    for (const structure of structures) {
      if (structure.children) {
        Utility.sortZoneListStructureByNameMap(structure.children, nameMap, typeOrder);
      }
    }
  }

  /**
   * ツリーリストアイテムをツリーリストの構成順にソートする。
   * 例えばツリーリストが以下の構造の場合、
   * - A
   *   - A-1
   *   - A-2
   * - B
   *   - B-1
   * - C
   *  アイテムは以下の順にソートされる。
   *  [A,A-1,A-2,B,B-1,C]
   * @param structures ツリーリスト構成。
   * @param items ツリーリストアイテム。ソートする対象。
   */
  /**
   * Tree list Sort items by tree list configuration order.
   * For example, if the tree list has the following structure,
   * - A
   *   - A-1
   *   - A-2
   * - B
   *   - B-1
   * - C
   *  Items are sorted in the following order:
   *  [A,A-1,A-2,B,B-1,C]
   * @param structures Tree list structure.
   * @param items Tree list item. What to sort.
   */
  static sortTreeListItemsByStucture(
    structures: ReadonlyArray<TreeListStructure>,
    items: TreeListItem[],
  ): void {
    const ids: string[] = Utility.getTreeListStructureIds(structures);
    const indexMap = new Map(ids.map((id, index) => [id, index] as [string, number]));

    items.sort((item1, item2) => {
      const index1 = indexMap.get(item1.id);
      const index2 = indexMap.get(item2.id);
      if (index1 > index2) {
        return 1;
      }
      if (index1 < index2) {
        return -1;
      }
      return 0;
    });
  }

  /**
   * ツリーリストからidを抽出し、配列を生成する。
   * 配列の順序はツリーリストの階層順。
   * 例えばツリーリストが以下の構造の場合、
   * - A
   *   - A-1
   * - B
   * - C
   * リストは[A, A-1,B,C]となる。
   *
   * @param structures ツリーリスト構成
   * @param ids Idを追加する配列。再帰関数内部で配列を共有するために使用。
   * @returns id一覧
   */
  /**
   * Extract the id from the tree list and generate an array.
   * The order of the array is the hierarchical order of the tree list.
   * For example, if the tree list has the following structure,
   * - A
   *   - A-1
   * - B
   * - C
   * The list is [A, A-1, B, C].
   *
   * @param structures Tree list structure
   * @param ids An array to add Id to. Used to share arrays inside recursive functions.
   * @returns id list
   */
  private static getTreeListStructureIds(
    structures: ReadonlyArray<TreeListStructure>,
    ids: string[] = [],
  ): string[] {
    for (const structure of structures) {
      ids.push(structure.id);
      for (const child of structure.children) {
        Utility.getTreeListStructureIds([child], ids);
      }
    }
    return ids;
  }

  /**
   * 日付文字列変換処理
   * 日付文字列(YYYY-MM-DDThh:mm:00)を日付に変換した場合に、iOSでは、UTC時刻扱いにより9時間ズレてしまうので、
   * 現地時刻扱いになるように、フォーマットを変換する(YYYY/MM/DD hh:mm:00)
   * @param convertBefore 変換前の文字列日付(YYYY-MM-DDThh:mm:00)
   * @returns 変換後の文字列日付(YYYY/MM/DD hh:mm:00)
   */
  /**
   * Date string conversion process
   * When the date character string (YYYY-MM-DDThh: mm: 00) is converted to a date,
   * it will be off by 9 hours due to UTC time handling on iOS.
   * Convert the format so that it is treated as the local time (YYYY / MM / DD hh: mm: 00)
   * @param convertBefore String date before conversion (YYYY-MM-DDThh: mm: 00)
   * @returns Converted string date (YYYY / MM / DD hh: mm: 00)
   */
  static convertStringDate(convertBefore: string) {
    return convertBefore.replace(/-/g, '/').replace('T', ' ');
  }

  /**
   * テーブルの最低限の高さを計算する
   * @param tableHeaderElement テーブルのヘッダーの要素
   * @param tableFooterElement テーブルのフッターの要素
   * @param margin テーブルのマージン
   * @returns 最低限のテーブルの高さ
   */
  /**
   * Return the minimum height of the table
   * @param tableHeaderHeight Table header elements
   * @param tableFooterHeight Table footer elements
   * @param margin Table margin
   * @returns Minimum table height
   */
  static calcMinTableHeight(
    tableHeaderClientHight: number = 0,
    tableFooterClientHeight: number = 0,
    margin: number = 0,
  ) {
    // 仮定のテーブル行3行分、テーブルヘッダーの高さ、テーブルフッタの高さ、マージンを加算
    // Add 3 assumed table rows, table header height, table footer height, and margin
    return TABLE_ROW_HEIGHT * 3 + tableHeaderClientHight + tableFooterClientHeight + margin;
  }

  /**
   * テーブルの高さを計算する
   * @param innerAndTableHeight 画面の高さ／テーブルの高さ
   * @param minTableHeight 最低限のテーブルの高さ
   * @param offsetHeight 補正する高さ
   * @returns テーブルの高さ
   */
  /**
   * Return the height of the table
   * @param innerAndTableHeight Screen height / table height
   * @param minTableHeight Minimum table height
   * @param offsetHeight Height to correct
   * @returns Table height
   */
  static calcTableHeight(
    innerAndTableHeight: number,
    minTableHeight: number,
    offsetHeight?: number,
  ) {
    let tableHeight = innerAndTableHeight;

    // innerAndTableHeightを指定していない場合の高さ設定
    // Setting the height when innerAndTableHeight is not specified
    if (tableHeight === null) {
      tableHeight = window.innerHeight - OTHER_HEADER_HEIGHT;
      if (window.innerWidth <= SMARTPHONE_MAX_WIDTH) {
        tableHeight = window.innerHeight - SMARTPHONE_HEADER_HEIGHT;
      }
    }

    const offset = offsetHeight ? offsetHeight : 0;
    return tableHeight - offset < minTableHeight ? minTableHeight : tableHeight - offset;
  }

  /**
   * テーブルの高さを取得する。
   * PC以外の場合undefined。
   * @param prop.otherElements テーブル以外の要素。
   * コンテンツ領域からこれらの要素の高さの合計を引いた領域をテーブルの領域とする。
   * @returns テーブルの高さ
   */
  /**
   * Get the height of the table.
   * Undefined if other than PC mode.
   * @param prop.otherElements Elements other than the table. The territory of the table,
   *  which has the sum of the height of these elements from the content area, is the area of the table.
   * @returns Table height
   */
  static getTableHeight(prop?: {
    otherElements?: readonly Element[] | HTMLCollectionOf<Element>;
  }): number | undefined {
    if (DataManagementService.userAgentType() !== AgentType.WindowsPC) {
      return undefined;
    }
    const [tableHeaderElement] = Array.from(
      document.getElementsByClassName('k-grid-header-wrap') as HTMLCollectionOf<HTMLElement>,
    );
    const minTableHeight = Utility.calcMinTableHeight(tableHeaderElement?.clientHeight ?? 0);

    const otherElements = prop?.otherElements ?? [];
    const otherElementsArray: readonly Element[] = Array.isArray(otherElements)
      ? otherElements
      : Array.from(otherElements);

    const offset = otherElementsArray
      .map((e) => e?.clientHeight ?? 0)
      .reduce((sum, height) => sum + height, 0);

    return Utility.calcTableHeight(null, minTableHeight, offset);
  }

  /**
   * レスポンスエラーが、ベースアプリAPIからのエラーかどうか判定する。
   * @param error エラー
   * @returns error is Response<ResponseErrors>
   */
  /**
   * It is determined whether the response error is an error from the base app API.
   * @param error error
   * @returns error is Response<ResponseErrors>
   */
  static isBaseAppError(error: unknown): error is Response<ResponseErrors> {
    if (error === undefined || error === null) {
      return false;
    }
    const errorData = (error as Response<ResponseErrors>).data;
    return (
      errorData !== undefined &&
      errorData !== null &&
      errorData.errors !== undefined &&
      errorData.errors.length !== 0
    );
  }

  /**
   * レスポンスエラーにステータス、エラーコードが含まれているかどうか判定する。
   * エラーコードを省略した場合は、エラーステータスが一致しているかどうか判定する。
   * @example
   * if(Utility.hasBaseAppErrorCode(error,{
   *  status:400,codes:['BEW90001','BEW90006','BEZ90001','EZ90030','EZ90031']})){
   *   // システムエラー
   * }
   * @param error エラー
   * @param patterns ステータス、エラーコードのエラーパターン。
   * @returns エラーパターンが含まれているかどうか。
   */
  /**
   * Determines whether the response error contains status and error code.
   * If the error code is omitted, it is determined whether the error status matches.
   * @example
   * if(Utility.hasBaseAppErrorCode(error,{
   *  status:400,codes:['BEW90001','BEW90006','BEZ90001','EZ90030','EZ90031']})){
   *   // System error
   * }
   * @param error error
   * @param patterns Status, error code error pattern.
   * @returns Whether an error pattern is included.
   */
  static hasBaseAppErrorCode(error: Response<unknown>, patterns: ErrorCodes): boolean {
    if (patterns.codes === undefined) {
      return error.status === patterns.status;
    }
    if (
      error.data !== undefined &&
      error.data !== null &&
      (error.data as any).errors !== undefined
    ) {
      const errorData = error.data as ResponseErrors;
      return errorData.errors.some(
        (e) => patterns.status === error.status && patterns.codes.some((code) => code === e.code),
      );
    }
  }

  /**
   * レスポンスエラーにステータスコード、エラーコードにマッチした値を取得する。
   * マッチしなかった場合はdefaultValueを返す。
   * @example
   *  const sid = this.openErrorToastFromMatchPattern(error, {
   *   items: [
   *     {
   *       pattern: {
   *         status: 403,
   *         codes: ['BEW90001', 'BEW90006', 'BEZ90001', 'BEZ90030', 'BEZ90031'],
   *       },
   *       value: 'sidSystemError',
   *     },
   *   ],
   *   defaultValue: 'sidFailedAcquire',
   * });
   * this.toastService.openToast('error','center',this.lang.dictionary(sid));
   * @param error error
   * @param pattern patterns
   * @returns match pattern value
   */
  /**
   * Acquire a value matched to a response error and an error code.
   * If not matched, it returns DefaultValue.
   * @example
   *  const sid = this.openErrorToastFromMatchPattern(error, {
   *   items: [
   *     {
   *       pattern: {
   *         status: 403,
   *         codes: ['BEW90001', 'BEW90006', 'BEZ90001', 'BEZ90030', 'BEZ90031'],
   *       },
   *       value: 'sidSystemError',
   *     },
   *   ],
   *   defaultValue: 'sidFailedAcquire',
   * });
   * this.toastService.openToast('error','center',this.lang.dictionary(sid));
   * @param error error
   * @param pattern patterns
   * @returns match pattern value
   */
  static matchBaseAppErrorCode<Value>(error: unknown, pattern: ErrorCodePattern<Value>): Value {
    if (error instanceof Response) {
      const param = pattern.items.find((param) =>
        Utility.hasBaseAppErrorCode(error, param.pattern),
      );
      if (param !== undefined) {
        return param.value;
      }
    }
    return pattern.defaultValue;
  }

  /**
   * エラーコードパターンを生成する。
   * 生成したエラーコードパターンはUtility.matchBaseAppErrorCode()の引数で使用する。
   * @param settings エラーコードパターン
   * @param defaultValue パターンに一致しなかった場合のデフォルト値
   * @returns エラーコードパターン
   */
  /**
   * Generate an error code pattern.
   * The generated error code pattern is used in the argument of utility.matchbaseaPperrorCode ().
   * @param settings Error code pattern
   * @param defaultValue Default value when it does not match the pattern
   * @returns Error code pattern
   */
  static createErrorCodePatterns<Value>(
    settings: readonly ErrorCodeSetting<Value>[],
    defaultValue: Value,
  ): ErrorCodePattern<Value> {
    return {
      items: settings.map((item) => ({
        pattern: {
          status: item.status,
          codes: item.codes,
        },
        value: item.value,
      })),
      defaultValue: defaultValue,
    };
  }

  /**
   * 色情報と不透明度をrgbaに変換する
   * @param hex 色情報
   * @param opacity 不透明度
   * @returns rgba
   */
  /**
   * Convert color information and opacity to rgba
   * @param hex color information
   * @param opacity opacity
   * @returns rgba
   */
  static convertHexToRgba(hex: string, opacity: number): string {
    let colorHex = hex.slice(0, 1) === '#' ? hex.slice(1) : hex;
    if (colorHex.length === 3) {
      colorHex =
        colorHex.slice(0, 1) +
        colorHex.slice(0, 1) +
        colorHex.slice(1, 2) +
        colorHex.slice(1, 2) +
        colorHex.slice(2, 3) +
        colorHex.slice(2, 3);
    }

    const [red, green, blue] = [
      colorHex.slice(0, 2),
      colorHex.slice(2, 4),
      colorHex.slice(4, 6),
    ].map((str) => parseInt(str, 16));

    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  }

  /**
   * 契約チェック
   * 下記を満たす場合は有効な契約、それ以外は無効な契約
   * ①物件データ取得API実施結果にトライアルもしくは有効のパッケージがある
   * ②①のパッケージのいずれかで遠隔応急運転設定が有効
   * ③①が無効の場合、エアネット専用物件または併売物件で、機器IDを元に該当の機器が紐づく系統の契約が有効
   * @param buildingData 物件データ
   * @param systemPackages パッケージ一覧
   * @param equipmentId 機器ID
   * @param airnetOnlyPackage エアネット専用パッケージ(機器系統)
   * @param screenId 画面ID
   * @returns チェック結果
   */
  /**
   * Contract check
   * ① There is a trial or valid package in the property data acquisition API implementation result
   * ② Remote emergency operation setting is valid in any of the packages ①
   * ③ If (1) is invalid, the contract for the system to which the relevant device is
   *    linked based on the device ID is valid for the property exclusively for Airnet or
   *    the property sold together.
   * @param buildingData building data
   * @param systemPackages package list
   * @param equipmentId equipment ID
   * @param airnetOnlyPackage Airnet only package (equipment system)
   * @param screenId screen ID
   * @returns check result
   */
  static checkAirnetContract(
    buildingData: BuildingBuildingGetResponse,
    systemPackages: SystemPackageGetResponse,
    equipmentId: string,
    airnetOnlyPackage: string,
    screenId: string,
  ): boolean {
    /**
     * 契約が有効かを判定
     */
    /**
     * Determine if the contract is valid
     */
    const isContractAvailable = () => {
      // 物件データ取得API結果が不正なら無効
      // Invalid if the property data acquisition API result is invalid
      if (
        buildingData === null ||
        buildingData.packageList === null ||
        buildingData.packageList.length === 0
      ) {
        return false;
      }
      // パッケージ一覧取得API結果が不正なら無効
      // Invalid if the package list acquisition API result is invalid
      if (
        systemPackages === null ||
        systemPackages.items === null ||
        systemPackages.items.length === 0
      ) {
        return false;
      }
      // 0:トライアルもしくは1:有効のパッケージを抽出
      // 0: Trial or 1: Extract valid packages
      const targetPackageList = buildingData.packageList.filter(
        (p) => p.status === 0 || p.status === 1,
      );
      // 抽出したパッケージの中に、機能が有効なものがあれば有効
      // Valid if some of the extracted packages have valid email notification settings
      for (const targetPackage of targetPackageList) {
        /**
         * パッケージIDからメール通知設定の有効を取得
         * @param packageId パッケージID
         */
        /**
         * Get valid email notification settings from package ID
         * @param packageId Package ID
         */
        const getAvailabilityByPackageId = (packageId: string) => {
          // 指定のパッケージがパッケージ一覧になければ無効
          // Invalid if the specified package is not in the package list
          const targetPackage = systemPackages.items.find((p) => p.packageId === packageId);
          if (targetPackage === null) {
            return false;
          }
          // 指定のパッケージで機能が有効であれば有効
          // Valid if email notification settings are valid for the specified package
          for (const functionStatus of targetPackage.functionStatusList) {
            if (functionStatus.subMenu.find((s) => s.screenId === screenId && s.availability)) {
              return true;
            }
          }
          // それ以外は無効
          // Otherwise invalid
          return false;
        };
        if (getAvailabilityByPackageId(targetPackage.packageId)) {
          return true;
        }
      }
      return false;
    };

    // 契約が無効かを判定
    // Determine if the contract is invalid
    if (!isContractAvailable()) {
      // 無効
      // Invalid
      // エアネット専用物件または併売物件か
      // Airnet exclusive property or joint sale property
      if (buildingData.isAirnetOnly || buildingData.isAirnetAndBase) {
        // 対象の室外機(系統)データを抽出
        // Extract target outdoor unit (system) data
        let equipment = null;
        for (const edge of buildingData.edgeList) {
          equipment = edge.equipmentList.find((equipment) => equipment.equipmentId === equipmentId);
          if (equipment) {
            break;
          }
        }
        // 対象の室外機(系統)の遠隔応急運転サービス契約の有効・無効チェック
        // Checking the validity / invalidity of the emergency operation service contract for the target outdoor unit (system)
        if (!equipment.airnetOnlyPackageIdList.includes(airnetOnlyPackage)) {
          // 無効
          // Invalid
          return false;
        }
      } else {
        return false;
      }
    }
    // 有効
    // Valid
    return true;
  }

  /**
   * 文字列から制御文字を除外する。
   * 除外する対象は以下の制御文字のうちタブ (U+0009)、改行 (U+000A)、復帰 (U+000D)以外。
   * - U+0000–U+001F
   * - U+007F–U+009F
   *
   * @param value 文字列
   * @returns 制御文字を除去した文字列
   */
  /**
   * Exclude control characters from strings.
   * The target to be excluded is a tab (U+0009), a line feed (U+000A), and a reversion (U+000D).
   * - U+0000–U+001F
   * - U+007F–U+009F
   *
   * @param value String
   * @returns String with control character removed
   */
  static removeControlCharacters(value: string): string {
    const pattern = /[\u0000-\u0008\u000B-\u000C\u000E-\u001F\u007F-\u009F]/g;
    return value.replace(pattern, '');
  }

  /**
   * システムエラーかどうかを判定する
   * @param error エラー
   * @returns システムエラーの場合、true
   */
  /**
   * Judge whether it is a system error
   * @param error error
   * @returns In the case of a system error
   */
  private static isSystemError(error: Response): boolean {
    const errorCode = this.getErrorCode(error);
    if (!errorCode) {
      return false;
    }
    if (this.systemErrorCodes.includes(errorCode)) {
      return true;
    }
    return false;
  }

  /**
   * エラーメッセージのSIDを取得する
   * @param error エラー情報
   * @param apiType API種別
   * @param errorPatterns 個別エラー処理のパターン
   * @param defaultErrorSid デフォルトのエラーメッセージSID
   * @returns エラーメッセージのSID
   */
  /**
   * Get the SID of the error message
   * @param error error
   * @param apiType API type
   * @param errorPatterns Individual error processing pattern
   * @param defaultErrorSid Default error message SID
   * @returns Error message SID
   */
  static getErrorMessageSid(
    error: Response,
    apiType: ApiType,
    errorPatterns: ErrorCodePatternSid[] = [],
    defaultErrorSid?: string,
  ): string {
    let sid = defaultErrorSid ? defaultErrorSid : this.defaultErrorSid[apiType];
    if (!error) {
      return sid;
    }

    // 個別のエラー判定
    // Individual error judgment
    const uniqueErrorSid = this.getUniqueErrorCheckSid(error, errorPatterns);
    if (uniqueErrorSid) {
      sid = uniqueErrorSid;
      return sid;
    }

    // 通信エラーの場合
    // In the case of a communication error
    if (this.communicationErrorStatus.includes(error.status)) {
      sid = 'sidServerErrorOccurred';
      return sid;
    }

    // システム障害エラー
    // System failure error
    if (this.isSystemError(error)) {
      sid = 'sidSystemError';
      return sid;
    }
    return sid;
  }

  /**
   * 個別のエラー処理を行い、sidを取得する
   * @param error エラー情報
   * @param errorPatterns 個別エラー処理のパターン
   * @returns 個別エラーのsid または undefinedの場合該当エラー無し
   */
  /**
   * Perform individual error processing and get SID
   * @param error Error information
   * @param errorPatterns Individual error processing pattern
   * @returns In the case of SID or undefined SID or undefined, there is no corresponding error
   */
  private static getUniqueErrorCheckSid(
    error: Response,
    errorPatterns: ErrorCodePatternSid[] = [],
  ): string {
    const errorCode = this.getErrorCode(error);
    const errorStatus = error.status;
    let errorPattern: ErrorCodePatternSid;

    // ステータスコードとエラーコードの判定
    // Judgment of status code and error code
    if (errorCode) {
      let errorPattern = errorPatterns.find(
        (item) => item.errorStatus === errorStatus && item.errorCode === errorCode,
      );
      if (errorPattern) {
        return errorPattern.sid;
      }

      // エラーコードの判定
      // Error code judgment
      errorPattern = errorPatterns.find(
        (item) =>
          item.errorCode === errorCode &&
          (item.errorStatus === null || item.errorStatus === undefined),
      );
      if (errorPattern) {
        return errorPattern.sid;
      }
    }

    // ステータスコードの判定
    // Status code judgment
    errorPattern = errorPatterns.find(
      (item) => item.errorStatus === errorStatus && !item.errorCode,
    );
    if (errorPattern) {
      return errorPattern.sid;
    }

    return;
  }

  /**
   * エラー情報からエラーコードを取得する
   * @param error エラー情報
   * @returns エラーコード
   */
  /**
   * Get error code from error information
   * @param error Error information
   * @returns Error code
   */
  private static getErrorCode(error: Response): string {
    if (!error || !error.data) {
      return;
    }
    const errors = error.data.errors as ResponseError[];
    if (!errors || errors.length === 0 || !errors[0]) {
      return;
    }
    return errors[0].code;
  }

  /**
   * lengthに指定された個数の長さを持つ配列を返す。
   * 要素は配列のindexとなる。
   * 例: Utility.indexes(5) => [0,1,2,3,4]
   * @param length 配列の長さ
   * @returns 数値の配列
   */
  /**
   * Returns an array with the length of the number specified in the count.
   * The element is the layout index.
   * Example: Utility.indexes(5) => [0,1,2,3,4]
   * @param length Length of array
   * @returns Numerical array
   */
  static indexes(length: number): readonly number[] {
    return [...Array(length)].map((_, i) => i);
  }

  /**
   * 配列を特定の個数ごとに小分けする。
   * @example Utility.chunk([1,2,3,4,5],2) => [[1,2],[3,4],[5]]
   * @param array 配列
   * @param chunkSize 小分けする個数
   * @returns 小分けした配列
   */
  /**
   * The array is subdivided for each specific number.
   * @example Utility.chunk([1,2,3,4,5],2) => [[1,2],[3,4],[5]]
   * @param array arrangement
   * @param chunkSize Number of subdivisions
   * @returns Subsized array
   */
  static chunk<T>(array: readonly T[], chunkSize: number): T[][] {
    const arraySize = Math.ceil(array.length / chunkSize);
    return Utility.indexes(arraySize).map((i) =>
      array.slice(i * chunkSize, i * chunkSize + chunkSize),
    );
  }

  /**
   * 指定された値の範囲内に丸める
   * @param value 値
   * @param min 最小値
   * @param max 最大値
   * @returns 丸められた値
   */
  /**
   * Round within the specified value
   * @param value value
   * @param min minimum value
   * @param max Maximum value
   * @returns Rounded value
   */
  static clamp(value: number, min: number, max: number): number {
    let result = value;
    result = Math.min(result, max);
    result = Math.max(result, min);
    return result;
  }

  /**
   * 小数点以下の末尾0を削除する
   * @example
   * Utility.trimDecimalZero('10.0230') => '10.023'
   * Utility.trimDecimalZero('10.0000') => '10'
   * @param value 数値を文字列かした値
   * @returns 末尾0を削除した数値(文字列)
   */
  /**
   * Delete the end 0 of the decimal point.
   * @example
   * Utility.trimDecimalZero('10.0230') => '10.023'
   * Utility.trimDecimalZero('10.0000') => '10'
   * @param value A value in which the numbers are strict
   * @returns Numerical values (character strings) deleted at the end 0
   */
  static trimDecimalZero(value: string): string {
    if (value.includes('.')) {
      return value.replace(/\.?0+$/, '');
    }
    return value;
  }

  /**
   * キー名でソートする
   * @param array 配列
   * @param key キー名
   * @param option.order ソート順
   * @returns ソートされた配列
   */
  /**
   * Sort with the key name
   * @param array arrangement
   * @param key Key name
   * @param option.order Sort order
   * @returns Sorted array
   */
  static sortByKey<Key extends string, T extends { [key in Key]: string | number }>(
    array: readonly T[],
    key: Key,
    option?: { order?: 'asc' | 'desc' },
  ): readonly T[] {
    const order = option?.order ?? 'asc';
    const orderValue = order === 'asc' ? 1 : -1;
    const result = [...array];
    result.sort((a, b) => {
      if (a[key] > b[key]) {
        return 1 * orderValue;
      }
      if (a[key] < b[key]) {
        return -1 * orderValue;
      }
      return 0;
    });
    return result;
  }
}
