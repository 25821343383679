import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
  Observable,
  throwError,
  timer,
  from,
  TimeoutError,
  of,
  Subject,
  OperatorFunction,
} from 'rxjs';
import { map, retryWhen, mergeMap, catchError, delay, tap, timeout } from 'rxjs/operators';
import { default as Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';
import { Response } from '../interfaces/common/response';
import { ResponseErrors, ResponseError } from '../interfaces/common/response-errors';
import { DataManagementService } from '../../data-management/data-management.service';
import { MultiLanguageMessageService } from '../../multi-language-message/multi-language-message.service';
import { ToastService } from '../../toast/toast.service';
import { LoaderService, loaderMaxTime } from '../../../components/loader/loader.service';
import { screenIdList } from '../classes/screen-id-list';
import { AuthorityManagerService } from '../../../../shared-main/components/authority-control/authority-manager.service';
import { ScreenId } from '../../../../shared-main/enums/screen-id.enum';
import { QueryParameter } from '../../../../shared-main/components/authority-control/classes/query-parameter';
import { RestClientCaches } from '../classes/rest-client-caches';
import { ErrorState } from '../../../../main/enums';
import { PackageManagementService } from '../../../../main/main/services/package-management/package-management.service';
import { limitOfTimeout, limitOfRetryCount, retryTriggerStatus } from '../constants/retry';
import { TransactionResult } from '../interfaces/common/transaction-result';
import { TransactionStatus } from '../enums/transaction-status.enum';
import { PagedData } from '../interfaces/common/paded-data';
import { LoaderVisibleType } from 'src/app/core/components/loader/enums/loader-visible-type';
import { LoaderVisible } from 'src/app/core/components/loader/interfaces/loader-visible-stream';
import { Utility } from 'src/app/shared-main/classes/utility';
import { CallbackSqsResponse, SqsRequestOption } from '../interfaces/common/sqs';

const incorrectAuthorityCode = [
  'BEZ99989',
  'BEZ99990',
  'BEZ99991',
  'BEZ99992',
  'BEZ99993',
  'BEZ99994',
  'BEZ99995',
  'BEZ99996',
  'BEZ99997',
  'BEZ99999',
];
export const apiVersionOrigin = 'v1';

@Injectable({
  providedIn: 'root',
})
export class RestClientCommonService {
  public endPoint = environment.backend;
  public authenticatedUser;
  public caches = new RestClientCaches();

  // 強制ログアウトフラグ
  // Force logout flag
  public forceLogoutFlag: boolean = false;

  private sessionTimer;
  private screenId = '';

  /**
   * コンストラクタ
   *
   * @param httpClient HTTPクライアント
   * @param router ルータ情報
   * @param dataManagementService データ管理サービス
   * @param multiLanguageMessageService 多言語サービス
   * @param toastService トーストサービス
   * @param loaderService ローダーサービス
   * @param authorityManager 認証管理サービス
   * @param packageManagement パッケージ管理サービス
   */
  /**
   * constructor
   *
   * @param httpClient HTTP client
   * @param router Router information
   * @param dataManagementService Data management service
   * @param multiLanguageMessageService Multi language service
   * @param toastService Toast service
   * @param loaderService Loader service
   * @param authorityManager Authority management service
   * @param packageManagement Package management service
   */
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataManagementService: DataManagementService,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private authorityManager: AuthorityManagerService,
    private packageManagement: PackageManagementService,
  ) {
    this.loaderService.timeoutStream().subscribe(() => {
      this.forceLogout('sidSystemError');
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  //  共通処理
  //  Common processing
  /////////////////////////////////////////////////////////////////////////////

  /**
   * APIリクエスト通信
   *
   * @param {string} method リクエストメソッド
   * @param {string} url リクエストURL
   * @param {object} [body] リクエストボディ
   * @param {string} [screenId=''] 画面ID
   * @param {boolean} [polling=false] ポーリングフラグ
   * @param {boolean} [retrySkip=false] リトライ対象外フラグ
   * @param {boolean} [generalErrorScreenSkip=false] 汎用エラー画面スキップフラグ
   * @return {Observable<Response>} APIレスポンス
   */
  /**
   * API request communication
   *
   * @param {string} method Request method
   * @param {string} url Request URL
   * @param {object} [body] Request body
   * @param {string} [screenId=''] Screen ID
   * @param {boolean} [polling=false] Polling flag
   * @param {boolean} [retrySkip=false] Non-retry flag
   * @param {boolean} [generalErrorScreenSkip=false] General error screen skip flag
   * @return {Observable<Response>} API response
   */
  public request(
    method: string,
    url: string,
    body?: object,
    screenId: string = '',
    polling: boolean = false,
    retrySkip: boolean = false,
    generalErrorScreenSkip: boolean = false,
  ): Observable<Response> {
    // 汎用エラー画面表示中は画面共通以外からの後続の通信を行わない
    // While the general error screen is displayed, subsequent communication from other than the common screen is not performed.
    if (this.checkGeneralError() && screenId !== ScreenId.ScreenCommon) {
      return this.handleError(0, 'Unexpected error');
    }
    // リクエスト前に権限チェックを実施し、権限が無ければ強制ログアウト
    // Permission check is performed before request, and if there is no permission, forced logout
    if (!this.checkApiAuthority(url, method, screenId)) {
      this.forceLogout('sidAccessDenied');
      return this.handleError(0, 'Unexpected error');
    }

    if (!polling) {
      this.sessionTimerReset();
    }
    return from(this.createRequestHeader(true, screenId)).pipe(
      mergeMap((httpHeaders) => {
        return this.httpClient
          .request<HttpClient>(method, url, {
            body,
            headers: httpHeaders,
            observe: 'response',
          })
          .pipe(timeout(limitOfTimeout))
          .pipe(
            map(({ status, body, headers }) => {
              return new Response(status, body, headers);
            }),
            retryWhen((errors) =>
              this.errorStatusCheck(
                errors,
                limitOfRetryCount,
                retrySkip,
                polling,
                generalErrorScreenSkip,
              ),
            ),
          );
      }),
    );
  }

  /**
   * APIリクエスト通信（レスポンスタイプがBlob）
   *
   * @param {string} method リクエストメソッド
   * @param {string} url リクエストURL
   * @param {object} [body] リクエストボディ
   * @param {string} [screenId=''] 画面ID
   * @param {boolean} [polling=false] ポーリングフラグ
   * @param {boolean} [retrySkip=false] リトライ対象外フラグ
   * @param {boolean} [generalErrorScreenSkip=false] 汎用エラー画面スキップフラグ
   * @return {Observable<Response>} APIレスポンス
   */
  /**
   * API request communication（Response type is Blob）
   *
   * @param {string} method Request method
   * @param {string} url Request URL
   * @param {object} [body] Request body
   * @param {string} [screenId=''] Screen ID
   * @param {boolean} [polling=false] Polling flag
   * @param {boolean} [retrySkip=false] Non-retry flag
   * @param {boolean} [generalErrorScreenSkip=false] General error screen skip flag
   * @return {Observable<Response>} API response
   */
  public requestForBlob(
    method: string,
    url: string,
    body?: object,
    screenId: string = '',
    polling: boolean = false,
    retrySkip: boolean = false,
    generalErrorScreenSkip: boolean = false,
  ): Observable<Response> {
    // 汎用エラー画面表示中は画面共通以外からの後続の通信を行わない
    // While the general error screen is displayed, subsequent communication from other than the common screen is not performed.
    if (this.checkGeneralError() && screenId !== ScreenId.ScreenCommon) {
      return this.handleError(0, 'Unexpected error');
    }
    // リクエスト前に権限チェックを実施し、権限が無ければ強制ログアウト
    // Permission check is performed before request, and if there is no permission, forced logout
    if (!this.checkApiAuthority(url, method, screenId)) {
      this.forceLogout('sidAccessDenied');
      return this.handleError(0, 'Unexpected error');
    }

    if (!polling) {
      this.sessionTimerReset();
    }
    return from(this.createRequestHeader(true, screenId)).pipe(
      mergeMap((httpHeaders) => {
        return this.httpClient
          .request<HttpClient>(method, url, {
            body,
            headers: httpHeaders,
            observe: 'response',
            responseType: 'blob' as 'json',
          })
          .pipe(timeout(limitOfTimeout))
          .pipe(
            map(({ status, body, headers }) => {
              return new Response(status, body, headers);
            }),
            retryWhen((errors) =>
              this.errorStatusCheck(
                errors,
                limitOfRetryCount,
                retrySkip,
                polling,
                generalErrorScreenSkip,
              ),
            ),
          );
      }),
    );
  }

  /**
   * APIリクエストヘッダ生成
   *
   * @param {boolean} authentication 認証有無
   * @param {string} [screenId=''] 画面ID
   * @return {Promise<HttpHeaders>} リクエストヘッダ
   */
  /**
   * API request header generation
   *
   * @param {boolean} authentication Authentication
   * @param {string} [screenId=''] Screen ID
   * @return {Promise<HttpHeaders>} Request header
   */
  private createRequestHeader(
    authentication: boolean,
    screenId: string = '',
  ): Promise<HttpHeaders> {
    let headers = new HttpHeaders({});

    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (authentication) {
            // 認証情報からリクエストヘッダを作成
            // Create request header from authentication information
            headers = headers.append('Authorization','Bearer ' + user.signInUserSession.idToken.jwtToken);
          }
          // headers = this.headerDetails(headers, screenId);
          resolve(headers);
        })
        .catch(() => {
          // ユーザ情報が取得できなければ認証情報を除いたリクエストヘッダを作成
          // If user information cannot be obtained, create a request header excluding authentication information
          // headers = this.headerDetails(headers, screenId);
          resolve(headers);
        });
    });
  }

  /**
   * リクエストヘッダ詳細設定
   *
   * @param headers リクエストヘッダ
   * @param [screenId=''] 画面ID
   * @return リクエストヘッダ
   */
  /**
   * Detailed request header settings
   *
   * @param headers Request headers
   * @param [screenId='' Screen ID
   * @return Request header
   */
  private headerDetails(headers: HttpHeaders, screenId: string): HttpHeaders {
    let buildingId = this.dataManagementService.building().id;
    let localHeaders = headers;
    if (buildingId === 'ALL') {
      buildingId = this.dataManagementService.building().selectedId;
    }
    if (buildingId) {
      localHeaders = localHeaders.set('BuildingId', buildingId);
    } else {
      localHeaders = localHeaders.set('BuildingId', 'NONE');
    }
    if (screenId) {
      localHeaders = localHeaders.set('ScreenId', screenId);
    } else {
      localHeaders = localHeaders.set('ScreenId', this.getScreenId());
    }
    return localHeaders;
  }

  /**
   * エラーハンドリング
   *
   * @param status エラーステータス
   * @param message エラーメッセージ
   * @param code エラーコード
   * @return レスポンス
   */
  /**
   * Error handling
   *
   * @param status Error status
   * @param message Error message
   * @param code Error code
   * @return Response
   */
  public handleError(status: number, message: string, code?: string): Observable<never> {
    const error = new ResponseErrors(new ResponseError(code, message));
    return throwError(new Response(status || 400, error));
  }

  /**
   * エラーレスポンスチェック処理
   *
   * @param errors エラー
   * @param retryCount リトライ回数
   * @param retrySkip リトライ無効フラグ
   * @param polling ポーリングフラグ
   * @param generalErrorScreenSkip 汎用エラー画面スキップフラグ
   * @return エラーレスポンス
   */
  /**
   * Error response check process
   *
   * @param errors error
   * @param retryCount Number of retries
   * @param retrySkip Retry invalid flag
   * @param polling Polling flag
   * @param generalErrorScreenSkip General error screen skip flag
   * @return  Error response
   */
  private errorStatusCheck(
    errors: Observable<TimeoutError | HttpErrorResponse | unknown>,
    retryCount: number,
    retrySkip: boolean,
    polling: boolean,
    generalErrorScreenSkip: boolean,
  ): Observable<number> {
    let retryTime: number = 0;
    return errors.pipe(
      mergeMap((error) => {
        // リトライ条件を満たしていればリトライ実施
        // If retry conditions are met, retry is performed
        if (this.retryTriggerCheck(error) && !retrySkip) {
          if (retryTime < retryCount) {
            const waitTime = this.getRetryWaitTime(retryTime);
            retryTime += 1;
            return timer(waitTime);
          }
        }

        // タイムアウト、疎通不可時は408エラーとしてフロントに返却
        // When timeout or communication is impossible, return to the front as 408 error
        if (error instanceof TimeoutError || (error as any).status === 0) {
          return this.handleError(408, 'Timeout Error');
        }

        if (error instanceof HttpErrorResponse) {
          const errorResponse = error as HttpErrorResponse;
          const errorDetail = errorResponse.error;
          // 401エラーは権限不足
          // 401 error is insufficient
          if (errorResponse.status === 401) {
            // Force logout in case of permission error
            this.forceLogout('sidAccessDenied');
          }

          // 403エラーはコード種によってパッケージエラーまたは権限不足または処理継続不可の場合がある
          // The 403 error may be a package error, lack of authority, or the processing cannot be continued depending on the code type.
          if (errorResponse.status === 403) {
            // パッケージエラーの場合はパッケージ管理サービスを呼び出す
            // In the case of a package error, call the package management service
            if (errorDetail && errorDetail.code) {
              if (errorDetail.code === 'BEZ99998') {
                this.packageManagement.updatePackages();
              } else if (incorrectAuthorityCode.indexOf(errorDetail.code) >= 0) {
                // Force logout in case of permission error
                this.forceLogout('sidAccessDenied');
              }
            }
            const errorDetailErrors: ResponseError[] = errorDetail.errors;
            if (errorDetailErrors && errorDetailErrors.length > 0) {
              if (errorDetailErrors[0].code === 'BEW90006') {
                // Force logout in case of permission error
                this.forceLogout('sidAccessDenied');
              } else if (errorDetailErrors[0].code === 'BEZ99998') {
                this.packageManagement.updatePackages();
              } else if (errorDetailErrors[0].code === 'BEZ99997') {
                this.forceLogout('sidAccessDenied');
              } else if (errorDetailErrors[0].code === 'BEZ90004') {
                this.router.navigate(['general-error', ErrorState.SystemError]);
              }
            }
          }

          // WAFのエラー
          // WAF error
          if (errorResponse.status === 499) {
            this.toastService.openToast(
              'error',
              'center',
              this.multiLanguageMessageService.dictionary('sidInvalidCommand'),
            );
          }

          // 5XXエラーは通信異常
          // 5xx errors are communication abnormalities
          if (500 <= errorResponse.status && errorResponse.status <= 599) {
            if (!generalErrorScreenSkip) {
              this.router.navigate(['general-error', ErrorState.SystemError]);
            }
          }

          // 4XXエラーでエラーコードが存在しない場合、システムエラー
          // If there is no error code with 4xx errors, system errors
          if (!errorDetail || (!errorDetail.code && !errorDetail.errors)) {
            if (!polling && 400 <= errorResponse.status && errorResponse.status <= 499) {
              this.toastService.openToast(
                'error',
                'center',
                this.multiLanguageMessageService.dictionary('sidSystemError'),
              );
            }
          }

          // 画面側に通知する
          // Notify the screen
          return throwError(
            new Response(errorResponse.status, errorResponse.error, errorResponse.headers),
          );
        }
        // 想定外のエラー
        // Unexpected error
        return this.handleError(400, 'Unexpected error');
      }),
    );
  }

  /**
   * リトライ条件チェック
   *
   * @param error エラー
   * @return リトライ有無
   */
  /**
   * Retry condition check
   *
   * @param error error
   * @return Whether to retry
   */
  private retryTriggerCheck(error: TimeoutError | HttpErrorResponse | unknown): boolean {
    if (error instanceof TimeoutError) {
      return true;
    }
    if (!(error instanceof HttpErrorResponse)) {
      return false;
    }

    return retryTriggerStatus.some((n) => {
      if (n.status !== error.status) {
        return false;
      }
      let errors: ResponseError[] = [];
      if (error.error && error.error.errors) {
        errors = error.error.errors;
      }
      return n.exclusionCode.every((exclusionCode) => {
        if (typeof exclusionCode === 'string') {
          return errors.every((error) => error.code !== exclusionCode);
        }
        return !exclusionCode(errors);
      });
    });
  }

  /**
   * リトライ間隔を取得する。
   * アルゴリズムは以下のリンクのFull Jitterを採用。
   * @see https://aws.typepad.com/sajp/2015/03/backoff.html
   * @param retryCount リトライ回数(0始まり)
   * @returns リトライ間隔(ミリ秒)
   */
  /**
   * Acquire retry intervals.
   * The algorithm adopts the following link Full Jitter.
   * @see https://aws.amazon.com/jp/blogs/architecture/exponential-backoff-and-jitter/
   * @param retryCount Number of retries (0 start)
   * @returns Retry spacing (milliseconds)
   */
  private getRetryWaitTime(retryCount: number): number {
    return Math.random() * Math.pow(2, retryCount) * 100;
  }

  /**
   * セッションタイムアウトカウンタスタート&リセット
   *
   */
  /**
   * Session timeout counter start & reset
   *
   */
  sessionTimerReset() {
    if (this.sessionTimer) {
      clearTimeout(this.sessionTimer);
    }

    let automaticLogoutTime: number;
    if (this.dataManagementService.person().automaticLogoutTime) {
      automaticLogoutTime = this.dataManagementService.person().automaticLogoutTime;
      automaticLogoutTime = automaticLogoutTime * 60 * 1000;
    }

    if (automaticLogoutTime) {
      this.sessionTimer = setTimeout(() => this.forceLogout(undefined, true), automaticLogoutTime);
    }
  }

  /**
   * 強制ログアウト
   *
   * @param {string} [message] トースト表示文言(リソースID)
   * @param {boolean} [cachesLoginDataFlg] ログインデータをキャッシュするかどうか
   */
  /**
   * Force logout
   *
   * @param {string} [message] Toast wording (resource ID)
   * @param {boolean} [cachesLoginDataFlg] Whether to cache login data
   */
  forceLogout(message?: string, cachesLoginDataFlg: boolean = false) {
    this.forceLogoutFlag = true;
    this.logoutCommon(cachesLoginDataFlg);
    this.loaderService.forcedReset();
    if (message) {
      this.toastService.openToast(
        'error',
        'center',
        this.multiLanguageMessageService.dictionary(message),
      );
    }
    this.router.navigate(['login']);
  }

  /**
   * ログアウト
   *
   * @param {boolean} [cachesLoginDataFlg] ログインデータをキャッシュするかどうか
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Logout
   *
   * @param {boolean} [cachesLoginDataFlg] Whether to cache the login data
   * @return {Observable<Response>} status: HTTP status
   */
  authLogout(cachesLoginDataFlg: boolean = false): Observable<Response> {
    this.logoutCommon(cachesLoginDataFlg);
    if (!this.authenticatedUser) {
      this.router.navigate(['']).then().catch();
      return this.handleError(400, 'is not login');
    }
    return from(Auth.signOut()).pipe(
      map(() => new Response(200, null)),
      catchError((error) => {
        throw new Response(400, new ResponseErrors(new ResponseError(error.code, error.message)));
      }),
    );
  }

  /**
   * ログアウト共通処理
   * @param {boolean} [cachesLoginDataFlg] ログインデータをキャッシュするかどうか
   *
   */
  /**
   * Logout common
   * @param {boolean} [cachesLoginDataFlg] Whether to cache login data
   *
   */
  logoutCommon(cachesLoginDataFlg: boolean = false) {
    const caches: RestClientCaches = Utility.deepCopy(this.caches);
    // キャッシュクリア
    // Clear cash
    if (!this.forceLogoutFlag) {
      this.clearCache();
    }
    // Google Map API スクリプト削除
    // Remove Google Map API scripts
    this.removeGoogleMapAPIScripts();
    this.dataManagementService.setIsLogin(false, cachesLoginDataFlg, caches);
  }

  /**
   * 権限チェック
   *
   * @param {string} url リクエストURL
   * @param {string} method メソッド
   * @param {boolean} id 画面ID
   * @return {boolean} API利用可否
   */
  /**
   * Authority check
   *
   * @param {string} url Request URL
   * @param {string} method The method
   * @param {boolean} id Screen ID
   * @return {boolean} API availability
   */
  checkApiAuthority(url: string, method: string, id: string): boolean {
    // 多店舗アプリ対応： 細かい制御は必要ないので常にtrueを返す
    return true;

    // let screenId = this.screenId;
    // const query = [];
    // const urlData = url.split('?');
    // const requestUrl = `/${urlData[0].replace(this.endPoint, '')}`;
    // if (urlData[1]) {
    //   for (const item of urlData[1].split('&')) {
    //     const queryParam = new QueryParameter();
    //     queryParam.key = item.split('=')[0];
    //     queryParam.values = item.split('=')[1].split(',');
    //     query.push(queryParam);
    //   }
    // }

    // if (id) {
    //   screenId = id;
    //   // 共通部からの人データ取得API, アクセスレベル取得APIは権限管理サービスの起動時に必要なので無条件で許可
    //   // The API for acquiring human data from the common part and the API for acquiring access level are required when starting the authority management service, so they are allowed unconditionally.
    //   if (
    //     (requestUrl === '/person/v1/persons/self' && method === 'get') ||
    //     (requestUrl.startsWith('/authority/v1/accessLevels/') && method === 'get')
    //   ) {
    //     return true;
    //   }
    // } else {
    //   screenId = this.getScreenId();
    // }

    // // ログイン画面からは権限管理サービスに先行して通信が走るため許可
    // // Allowed from the login screen to communicate prior to the authority management service
    // if (screenId === ScreenId.Login) {
    //   return true;
    // }

    // return this.authorityManager.apiAvailable(screenId, requestUrl, method.toUpperCase(), query);
  }

  /**
   * 現在の画面URLから画面ID取得
   *
   * @return {string} 画面ID
   */
  /**
   * Get screen ID from current screen URL
   *
   * @return {string} Screen ID
   */
  getScreenId(): string {
    const url = this.router.url;
    const screen = screenIdList.find((screen) => url.startsWith(screen.url));
    if (screen) {
      if ('id' in screen) {
        const screenId = screen.id;
        return screenId as string;
      }
    }
    return '';
  }

  /**
   * キャッシュをクリアする
   */
  /**
   * Clear cache
   */
  clearCache() {
    this.caches = new RestClientCaches();
  }

  /**
   * Google Map API スクリプトを削除する
   */
  /**
   * Remove Google Map API scripts
   */
  removeGoogleMapAPIScripts() {
    document
      .querySelectorAll("script[src*='maps.googleapis.com']")
      .forEach((script) => script.parentNode.removeChild(script));

    window['google'] = null;
  }

  /**
   * 現在の画面が汎用エラー画面かをチェック
   *
   * @return {boolean} チェック結果
   */
  /**
   * Check if current screen is general error screen
   *
   * @return {boolean} Check result
   */
  checkGeneralError(): boolean {
    const url = this.router.url;
    return url.startsWith('/general-error');
  }

  /**
   * APIリクエスト通信(SQS完了確認実施)
   *
   * @param callback APIリクエストコールバック
   * @param param SQSリクエストのポーリング間隔時間
   * @return {Observable<Response>} 確認結果
   */
  /**
   * API request communication(confirm SQS completion)
   *
   * @param callback API request callback
   * @param param SQS request polling interval time
   * @return {Observable<Response>} result
   */
  sqsRequest<T = any>(
    callback: () => Observable<Response>,
    param?: SqsRequestOption,
  ): Observable<Response<T>> {
    let responseOfRequest: Response;
    const dueTime = param ? param.dueTime : 3000;
    const periodTime = param ? param.periodTime : 10000;
    return callback().pipe(
      tap((result) => {
        if (!result || !result.data || !result.data.transactionId) {
          throw 'request error';
        }
      }),
      delay(dueTime),
      mergeMap((result) => {
        responseOfRequest = result as Response;
        return this.recursiveSqsRequest(responseOfRequest, periodTime);
      }),
      map((result) => {
        if (result.data.transactionResponse) {
          responseOfRequest.data = JSON.parse(result.data.transactionResponse);
        } else {
          responseOfRequest.data = result.data.transactionResponse;
        }
        return responseOfRequest;
      }),
      catchError((error) => {
        console.error('sqsRequest: ', error);
        let response = error as Response;
        if (responseOfRequest && response && response.data && response.data.transactionResponse) {
          const transactionResponse = JSON.parse(response.data.transactionResponse);
          response = new Response(
            Number(transactionResponse.statusCode),
            new ResponseErrors(new ResponseError(transactionResponse.code, '')),
          );
          if (transactionResponse.body) {
            response.body = transactionResponse.body;
          }
        } else if (
          !(error instanceof Response) ||
          (!(error instanceof Response && error.status === 408) && responseOfRequest)
        ) {
          // エラーを丸める条件
          // 1) 内部エラー(errorがResponse型でない場合)
          // 2) エラーコードが408以外かつ、トランザクション結果確認に失敗した場合
          //    (要求が成功した且つtransactionResponseが存在しない)
          // Conditions for rounding errors
          // 1) Internal error. (If error is not Response type)
          // 2) When the error code is other than 408 and the transaction result confirmation fails.
          //    (Request succeeded and transactionResponse dose not exist)
          response = new Response(
            403,
            new ResponseErrors(new ResponseError('BEZ90030', 'sqsRequest Error')),
          );
        }
        // 要求が失敗した場合はAPIから返されるエラーをそのまま返却する
        // If the request fails, return the error returned from the API as it is.
        throw response;
      }),
    );
  }

  /**
   * sqsRequest用の再帰関数。
   * リクエストが完了するまで、再帰的にAPIを呼び出す。
   * @param responseOfRequest SQSのレスポンス
   * @param periodTime ポーリング間隔時間
   * @returns 確認結果
   */
  /**
   * Recursive function for sqsRequest.
   * Call the API recursively until the request is completed.
   * @param responseOfRequest SQS response
   * @param periodTime Polling interval time
   * @returns Check result
   */
  private recursiveSqsRequest(
    responseOfRequest: Response<{ transactionId: string }>,
    periodTime: number,
  ): Observable<Response<TransactionResult>> {
    return this.getTransactionResults(responseOfRequest.data.transactionId).pipe(
      mergeMap((resultOfTransaction) => {
        if (!resultOfTransaction || !resultOfTransaction.data) {
          throw 'transaction result data error';
        }
        const transaction = resultOfTransaction.data;
        if (transaction.transactionResult === TransactionStatus.Error) {
          throw resultOfTransaction;
        }

        if (transaction.transactionResult === TransactionStatus.Success) {
          return of(resultOfTransaction);
        }
        return of(responseOfRequest).pipe(
          delay(periodTime),
          mergeMap((responseOfRequest) => {
            return this.recursiveSqsRequest(responseOfRequest, periodTime);
          }),
        );
      }),
    );
  }

  /**
   * APIリクエスト通信(SQS完了確認実施)
   * この関数はsqsRequest()のレスポンスと、callback()のレスポンスの
   * 二つのレスポンスを返却する。
   * sqsRequestのうちcallback()のレスポンス情報を取得したい場合は、この関数を使用する。
   * 引数はsqsRequest()と同じ。
   * @param callback APIリクエストコールバック
   * @param param SQSリクエストのポーリング間隔時間
   * @returns sqsRequest()のレスポンスと、callback()のレスポンス
   */
  /**
   * API request communication (SQS completion confirmation)
   * This function returns two response: sqsRequest() response and Callback() response.
   * If you want to get the response information of Callback() in the sqsRequest, use this function.
   * The argument is the same as sqsRequest().
   * @param callback API request callback
   * @param param SQS request polling interval time
   * @returns sqsRequest() response and Callback() response
   */
  sqsRequestAndReturnCallback<T_CallbackResponseData = any, T_SQSResponseData = any>(
    callback: () => Observable<Response<T_CallbackResponseData>>,
    param?: SqsRequestOption,
  ): CallbackSqsResponse<T_CallbackResponseData, T_SQSResponseData> {
    const callbackResponse = new Subject<Response<T_CallbackResponseData>>();
    const sqsResponse = this.sqsRequest(() => {
      return callback().pipe(
        tap(
          (response) => {
            callbackResponse.next(response);
          },
          (error) => {
            callbackResponse.error(error);
          },
          () => {
            callbackResponse.complete();
          },
        ),
      );
    }, param);

    return {
      callbackResponse,
      sqsResponse,
    };
  }

  /**
   * トランザクション結果確認
   * response body: TransactionResult
   *
   * @param transactionId トランザクションID
   * @return {Observable<Response>} 確認結果
   */
  /**
   * Transaction result confirmation
   * response body: TransactionResult
   *
   * @param transactionId Transaction ID
   * @return {Observable<Response>} result
   */
  getTransactionResults(transactionId: string): Observable<Response<TransactionResult>> {
    return this.request(
      'get',
      `${this.endPoint}state/${apiVersionOrigin}/transactionResults?transactionId=${transactionId}`,
      undefined,
      ScreenId.ScreenCommon,
      false,
      true,
    );
  }

  /**
   * Observableの開始、エラー、完了にローダーの表示を同期するためのOperatorFunctionを生成する。
   * 開始時にローダーの表示要求を発行し、エラーまたは完了時にローダーの非表示要求を発行する。
   * @param loaderVisibleStream ローダー表示切り替え要求
   * @param loaderTimeout ローダーのタイムアウト時間
   * @return OperatorFunction
   */
  /**
   * Generate OperatorFunction for synchronizing
   * the loader display to the start, error and completion of the Observable.
   * Issue loader show request on start and issue loader hide request on error or completion.
   * @param loaderVisibleStream Loader display switching request
   * @param loaderTimeout Loader timeout
   * @return OperatorFunction
   */
  syncLoaderVisible<T>(
    loaderVisibleStream: Subject<LoaderVisible>,
    loaderTimeout: number = loaderMaxTime,
  ): OperatorFunction<T, T> {
    return (observable) =>
      new Observable<T>((observer) => {
        loaderVisibleStream.next({ type: LoaderVisibleType.Show, timeout: loaderTimeout });
        observable.subscribe({
          next(value) {
            observer.next(value);
          },
          error(err) {
            loaderVisibleStream.next({ type: LoaderVisibleType.Hide });
            loaderVisibleStream.complete();
            observer.error(err);
          },
          complete() {
            loaderVisibleStream.next({ type: LoaderVisibleType.Hide });
            loaderVisibleStream.complete();
            observer.complete();
          },
        });
      });
  }

  /**
   * ページングされたAPIに対して全件取得するリクエストを生成する。
   * レスポンスボディに{ items:any[],nextKey?:string} が設定されているAPIが対象。
   * @param createRequest APIリクエスト生成関数
   * @param loaderService ローダーサービス。引数に指定した場合は、ローディングアニメーションの表示制御を行う。
   * @param loaderTimeout ローダーのタイムアウト時間
   * @returns 全件取得結果
   */
  /**
   * Generate a request to fetch all records for the paged API.
   * Applies to APIs with {item:any[],nextKey?:string} set in the response body.
   * @param createRequest API request generation function
   * @param loaderService Loader service. When specified as an argument,
   *  display control of the loading animation is performed.
   * @param loaderTimeout Loader timeout
   * @returns Get results for all
   */
  requestAllForPagedAPI<T_Item>(
    createRequest: (nextKey?: string) => Observable<Response<PagedData<T_Item>>>,
    loaderService?: LoaderService,
    loaderTimeout: number = loaderMaxTime,
  ): Observable<Response<PagedData<T_Item>>> {
    const loaderVisibleStream = new Subject<LoaderVisible>();
    if (loaderService) {
      loaderService.updateLoaderFromStream(loaderVisibleStream);
    }
    return this.recursiveRequestAllForPagedAPI<T_Item>(
      createRequest,
      loaderVisibleStream,
      loaderTimeout,
    ).pipe(this.syncLoaderVisible(loaderVisibleStream, loaderTimeout));
  }

  /**
   * requestAllForPagedAPI()用の再起関数。
   * @param createRequest APIリクエスト生成関数
   * @param loaderVisibleStream ローダー表示切り替え要求
   * @param loaderTimeout ローダーのタイムアウト時間
   * @param nextKey 次ページのキー
   * @param items アイテム一覧
   * @returns 全件取得するリクエスト
   */
  /**
   * A recursive function for requestAllForPagedAPI().
   * @param createRequest API request generation function
   * @param loaderVisibleStream Loader display switching request
   * @param loaderTimeout Loader timeout
   * @param nextKey Next page key
   * @param items Item List
   * @returns Request to get all records
   */
  private recursiveRequestAllForPagedAPI<T_Item>(
    createRequest: (nextKey?: string) => Observable<Response<PagedData<T_Item>>>,
    loaderVisibleStream: Subject<LoaderVisible>,
    loaderTimeout: number,
    nextKey?: string,
    items: T_Item[] = [],
  ): Observable<Response<PagedData<T_Item>>> {
    return createRequest(nextKey).pipe(
      mergeMap((response) => {
        const data = response.data;
        if (data && data.items) {
          items.push(...data.items);
        }
        // レスポンスボディにnextKeyがない場合はすべて取得完了とみなし、処理を完了する。
        // If there is no nextKey in the response body,
        // it is considered that all acquisition is completed and the process is completed.
        if (!data || !data.nextKey) {
          return of(new Response(response.status, { items }));
        }
        loaderVisibleStream.next({ type: LoaderVisibleType.Restart, timeout: loaderTimeout });
        return this.recursiveRequestAllForPagedAPI(
          createRequest,
          loaderVisibleStream,
          loaderTimeout,
          data.nextKey,
          items,
        );
      }),
    );
  }
}
