import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import {
  ForcedStopProgramPostRequest,
  ForcedStopProgramPutRequest,
} from '../interfaces/forced-stop-service';

const pathOfEmergency = `emergencystop/${apiVersion}/`;

@Injectable()
export class RestClientForcedStopService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-13. 緊急停止サービス
  //  3-13. Emergency stop service
  /////////////////////////////////////////////////////////////////////////////
  //  3-13-1. 緊急停止管理
  //  3-13-1. Emergency stop management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 緊急停止プログラム一覧取得API
   * response body: ForcedStopProgramListGetResponse
   *
   * @param {string} edgeId エッジID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop program list acquisition API
   * response body: ForcedStopProgramListGetResponse
   *
   * @param {string} edgeId Edge ID
   * @return {Observable<Response>} status:HTTP status
   */
  getForcedStopProgramList(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs`,
    );
  }

  /**
   * 緊急停止プログラム取得API
   * response body: ForcedStopProgramGetResponse
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 緊急停止プログラムID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop program acquisition API
   * response body: ForcedStopProgramGetResponse
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Emergency stop program ID
   * @return {Observable<Response>} status:HTTP status
   */
  getForcedStopProgram(edgeId: string, prgId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs/${prgId}`,
    );
  }

  /**
   * 緊急停止プログラム有効/無効更新API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 緊急停止プログラムID
   * @param {boolean} [valid=false] 有効/無効状態
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop program enable / disable update API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Emergency stop program ID
   * @param {boolean} [valid=false] Enabled / Disabled state
   * @return {Observable<Response>} status:HTTP status
   */
  putForcedStopProgramValid(
    edgeId: string,
    prgId: string,
    valid: boolean = false,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs/${prgId}/valid`,
      { valid },
    );
  }

  /**
   * 緊急停止プログラム登録API
   * response body: prgId(string)
   *
   * @param {string} edgeId エッジID
   * @param {ForcedStopProgramPostRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop program registration API
   * response body: prgId(string)
   *
   * @param {string} edgeId Edge ID
   * @param {ForcedStopProgramPostRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  postForcedStopProgram(edgeId: string, param: ForcedStopProgramPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs`,
      param,
    );
  }

  /**
   * 緊急停止プログラム更新API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 緊急停止プログラムID
   * @param {ForcedStopProgramPutRequest} param リクエストボディ
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop program update API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Emergency stop program ID
   * @param {ForcedStopProgramPutRequest} param Request body
   * @return {Observable<Response>} status:HTTP status
   */
  putForcedStopProgram(
    edgeId: string,
    prgId: string,
    param: ForcedStopProgramPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs/${prgId}`,
      param,
    );
  }

  /**
   * 緊急停止プログラム削除API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 緊急停止プログラムID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop program deletion API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Emergency stop program ID
   * @return {Observable<Response>} status:HTTP status
   */
  deleteForcedStopProgram(edgeId: string, prgId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs/${prgId}`,
    );
  }

  /**
   * 緊急停止解除API
   * response body: none
   *
   * @param {string} edgeId エッジID
   * @param {string} prgId 緊急停止プログラムID
   * @param {number} releaseOrder 緊急停止の解除指令
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Emergency stop cancellation API
   * response body: none
   *
   * @param {string} edgeId Edge ID
   * @param {string} prgId Emergency stop program ID
   * @param {number} releaseOrder Emergency stop release command
   * @return {Observable<Response>} status:HTTP status
   */
  putForcedStopRelease(edgeId: string, prgId: string, releaseOrder: number): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfEmergency}edges/${edgeId}/prgs/${prgId}/release`,
      { releaseOrder },
    );
  }
}
