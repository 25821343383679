import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { LoginService } from '../services/login.service';
import { LanguageListItem } from '../interfaces/login-model';
import { passwordMatchValidator } from 'src/app/shared-preparation/components/text-box/validators/password.validator';

export class PasswordConfirmationFormModel {
  constructor(public password?: string, public passwordVerify?: string) {}
}

@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss'],
})
export class PasswordConfirmationComponent implements OnInit {
  formGroup: UntypedFormGroup;
  formModel: PasswordConfirmationFormModel;
  errorMessage: string;
  submitted: boolean = false;
  languages: LanguageListItem[] = [];
  defaultLanguageItem: string;
  localeId: string;

  get password(): AbstractControl {
    return this.formGroup.get('password');
  }

  get passwordVerify(): AbstractControl {
    return this.formGroup.get('passwordVerify');
  }

  get isRegistrationEnabled(): boolean {
    if (
      !this.password.errors &&
      !this.passwordVerify.errors &&
      this.password.value === this.passwordVerify.value
    ) {
      return true;
    }
    return false;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private restClientAuthService: RestClientAuthService,
    private loaderService: LoaderService,
    public multiLanguageService: MultiLanguageMessageService,
    private toastService: ToastService,
    private loginService: LoginService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.formModel = new PasswordConfirmationFormModel();

    this.formGroup = this.formBuilder.group({
      password: [this.formModel.password, [Validators.required]],
      passwordVerify: [
        this.formModel.passwordVerify,
        { updateOn: 'change' },
        [Validators.required],
      ],
    });
    this.passwordVerify.validator = passwordMatchValidator(this.password);

    this.loadData();
  }

  /**
   * 言語の取得
   */
  /**
   * Get language
   */
  async loadData() {
    this.loaderService.showLoader();
    const languageItem = await this.loginService.asyncGetLanguage(this.multiLanguageService);
    this.languages = languageItem.languages;
    this.localeId = languageItem.localeId;
    this.loaderService.hideLoader();
  }

  /**
   * 比較チェック
   */
  /**
   * Comparison check
   */
  onChangePassword() {
    this.passwordVerify.updateValueAndValidity();
    if (this.password.value !== this.passwordVerify.value && this.passwordVerify.value !== null) {
      this.errorMessage = 'sidPassDoNotMatch';
    } else {
      this.errorMessage = null;
    }
  }

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    if (this.isRegistrationEnabled) {
      this.onSubmit();
    }
  }

  /**
   * データ送信
   */
  /**
   * Data transmission
   */
  onSubmit() {
    this.errorMessage = null;
    this.submitted = true;

    if (this.password.value !== this.passwordVerify.value) {
      this.errorMessage = 'sidPassDoNotMatch';
      return;
    }

    // パスワードが要件を満たしていない
    // Password does not meet requirements
    if (this.password.errors && this.passwordVerify.errors) {
      this.errorMessage = 'sidPassDoNotMatch';
      return;
    }

    this.loaderService.showLoader();
    // パスワード変更処理をリクエストする
    // Request a password change process
    this.restClientAuthService.authRegistNewPassword(this.password.value).subscribe(
      () => {
        // 正常時
        // normal time
        this.loaderService.hideLoader();
        // ログイン画面に遷移する
        // Transition to the login screen
        this.restClientAuthService.authLogout().subscribe(
          () => this.router.navigate(['../'], { relativeTo: this.route }),
          () => this.router.navigate(['../'], { relativeTo: this.route }),
        );
      },
      (err) => {
        // エラー時
        // on error
        this.loaderService.hideLoader();
        this.handleRegisterNewPasswordError(err);
      },
    );
  }

  /**
   * authRegistNewPasswordのエラーレスポンスを処理する
   *
   * @param   {Response}  response  エラーレスポンス
   */
  /**
   * Handle authRegistNewPassword error response
   *
   * @param   {Response}  response  Error response
   */
  private handleRegisterNewPasswordError(response: Response) {
    const resErrors = response.data as ResponseErrors;
    const firstError = resErrors.errors[0];
    this.errorMessage = null;

    if (
      firstError.code === 'InvalidPasswordException' &&
      firstError.message === 'Provided password cannot be used for security reasons.'
    ) {
      this.errorMessage = 'sidFailedChangePassSec';
      return;
    }

    this.errorMessage = 'sidServerErrorOccurred';
    this.toastService.openToast(
      'error',
      'center',
      this.multiLanguageService.dictionary('sidServerErrorOccurred'),
    );
  }

  /** ロケールを変更した時 */
  /** When changing locale */
  onSelectionChange(e: any) {
    this.loaderService.showLoader();
    this.multiLanguageService.changeLocale(e.localeId).subscribe(
      () => {
        this.loaderService.hideLoader();
      },
      () => {
        this.loaderService.hideLoader();
      },
    );
  }
}
