import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import {
  DialogEventType,
  DialogOutput,
} from 'src/app/shared-preparation/components/dialog/dialog.component';
import { CookieService } from 'src/app/main/services/cookie/cookie.service';

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
})
export class CookieDialogComponent implements OnInit, OnDestroy {
  openCookiesDialog: boolean = false;

  private onDestroy: Subject<void> = new Subject();

  get cookiesAccepted(): boolean {
    return this.cookieService.getCookieConsentState();
  }

  /** コンストラクター関数
   * @param cookieService クッキーサービス
   * @param multiLanguageMessageService 多言語対応サービス
   */
  /**
   * Constructor function
   * @param cookieService Cookie service
   * @param multiLanguageMessageService Multilingual service
   */
  constructor(
    private cookieService: CookieService,
    public multiLanguageMessageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.cookieService.$showCookieStream.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.openCookiesDialog = true;
    });
  }

  /**
   * Angular Life Cycle Method
   */
  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * cookie使用同意状態を確認し、cookie通知画面ダイアログのボタンの形式を渡す
   * @param output ダイアログのボタンの形式
   */
  /**
   * Confirm the cookie usage agreement status and
   * pass the button format of the cookie notification screen dialog
   * @param output dialog button type
   */
  onCookiesOutput(output: DialogOutput) {
    if (output.eventType === DialogEventType.Accept) {
      this.cookieService.setCookieConsentState();
    }

    this.openCookiesDialog = false;
  }
}
