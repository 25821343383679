import { ResponseError } from '../interfaces/common/response-errors';

export interface RetryTrigger {
  status: number;
  exclusionCode: (string | RetryExclusionCodeFunction)[];
}

export type RetryExclusionCodeFunction = (errors: ResponseError[]) => boolean;

export const limitOfTimeout = 30000;
export const limitOfRetryCount = 3;
export const retryTriggerStatus: RetryTrigger[] = [
  {
    status: 409,
    exclusionCode: ['EY90025'],
  },
  {
    status: 0,
    exclusionCode: [],
  },
  {
    status: 503,
    exclusionCode: [(errors: ResponseError[]) => errors.length > 0],
  },
  {
    status: 504,
    exclusionCode: [],
  },
];
export const retryTriggerCode = ['NetworkError'];
export const limitOfSqsRequestTimeout = 15 * 60 * 1000;
