import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { UserTagCompanyGroupPutRequest } from '../interfaces/role-service';

const pathOfRole = `role/${apiVersion}/`;
const pathOfUserTag = `userTag/${apiVersion}/`;

@Injectable()
export class RestClientUserService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  //  3-16. ユーザ・物件情報サービス
  //  3-16. User / Property Information Service
  /////////////////////////////////////////////////////////////////////////////
  // 3-16-2. 役割管理
  // 3-16-2. Role management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * 役割一覧取得API
   * response body: RoleRolesResponse
   *
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Role list acquisition API
   * response body: RoleRolesResponse
   *
   * @param {boolean} [force = false] Get information from backend without using cache
   * @return {Observable<Response>}  status:HTTP status
   */
  getRoleRoles(force: boolean = false): Observable<Response> {
    return this.restClientCommonService.request('get', `${this.endPoint}${pathOfRole}roles/`);
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-16-3. ユーザタグ管理
  // 3-16-3. User tag management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * アフィリエイト一覧取得API
   * response body: UserTagAffiliateListGetResponse
   *
   * @param {boolean} [force = false] キャッシュを利用せずバックエンドから情報を取得する
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Affiliate list acquisition API
   * response body: UserTagAffiliateListGetResponse
   *
   * @param {boolean} [force = false] Get information from backend without using cache
   * @return {Observable<Response>}  status:HTTP status
   */
  getUserTagAffiliateList(force: boolean = false): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfUserTag}affiliates`,
    );
  }

  /**
   * アフィリエイト更新API
   * response body: none
   *
   * @param {string} affiliateId アフィリエイトID
   * @param {string[]} [companyGroupIdList=[]] カンパニーグループIDリスト
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Affiliate update API
   * response body: none
   *
   * @param {string} affiliateId Affiliate ID
   * @param {string[]} [companyGroupIdList=[]] Company group ID list
   * @return {Observable<Response>}  status:HTTP status
   */
  putUserTagAffiliate(
    affiliateId: string,
    companyGroupIdList: string[] = [],
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfUserTag}affiliates/${affiliateId}`,
      { companyGroupIdList },
    );
  }

  /**
   * カンパニーグループ一覧取得API
   * response body: UserTagCompanyGroupListGetResponse
   *
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Company group list acquisition API
   * response body: UserTagCompanyGroupListGetResponse
   *
   * @return {Observable<Response>}  status:HTTP status
   */
  getUserTagCompanyGroupList(): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfUserTag}companyGroups`,
    );
  }

  /**
   * カンパニーグループ更新API
   * response body: none
   *
   * @param {string} companyGroupId カンパニーグループID
   * @param {UserTagCompanyGroupPutRequest} param リクエストボディ
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Company Group Update API
   * response body: none
   *
   * @param {string} companyGroupId Company group ID
   * @param {UserTagCompanyGroupPutRequest} param Request body
   * @return {Observable<Response>}  status:HTTP status
   */
  putUserTagCompanyGroup(
    companyGroupId: string,
    param: UserTagCompanyGroupPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfUserTag}companyGroups/${companyGroupId}`,
      param,
    );
  }

  /**
   * カンパニーグループ登録API
   * response body: companyGroupId(string)
   *
   * @param {string} companyGroupName カンパニーグループ名
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Company group registration API
   * response body: companyGroupId(string)
   *
   * @param {string} companyGroupName Company group name
   * @return {Observable<Response>}  status:HTTP status
   */
  postUserTagCompanyGroup(companyGroupName: string): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfUserTag}companyGroups`,
      { companyGroupName },
    );
  }

  /**
   * カンパニー一覧取得API
   * response body: UserTagCompanyListGetResponse
   *
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Company list acquisition API
   * response body: UserTagCompanyListGetResponse
   *
   * @return {Observable<Response>}  status:HTTP status
   */
  getUserTagCompanyList(): Observable<Response> {
    return this.restClientCommonService.request('get', `${this.endPoint}${pathOfUserTag}companies`);
  }

  /**
   * カンパニー更新API
   * response body: none
   *
   * @param {string} companyId カンパニーID
   * @param {string} companyName カンパニーグループ名
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Company update API
   * response body: none
   *
   * @param {string} companyId Company ID
   * @param {string} companyName Company group name
   * @return {Observable<Response>}  status:HTTP status
   */
  putUserTagCompany(companyId: string, companyName: string): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfUserTag}companies/${companyId}`,
      { companyName },
    );
  }

  /**
   * カンパニー登録API
   * response body: companyId(string)
   *
   * @param {string} companyName カンパニー名
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Company registration API
   * response body: companyId(string)
   *
   * @param {string} companyName Company name
   * @return {Observable<Response>}  status:HTTP status
   */
  postUserTagCompany(companyName: string): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfUserTag}companies/`,
      { companyName },
    );
  }

  /**
   * 関連カンパニーグループ一覧取得API
   * response body: UserTagCompanyGroupListGetResponse
   *
   * @param {string} affiliateId アフィリエイトID
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Related Company Group List Acquisition API
   * response body: UserTagCompanyGroupListGetResponse
   *
   * @param {string} affiliateId Affiliate ID
   * @return {Observable<Response>}  status:HTTP status
   */
  getUserTagAffiliateCompanyGroup(affiliateId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfUserTag}affiliates/${affiliateId}/companyGroups`,
    );
  }

  /**
   * 関連カンパニー一覧取得API
   * response body: UserTagCompanyListGetResponse
   *
   * @param {string} companyGroupId カンパニーグループID
   * @return {Observable<Response>}  status:HTTPステータス
   */
  /**
   * Related Company List Acquisition API
   * response body: UserTagCompanyListGetResponse
   *
   * @param {string} companyGroupId Company group ID
   * @return {Observable<Response>}  status:HTTP status
   */
  getUserTagAffiliateCompany(companyGroupId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfUserTag}companyGroups/${companyGroupId}/companies`,
    );
  }
}
