<div *ngIf="data?.summary" class="alert-bar" [class.alert-bar--error]="isTypeError">
  <div *ngIf="isZoomOut" class="alert-bar__zoom-out-panel" (click)="isZoomOut = false">
    <img src="assets/icons/triangl-down_w.svg" alt="zoom out" />
  </div>
  <div [class.alert-bar__zoom-out-hidden]="isZoomOut">
    <div class="alert-bar__header">
      <div class="alert-bar__icon"></div>
      <p class="alert-bar__summary">
        {{ data.summary }}
        {{(isTypeError && showStopSoundLink && enableStopSoundLink) ? notificationMessage: ''}}
      </p>
      <button *ngIf="hasDetails" class="alert-bar__toggle" (click)="onDetailsButtonClick()"
        [class.disabled]="isDetailButtonDisabled">
        <span *ngIf="isShowDetails">{{ 'sidHideDetail' | translate }}</span>
        <span *ngIf="!isShowDetails">{{ 'sidShowDetail' | translate }}</span>
      </button>
      <ng-container *ngIf="isTypeError && showStopSoundLink">
        <button class="alert-bar__link" [disabled]="!enableStopSoundLink"
          [class.disabled]="!enableStopSoundLink" (click)="onStopSound()">
          {{ 'sidStopNotificationSound' | translate }}
        </button>
        <audio #audio class="alert-bar__audio" src="assets/buzzer/Buzzer.wav" muted="muted"></audio>
      </ng-container>
      <button class="alert-bar__link" (click)="isZoomOut = true">
        {{ 'sidZoomOut' | translate }}
      </button>
    </div>
    <div *ngIf="hasDetails" class="alert-bar__details"
      [@expandCollapse]="isShowDetails ? 'expand' : 'collapse'">
      <span class="alert-bar__details-title">{{ data.details.title }}</span>
      <ul class="alert-bar__details-list">
        <ng-container *ngFor="let item of data.details.list">
          <ng-container [ngSwitch]="item.link">
            <ng-container *ngSwitchCase="'info'">
              <li>
                <div class="alert-bar__details-list-item">
                  <span class="alert-bar__detail">{{ item.detail }}</span>
                  <button class="alert-bar__link" [tabIndex]="isShowDetails ? '0' : '-1'"
                    (click)="onInfoButtonClick()">
                    {{ 'sidInfo' | translate }}
                  </button>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="'download'">
              <li>
                <div class="alert-bar__details-list-item">
                  <span class="alert-bar__detail">{{ item.detail }}</span>
                  <app-authority-control [screenId]="screenCommonId"
                    [itemId]="downloadAtAlertBarId">
                    <button class="alert-bar__link" [tabIndex]="isShowDetails ? '0' : '-1'"
                      (click)="onDownloadButtonClick()">
                      {{ 'sidDownload' | translate }}
                    </button>
                  </app-authority-control>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="'subDetails'">
              <ng-container>
                <li>
                  <div class="alert-bar__details-list-item">
                    <span class="alert-bar__detail">
                      {{ item.detail }}
                    </span>
                  </div>
                  <ul class="alert-bar__details-list">
                    <div class="alert-bar__details-list-equipment-error">
                      <ng-container *ngFor="let subDetail of item.subDetails">
                        <li>
                          <span class="alert-bar__detail">{{ subDetail.detail }}</span>
                        </li>
                      </ng-container>
                    </div>
                  </ul>
                </li>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <li>
                <div class="alert-bar__details-list-item">
                  <span class="alert-bar__detail">{{ item.detail }}</span>
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>