import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { passwordValidator } from 'src/app/login/reset-password/validators/password.validator';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmResetPasswordModel } from 'src/app/login/interfaces/login-model';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';
import { RestClientAuthService } from 'src/app/core/services/rest-client';
import { LoginService } from 'src/app/login/services/login.service';
import { ResponseErrors } from 'src/app/core/services/rest-client/interfaces/common/response-errors';
import { Response } from 'src/app/core/services/rest-client/interfaces/common/response';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { passwordMatchValidator } from 'src/app/shared-preparation/components/text-box/validators/password.validator';
import { LoaderService } from 'src/app/core/components/loader/loader.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit {
  confirmResetPassword: ConfirmResetPasswordModel = new ConfirmResetPasswordModel();
  resetPasswordForm: UntypedFormGroup;
  errorMessage: string = null;

  get enableResetButton(): boolean {
    return this.errorCheck();
  }

  get code(): AbstractControl {
    return this.resetPasswordForm.get('code');
  }
  get password(): AbstractControl {
    return this.resetPasswordForm.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.resetPasswordForm.get('confirmPassword');
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private multiLanguageService: MultiLanguageMessageService,
    private restClientAuthService: RestClientAuthService,
    private loginService: LoginService,
    private toastService: ToastService,
    private loaderService: LoaderService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.confirmResetPassword.email = this.loginService.email;
    this.resetPasswordForm = this.formBuilder.group(
      {
        code: [this.confirmResetPassword.code, [Validators.required]],
        password: [this.confirmResetPassword.password, [passwordValidator]],
        confirmPassword: [
          this.confirmResetPassword.confirmPassword,
          { updateOn: 'change' },
          [Validators.required],
        ],
      },
      {
        validator: passwordValidator,
      } as AbstractControlOptions,
    );

    this.resetPasswordForm.get('confirmPassword').validator = passwordMatchValidator(
      this.resetPasswordForm.get('password'),
    );
  }

  /**
   * フォーム入力中のEnterキー押下時
   */
  /**
   * When the Enter key is clicked while inputting
   */
  onEnter() {
    if (this.enableResetButton) {
      this.onResetPasswordClick();
    }
  }

  /**
   * データ送信
   */
  /**
   * Data transmission
   */
  onResetPasswordClick() {
    this.errorMessage = null;
    if (this.resetPasswordForm.errors && this.resetPasswordForm.errors.mismatch) {
      this.errorMessage = this.multiLanguageService.dictionary('sidPassDoNotMatch');
      return;
    }

    this.loaderService.showLoader();
    this.restClientAuthService
      .authResetPassword(this.confirmResetPassword.email, this.code.value, this.password.value)
      .subscribe(
        () => {
          this.loaderService.hideLoader();
          this.router.navigate(['/login'], { relativeTo: this.route });
        },
        (err) => {
          this.loaderService.hideLoader();
          this.handleResetPasswordError(err);
        },
      );
  }

  /**
   * ログイン画面に戻る
   */
  /**
   * Return to login screen
   */
  onBackToLoginClick() {
    this.router.navigate(['/login'], { relativeTo: this.route });
  }

  /**
   * ResetPasswordのエラー処理
   */
  /**
   * Error handling for ResetPassword
   */
  private handleResetPasswordError(response: Response) {
    const resErrors = response.data as ResponseErrors;
    const firstError = resErrors.errors[0];
    this.errorMessage = null;

    if (
      firstError.code === 'InvalidPasswordException' &&
      firstError.message === 'Provided password cannot be used for security reasons.'
    ) {
      this.errorMessage = this.multiLanguageService.dictionary('sidFailedChangePassSec');
      return;
    }

    if ('CodeMismatchException' === firstError.code && response.status === 400) {
      // コード不一致
      // code mismatch
      this.errorMessage = this.multiLanguageService.dictionary('sidWrongVerificationCode');
    } else if ('ExpiredCodeException' === firstError.code && response.status === 400) {
      // コード有効期限切れ
      // code expired
      this.errorMessage = this.multiLanguageService.dictionary('sidExpireVerifyCode');
    } else {
      this.errorMessage = this.multiLanguageService.dictionary('sidServerErrorOccurred');
      this.toastService.openToast(
        'error',
        'center',
        this.multiLanguageService.dictionary('sidServerErrorOccurred'),
      );
    }
  }

  /**
   * 比較チェック
   */
  /**
   * Comparison check
   */
  onChangePassword() {
    this.confirmPassword.updateValueAndValidity();
    if (this.password.value !== this.confirmPassword.value && this.confirmPassword.value !== null) {
      this.errorMessage = this.multiLanguageService.dictionary('sidPassDoNotMatch');
    } else {
      this.errorMessage = null;
    }
  }

  /**
   * 入力値のエラーチェック
   *
   * @return  {boolean} true:エラー無し　false:エラー有り
   */
  /**
   * Input value error check
   *
   * @return  {boolean} true: No error false: Error
   */
  errorCheck(): boolean {
    const code = this.resetPasswordForm.get('code');
    const password = this.resetPasswordForm.get('password');
    const confirmPassword = this.resetPasswordForm.get('confirmPassword');

    if (!code.value || !password.value || !confirmPassword.value) {
      return false;
    }

    if (password.errors || confirmPassword.errors) {
      return false;
    }
    return true;
  }

  /**
   * 入力キーの確認
   *
   * @param   {[type]}  e  キー
   */
  /**
   * Confirm input key
   *
   * @param   {[type]}  e  Key
   */
  onKeydown(e) {
    if (e.keyCode === 13) {
      this.onBackToLoginClick();
    }
  }
}
