<div class="menu-footer">
  <ul class="menu-footer__list">
    <ng-container *ngFor="let item of menuFooterItems">
      <li *ngIf="item.isDisplay" class="menu-footer__item" tabIndex="0"
        (keydown)="onSelectKeyDown($event, item)">
        <a class="menu-footer__link" (click)="$event.stopPropagation(); onLinkClick(item)">
          {{ item.name | translate }}
        </a>
      </li>
    </ng-container>
  </ul>
  <div class="menu-footer__copyright">{{ 'sidCopyright' | translate }}</div>
</div>