import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';

import {
  LayoutScreenPostRequest,
  LayoutScreenPutRequest,
  LayoutScreenOrderPutRequest,
  LayoutScreenMoveCopyPostRequest,
  // LayoutEquipmentlinksPostRequest,
  // LayoutEquipmentlinksPutRequest,
  LayoutScreenGroupLinkPostRequest,
  LayoutScreenGroupLinkPutRequest,
  LayoutScreenGroupPostRequest,
  LayoutScreenGroupPutRequest,
  LayoutLinkButtonPostRequest,
  LayoutLinkButtonPutRequest,
} from '../interfaces/layout-service';
import { LayoutPutRequest } from 'src/app/core/services/rest-client/interfaces/layout-service/layout-put';
import { LayoutScreenGroupLinkGetRequest } from 'src/app/core/services/rest-client/interfaces/layout-service/layout-screen-group-link-get';

const pathOfLayout = `layout/${apiVersion}/`;

@Injectable()
export class RestClientLayoutService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////
  // 3-X. レイアウトサービス
  // 3-X. Layout service
  /////////////////////////////////////////////////////////////////////////////
  // 3-X-1. レイアウト管理
  // 3-X-1. Layout management
  /////////////////////////////////////////////////////////////////////////////

  /**
   * レイアウトスクリーン一覧取得API
   *
   * @param {string} [buildingId] 物件ID
   * @param {boolean} [temporaryFlg = false] 一時保存したレイアウトを含めるかの判定フラグ
   * @param {string} [personId = ''] 人ID
   * @param {string} [edgeId = ''] エッジID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen list acquisition API
   *
   * @param {string} buildingId Property ID
   * @param {boolean} [temporaryFlg = false] Determines whether temporarily saved layout is included
   * @param {string} [personId = ''] Person ID
   * @param {string} [edgeId = ''] Edge ID
   * @returns {Observable<Response>} acquisition result
   */
  getLayoutScreens(
    buildingId: string,
    temporaryFlg: boolean = false,
    personId: string = '',
    edgeId: string = '',
  ): Observable<Response> {
    let query = '';
    if (temporaryFlg) {
      query = `?temporaryFlg=1`;
    } else {
      query = `?temporaryFlg=0`;
    }

    if (personId !== '') {
      query = `${query}&personId=${personId}`;
    }

    if (edgeId !== '') {
      query = `${query}&edgeId=${edgeId}`;
    }

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/screen${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウトスクリーン登録API
   * response body: LayoutScreenPostResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {LayoutScreenPostRequest} [param] リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen registration API
   * response body: LayoutScreenPostResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {LayoutScreenPostRequest} [param] request body
   * @returns {Observable<Response>} status: HTTP status
   */
  postLayoutScreen(buildingId: string, param: LayoutScreenPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/screen`,
      param,
      this.screenId,
    );
  }

  /**
   * レイアウトスクリーン更新API
   * response body: LayoutScreenPutResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @param {LayoutScreenPutRequest} [param] リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen update API
   * response body: LayoutScreenPutResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @param {LayoutScreenPutRequest} [param] request body
   * @returns {Observable<Response>} status: HTTP status
   */
  putLayoutScreen(
    buildingId: string,
    layoutId: string,
    param: LayoutScreenPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/${layoutId}/screen`,
      param,
      this.screenId,
    );
  }

  /**
   * レイアウトスクリーン削除API
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen delete API
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteLayoutScreen(buildingId: string, layoutId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/${layoutId}/screen`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウトスクリーン並び順更新API
   *
   * @param {string} [buildingId] 物件ID
   * @param {LayoutScreenOrderPutRequest} [param] リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen order update API
   *
   * @param {string} [buildingId] Property ID
   * @param {LayoutScreenOrderPutRequest} [param] request body
   * @returns {Observable<Response>} status: HTTP status
   */
  putLayoutScreensOrder(
    buildingId: string,
    param: LayoutScreenOrderPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/order`,
      param,
      this.screenId,
    );
  }

  /**
   * レイアウトスクリーン移動・複製API
   *
   * @param {string} [edgeId] エッジ ID
   * @param {LayoutScreenMoveCopyPostRequest} [param] リクエストボディ
   * @param {boolean} [copyFlg] true: コピー、false: 移動
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen move/copy API
   *
   * @param {string} [edgeId] Edge ID
   * @param {LayoutScreenMoveCopyPostRequest} [param] request body
   * @param {boolean} [copyFlg] true: copy, false: move
   * @returns {Observable<Response>} status: HTTP status
   */
  postLayoutScreenMoveCopy(
    edgeId: string,
    param: LayoutScreenMoveCopyPostRequest,
    copyFlg: boolean = false,
  ): Observable<Response> {
    const query = copyFlg ? `?copyFlg=true` : '';
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}edges/${edgeId}/movecopy${query}`,
      param,
      this.screenId,
    );
  }

  /**
   * レイアウト機器紐づけ一覧取得API
   * response body: LayoutEquipmentlinksGetResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout device connection list acquisition API
   * response body: LayoutEquipmentlinksGetResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [buildingId] Layout ID
   * @returns {Observable<Response>} status: HTTP status
   */
  getLayoutEquipmentlinks(buildingId: string, layoutId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/${layoutId}/equipmentlinks`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウト機器紐づけ登録API
   * response body: LayoutEquipmentlinksPostResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @param {LayoutEquipmentlinksPostRequest} param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout device connection registration API
   * response body: LayoutEquipmentlinksPostResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @param {LayoutEquipmentlinksPostRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  postLayoutEquipmentlinks(
    buildingId: string,
    layoutId: string,
    // param: LayoutEquipmentlinksPostRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/${layoutId}/equipmentlinks`,
      // param,
      // this.screenId,
    );
  }

  /**
   * レイアウト機器紐づけ更新API
   * response body: LayoutEquipmentlinksPutResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @param {string} [equipmentLinkId] 機器紐づけID
   * @param {LayoutEquipmentlinksPutRequest} param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout device association update API
   * response body: LayoutEquipmentlinksPutResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @param {string} [equipmentLinkId] Device connection ID
   * @param {LayoutEquipmentlinksPutRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  putLayoutEquipmentlinks(
    buildingId: string,
    layoutId: string,
    equipmentLinkId: string,
    // param: LayoutEquipmentlinksPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}` +
        `buildings/${buildingId}/layouts/${layoutId}/equipmentlinks/${equipmentLinkId}`,
      // param as LayoutEquipmentlinksPutRequest,
      // this.screenId,
    );
  }

  /**
   * レイアウト機器紐づけ削除API
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @param {string} [equipmentLinkId] 機器紐づけID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout device connection deletion API
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @param {string} [equipmentLinkId] Device connection ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteLayoutEquipmentlinks(
    buildingId: string,
    layoutId: string,
    equipmentLinkId: string,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfLayout}` +
        `buildings/${buildingId}/layouts/${layoutId}/equipmentlinks/${equipmentLinkId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウトリンクボタン一覧取得API
   * response body: LayoutLinkButtonGetResponse
   *
   * @param {string} [layoutId] レイアウトID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout link button list acquisition API
   * response body: LayoutLinkButtonGetResponse
   *
   * @param {string} [layoutId] Layout ID
   * @returns {Observable<Response>} status: HTTP status
   */
  getLayoutLinkButtons(layoutId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}layouts/${layoutId}/linkButtons`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウトリンクボタン登録API
   * response body: LayoutLinkButtonPostResponse
   *
   * @param {string} [layoutId] レイアウトID
   * @param {LayoutLinkButtonPostRequest} [param] リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout link button registration API
   * response body: LayoutLinkButtonPostResponse
   *
   * @param {string} [layoutId] Layout ID
   * @param {LayoutLinkButtonPostRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  postLayoutLinkButton(layoutId: string, param: LayoutLinkButtonPostRequest): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}layouts/${layoutId}/linkButtons`,
      param,
      this.screenId,
    );
  }

  /**
   * レイアウトリンクボタン更新API
   * response body: LayoutLinkButtonPutResponse
   *
   * @param {string} [layoutId] レイアウトID
   * @param {string} [linkButtonId] リンクボタンID
   * @param {LayoutLinkButtonPutRequest} param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout Link button update API
   * response body: LayoutLinkButtonPutResponse
   *
   * @param {string} [layoutId] Layout ID
   * @param {string} [linkButtonId] Link button ID
   * @param {LayoutLinkButtonPutRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  putLayoutLinkButton(
    layoutId: string,
    linkButtonId: string,
    param: LayoutLinkButtonPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}layouts/${layoutId}/linkButtons/${linkButtonId}`,
      param,
      this.screenId,
    );
  }

  /**
   * レイアウトリンクボタン削除API
   *
   * @param {string} [layoutId] レイアウトID
   * @param {string} [linkButtonId] リンクボタンID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout link button deletion API
   *
   * @param {string} [layoutId] Layout ID
   * @param {string} [linkButtonId] Link button ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteLayoutLinkButton(layoutId: string, linkButtonId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfLayout}layouts/${layoutId}/linkButtons/${linkButtonId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウト本登録API
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @param {LayoutPutRequest} param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout main registration API
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @param {LayoutPutRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  putLayout(
    buildingId: string,
    layoutId: string,
    param: LayoutPutRequest = undefined,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}buildings/${buildingId}/layouts/${layoutId}`,
      param,
    );
  }

  /**
   * レイアウトビューAPI
   * response body: LayoutViewGetResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [buildingId] レイアウトID
   * @param {string} [personId] 人ID
   * @param {boolean} [temporaryFlg = false] 一時保存したレイアウトを含めるかの判定フラグ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout view API
   * response body: LayoutViewGetResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [buildingId] Layout ID
   * @param {string} [personId] Person ID
   * @param {boolean} [temporaryFlg = false] Determines whether temporarily saved layout is included
   * @returns {Observable<Response>} status: HTTP status
   */
  getLayoutView(
    buildingId: string,
    layoutId: string,
    personId?: string,
    temporaryFlg: boolean = false,
  ): Observable<Response> {
    let query = '';
    if (temporaryFlg) {
      query = `?temporaryFlg=1`;
    } else {
      query = `?temporaryFlg=0`;
    }
    if (personId) {
      query = `${query}&personId=${personId}`;
    }

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}` +
        `buildings/${buildingId}/layouts/${layoutId}/view${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * スクリーングループ一覧取得API
   * @param {string} [buildingId] 物件ID
   * @param {string} [edgeId] エッジID
   * @param {boolean} [layoutIds] レイアウト一覧取得フラグ
   * @param {string} [layoutGroupId] レイアウトスクリーングループID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Screen group list acquisition API
   * @param {string} [buildingId] Property ID
   * @param {string} [edgeId] Edge ID
   * @param {boolean} [layoutIds] Layout list acquisition flag
   * @param {string} [layoutGroupId] Layout group id
   * @returns {Observable<Response>} status: HTTP status
   */
  getLayoutScreenGroups(
    buildingId: string,
    edgeId: string = null,
    layoutIds: boolean = null,
    layoutGroupId: string = null,
  ): Observable<Response> {
    let query = '';
    const queryArray: string[] = [];
    if (edgeId) {
      queryArray.push(`edgeId=${edgeId}`);
    }
    if (layoutIds != null) {
      queryArray.push(`layoutIds=${layoutIds}`);
    }
    if (layoutGroupId) {
      queryArray.push(`layoutGroupId=${layoutGroupId}`);
    }
    if (queryArray.length > 0) {
      query = `?${queryArray.join('&')}`;
    }

    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groups${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * スクリーングループ登録API
   * @param buildingId 物件ID
   * @param param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Screen group registration API
   * @param {string} [buildingId] Property ID
   * @param {LayoutScreenGroupPostRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  postLayoutScreenGroup(
    buildingId: string,
    param: LayoutScreenGroupPostRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groups`,
      param,
      this.screenId,
    );
  }

  /**
   * スクリーングループ更新API
   * @param buildingId 物件ID
   * @param layoutGroupId レイアウトグループID
   * @param param リクエストボディ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Screen group update API
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutGroupId] Layout group ID
   * @param {LayoutScreenGroupPutRequest} param request body
   * @returns {Observable<Response>} status: HTTP status
   */
  putLayoutScreenGroup(
    buildingId: string,
    layoutGroupId: string,
    param: LayoutScreenGroupPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groups/${layoutGroupId}`,
      param,
      this.screenId,
    );
  }

  /**
   * スクリーングループ削除API
   * @param buildingId 物件ID
   * @param layoutGroupId レイアウトグループID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Screen group deletion API
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutGroupId] Layout group ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteLayoutScreenGroup(buildingId: string, layoutGroupId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groups/${layoutGroupId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * スクリーングループ紐づけ取得API
   * @param buildingId 物件ID
   * @param param リクエストボディー
   * @returns スクリーングループ紐づけ取得結果
   */
  /**
   * Screen group link acquisition API
   * @param buildingId Property ID
   * @param param Request body
   * @returns Screen group link acquisition result
   */
  getLayoutScreenGroupLink(
    buildingId: string,
    param: LayoutScreenGroupLinkGetRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groupLink/get`,
      param,
      this.screenId,
    );
  }

  /**
   * スクリーングループ紐づけ登録API
   * @param buildingId 物件ID
   * @param param リクエストボディ
   * @returns スクリーングループ紐づけ登録結果
   */
  /**
   * Screen group link registration API
   * @param buildingId Property ID
   * @param param Request body
   * @returns Screen group link registration results
   */
  postLayoutScreenGroupLink(
    buildingId: string,
    param: LayoutScreenGroupLinkPostRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groupLink`,
      param,
      this.screenId,
    );
  }

  /**
   * スクリーングループ紐づけ更新API
   * @param buildingId 物件ID
   * @param personId 人ID
   * @param param リクエストボディ
   * @returns Screen group association update results
   */
  /**
   * Screen group link update API
   * @param buildingId Property ID
   * @param personId Person ID
   * @param param Request body
   * @returns Screen group link update results
   */
  putLayoutScreenGroupLink(
    buildingId: string,
    personId: string,
    param: LayoutScreenGroupLinkPutRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'put',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groupLink/${personId}`,
      param,
      this.screenId,
    );
  }

  /**
   * スクリーングループ紐づけ削除API
   * @param buildingId 物件ID
   * @param personId 人ID
   * @returns スクリーングループ紐づけ削除結果
   */
  /**
   * Screen group link delete API
   * @param buildingId Property ID
   * @param personId Person ID
   * @returns Screen group link delete results
   */
  deleteLayoutScreenGroupLink(buildingId: string, personId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfLayout}` + `buildings/${buildingId}/layouts/groupLink/${personId}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウトスクリーン画像取得API
   * response body: LayoutScreenImageGetResponse
   *
   * @param {string} [buildingId] 物件ID
   * @param {string} [layoutId] レイアウトID
   * @param {boolean} [temporaryFlg = false] 一時保存したレイアウトを含めるかの判定フラグ
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout screen image acquisition API
   * response body: LayoutScreenImageGetResponse
   *
   * @param {string} [buildingId] Property ID
   * @param {string} [layoutId] Layout ID
   * @param {boolean} [temporaryFlg = false] Determines whether temporarily saved layout is included
   * @returns {Observable<Response>} status: HTTP status
   */
  getLayoutScreenImage(
    buildingId: string,
    layoutId: string,
    temporaryFlg: boolean = false,
  ): Observable<Response> {
    let query = '';
    if (temporaryFlg) {
      query = `?temporaryFlg=1`;
    }
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}` +
        `buildings/${buildingId}/layouts/${layoutId}/image${query}`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウト一時データ削除API
   *
   * @param {string} [layoutId] レイアウトID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout temporary delete API
   *
   * @param {string} [layoutId] Property ID
   * @returns {Observable<Response>} status: HTTP status
   */
  deleteTemporaryData(layoutId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'delete',
      `${this.endPoint}${pathOfLayout}layouts/${layoutId}/temporaryData`,
      undefined,
      this.screenId,
    );
  }

  /**
   * レイアウトデータ状態確認API
   * response body: LayoutDataStatusGetResponse
   *
   * @param {string} [edgeId] エッジID
   * @returns {Observable<Response>} status:HTTPステータス
   */
  /**
   * Layout Data Status Check API
   * response body: LayoutDataStatusGetResponse
   *
   * @param {string} [edgeId] Edge ID
   * @returns {Observable<Response>} status: HTTP status
   */
  checkLayoutDataStatus(edgeId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfLayout}edges/${edgeId}/layoutDataStatus`,
      undefined,
      this.screenId,
    );
  }
}
