import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';

import {
  DialogComponent,
  DialogOutput,
  DialogFlexibility,
} from 'src/app/shared-preparation/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  overlayRef: OverlayRef = null;
  componentRef: ComponentRef<DialogComponent> = null;

  /**
   * コンストラクター
   * @param overlay オーバーレイ
   * @param injector インジェクター
   */
  /**
   * Constructor
   * @param overlay Overlay
   * @param injector Injector
   */
  constructor(private overlay: Overlay, private injector: Injector) {}

  /**
   * ダイアログを開く
   * @param title ダイアログのタイトル
   * @param messages ダイアログのコンテンツ
   * @param confirmButtonLabel ダイアログの OK ボタンのラベル
   * @param declineButtonLabel ダイアログの Cancel ボタンのラベル
   * @param componentPortal ダイアログのエンベデッドコンポーネント
   * @param disabled ダイアログの非活性フラグ
   * @param flexibility ダイアログのフレキシビリティー
   * @returns ダイアログの出力の Observable
   */
  /**
   * Opens dialog
   * @param title Dailog title
   * @param messages Dialog contents
   * @param confirmButtonLabel Dialog OK button label
   * @param declineButtonLabel Dialog Cancel button label
   * @param componentPortal Dialog embedded component
   * @param disabled Dialog disbaled flag
   * @param flexibility Dialog flexibility
   * @returns Observable of dialog's output
   */
  openDialog(
    title: string = '',
    messages: string = '',
    confirmButtonLabel: string = '',
    declineButtonLabel: string = '',
    componentPortal: ComponentPortal<any> = null,
    disabled: boolean = false,
    flexibility: DialogFlexibility = DialogFlexibility.None,
  ): Observable<DialogOutput> {
    if (!this.overlayRef) this.overlayRef = this.overlay.create();

    const dialogComponentPortal = new ComponentPortal(DialogComponent, null, this.injector);
    this.componentRef = this.overlayRef.attach(dialogComponentPortal);

    this.componentRef.instance.title = title;
    this.componentRef.instance.messages = messages;
    this.componentRef.instance.componentPortal = componentPortal;
    this.componentRef.instance.confirmButtonLabel = confirmButtonLabel;
    this.componentRef.instance.declineButtonLabel = declineButtonLabel;
    this.componentRef.instance.disabled = disabled;
    this.componentRef.instance.flexibility = flexibility;

    this.componentRef.instance.output.subscribe(() => this.closeDialog());

    return this.componentRef.instance.output;
  }

  /**
   * ダイアログを閉じる
   */
  /**
   * Closes dialog
   */
  closeDialog() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
