import { Injectable } from '@angular/core';
import { CheckActionMethods } from 'src/app/shared-main/interfaces/check-action-methods';

@Injectable()
export class AccountPanelService {
  // ログアウト前に行う処理
  // Processing to be performed before changing the property
  beforeLogout: CheckActionMethods[] = [];

  /**
   * コンストラクター関数
   */
  /**
   * Constructor function
   */
  constructor() {}
}
