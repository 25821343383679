import { HttpHeaders } from '@angular/common/http';

export class Response<T = any> {
  // status code
  status: number;
  // response body (json)
  data: T;
  // response header
  headers: HttpHeaders;
  // response body (sub)
  body?: T;

  /**
   * コンストラクタ
   *
   * @param status HTTPステータスコード
   * @param data レスポンスボディ
   * @param headers レスポンスヘッダ
   * @param body レスポンスボディ(sub)
   */
  /**
   * constructor
   *
   * @param status HTTP status code
   * @param data Response body
   * @param headers Response header
   * @param data Response body(sub)
   */
  constructor(status: number, data: T, headers?: HttpHeaders, body?: T) {
    this.status = status;
    this.data = data;
    this.headers = headers;
    this.body = body;
  }
}
