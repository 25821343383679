export interface LanguageListItem {
  localeId: string;
  displayName: string;
}

export class ResetPasswordModel {
  email: string;
  constructor(email?: string) {
    this.email = email;
  }
}

export class ConfirmResetPasswordModel {
  password: string;
  confirmPassword: string;
  code: string;
  email: string;

  constructor(code?: string, password?: string, confirmPassword?: string) {
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.code = code;
  }
}

export class ConfirmationEmailModel {
  password: string;
  email: string;
  localeId: string;

  constructor(email?: string, password?: string, localeId?: string) {
    this.password = password;
    this.email = email;
    this.localeId = localeId;
  }
}

export class ChangeEmailModel {
  hashCode: string = null;
}

export class LanguageModel {
  languages: LanguageListItem[] = [];
  localeId: string = null;
}
