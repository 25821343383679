import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/common/response';
import { RestClient, apiVersion } from '../base/rest-client';
import { EmergencyMailSettingRequest } from '../interfaces/emergency-service';
import { map } from 'rxjs/operators';

const pathOfEmergencyOperation = `emergencyOperation/${apiVersion}/`;

@Injectable()
export class RestClientEmergencyOperationService extends RestClient {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor() {
    super();
  }

  /**
   * メール通知設定API
   * response body: none
   *
   * @param {string} personId 人ID
   * @param {EmergencyMailSettingRequest} mailSetting メール設定
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Mail notification setting API
   * response body: none
   *
   * @param {string} personId Person ID
   * @param {EmergencyMailSettingRequest} mailSetting Mail setting
   * @return {Observable<Response>} status:HTTP status
   */
  postMailSetting(
    personId: string,
    mailSetting: EmergencyMailSettingRequest,
  ): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEmergencyOperation}notification/${personId}`,
      mailSetting,
    );
  }

  /**
   * メール通知状態取得API
   * response body: none
   *
   * @param {string} personId 人ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Mail notification getting API
   * response body: none
   *
   * @param {string} personId Person ID
   * @return {Observable<Response>} status:HTTP status
   */
  getMailSetting(personId): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEmergencyOperation}notification/${personId}`,
    );
  }

  /**
   * 機器異常状態取得API
   * response body: EmergencyGetErrorInfoResponse
   *
   * @param {string} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Equipment error state getting API
   * response body: EmergencyGetErrorInfoResponse
   *
   * @param {string} equipmentId Equipment Id
   * @return {Observable<Response>} status:HTTP status
   */
  getErrorInfo(equipmentId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'get',
      `${this.endPoint}${pathOfEmergencyOperation}errorInfo/${equipmentId}`,
    );
  }

  /**
   * 応急運転可否チェックAPI
   * response body: none
   *
   * @param {string} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Equipment error state getting API
   * response body: none
   *
   * @param {string} equipmentId Equipment Id
   * @return {Observable<Response>} status:HTTP status
   */
  postCheck(equipmentId: string): Observable<Response> {
    const callback = () =>
      this.restClientCommonService.request(
        'post',
        `${this.endPoint}${pathOfEmergencyOperation}check/${equipmentId}`,
      );
    return this.restClientCommonService
      .sqsRequest(callback, { dueTime: 300, periodTime: 1000 })
      .pipe(
        map((response) => {
          if (
            response.data &&
            'statusCode' in response.data &&
            Number(response.data.statusCode) === 204
          ) {
            return new Response(204, undefined);
          }
          return response;
        }),
      );
  }
  /**
   * 応急運転設定API
   * response body: none
   *
   * @param {string} equipmentId 機器ID
   * @return {Observable<Response>} status:HTTPステータス
   */
  /**
   * Equipment error state getting API
   * response body: none
   *
   * @param {string} equipmentId Equipment Id
   * @return {Observable<Response>} status:HTTP status
   */
  postEmergencyOn(equipmentId: string): Observable<Response> {
    return this.restClientCommonService.request(
      'post',
      `${this.endPoint}${pathOfEmergencyOperation}emergencyOn/${equipmentId}`,
    );
  }
}
