import { Component, ElementRef, ViewChild } from '@angular/core';

import { CookieService } from '../services/cookie/cookie.service';
import settingsJson from 'src/settings.json';
@Component({
  selector: 'app-main-login',
  templateUrl: './main-login.component.html',
  styleUrls: ['./main-login.component.scss'],
})
export class MainLoginComponent {
  @ViewChild('cookieBar', { read: ElementRef, static: true }) cookieBar: ElementRef;
  descriptionExist: boolean = settingsJson.descriptionSetting.exist;
  get offsetHeight(): string {
    const clientHeight =
      this.cookieBar.nativeElement.lastElementChild &&
      this.cookieBar.nativeElement.lastElementChild.clientHeight;
    const cookieBarElemHeight = !this.cookieService.getCookieConsentState() ? clientHeight || 0 : 0;

    return `calc(100vh - ${cookieBarElemHeight}px)`;
  }

  /**
   * コンストラクター関数
   * @param cookieService クッキーサービス
   */
  /**
   * Constructor function
   * @param cookieService Cookie Service
   */
  constructor(private cookieService: CookieService) {}
}
