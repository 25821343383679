import { ScreenId } from '../../../../shared-main/enums/screen-id.enum';
export class Package {
  id: string;
  name: string;
  functionStatusList: FunctionStatus[];

  /**
   * コンストラクタ
   *
   * @param id パッケージID
   * @param name パッケージ名
   * @param functionStatusList 機能毎の状態リスト
   */
  /**
   * constructor
   *
   * @param id Package id
   * @param name Package name
   * @param functionStatusList Status list for each function
   */
  constructor(id: string, name: string, functionStatusList: FunctionStatus[] = []) {
    this.id = id;
    this.name = name;
    this.functionStatusList = functionStatusList;
  }
}

export class FunctionStatus {
  mainMenu: Menu;
  subMenu: Menu[];

  /**
   * コンストラクタ
   *
   * @param mainMenu メインメニュー状態
   * @param subMenu サブメニュー状態
   */
  /**
   * constructor
   *
   * @param mainMenu Main menu status
   * @param subMenu Sub menu status
   */
  constructor(mainMenu: Menu, subMenu: Menu[] = []) {
    this.mainMenu = mainMenu;
    this.subMenu = subMenu;
  }
}

export class Menu {
  screenId: ScreenId;
  availability: boolean;

  /**
   * コンストラクタ
   *
   * @param screenId スクリーンID
   * @param availability 有効フラグ
   */
  /**
   * constructor
   *
   * @param screenId Screen ID
   * @param availability Display valid flag
   */
  constructor(screenId: ScreenId, availability: boolean) {
    this.screenId = screenId;
    this.availability = availability;
  }
}
