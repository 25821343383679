import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-password-mail',
  templateUrl: './reset-password-mail.component.html',
  styleUrls: ['./reset-password-mail.component.scss'],
})
export class ResetPasswordMailComponent {
  constructor() {}
}
