<form novalidate class="terms-of-use" [formGroup]="termsOfUseForm">
  <div [innerHtml]="template"></div>
  <div *ngIf="isFirstLogin" class="terms-of-use__check">
    <app-checkbox label="{{ 'sidTermsSentence15_02' | translate }}" formControlName="check">
    </app-checkbox>
  </div>
  <div *ngIf="isFirstLogin" class="terms-of-use__button">
    <app-button [disabled]="termsOfUseForm.invalid" (generalButtonClick)="onAccept()">
      {{ 'sidAccept' | translate }}
    </app-button>
  </div>
</form>