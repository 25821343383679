import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { MultiLanguageMessageService } from 'src/app/core/services/multi-language-message/multi-language-message.service';

import { ErrorState } from '../enums';

@Component({
  selector: 'app-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss'],
})
export class GeneralErrorComponent implements OnInit {
  errorMessage: string;

  /**
   * コンストラクター関数
   * @param router Angular ルーター
   * @param loaderService ローディングアニメーションサービス
   * @param multiLanguageMessageService 多言語対応サービス
   */
  /**
   * Constructor function
   * @param router Angular Router
   * @param loaderService Loading animation service
   * @param multiLanguageMessageService Multilingual service
   */
  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private multiLanguageMessageService: MultiLanguageMessageService,
  ) {}

  /**
   * Angular Life Cycle Method
   */
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.loaderService.forcedReset();

      // 発生したエラーに応じて画面に表示する文言を切り替える
      // Switch the wording displayed on the screen according to the error that occurred
      switch (params.get('state') as ErrorState) {
        case ErrorState.Maintenance:
          this.errorMessage = this.multiLanguageMessageService.dictionary(
            'sidWebsiteDownMaintenance',
          );
          break;
        case ErrorState.SystemError:
          this.errorMessage = this.multiLanguageMessageService.dictionary('sidPageNotFound');
          break;
        case ErrorState.CookiesDisabled:
          this.errorMessage =
            this.multiLanguageMessageService.dictionary('sidNeedPermissionCookie');
          break;
      }
    });
  }
}
