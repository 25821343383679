export const environment = {
  production: false,
  backend: 'https://sds.multistoredev1.gpf-dit.com/',
  cognito: {
    region: 'Singapore',
    userPoolId: 'ap-southeast-1_FAtAOuqcD',
    userPoolWebClientId: '1s7188oia9kdquqgkp2foa85fn',
  },
  googleMapApiKey: 'AIzaSyAWGzHI_bvQUM7CVvU-0HZRR9sW7N2KYx8',
  googleMapVersion: 'quarterly',
};
